.dashboard-wrap,
#goals.dashboard-wrap,
#add-meeting.dashboard-wrap {
    flex: 1;
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    width: 100%;
    padding-bottom: 50px;
    flex-direction: column;
    padding-top: 0 !important;

    @media all and (max-width: 1300px) {
        width: 100vw;
        padding: 0 30px;
    }

    @media all and (max-width: 800px) {
        width: 100vw;
        padding: 0 20px;
    }

    .dashboard-heading {
        padding: 0;
        text-align: center;
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;

        .dashboard-title {
            width: 100%;
            text-align: center;
            padding: 0;

            @media all and (max-width: 500px) {
                text-align: left;
            }
        }

        .toggle {
            display: flex;
            align-items: center;

            .label {
                font-size: $font10;
                color: $lightColour;
                font-weight: bold;
                text-transform: uppercase;

                &.left {
                    text-align: right;
                    margin-right: 10px;
                }

                &.right {
                    text-align: left;
                    margin-left: 10px;
                }
            }


        }

        .spacer {
            display: block;
            min-width: 120px;

            @media all and (max-width: 500px) {
                min-width: 0;
            }
        }

    }

    

    &#goals,
    &#add-meeting {
        flex-direction: row;
        padding-top: 50px !important;
    }

    .dashboard-content {
        display: flex;
        justify-content: space-between;
        flex-direction: column;
        width: 100%;

        &.layout-reverse {
            flex-direction: column-reverse;
        }

        h2 {
            font-size: 1.3em;
            margin-bottom: 10px;
            text-align: center; 
        }

        .section-tagline {
            font-size: $font14;
            //font-style: italic;
            margin-top: 0;
            margin-bottom: 10px;
            text-align: center;
            min-height: 40px;
        }

        .top-section {
            margin-bottom: 40px;

            @media all and (max-width: 800px) {
                margin-bottom: 0;
            }

            .about-me {
                width: 100%;
                margin-top: 0;

                .quiz-section-wrap {
                    display: flex;
                    flex-wrap: wrap;
                    justify-content: center;

                    @media all and (max-width: 800px) {
                        padding-top: 30px;
                    }
                }
            }
        }

        .bottom-section {
            display: flex;
            justify-content: space-between;
            margin-bottom: 40px;

            @media all and (max-width: 800px) {
                flex-direction: column;
                margin-bottom: 0;
            }

            .goals-progress, .support-network {
                width: 50%;
            }

            .goals-progress {
                margin-right: 50px;
            }

            .support-network {
                margin-left: 50px;
            }

            @media all and (max-width: 800px) {
                .goals-progress, .support-network {
                    width: 100%;
                    margin: 0 0 50px;
                }
            }

        }

    }

    &.team-member {
        flex-direction: column;
        padding-top: 50px !important;
    }

    @media all and (max-width: 768px) {
        flex-direction: column;
        align-items: center;
    }

    .youth-switcher {
        position: absolute;
        top: 120px;
        right: 50px;
    }

    .young-person {
        width: 100%;
        flex-direction: row;
        //display: flex;
        justify-content: space-between;
        align-items: flex-start;
        display: none;

        &.visible {
            display: flex;
        }
    }

    .about-me,
    .goals-progress,
    .support-network {
        width: 30%;

        @media all and (max-width: 768px) {
            width: 80%;
            margin: 50px 0;
        }
    }

    .about-me {

        .quiz-section-wrap {
            //margin-top: 40px;
        }

        .quiz-complete {
            margin-top: 15px;
            display: flex;
            justify-content: center;

            .butt-results {
                flex-direction: row-reverse;
                margin-left: 0;
                width: auto;

                .title {
                    margin-left: 10px;
                }

                .results {
                    background-image: url(../images/icons/icon-results-blue.svg);
                }
            }
        }


        .quiz-progress {
            width: 100%;
            height: 42px;
            background: $lightColour;
            border-radius: 50px;
            margin-top: 15px;
            position: relative;
            padding: 0 4px 0 20px;

            .progress-back {
                background: $bgColourFourteen;
                height: 6px;
                position: absolute;
                width: calc(100% - 24px);
                top: 0;
                bottom: 0;
                margin: auto;
                border-radius: 20px;
            }

            .progress-dots {
                width: 100%;
                height: 100%;
                display: flex;
                justify-content: space-between;
                align-items: center;

                .dot {
                    width: 16px;
                    height: 16px;
                    border-radius: 40px;
                    z-index: 1;

                    &.career {
                        background: #D4E9F7;

                        &.completed {
                            background: $bgColourEight;
                        }
                    }

                    &.strengths {
                        background: $bgColourFive;

                        &.completed {
                            background: $bgColourThree;
                        }
                    }

                    &.work {
                        background: #F8E9B9;

                        &.completed {
                            background: #F3C762;
                        }
                    }

                    &.training {
                        background: $bgColourNine;

                        &.completed {
                            background: #86CC88;
                        }
                    }

                    &.life {
                        background: #F7DADA;

                        &.completed {
                            background: #EE9A95;
                        }
                    }

                    &.results {
                        background-color: #D4E9F7;
                        background-image: url(../images/icons/icon-results.svg);
                        background-repeat: no-repeat;
                        background-position: center center;
                        width: 34px;
                        height: 34px;
                    }
                }
            }
        }

    }

    .goals-progress {

        .add-goal-button {
            display: flex;
            justify-content: center;
            margin: 30px 0 0 0;

            .butt-general {
                flex-direction: row-reverse;
                margin-left: 0;

                .butt-icon {
                    &.add-goal {
                        background-image: url(../images/icons/icon-add-purple.svg);
                    }
                }
            }
        }

        .goals-wrap {
            //margin-top: 40px;
            background: $lightColour;
            border-radius: 40px;
            padding: 20px;

            .small {
                color: $bgColourFour;
                font-size: 0.8em;
                font-style: italic;
                text-align: center;
            }

            .goal {
                background: $buttBgColourYellow;//$lightColour;
                border-radius: 40px;
                padding: 10px;
                display: block;
                cursor: pointer;
                text-decoration: none;
                margin: 0 0 15px;

                .progress {
                    width: 100%;
                    height: 16px;
                    background: $bgColourFive;
                    border-radius: 16px;
                    padding: 0 5px;

                    .progress-fill {
                        background: #CAC2EC;
                        height: 4px;
                        position: absolute;
                        width: calc(100% - 10px);
                        top: 0;
                        bottom: 0;
                        margin: auto;
                        border-radius: 20px;
                        width: 0%; // dynamic
                    }

                    .progress-dots {
                        width: 100%;
                        height: 100%;
                        display: flex;
                        justify-content: space-between;
                        align-items: center;

                        .dot {
                            width: 8px;
                            height: 8px;
                            border-radius: 20px;
                            background: #CAC2EC;

                            &.completed {
                                background: $bgColourFour;
                            }
                        }
                    }
                }

                .details {
                    display: flex;
                    align-items: center;
                    //margin-top: 15px;

                    .icon {
                        width: 34px;
                        height: 34px;
                        min-width: 34px;
                        border-radius: 50px;
                        background: $buttBgColourYellow url(../images/icons/icon-goal.svg) no-repeat;
                        background-size: 76%;
                        background-position: 53% 53%;
                    }

                    .title {
                        color: $fontColourDark;
                        font-weight: 600;
                        font-size: 0.85em;
                        padding-left: 10px;
                    }
                }

                &.disabled .details {
                    opacity: 0.5;
                }

                &.active {
                    .details {
                        opacity: 1;
                    }
                }

            }

        }

    }

    .support-network {

        .support-wrap {
            background: $lightColour;
            border-radius: 40px;
            box-shadow: 2px 2px 40px 1px rgba(36, 58, 71, 0.25);
            color: $fontColourDark;
            padding: 20px;

            &:last-child {
                margin-top: 15px;
            }

            h3 {
                font-size: $font18;
                color: $fontColourDark;
                margin: 0;
                margin-bottom: 20px;

                &.slim {
                    font-weight: 300;
                }
            }

            .small {
                color: $bgColourFour;
                font-size: 0.8em;
                font-style: italic;
            }

            .meeting-wrap {
                text-align: center;

                .butt-general {
                    margin: 0 auto;
                    margin-top: 10px;
                }
            }

            .meeting-small-info {
                display: flex;
                color: $bgColourFour;
                font-size: 0.8em;
                align-items: center;
                margin: 5px 0;

                .meeting-icon {
                    width: 34px;
                    height: 34px;
                    border-radius: 40px;
                    border: 2px solid $bgColourFour;
                    margin-right: 10px;

                    &.date {
                        background: url(../images/icons/icon-addcal-purple.svg) center center no-repeat;
                    }

                    &.time {
                        background: url(../images/icons/icon-time.svg) center center no-repeat;
                    }

                    &.address {
                        background: url(../images/icons/icon-location.svg) center center no-repeat;
                    }
                }

                .butt-save-to-calendar {
                    display: block;
                    background: $bgColourFive;
                    padding: 5px 10px;
                    border-radius: 20px;
                    text-decoration: none;
                    margin: 10px 0;

                    &:hover {
                        cursor: pointer;
                        animation-duration: 0.4s;
                        animation-fill-mode: both;
                        animation-name: pulse;
                        color: $bgColourSeven;
                    }
                }
            }


            .team-wrap {
                text-align: center;
                

                h2 {
                    text-align: left;
                }

                .team {
                    display: flex;
                    //justify-content: space-between;
                    justify-content: center;
                    align-items: center;
                    margin: 20px 0;

                    .me {
                        height: 72px;
                        max-width: 95px;
                        flex: 1;
                        border: 1px solid $bgColourFourteen;
                        border-radius: 24px;
                        min-width: 60px;
                        overflow: hidden;
                        background-image: url(../images/icons/icon-default-profile.svg);
                        background-position: center center;
                        background-size: 50%;
                        background-repeat: no-repeat;

                        &.has-avatar {
                            background-image: none;
                        }

                        img {
                            width: 100%;
                            object-fit: cover;
                            height: 100%;
                        }

                        @media all and (max-width: 1024px) and (min-width: 769px) {
                            height: 60px;
                            width: 60px;
                            max-width: 60px;
                        }

                    }

                    .member {
                        width: 34px;
                        height: 34px;
                        border: 1px solid $bgColourFourteen;
                        border-radius: 40px;
                        margin-left: 5px;
                        font-size: 0.8em;
                        color: $fontColourLight;
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        font-weight: 600;

                        &.filled {
                            border: none;

                            &:nth-child(1) {
                                background: #468449;
                            }

                            &:nth-child(2) {
                                background: $bgColourEight;
                            }

                            &:nth-child(3) {
                                background: #EE9A95;
                            }

                            &:nth-child(4) {
                                background: #F3C762;
                            }

                            &:nth-child(5) {
                                background: #468449;
                            }
                        }



                        @media all and (max-width: 1024px) and (min-width: 769px) {
                            width: 24px;
                            height: 24px;
                        }
                    }

                }

                .butt-add-team {
                    margin: 0;
                }

                .butt-general {
                    margin: 0 auto;
                    margin-top: 10px;
                }
            }

        }

    }

    .complete-about {
        text-align: center;
        font-style: italic;
        background: rgba(255,255,255,0.45);// url(../images/icons/icon-goal.svg) center 20px no-repeat;
        border-radius: 20px;
        padding: 20px;
        font-size: $font14;
    }

    .goals-list-column,
    .task-list-column,
    .main-goal-column {
        @media all and (max-width: 768px) {
            width: 80% !important;
            margin: 50px 0;
        }

        h1,
        h2 {
            color: $fontColourDark;
        }

        p {
            color: black;
        }

        .butt-wrap {
            width: 100%;
            display: flex;

            button.butt-edit {
                margin: 10px auto;
                width: 200px;
            }

            button.butt-save-team {
                margin: 10px auto;
                width: 200px;
            }
        }
    }

    .goals-creator-container {
        flex: 1;
        flex-direction: column;
        display: flex;
        width: 100%;
    }

    .goals-list-column {
        width: 30%;
        max-width: 320px;

        .light-bar {
            width: calc(100% - 46px) !important;
        }

        .header-wrap {
            display: flex;
            align-items: center;

            .butt-help {
                width: 25px;
                height: 25px;
                background: white;
                border-radius: 30px;
                display: flex;
                justify-content: center;
                align-items: center;
                font-weight: bold;
                color: $fontColourDark;
                margin-left: 10px;
                text-decoration: none;
            }
        }

        .goal-wrapper {
            width: 100%;
            display: flex;
            justify-content: center;
            align-items: center;
            position: relative;
            flex-direction: column;

            .butt-goal,
            .butt-add-goal {
                width: 100%;
                height: 74px;
                margin: 5px 0;
                flex-direction: row-reverse;
                padding: 0 10px;

                .butt-icon {
                    width: 54px;
                    height: 54px;
                    background-color: $buttBgColourYellow;
                }

                .title {
                    color: $fontColourDark;
                    font-size: 1.2em;
                    text-transform: unset;
                    text-align: left;
                    padding-left: 10px;
                    text-transform: initial;
                }
            }

            .suggested-goal-dropdown {
                width: 100%;
                background: white;
                border-radius: 40px;
                margin-top: -5px;
                margin-top: -80px;
                padding-top: 80px;
                color: $fontColourDark;
                height: 0;
                opacity: 0;
                transition: all 0.4s;
                overflow: hidden;
                pointer-events: none;
                display: flex;
                flex-direction: column;
                justify-content: flex-start;
                align-items: center;
                padding: 80px 20px 0 20px;
                overflow: hidden;

                &.active {
                    height: 400px;
                    opacity: 1;
                }

                .suggested-goal {
                    height: 44px;
                    display: flex;
                    align-items: center;
                    border-radius: 50px;
                    pointer-events: initial;
                    cursor: pointer;
                    text-align: left;
                    width: 100%;
                    -webkit-appearance: none;
                    border: none;
                    margin-bottom: 10px;
                    font-family: 'Open Sans', sans-serif;

                    .title {
                        padding: 0 20px;
                    }

                    &.selected,
                    &:hover {
                        background: #F8E9B9;
                    }
                }

                .custom-goal {
                    width: 100%;

                    input {
                        pointer-events: initial;
                        height: 44px;
                        background: $bgColourFourteen;
                        border-radius: 44px;
                        border: none;
                        padding: 0 20px;
                        font-size: $font16;
                        font-family: 'Open Sans', sans-serif;
                        width: 100%;
                        margin-top: 10px;
                        outline: none;
                        color: $fontColourDark;
                    }

                }

                .butt-general {
                    pointer-events: initial;
                    background: $bgColourThree;
                    margin: 20px 0;
                    color: $fontColourLight;

                    .butt-icon {
                        background-color: white;
                    }
                }
            }

            .butt-add-goal {
                background: rgba(255, 255, 255, 0.5);
                z-index: 4;
            }

            .butt-groups {
                height: 90%;
                display: flex;
                flex-direction: column;
                justify-content: space-between;
                position: absolute;
                right: -44px;
                opacity: 0;
                visibility: hidden;
                transform: scale(0);
                transition: transform 0.2s, opacity 0.2s;

                .butt-icon {
                    z-index: 1;
                    width: 34px;
                    height: 34px;
                    background-size: 80%;
                    background-color: rgba(255, 255, 255, 0.45);
                    border-radius: $buttHeight - 10px;
                    background-position: center center;
                    background-repeat: no-repeat;

                    &.add-team {
                        background-image: url(../images/icons/icon-team-transparent.svg);
                        // background-color: $bgColourEight;
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        font-weight: 600;
                        //font-size: 0.8em;
                        position: relative;
                    }

                    &.add-calendar {
                        background-image: url(../images/icons/icon-addcal-purple.svg);
                        background-size: 100%;
                    }
                }
            }

            &.active {

                .butt-goal {
                    background: $buttBgColourYellow;
                    border: 5px solid white;
                }

                .butt-groups {
                    opacity: 1;
                    visibility: visible;
                    transform: scale(1);
                }

            }
        }

        .goals-wrap {
            width: 100%;
            margin-top: 15px;
            height: 74px;
            background: rgba(255, 255, 255, 1);
            border-radius: 42px;
            border: none;
            outline: none;

            a {
                padding: 0 10px 0 4px;
            }

            .goals-creator-wrap {
                height: 64px;
                padding: 5px;
                font-size: $font18;
                text-transform: unset;

                border-radius: 42px;
                border: none;
                outline: none;
            }

            .title-wrap {
                flex: 1;
                display: flex;
                flex-direction: column;

                .body {
                    color: $fontColourPurple;
                    font-weight: 600;
                    font-size: 0.65em;
                    padding-left: 10px;
                    padding-top: 5px;
                }
            }

            .title {
                color: $fontColourDark;
                font-weight: 600;
                font-size: 0.85em;
                padding-left: 10px;
            }

            .edit,
            .close-yellow {
                height: 40px;
                width: 40px;
                margin: 0 5px;
                background-color: rgba(255, 255, 255, 0.75);
            }
        }
    }

    .task-list-column {
        width: 40%;
        margin-top: 40px;
        background-color: $lightColour;
        border-radius: 40px;
        box-shadow: 2px 2px 40px 1px rgba(36, 58, 71, 0.25);
        color: $fontColourDark;
        padding: 40px;

        .form-actions {
            text-align: center;

            input[type="submit"] {
                background: $bgColourFour;
                text-align: center;
                margin: 0;
                justify-content: center;
                color: white;
                margin-top: 10px;
            }
        }

        ul {
            list-style-type: none;
            padding: 0;

            li {
                flex: 1;
                display: flex;
                flex-direction: row;
                align-items: center;

                .task-wrap {
                    background-color: #fdf7e4;
                    height: 55px;
                    width: 100%;
                    border-radius: 50px;
                    padding: 10px;

                    flex: 1;
                    display: flex;
                    flex-direction: row;
                    align-items: center;

                    .circle {
                        height: 36px;
                        width: 36px;
                        border-radius: 36px;
                        border: $buttBgColourDarkYellow 2px solid;

                        &.butt-icon.edit {
                            background-image: url(../images/icons/icon-edit.svg);
                            background-size: 100%;
                            background-position: 55% 50%;
                        }
                    }

                    p {
                        width: calc(100% - 76px);
                        padding: 0 10px;
                    }

                }

                .butt-icon {
                    &.add-team {
                        background-image: url(../images/icons/icon-team-transparent.svg);
                        margin: 5px 0 5px 10px;
                        background-color: $buttBgColourLightPurple;
                        background-size: 80%;
                        height: 36px;
                        width: 36px;
                        border-radius: $buttHeight - 10px;
                        z-index: 1;
                        background-position: center center;
                        background-repeat: no-repeat;
                    }
                }
            }
        }

        .form-item-field-dream {

            label {
                position: relative;
                left: 0;
                width: 100%;
                display: block;
                margin-bottom: 10px;
            }

            textarea {
                width: 100%;
                background: $bgColourFive;
                border: none;
                border-radius: 10px;
                outline: none;
                padding: 20px;
                font-size: $font16;
                font-family: 'Open Sans', sans-serif;
                color: $fontColourDark;
            }

        }
    }

    .main-goal-column {
        width: 20%;
        margin-top: 40px;

        .goal-content-box {
            width: 100%;
            margin: 0 0 15px !important;
            height: 100%;
            background: rgba(255, 255, 255, 1);
            border-radius: 42px;
            border: none;
            outline: none;
            padding: 15px 30px;

            h2 {
                word-wrap: break-word;
            }

            .small {
                color: $bgColourFour;
                font-size: 0.8em;
                font-style: italic;
            }
        }

        img {
            width: 100%;
            margin: 15px 0;
            border-radius: 42px;
            border: none;
            outline: none;
        }
    }

    .my-team,
    .support-network-meeting {
        margin: 10px;

        @media all and (max-width: 768px) {
            width: 80%;
            margin: 50px 0;
        }
    }

    .my-team {
        width: 30%;
        flex: 1;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;

        .team-member {
            width: 100%;
            margin: 15px 0;
            height: 74px;
            background: rgba(255, 255, 255, 1);
            padding: 5px;
            border-radius: 42px;
            border: none;
            outline: none;

            .title-wrap {
                padding: 0 15px;
                flex: 1;
                display: flex;
                flex-direction: column;

                .body {
                    color: black;
                    font-weight: normal;
                    font-size: 0.8em;
                    padding-top: 5px;
                }
            }

            .name-avatar {
                display: inline-block;
                background-color: $buttColour;
                height: 55px;
                width: 55px;
                border-radius: 45px;
                z-index: 1;
                background-position: center center;
                background-repeat: no-repeat;
                background-size: 60%;
                text-align: center;

                span {
                    color: white;
                    line-height: 55px;
                }
            }

        }

        .butt-add-meeting {
            margin: 15px 0;
        }
    }

    .support-network-meeting {
        width: 70%;
        margin-top: 15px;
        height: 100%;
        background: rgba(255, 255, 255, 1);
        border-radius: 42px;
        border: none;
        outline: none;
        padding: 15px 30px;

        h3 {
            color: $fontColourDark;
        }

        p {
            color: black;
        }

        .title {
            font-weight: normal;
            padding: 0 10px;
        }

        .butt-guide {
            width: 100%;
            margin: 10px 0;

            .title {
                text-align: left;
                color: black;
                margin: auto;
            }

            .thick-text {
                font-weight: bold;
            }
        }

        .support-network-meeting-content {
            flex: 1;
            display: flex;
            flex-direction: row;
            justify-content: space-between;

            @media all and (max-width: 1024px) {
                flex-direction: column;
            }

            .calendar-wrap {
                width: 45%;
                height: 100%;

                @media all and (max-width: 1024px) {
                    width: 100%;
                }

                .ui-datepicker {
                    height: 100%;
                    border-radius: 25px;
                }

                .ui-widget-header {
                    border: 0px;
                    background-color: transparent;
                }

                .ui-widget.ui-widget-content {
                    border: 1px solid lightgrey;
                }

                .ui-datepicker th {
                    text-transform: uppercase;
                    font-size: 8pt;
                    font-weight: normal;
                    color: $fontColourPurple;
                    /*text-shadow: 1px 0px 0px #fff;*/
                    /*filter: dropshadow(color=#fff, offx=1, offy=0);*/
                }

                .ui-datepicker tbody {
                    border-top: 1px $fontColourPurple solid;
                    border-bottom: 1px $fontColourPurple solid;
                }

                .ui-datepicker tbody td {
                    padding: 0;

                    a {
                        height: 37px;
                        width: 37px;
                    }
                }

                .ui-widget-content {
                    background: transparent;
                    border: 0px;
                    text-align: center;
                    padding: 15px 10px;
                    width: 80%;
                }

                .ui-datepicker-calendar .ui-state-active {
                    height: 37px;
                    text-align: center;
                    line-height: 37px;
                    padding: 0;
                    border: 0;
                }

                .ui-datepicker-calendar .ui-state-default {
                    background: transparent;
                    border: 0px;
                    padding: 0;
                    text-align: center;
                    line-height: 37px;
                }

                .ui-datepicker-calendar .ui-state-hover {
                    opacity: 0.6;
                }

                .ui-datepicker-today>a {
                    color: $fontColourPurple;
                }

                .ui-datepicker-current-day {
                    font-weight: bold;
                    color: $fontColourDark;
                    border-radius: 50%;
                    background: $fontColourPurple !important;

                    a {
                        color: $fontColourLight !important;
                    }
                }

                .ui-widget-header .ui-icon {
                    background-image: url(../images/icons/icon-arrow-next-black.svg);
                    background-size: 35%;
                    background-position: 58% 50%;
                }

                .ui-datepicker .ui-datepicker-prev {
                    transform: rotate(180deg);
                }
            }

            .guide-wrap {
                width: 55%;

                @media all and (max-width: 1024px) {
                    width: 100%;
                }

                .butt-icon {
                    background-color: transparent;
                    border: 1px solid $buttBgColourDarkYellow;
                }
            }
        }

        .meeting-info {
            flex: 1;
            display: flex;
            flex-direction: row;
            justify-content: space-between;

            .meeting-desc {
                text-align: left;
            }

            .meeting-time,
            .meeting-address {
                display: flex;
                height: auto;

                .meeting-desc {
                    height: auto;
                    color: $bgColourThree;
                    margin: 2.5px 0;
                    width: 80%;
                }

                .butt-icon {
                    width: 30px;
                    height: 30px;
                    border-radius: 30px;

                    &.clock-purple {
                        background-image: url(../images/icons/icon-clock-purple.svg);
                        background-size: 100%;
                    }

                    &.location-purple {
                        background-image: url(../images/icons/icon-location-purple.svg);
                        background-size: 100%;
                    }
                }
            }

            .meeting-time {
                width: 20%;
            }

            .meeting-address {
                width: 40%;
            }
        }
    }
}

.image-widget {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 20px;

    img {
        border-radius: 20px;
        width: 70px;
        height: 70px;
    }

    span {
        padding: 0 10px;

        a {
            max-width: 100px;
            overflow: hidden;
            display: block;
            height: 70px;
        }
    }

    .file-size {
        display: none;
    }

    input[name="field_photo_remove_button"] {
        text-align: center;
        justify-content: center;
        margin: 0;
        padding: 0;
        width: 100px;
    }
}

#goals.dashboard-wrap {
    @media all and (max-width: 1300px) {
        width: 100vw;
        padding: 50px;
    }
}

.node-goal-form,
.node-goal-edit-form {
    width: 100%;
}

.zzdashboard-wrap {
    display: flex;

    @media all and (max-width: 1300px) {
        width: 100vw;
        padding: 0 30px;
    }

    @media all and (max-width: 800px) {
        width: 100vw;
        padding: 0 20px;
        flex-direction: column;
    }

    .header-wrap {
        display: flex;
        align-items: center;

        h1 {
            margin: 20px 10px 20px 0;

            @media all and (max-width: 800px) {
                display: none; 

                &.team-member {
                    display: block;
                    flex: 1;
                    text-align: center;
                }
            }

        }

        @media all and (max-width: 800px) {
            justify-content: space-between;
            padding-top: 20px;
        }

        .butt-dashboard {
            display: none;

            @media all and (max-width: 800px) {
                display: flex;
                width: 42px;
                padding-left: 5px;

                .butt-icon {
                    min-width: 32px;
                }
                .title { 
                    display: none;
                }
            }



        }

        .butt-help,
        .butt-add-goal {
            height: 25px;
            min-width: 110px;
            border-radius: 30px;
            display: flex;
            justify-content: center;
            align-items: center;
            font-weight: bold;
            color: $fontColourDark;
            margin-left: 10px;
            text-decoration: none;
            margin-top: 5px;
            transition: all 0.2s;
            animation: none;
            padding: 10px;
            font-size: 12px;
            text-transform: uppercase;
            background: $buttBgColourYellow;

            &:hover {
                transform: scale(1.1);
            }

            @media all and (max-width: 800px) {
                display: none;
            }
        }
    }

    .goals-creator-container {

        &.team-member {
            details {
                border-radius: 20px;
                background: $lightColour;
                padding: 0 20px;
    
                summary {
                    padding: 10px 0;
                    font-family: 'Open Sans', sans-serif;
                    font-weight: bold;
                    display: revert;
                    
                    &::marker {
                        margin-right: 10px;
                    }
                }
    
                .butt-goal {
                    border: none;
                }
    
                .goal-wrapper {
                    &:last-child {
                        padding-bottom: 20px;
                    }
                }
    
            }
            
        }

        @media all and (max-width: 800px) {
            display: none;

            &.team-member {
                display: block;
                margin: 40px 0;

                
            }
        }

        

    }

    .template-selection {
        padding-top: 10px;
    }

    .zzgoals-list-column {
        margin-right: 65px;
        width: 30%;
        max-width: 340px;
        min-width: 340px;

        h2 {
            margin-bottom: 15px;
            margin-top: 30px;
            font-size: $font14;
        }

        @media all and (max-width: 800px) {
            width: 100%;
            max-width: 100%;
        }
    }

    .goal-wrapper {
        margin-bottom: 10px;

        .butt-icon {
            order: -1;
        }

        .title {

            // Use flex to vertically centre single line text
            // https://css-tricks.com/centering-css-complete-guide/
            display: flex;
            flex-direction: column;
            justify-content: center;

            .title_inner {
                // Wrap text within two lines
                // https://stackoverflow.com/a/11989697/5565611
                text-align: left;
                margin-left: 10px;
                line-height: 1.3em;
                // max-height is key to making this work for keeping single lines
                // centred.
                max-height: 2.6em;
                overflow: hidden;
            }
        }

        .butt-goal {
            height: 50px;
            border: 5px solid $lightColour;
        }

        &.active {

            .butt-goal {
                background: $buttBgColourYellow;
                color: $fontColourDark;

                .butt-icon {
                    background-color: $buttBgColourYellow;
                }
            }

            .butt-groups {
                opacity: 1;
                visibility: visible;
                transform: scale(1);
            }

        }
    }

    .butt-general {
        width: 100%;
        margin: 0;
        text-transform: none;
    }



    // .butt-category {
    //     flex-direction: column;
    //     //align-items: center;

    //     .small {font-size: 70%;}
    // }

    .zztask-list-column {


        .goal-info {
            margin: 50px 0 30px 0;
            text-align: center;

            @media all and (max-width: 800px) {
                margin-top: 0;
            }
        }

        .goal-definition-wrap {
            margin-top: 50px;
            background: $lightColour;
            border-radius: 50px;
            padding: 20px 40px;
            box-shadow: 2px 2px 40px 1px rgba(36,58,71,0.25);

            @media all and (max-width: 800px) {
                padding: 20px;
            }

            h3 {
                text-align: center;
            }

            .goal-field {
                padding-bottom: 20px;
                display: flex;

                h4 {
                    width: 100px;
                    margin: 0;
                    font-size: 25px;
                    margin-top: 30px;
                    color: $buttColour;
                    text-align: right;
                    padding-right: 20px;

                    @media all and (max-width: 800px) {
                        font-size: 18px;
                        width: 75px;
                        padding: 5px 10px 0 0;
                    }
                }

                .fields {
                    flex: 1;
                }


            }

            .written-goal {
                h4 {
                    text-align: center;
                    font-size: 1.17em;
                    color: $fontColourDark;
                }
            }
        }

        .goal-actions-wrap {
            margin-top: 50px;
            background: $lightColour;
            border-radius: 50px;
            padding: 20px 40px;
            box-shadow: 2px 2px 40px 1px rgba(36,58,71,0.25);

            h4 {
                font-size: 1.17em;
            }

            p {
                font-weight: normal;
                margin-bottom: 40px;
            }

            @media all and (max-width: 800px) {
                padding: 20px;
            }

            .field-multiple-drag {
                padding: 0;
            }

            td {
                padding: 0;
            }

            // Actions
            .paragraphs-subform {
                align-items: center;

                >div {
                    flex: 1;
                }

                >div:nth-child(2) {
                    flex: 0;
                }

                .field--widget-boolean-checkbox {
                    order: -1;
                }

                .js-form-type-textfield {
                    label {
                        display: none;
                    }
                }
            }
        }

        .goal-notify-wrap {
            margin: 50px;
            text-align: center;

            @media all and (max-width: 800px) {
                margin: 50px 0;
            }

            .js-form-type-checkbox {
                justify-content: flex-start;
                text-align: left;
                margin: 0 auto;
                width: fit-content;

                label {
                    display: block!important;
                    font-weight: normal;
                }
            }
        }

        .goal-status-wrap {
            margin: 50px 0;
            background: $lightColour;
            text-align: center;
            border-radius: 50px;
            padding: 20px 40px;
            box-shadow: 2px 2px 40px 1px rgba(36,58,71,0.25);

            #edit-feedback {
                margin-top: 50px;
            }
        }

        .form-actions {
            text-align: center;
            margin-bottom: 50px;

            .butt-general {
                text-transform: uppercase;
                width: 180px;
            }

            .butt-dashboard {
                position: absolute;
                left: 30px;

                @media all and (max-width: 800px) {
                    left: 20px;
                    width: 42px;

                    .title {
                        display: none;
                    }

                    .butt-icon {
                        min-width: 32px;
                    }
                }
            }
        }
        

        // Notify team members checkbox
        .form-item-field-notify-team-members-value {
            display: flex;
        }

        // Who? description
        #edit-field-assigned-to--description {
            font-size: .8em;
            text-align: right;

            @media all and (max-width: 800px) {
                padding-top: 10px;
            }
        }
    }

}

.confirm-remove-user-form {
    text-align: center;
    
    @media all and (max-width: 600px) {
        width: 80%;
    }

    .form-actions {
        display: flex;
        align-items: center;
        justify-content: center;

        @media all and (max-width: 600px) {
            flex-direction: column-reverse;
        }
    }
    
    .butt-general {
        margin: 20px;

        &[name="support-remove-user-back"] {
            .butt-icon {
                transform: rotate(180deg);
            }
        }

        &[name="support-remove-user-remove"] {
            background: #dc3830;
            color: $fontColourLight;

            .butt-icon {
                background: #dc3830 url(../images/icons/icon-close-light.svg);
                background-position: 58% 50%;
                background-repeat: no-repeat;
            }
        }

    }
}