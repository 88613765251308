.job-wrap {
    width: 1200px;
    //padding: 0 20px;
    padding-top: 50px;
    padding-bottom: 50px;

    @media all and (max-width: 1300px) {
        width: 100%;
        padding: 0 30px;
    }

    @media all and (max-width: 800px) {
        max-width: 100%;
        padding: 30px 20px;
        overflow: hidden;
    }

    .title-section {
        display: flex;
        flex: 1;
        flex-direction: row;

        .side-back {
            width: 180px;

            .butt-general {
                margin: 0;
            }
        }

        .main-title {
            width: calc(100% - 360px);
        }

        @media all and (max-width: 800px) {
            flex-direction: column;

            .main-title {
                width: 100%;
                margin-top: 30px;
            }
        }
    }

    h1 {
        text-align: center;
    }

    .details-wrap {
        color: $fontColourDark;
        width: 84%;
        margin: 0 auto;
        padding: 40px 0 0;

        p {
            margin: 0;
        }

        @media all and (max-width: 1300px) {
            width: 80%;
        }
    }

    .result-options-wrap {
        // overflow: hidden;
        padding: 50px 0 20px 0;

            .result-content {
                flex: 1;
                max-width: 1200px;
                display: flex;
                flex-direction: column;

                // result career
                .outer-wrap {
                    padding: 0 !important;
                }

                .two-columns {
                    @media screen and (max-width: 768px) {
                        flex-direction: column !important;

                        .left-content, .right-content {
                            width: 100% !important;
                        }
                    }
                }

                .content-wrap {
                    flex: 1;
                    display: flex;
                    flex-wrap: wrap;
                    flex-direction: row;
                    justify-content: center;
                    height: 100%;

                    ul {
                        color: $fontColourDark;
                        margin: 10px 0 10px;
                        //line-height: 2.2em;
                        padding: 0 25px;
                        list-style: none;

                        li {
                            font-size: 0.9em;
                            padding-bottom: 8px;
                        }

                        li::before {
                            content: "\2022";
                            font-weight: 900;
                            display: inline-block;
                            width: $font16;
                            margin-left: -$font16;
                        }

                        &.list-node-yellow {
                            li::before {
                                color: $listDotColourYellow;
                            }
                        }

                        &.list-node-pink {
                            li::before {
                                color: $listDotColourPink;
                            }
                        }

                        &.list-node-green {
                            li::before {
                                color: $listDotColourGreen;
                            }
                        }

                        &.list-node-blue {
                            li::before {
                                color: $listDotColourBlue;
                            }
                        }

                    }

                    // title bar of a block box in different Colors
                    .title-bar-pink {
                        background-color: $bgColourDarkPink;
                    }

                    .title-bar-yellow {
                        background-color: $bgColourDarkYellow;
                    }

                    .title-sub-bar-yellow {
                        background-color: $bgColourLightYellow;
                    }

                    .title-bar-green {
                        background-color: $bgColourDarkGreen;
                    }

                    .title-sub-bar-green {
                        background-color: $bgColourLightGreen;
                    }

                    // result life-skills, result-training, work-preferences
                    .result {
                        background: #fff;
                        border-radius: 35px 35px 20px 20px ;
                        padding: 10px;
                        color: $fontColourDark;
                        text-align: left;
                        // margin: 20px 18px 30px 0;
                        margin: 15px;
                        height: auto;
                        overflow: hidden;
                        text-decoration: none;

                        h2 {
                            text-align: center;
                            width: 100%;
                            padding: 15px 10px;
                            border-radius: 30px;
                            margin: 0;
                            font-size: $font19;

                            @media screen and (max-width: 1300px){
                                padding: 10px;
                            }
                        }

                        h3 {
                            text-align: left;
                            width: 100%;
                            padding: 5px 10px;
                            border-radius: 30px;
                            margin: 10px 0 0 0;
                            font-size: $font14;
                        }

                        p {
                            padding: 0 10px;
                            font-size: 0.9em;
                        }

                    }

                    .job-box {
                        width: 28%;
                        margin: 20px;

                        @media screen and (max-width: 800px) {
                            width: 100%;
                        }

                    }

                }

        }
        .visible-options {
            width: 200%;
            display: flex;
            justify-content: space-evenly;
            align-items: self-start;
            transition: all 1s ease-in-out;

            &.scroll {
                transform: translateX(-50%);
            }
        }

        .result-option {
            width: 30%;
            background: $lightColour;
            border-radius: 46px 46px 16px 16px;
            padding: 10px;
            color: $fontColourDark;
            text-align: center;
            margin: 0 10px;
            height: 250px;
            max-height: 250px;
            max-width: 340px;
            overflow: hidden;
            text-decoration: none;
            display: inline-block;

            @media all and (max-width: 1300px) {
                max-height: 270px;
                height: 270px;
            }

            .title {
                height: 74px;
                background: $bgColourFive;
                border-radius: 74px;
                display: flex;
                justify-content: center;
                align-items: center;
                padding: 0 20px;

                h2 {
                    font-size: 1.06em;
                    color: $fontColourDark;
                    margin: 0;
                }
            }

            .blurb {
                padding: 20px 10px;
                font-size: 0.9em;
                text-align: left;
            }

            .link {
                color: $bgColourFour;
                font-weight: 600;
                font-size: 0.9em;
                text-align: left;
                padding: 0 10px;
            }

            &:hover {
                cursor: pointer;
                animation-duration: 0.5s;
                animation-fill-mode: both;
                animation-name: pulse;
            }
        }

    }
}