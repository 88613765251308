.contact-wrap {
    text-align: center;

    @media all and (max-width: 768px) {
        width: 100%;
    }

    .contact-details {
        background: white;
        border-radius: 30px;
        padding: 50px 80px;
        margin: 20px 50px;
        min-width: 600px;
        color: $fontColourDark;
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;

        @media all and (max-width: 900px) {
            padding: 50px;
        }

        @media all and (max-width: 768px) {
            min-width: 90%;
            width: 90%;
            margin: 20px auto;
            flex-direction: column;
        }

        .logo {
            min-width: 200px;
            width: 200px;
            position: relative;

            img {
                object-fit: contain;
                width: 100%;
            }
        }

        .details {
            padding-left: 20px;

            .detail {
                display: flex;
                margin: 20px 0;
                align-items: center;
                text-align: left;

                @media all and (max-width: 900px) {
                    align-items: flex-start;
                }

                @media all and (max-width: 768px) {
                    padding: 0;
                }

                strong {
                    color: $bgColourEight;
                    font-size: 0.9em;
                    font-weight: 300;
                    min-width: 100px;
                    text-align: right;
                    padding-right: 20px;
                }
            }
        }
    }
}