.quiz-category-wrap {
    position: relative;

    h1 {
        margin-top: 50px;
        text-align: center;
    }

    .quiz-info-wrap {
        background: $lightColour;
        border-radius: 40px;
        box-shadow: 2px 2px 40px 1px rgba(36,58,71,0.25);
        color: $fontColourDark;
        padding: 40px;
        width: 82%;
        max-width: 1200px;
        min-width: 700px;
        margin: 50px auto;
        text-align: center;

        .video {
            width:80%;
            max-width:600px;
            height:50%;
            margin: 0 auto;

            img {
                object-fit: cover;
                width: 100%;
            }

            .iframe-wrapper {
                position:relative;
                padding-top: 56.25%;
            }

            iframe {
                position:absolute;
                top:0;
                left:0;
                width:100%;
                height:100%;
            }
        }

        h2 {
            font-size: 1.4em;
            color: $bgColourFour;
        }

        .butt-start-quiz {
            background: $bgColourThree;
            color: #fff;

            .butt-icon {
                background-color: #fff;
            }

            .animation-bubble {
                background: #493d76;
            }
        }
    }

    .button-wrap {
        text-align: center;
        margin-bottom: 50px;

        .butt-start-quiz {
            margin: 0;
        }
    }

}