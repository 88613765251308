.update-whyareyouhere-form {

    h1 {
        text-align: center;
    }

    .whyareyouhere-block {
        padding: 30px 0;
    
        .js-form-type-checkbox {
            justify-content: flex-start;
            text-align: left;
    
            label {
                display: block!important;
                padding-left: 0;
                font-weight: normal;
            }
    
        }
    
    }

    .form-info-copy {
        text-align: center;
    }

    .content-info {
        display: none;
        padding: 15px;
        text-align: center;
        margin-bottom: 0;

        &.visible {
            display: block;
        }
    }

    .message-block {
        max-width: 390px;
    }

    .form-actions {
        text-align: center;
        margin-bottom: 30px;

        .butt-general {
            margin: 0;
        }
    }

}