@charset "UTF-8";
@import url("https://fonts.googleapis.com/css?family=Open+Sans:400,700&display=swap");
@keyframes pulse {
  0% {
    transform: translateZ(0) scale3d(1, 1, 1);
  }
  50% {
    transform: translateZ(0) scale3d(1.02, 1.02, 1.02);
  }
  100% {
    transform: translateZ(0) scale3d(1, 1, 1);
  }
}
@keyframes pulseBig {
  0% {
    transform: translateZ(0) scale3d(1, 1, 1);
  }
  50% {
    transform: translateZ(0) scale3d(1.08, 1.08, 1.08);
  }
  100% {
    transform: translateZ(0) scale3d(1, 1, 1);
  }
}
@keyframes pulseBigger {
  0% {
    transform: translateZ(0) scale3d(1, 1, 1);
  }
  50% {
    transform: translateZ(0) scale3d(1.2, 1.2, 1.2);
  }
  100% {
    transform: translateZ(0) scale3d(1, 1, 1);
  }
}
@keyframes jiggle {
  0% {
    transform: translateZ(0) scale3d(1, 1, 1);
  }
  10%, 20% {
    transform: translateZ(0) scale3d(0.9, 0.9, 0.9) rotate3d(0, 0, 1, -3deg);
  }
  30%, 50%, 70%, 90% {
    transform: translateZ(0) scale3d(1.1, 1.1, 1.1) rotate3d(0, 0, 1, 3deg);
  }
  40%, 60%, 80% {
    transform: translateZ(0) scale3d(1.1, 1.1, 1.1) rotate3d(0, 0, 1, -3deg);
  }
  100% {
    transform: translateZ(0) scale3d(1, 1, 1);
  }
}
@keyframes bouncey {
  0%, 100%, 20%, 53%, 80% {
    transition-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
    transform: translate3d(0, 0, 0);
  }
  40%, 43% {
    transition-timing-function: cubic-bezier(0.755, 0.05, 0.855, 0.06);
    transform: translate3d(0, -7px, 0);
  }
  70% {
    transition-timing-function: cubic-bezier(0.755, 0.05, 0.855, 0.06);
    transform: translate3d(0, -4px, 0);
  }
  90% {
    transform: translate3d(0, -2px, 0);
  }
}
@keyframes shake {
  0%, 100% {
    transform: translate3d(0, 0, 0);
  }
  10%, 30%, 50%, 70%, 90% {
    transform: translate3d(-3px, 0, 0);
  }
  20%, 40%, 60%, 80% {
    transform: translate3d(3px, 0, 0);
  }
}
.dialog-off-canvas-main-canvas {
  min-height: 100vh;
}

#edit-user-picture-0--label,
#edit-field-avatar-0--label,
#edit-field-avatar-0--description,
#edit-user-picture-0--description,
#edit-name--description,
#edit-mail--description,
div.password-confirm,
.form-item-user-picture-0 .description {
  display: none;
}

.form-item-field-terms-privacy-value, .form-item-field-marketing-opt-in-value, .js-form-type-checkbox {
  display: flex;
  align-items: center;
  font-size: 0.9em;
  line-height: 1.5em;
}
.form-item-field-terms-privacy-value #edit-field-marketing-opt-in-value--description, .form-item-field-marketing-opt-in-value #edit-field-marketing-opt-in-value--description, .js-form-type-checkbox #edit-field-marketing-opt-in-value--description {
  flex: 1;
  text-align: left;
}

.form-item-field-terms-privacy-value label,
.field-goal-task-values .js-form-type-checkbox label {
  display: block !important;
  position: absolute !important;
  top: -25px;
  opacity: 0;
}

.butt-update > input[type=submit] {
  height: 42px;
  width: auto;
  border-radius: 42px;
  background: transparent;
  border: none;
  outline: none;
  font-family: "Open Sans", sans-serif;
  font-size: 0.8rem;
  font-weight: 600;
  text-transform: uppercase;
  color: #ffffff;
  padding: 0 15px 0 4px;
  display: inline-flex;
  justify-content: flex-start;
  align-items: center;
  transition: all 0.2s;
  position: relative;
  overflow: hidden;
  margin-left: 20px;
  -webkit-appearance: none;
}
.butt-update > input[type=submit]:hover {
  cursor: pointer;
  animation-duration: 0.4s;
  animation-fill-mode: both;
  animation-name: pulse;
}
.butt-update > input[type=submit]:hover .animation-bubble {
  opacity: 0.2;
  transform: scale(20, 20);
  transition: transform 0.6s ease-out;
}

input[type=submit] {
  height: 42px;
  width: 180px;
  border-radius: 42px;
  background: #ffffff;
  border: none;
  outline: none;
  font-family: "Open Sans", sans-serif;
  font-size: 0.8em;
  font-weight: 600;
  text-transform: uppercase;
  color: #337ca5;
  padding: 0 15px 0 4px;
  display: inline-flex;
  justify-content: flex-start;
  align-items: center;
  transition: all 0.2s;
  position: relative;
  overflow: hidden;
  margin-left: 20px;
  -webkit-appearance: none;
}
input[type=submit]:hover {
  cursor: pointer;
  animation-duration: 0.4s;
  animation-fill-mode: both;
  animation-name: pulse;
}
input[type=submit]:hover .animation-bubble {
  opacity: 0.2;
  transform: scale(20, 20);
  transition: transform 0.6s ease-out;
}

.form-item.js-form-item-field-notify-team-members-value label {
  position: relative;
  pointer-events: none;
  margin: 0;
  display: flex;
  justify-content: flex-start !important;
  align-items: center;
  padding: 0;
  transition: all 0.3s;
  width: 200px;
  font-size: 1rem;
  color: #323333;
}

.form-item {
  position: relative;
  margin: 0 0 20px 0;
}
.form-item input[type=text],
.form-item input[type=email],
.form-item input[type=password],
.form-item input[type=number],
.form-item input[type=date],
.form-item select {
  appearance: none;
  height: 50px;
  border-radius: 50px;
  border: none;
  background-color: rgba(255, 255, 255, 0.45);
  outline: none;
  width: 100%;
  padding: 0 40px 0 20px;
  font-size: 1rem;
  font-family: "Open Sans", sans-serif;
  transition: all 0.2s;
  color: #323333;
}
.form-item input[type=text]:focus,
.form-item input[type=email]:focus,
.form-item input[type=password]:focus,
.form-item input[type=number]:focus,
.form-item input[type=date]:focus,
.form-item select:focus {
  background-color: rgb(255, 255, 255);
}
.form-item input[type=text].error,
.form-item input[type=email].error,
.form-item input[type=password].error,
.form-item input[type=number].error,
.form-item input[type=date].error,
.form-item select.error {
  border: 1px solid #ea8782;
}
.form-item .select {
  display: grid;
  grid-template-areas: "select";
  align-items: center;
}
.form-item .select::after {
  content: "";
  width: 0.8em;
  height: 0.5em;
  background-color: #323333;
  clip-path: polygon(100% 0%, 0 0%, 50% 100%);
  justify-self: end;
  margin-right: 17px;
  pointer-events: none;
}
.form-item select,
.form-item .select:after {
  grid-area: select;
}
.form-item input[type=checkbox] {
  width: 40px !important;
  height: 40px;
  border-radius: 10px;
  -webkit-appearance: none;
  background: white;
  padding: 20px;
  margin: 0 20px 0 0;
  outline: none;
  border: 1px solid #59A3D0;
}
.form-item input[type=checkbox]:hover {
  cursor: pointer;
  animation-duration: 0.4s;
  animation-fill-mode: both;
  animation-name: pulseBig;
  background: white url(../images/icons/icon-tick.svg) center center no-repeat;
  background-size: 50%;
}
.form-item input[type=checkbox]:checked {
  background: white url(../images/icons/icon-tick.svg) center center no-repeat;
  background-size: 50%;
}
.form-item input[type=checkbox]:not(:checked) {
  background: white center center no-repeat;
  background-size: 50%;
}
.form-item input[type=checkbox]:disabled {
  opacity: 0.5;
}
.form-item input[type=checkbox]:focus {
  outline: 1px solid #59A3D0 !important;
  outline-offset: 3px;
}
.form-item input[type=password] {
  padding-right: 50px;
}
.form-item.js-form-type-select {
  outline: none;
  width: 100%;
}
.form-item.js-form-type-select select.not-visible {
  opacity: 0;
}
.form-item.js-form-type-date {
  /*height: 50px;
  border-radius: 50px;
  border: none;
  background-color: rgba(255,255,255, 0.45);
  outline: none; // TODO: accessibility
  width: 100%;*/
}
.form-item.js-form-type-date input.not-visible {
  opacity: 0;
}
.form-item.js-form-type-textarea {
  width: 100%;
}
.form-item.js-form-type-textarea textarea {
  border: none;
  background-color: rgb(255, 255, 255);
  resize: none;
  padding: 20px;
  border-radius: 25px;
  font-size: 1.1rem;
  font-family: "Open Sans", sans-serif;
  font-weight: bold;
  width: 100%;
}
.form-item label {
  pointer-events: none;
  margin: 0 0 5px 0;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding: 0 0 0 20px;
  transition: all 0.3s;
  font-size: 0.88rem;
  font-weight: bold;
  color: #323333;
  max-height: 50px;
}
.form-item label.right {
  justify-content: flex-start;
  color: #323333;
}
.form-item label.form-required:after {
  content: "*";
  color: #7b68c8;
  padding-left: 4px;
}
.form-item .eye-close, .form-item .eye-open {
  width: 20px !important;
  position: absolute;
  bottom: 17px;
  right: 15px;
}

.custom-field .field--type-datetime {
  position: relative;
}
.custom-field .field--type-datetime h4 {
  position: relative;
  pointer-events: none;
  top: 0;
  bottom: 0;
  left: 20px;
  margin: 0 0 5px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding: 0;
  transition: all 0.3s;
  width: 150px;
  font-size: 0.88rem;
  font-weight: bold;
  color: #323333;
  z-index: 1;
}
.custom-field .field--type-datetime h4:after {
  content: "*";
  color: #7b68c8;
  padding-left: 4px;
}
.custom-field .field--type-datetime h4.right {
  justify-content: flex-end;
  font-size: 0.8em;
  color: #323333;
  left: calc(100% - 170px);
}

div[role=contentinfo] {
  width: 100%;
  margin: 15px auto;
  text-align: left;
  background: rgba(51, 56, 85, 0.3);
  min-height: 50px;
  border-radius: 50px;
  color: #ffffff;
  font-size: 0.8em;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0 20px;
}
div[role=contentinfo] a {
  margin: 0 15px;
  color: white;
}
@media all and (max-width: 800px) {
  div[role=contentinfo] {
    width: calc(100% - 40px);
  }
}

.sitemap {
  background: #fff;
  border-radius: 20px;
  padding: 20px 40px 0;
}
.sitemap h1 {
  margin: 0 0 50px;
}
.sitemap h2 {
  color: #323333;
  font-size: 1.3em;
}
.sitemap .content {
  margin-bottom: 40px;
}

#block-mywayemployability-footer, #block-mobilefootermenu {
  display: none;
}

#block-styleswitcher h2 {
  display: none;
}

.js input.form-autocomplete {
  background-position: 96%;
}
.js input.form-autocomplete.ui-autocomplete-loading {
  background-position: 96%;
}

.message-block {
  border-radius: 10px;
  padding: 10px;
  font-size: 0.75rem;
  margin-bottom: 40px;
  background: rgba(51, 56, 85, 0.3);
  min-height: 50px;
  border-radius: 25px;
  color: #ffffff;
  padding: 20px;
}
.message-block ul li {
  margin-left: -15px;
}
.message-block.light {
  background: #ffffff;
  color: #323333;
}

.colour-form .form-item input[type=text],
.colour-form .form-item input[type=email],
.colour-form .form-item input[type=password],
.colour-form .form-item input[type=number],
.colour-form .form-item input[type=date],
.colour-form .form-item select {
  background-color: #c0e5f7;
}
.colour-form .form-item input[type=text]:focus,
.colour-form .form-item input[type=email]:focus,
.colour-form .form-item input[type=password]:focus,
.colour-form .form-item input[type=number]:focus,
.colour-form .form-item input[type=date]:focus,
.colour-form .form-item select:focus {
  background-color: #5da5cd;
}
.colour-form .form-item.js-form-type-textarea textarea {
  background-color: #c0e5f7;
}

* {
  box-sizing: border-box;
  outline: none !important;
}
* a:focus,
* button:focus {
  outline: -webkit-focus-ring-color auto 1px !important;
}

body,
html {
  padding: 0;
  margin: 0;
  color: #323333;
  font-family: "Open Sans", sans-serif;
  box-sizing: border-box;
  background-color: rgb(192, 229, 247);
  background-color: linear-gradient(45deg, rgb(192, 229, 247) 0%, rgb(93, 165, 205) 100%);
  width: 100%;
  min-height: 100%;
  background-image: url(../images/bg-wave.svg), linear-gradient(45deg, rgb(192, 229, 247) 0%, rgb(93, 165, 205) 100%);
  background-size: 125%;
  background-position: bottom;
  background-repeat: no-repeat;
}
body.team-member,
html.team-member {
  background-image: url(../images/bg-wave-purple.svg), linear-gradient(45deg, rgb(228, 224, 247) 0%, rgb(124, 110, 195) 100%);
}
@media all and (max-width: 768px) {
  body.team-member header,
html.team-member header {
    background: #7c6ec3;
  }
}
body.greyscale,
html.greyscale {
  background-image: url(../images/bg-wave-greyscale.svg), linear-gradient(45deg, rgb(251, 251, 251) 0%, rgb(203, 203, 203) 100%);
}
body.greyscale.team-member,
html.greyscale.team-member {
  background-image: url(../images/bg-wave-greyscale.svg), linear-gradient(45deg, rgb(251, 251, 251) 0%, rgb(203, 203, 203) 100%);
}
body.greyscale .dialog-off-canvas-main-canvas,
html.greyscale .dialog-off-canvas-main-canvas {
  filter: grayscale(100%);
}
body.resource .portrait-overlay,
html.resource .portrait-overlay {
  display: none !important;
}
body[data-once="form-single-submit detailsAria"],
html[data-once="form-single-submit detailsAria"] {
  background-position: bottom;
}
body.maintenance-page,
html.maintenance-page {
  text-align: center;
  background-position: 0 100%;
}
body.maintenance-page .dialog-off-canvas-main-canvas,
html.maintenance-page .dialog-off-canvas-main-canvas {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
body.maintenance-page .dialog-off-canvas-main-canvas header[role=banner],
html.maintenance-page .dialog-off-canvas-main-canvas header[role=banner] {
  flex-direction: column;
  height: auto;
  padding-bottom: 50px;
  margin-top: -100px;
}
body.maintenance-page .dialog-off-canvas-main-canvas header[role=banner] a img,
html.maintenance-page .dialog-off-canvas-main-canvas header[role=banner] a img {
  width: 200px;
}
body.maintenance-page .dialog-off-canvas-main-canvas header[role=banner] h1,
html.maintenance-page .dialog-off-canvas-main-canvas header[role=banner] h1 {
  display: none;
}
body.maintenance-page .dialog-off-canvas-main-canvas main,
html.maintenance-page .dialog-off-canvas-main-canvas main {
  width: 1000px;
  margin: 0 auto;
}
body.maintenance-page .dialog-off-canvas-main-canvas main h1,
html.maintenance-page .dialog-off-canvas-main-canvas main h1 {
  margin-bottom: 20px;
}

a {
  color: #5243a0;
  transition: all 0.2s;
}
a:hover {
  color: #323333;
}

h1 {
  margin: 0;
  font-size: 2em;
  color: #323333;
}
@media all and (max-width: 1300px) {
  h1 {
    font-size: 1.6em;
  }
}

ul {
  margin-bottom: 5px !important;
}

ul.bold > li {
  font-size: 0.8rem;
  font-weight: bold;
  line-height: 1.5rem;
}

.portrait-overlay {
  width: 100vw;
  height: 100%;
  background: #5da5cd;
  position: fixed;
  left: 0;
  top: 0;
  display: none;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  z-index: 3;
}
.portrait-overlay h1,
.portrait-overlay p,
.portrait-overlay img {
  text-align: center;
  max-width: 60%;
}
@media all and (max-width: 1023px) {
  .portrait-overlay {
    display: flex;
  }
}

.switch {
  position: relative;
  display: inline-block;
  width: 60px;
  height: 34px;
  min-width: 60px;
}
.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}
.switch .slider {
  border-radius: 34px;
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ffffff;
  -webkit-transition: 0.4s;
  transition: 0.4s;
  border: 2px solid #ffffff;
}
.switch .slider::before {
  border-radius: 50%;
  position: absolute;
  content: "";
  height: 24px;
  width: 24px;
  left: 3px;
  bottom: 3px;
  background-color: #786ac1;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}
.switch input:checked + .slider {
  border: 2px solid #ffffff;
}
.switch input:focus + .slider {
  box-shadow: 0 0 1px #2196F3;
}
.switch input:checked + .slider:before {
  transform: translateX(26px);
}
.switch.portrait {
  width: 34px;
  height: 60px;
}
.switch.portrait .slider::before {
  top: 3px;
  bottom: initial;
}
.switch.portrait input:checked + .slider:before {
  transform: translateY(26px);
}

header {
  height: 100px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  max-width: 1200px;
  margin: 0 auto;
}
@media all and (max-width: 1300px) {
  header {
    max-width: 100vw;
    padding: 0 30px;
  }
}
@media all and (max-width: 800px) {
  header {
    padding: 0 20px;
    height: 70px;
  }
}
@media all and (max-width: 768px) {
  header {
    position: fixed;
    width: 100vw;
    background: #7ebada;
    z-index: 1;
  }
}
header .site-logo {
  max-width: 150px;
  margin-right: 40px;
  min-width: 70px;
  flex: 1;
  transition: all 0.2s;
}
header .site-logo:hover {
  cursor: pointer;
  animation-duration: 1s;
  animation-fill-mode: both;
  animation-name: bouncey;
}
@media all and (max-width: 767px) {
  header .site-logo {
    max-width: 100px;
    margin-right: 10px;
  }
}
@media all and (max-width: 768px) {
  header .site-logo.logged-in {
    display: none;
  }
}
header .dashboard-link {
  display: none;
  padding-left: 25px;
  background: #ffffff url("./../images/icons/icon-dashboard.svg") 5px no-repeat;
  background-size: 25px;
  padding: 7px 10px 7px 35px;
  height: 40px;
  border-radius: 40px;
}
header .dashboard-link a {
  text-decoration: none;
  color: #343853;
  text-transform: uppercase;
  font-weight: bold;
  font-size: 0.75rem;
  display: inline-block;
}
@media all and (max-width: 768px) {
  header .dashboard-link {
    display: block;
  }
}
header .header-links {
  flex: 1;
}
header .header-links .link {
  padding: 0;
  margin: 0 15px;
  display: inline-block;
  position: relative;
  text-align: center;
}
@media all and (max-width: 1300px) {
  header .header-links .link {
    margin: 0 10px;
  }
}
@media all and (max-width: 768px) {
  header .header-links .link {
    margin: 0 !important;
  }
}
header .header-links .link a {
  text-decoration: none;
  font-size: 0.8em;
  text-transform: uppercase;
  font-weight: 600;
  color: #323333;
  transition: all 0.2s ease-in-out;
  transform: scale(1);
  padding: 0 15px;
}
@media all and (max-width: 768px) {
  header .header-links .link a {
    padding: 0 10px;
  }
}
header .header-links .link .underline {
  position: absolute;
  width: 100%;
  height: 2px;
  background: #ffffff;
  top: calc(100% + 2px);
  /* transform: translateX(-50%); */
  left: 0;
  right: 0;
  margin: auto;
  border-radius: 10px;
  transition: all 0.3s ease-in-out;
  transform: scaleX(0);
}
header .header-links .link:hover a {
  color: #ffffff;
  transform: scale(1.04);
  transition: all 0.2s ease-in-out;
}
header .header-links .link:hover .underline {
  transform: scaleX(1);
  transition: all 0.3s ease-in-out;
}
@media all and (max-width: 767px) {
  header .header-links.desktop {
    display: none !important;
  }
}
header .header-links.mobile {
  display: flex;
  background: #5da5cd;
  width: 70vw;
  height: 100svh;
  position: fixed;
  z-index: 1;
  right: calc(-70vw - 40px);
  top: 0;
  box-shadow: 2px 2px 40px 1px rgba(36, 58, 71, 0.25);
  transition: all 0.3s;
  flex-direction: column;
  align-items: flex-end;
  padding: 20px;
  background-color: rgb(192, 229, 247);
  background: linear-gradient(180deg, rgb(192, 229, 247) 0%, rgb(93, 165, 205) 100%);
  background-image: url(../images/bg-wave.svg), linear-gradient(45deg, rgb(192, 229, 247) 0%, rgb(93, 165, 205) 100%);
  background-size: 500%;
  background-repeat: no-repeat;
  background-position: left bottom;
}
header .header-links.mobile #close-overlay {
  opacity: 0;
  background: rgba(255, 255, 255, 0.1);
  bottom: 0;
  width: 30vw;
  left: 0;
  margin: auto;
  position: absolute;
  right: 0;
  top: 0;
  z-index: 0;
  transition: all 0.3s;
}
header .header-links.mobile .butt-close {
  -webkit-appearance: none;
  border: none;
  width: 40px;
  height: 40px;
  border-radius: 50px;
  background: #ffffff url("../images/icons/icon-close.svg") center center no-repeat;
  background-size: 50%;
}
header .header-links.mobile .navigation-links {
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  padding: 40px 0;
}
header .header-links.mobile .navigation-links .link {
  height: 40px;
}
header .header-links.mobile .navigation-links .link a {
  padding: 0;
}
header .header-links.mobile .navigation-links .link .underline {
  top: 25px;
}
header .header-links.mobile .login-buttons {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  width: 100%;
}
header .header-links.mobile .login-buttons .butt-general {
  margin-left: 0;
  width: 100%;
}
header .header-links.mobile .login-buttons .butt-general:first-child {
  margin-bottom: 10px;
}
header .header-links.mobile .login-buttons .butt-general.butt-small .title {
  display: block;
}
header .header-links.mobile.show {
  transform: translateX(calc(-70vw - 40px));
}
header .header-links.mobile.show #close-overlay {
  transform: translateX(-50vw);
}
header .button-wrap :first-child {
  margin-right: 10px;
}
@media all and (max-width: 767px) {
  header .button-wrap {
    display: none !important;
  }
}
@media all and (max-width: 985px) and (min-width: 768px) {
  header .button-wrap .butt-general {
    width: 130px;
  }
  header .button-wrap .butt-general.butt-small {
    width: 44px;
  }
  header .button-wrap .butt-general.butt-logout {
    width: 44px;
  }
  header .button-wrap .butt-general.butt-logout .butt-icon {
    min-width: 32px;
  }
  header .button-wrap .butt-general.butt-logout .title {
    display: none;
  }
}
header .button-menu-wrap {
  display: none;
}
header .button-menu-wrap .butt-menu {
  -webkit-appearance: none;
  border: none;
  width: 40px;
  height: 40px;
  border-radius: 50px;
  background: #ffffff url("../images/icons/icon-menu.svg") center center no-repeat;
  background-size: 50%;
}
@media all and (max-width: 767px) {
  header .button-menu-wrap {
    display: block !important;
  }
}
@media all and (max-width: 985px) and (min-width: 768px) {
  header .site-logo {
    max-width: 110px;
    margin-right: 10px;
  }
}

footer {
  height: 50px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  max-width: 1200px;
  width: 1200px;
  margin: 0 auto;
  font-size: 0.8em;
  color: #323333;
}
footer .style-switch {
  flex: 1;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin: 0 30px;
}
@media all and (max-width: 800px) {
  footer .style-switch {
    margin-bottom: 20px;
  }
}
footer ul {
  list-style: none;
  padding: 0;
  display: flex;
  margin: 0 !important;
}
footer ul li {
  padding-left: 20px;
}
footer ul li a {
  font-size: 0.9em;
  text-transform: uppercase;
  text-decoration: none;
  color: #323333;
  font-weight: 600;
  transition: all 0.2s;
}
footer ul li a:hover {
  color: #ffffff;
}
footer ul.mobile-footer-menu {
  display: none;
  margin: 0;
}
@media all and (max-width: 767px) {
  footer ul.mobile-footer-menu {
    display: block;
  }
}
footer .desktop-menu {
  display: flex;
}
@media all and (max-width: 800px) {
  footer .desktop-menu {
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
  }
  footer .desktop-menu li {
    margin: 5px;
    padding: 0;
  }
}
@media all and (max-width: 1300px) {
  footer {
    width: 100%;
    padding: 0 50px;
  }
}
@media all and (max-width: 800px) {
  footer {
    flex-direction: column;
    height: fit-content;
    padding-bottom: 20px;
  }
  footer p {
    margin: 0;
  }
}
@media all and (max-width: 767px) {
  footer {
    padding: 0 20px 20px;
  }
  footer li {
    padding-left: 0;
  }
}

.post-footer {
  background: white;
  color: #323333;
  height: 300px;
  font-size: 0.9em;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
}
.post-footer .logos {
  height: 80px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.post-footer .logos img {
  height: 80px;
}
.post-footer .logos img:nth-child(2) {
  height: 60px;
  margin-left: 50px;
}
@media all and (max-width: 800px) {
  .post-footer {
    justify-content: flex-start;
    padding: 20px;
    height: fit-content;
  }
  .post-footer .logos {
    flex-direction: column;
    justify-content: flex-start;
  }
  .post-footer .logos img:nth-child(2) {
    margin: 0;
  }
}

.butt-animate .animation-bubble {
  position: absolute;
  pointer-events: none;
  background: #5da5cd;
  width: 20px;
  height: 20px;
  opacity: 0;
  transform: scale(0, 0);
  left: calc(50% - 10px);
  top: 75%;
  border-radius: 20px;
  transition: transform 0.6s ease-out;
}
.butt-animate:hover {
  cursor: pointer;
  animation-duration: 0.4s;
  animation-fill-mode: both;
  animation-name: pulse;
}
.butt-animate:hover .animation-bubble {
  opacity: 0.2;
  transform: scale(20, 20);
  transition: transform 0.6s ease-out;
}

.butt-general {
  height: 42px;
  width: 180px;
  border-radius: 42px;
  background: #ffffff;
  border: none;
  outline: none;
  font-family: "Open Sans", sans-serif;
  font-size: 0.8em;
  font-weight: 600;
  text-transform: uppercase;
  color: #337ca5;
  padding: 0 15px 0 4px;
  display: inline-flex;
  justify-content: flex-start;
  align-items: center;
  transition: all 0.2s;
  position: relative;
  overflow: hidden;
  backface-visibility: hidden;
  transform: translateZ(0);
  font-smoothing: antialiased !important;
  text-decoration: none;
}
.butt-general:focus {
  outline: 1px solid #ffffff;
  outline-offset: 3px;
}
.butt-general .butt-icon {
  display: inline-block;
  background-color: #337ca5;
  height: 32px;
  width: 32px;
  border-radius: 32px;
  z-index: 1;
  background-position: center center;
  background-repeat: no-repeat;
  background-size: 60%;
}
.butt-general .butt-icon.login, .butt-general .butt-icon.Account {
  background-image: url(../images/icons/icon-user.svg);
}
.butt-general .butt-icon.register {
  background-image: url(../images/icons/icon-register.svg);
}
.butt-general .butt-icon.register-yellow {
  background-image: url(../images/icons/icon-register-yellow.svg);
}
.butt-general .butt-icon.logout {
  background-image: url(../images/icons/icon-logout.svg);
}
.butt-general .butt-icon.user {
  background-image: url(../images/icons/icon-user-yellow.svg);
}
.butt-general .butt-icon.add-calendar {
  background-image: url(../images/icons/icon-addcal.svg);
  background-size: 100%;
}
.butt-general .butt-icon.edit {
  background-size: 90%;
  background-image: url(../images/icons/icon-edit.svg);
}
.butt-general .butt-icon.upload {
  background-image: url(../images/icons/icon-upload.svg);
}
.butt-general .butt-icon.close {
  background-image: url(../images/icons/icon-close-light.svg);
  background-size: 45%;
}
.butt-general .butt-icon.close-yellow {
  background-image: url(../images/icons/icon-close-yellow.svg);
  background-size: 40%;
}
.butt-general .butt-icon.started {
  background-image: url(../images/icons/icon-arrow-purple.svg);
  background-size: 35%;
  background-position: 55% 50%;
}
.butt-general .butt-icon.more {
  background-image: url(../images/icons/icon-arrow-green.svg);
  background-size: 35%;
  background-position: 55% 50%;
}
.butt-general .butt-icon.add-team {
  background-image: url(../images/icons/icon-team.svg);
  background-size: 80%;
}
.butt-general .butt-icon.add-meeting {
  background-image: url(../images/icons/icon-addcal-purple.svg);
  background-size: 100%;
}
.butt-general .butt-icon.previous {
  background-image: url(../images/icons/icon-arrow-previous.svg);
  background-size: 35%;
  background-position: 40% 50%;
}
.butt-general .butt-icon.next {
  background-image: url(../images/icons/icon-arrow-next.svg);
  background-size: 35%;
  background-position: 58% 50%;
}
.butt-general .butt-icon.next-purple {
  background-image: url(../images/icons/icon-arrow-next-purple.svg);
  background-size: 35%;
  background-position: 58% 50%;
}
.butt-general .butt-icon.next-light-blue {
  background-image: url(../images/icons/icon-arrow-back-blue.svg);
  transform: rotate(180deg);
  background-size: 35%;
  background-position: 50% 50%;
}
.butt-general .butt-icon.back {
  background-image: url(../images/icons/icon-arrow-back-blue.svg);
  background-size: 35%;
  background-position: 43% 50%;
}
.butt-general .butt-icon.back-white {
  background-image: url(../images/icons/icon-arrow-back.svg);
  background-size: 35%;
  background-position: 43% 50%;
}
.butt-general .butt-icon.start-quiz {
  background-image: url(../images/icons/icon-start.svg);
}
.butt-general .butt-icon.save {
  background-image: url(../images/icons/icon-save.svg);
}
.butt-general .butt-icon.save-purple {
  background-image: url(../images/icons/icon-save-purple.svg);
}
.butt-general .butt-icon.save-white {
  background-image: url(../images/icons/icon-save-white.svg);
}
.butt-general .butt-icon.group-talking {
  background-image: url(../images/icons/icon-group-talking.svg);
  background-size: 110%;
}
.butt-general .butt-icon.group-talking.selected {
  background-image: url(../images/icons/icon-group-talking-bg-yellow.svg);
  background-size: 110%;
}
.butt-general .butt-icon.group {
  background-image: url(../images/icons/icon-group.svg);
  background-size: 110%;
}
.butt-general .butt-icon.hear {
  background-image: url(../images/icons/icon-hear.svg);
  background-size: 110%;
}
.butt-general .butt-icon.butt-icon-text {
  color: #fff;
  font-size: 1.5em;
  line-height: 32px;
}
.butt-general .butt-icon.add-goal {
  background-image: url(../images/icons/icon-add.svg);
  background-size: 60%;
}
.butt-general .butt-icon.goal {
  background-image: url(../images/icons/icon-goal.svg);
}
.butt-general .butt-icon.due-date {
  background-image: url(../images/icons/icon-addcal-purple.svg);
}
.butt-general .title {
  flex: 1;
  z-index: 1;
  text-align: center;
}
.butt-general:hover {
  color: #337ca5;
}
.butt-general.butt-small {
  width: 44px;
  padding: 0 6px;
}
.butt-general.butt-small .title {
  display: none;
}
.butt-general.butt-register, .butt-general.butt-logout, .butt-general.butt-back, .butt-general.butt-save, .butt-general.butt-update, .butt-general.butt-edit {
  background: transparent;
  border: 2px solid #ffffff;
  color: #ffffff;
}
.butt-general.butt-register .butt-icon, .butt-general.butt-logout .butt-icon, .butt-general.butt-back .butt-icon, .butt-general.butt-save .butt-icon, .butt-general.butt-update .butt-icon, .butt-general.butt-edit .butt-icon {
  background-color: #ffffff;
}
.butt-general.butt-register .animation-bubble, .butt-general.butt-logout .animation-bubble, .butt-general.butt-back .animation-bubble, .butt-general.butt-save .animation-bubble, .butt-general.butt-update .animation-bubble, .butt-general.butt-edit .animation-bubble {
  background: #403379;
}
.butt-general.butt-register:hover .animation-bubble, .butt-general.butt-logout:hover .animation-bubble, .butt-general.butt-back:hover .animation-bubble, .butt-general.butt-save:hover .animation-bubble, .butt-general.butt-update:hover .animation-bubble, .butt-general.butt-edit:hover .animation-bubble {
  opacity: 0.2;
}
.butt-general.butt-profile-report-back {
  margin-left: 0;
  margin-bottom: 50px;
  color: #337ca5;
}
.butt-general.butt-profile-report-back .butt-icon {
  background-color: #337ca5;
}
.butt-general.butt-profile-report-back:hover {
  color: #5da5cd;
}
.butt-general.butt-dashboard {
  background: #ffffff;
  color: #337ca5;
  border: none;
}
.butt-general.butt-dashboard .butt-icon {
  background-color: #337ca5;
}
.butt-general.butt-dashboard .animation-bubble {
  background: #5da5cd;
}
.butt-general.butt-upload, .butt-general.butt-consent {
  background: #7b68c8;
  color: #ffffff !important;
  margin: 0;
}
.butt-general.butt-upload .butt-icon, .butt-general.butt-consent .butt-icon {
  background-color: #ffffff;
}
.butt-general.butt-upload .animation-bubble, .butt-general.butt-consent .animation-bubble {
  background: #493D76;
}
.butt-general.butt-meeting-time, .butt-general.butt-meeting-address {
  background: #ffffff;
  color: #7b68c8;
  margin: 0;
}
.butt-general.butt-meeting-time .butt-icon, .butt-general.butt-meeting-address .butt-icon {
  background-color: #ffffff;
}
.butt-general.butt-more-way-to-support {
  background: #337ca5;
  color: #ffffff;
  height: 43px;
  width: 380px;
  border-radius: 72px;
  font-size: 13px;
  padding-left: 15px;
  margin-top: 50px;
  margin-bottom: 20px;
}
.butt-general.butt-more-way-to-support .animation-bubble {
  background: #493D76;
}
.butt-general.butt-more-way-to-support:hover {
  color: white;
}
.butt-general.butt-get-started {
  background: #7b68c8;
  color: #ffffff;
  height: 62px;
  width: 250px;
  border-radius: 72px;
  font-size: 1rem;
  padding-left: 10px;
}
.butt-general.butt-get-started .butt-icon {
  background-color: #ffffff;
  height: 42px;
  width: 42px;
}
.butt-general.butt-get-started .animation-bubble {
  background: #493D76;
}
.butt-general.butt-find-out-more {
  background: #88cb88;
  color: #ffffff;
  height: 62px;
  width: 250px;
  border-radius: 72px;
  font-size: 1rem;
  padding-left: 10px;
}
.butt-general.butt-find-out-more .butt-icon {
  background-color: #ffffff;
  height: 42px;
  width: 42px;
}
.butt-general.butt-find-out-more .animation-bubble {
  background: #493D76;
}
.butt-general.butt-see-results {
  background: #7b68c8;
  color: #ffffff;
}
.butt-general.butt-see-results .butt-icon {
  background-color: #ffffff;
}
.butt-general.butt-see-results .animation-bubble {
  background: #493D76;
}
.butt-general.butt-add-team, .butt-general.butt-save-team {
  background: #7b68c8;
  color: #ffffff;
}
.butt-general.butt-add-team .butt-icon, .butt-general.butt-save-team .butt-icon {
  background-color: #ffffff;
}
.butt-general.butt-add-team .animation-bubble, .butt-general.butt-save-team .animation-bubble {
  background: #493D76;
}
.butt-general.butt-clear {
  background: transparent;
  color: #786ac1;
  margin: 0;
  border: 2px solid #7b68c8;
  width: 100%;
  max-width: 180px;
}
.butt-general.butt-clear:focus {
  outline: 1px solid #786ac1 !important;
  outline-offset: 3px;
}
.butt-general.butt-clear .butt-icon {
  background-color: #786ac1;
}
.butt-general.butt-clear .animation-bubble {
  background-color: #E4E0F7;
}
.butt-general.butt-add-cal {
  background: white;
  color: #786ac1;
  margin: 0;
  width: 100%;
  max-width: 180px;
}
.butt-general.butt-add-cal .butt-icon {
  background-color: #786ac1;
}
.butt-general.butt-add-cal .animation-bubble {
  background-color: #E4E0F7;
}
.butt-general.butt-quiz-section {
  height: 74px;
  width: 100%;
  background: #ffffff;
  margin: 0;
  color: #323333;
  font-size: 1.1rem;
  text-transform: unset;
  padding: 0 10px 0 10px;
  margin-bottom: 15px;
  min-width: 210px;
  width: 210px;
  height: 165px;
  display: flex;
  flex-direction: column-reverse;
  margin: 10px;
  padding: 10px 10px;
  border-radius: 30px;
}
@media all and (max-width: 500px) {
  .butt-general.butt-quiz-section {
    width: 100%;
    min-width: auto;
    flex-direction: row;
    height: auto;
    justify-content: center;
    margin-top: 0;
  }
}
.butt-general.butt-quiz-section .copy {
  flex: 1;
  text-align: center;
  line-height: 1.3rem;
}
@media all and (max-width: 500px) {
  .butt-general.butt-quiz-section .copy {
    text-align: left;
    padding: 0 15px;
  }
}
.butt-general.butt-quiz-section .copy .description {
  font-size: 0.63rem;
  line-height: 0.88rem;
  display: block;
  padding-top: 2px;
  font-weight: 400;
  font-style: italic;
}
.butt-general.butt-quiz-section .title {
  text-align: left;
  font-size: 1rem;
}
.butt-general.butt-quiz-section.team-member {
  min-height: 74px;
  height: auto;
}
.butt-general.butt-quiz-section .results {
  flex: 1;
  display: flex;
  flex-direction: column;
  position: relative;
}
.butt-general.butt-quiz-section .results .result {
  padding-left: 15px;
  font-weight: normal;
  font-size: 0.9em;
  height: 30px;
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  height: auto;
  padding-bottom: 10px;
  position: relative;
  padding-top: 5px;
}
.butt-general.butt-quiz-section .results .result:before {
  content: "";
  position: absolute;
  background: url(../images/icons/icon-list-blue.svg) center center no-repeat;
  width: 6px;
  height: 12px;
  left: 0;
  top: 10px;
}
.butt-general.butt-quiz-section .results .title {
  padding-top: 10px;
}
.butt-general.butt-quiz-section .butt-icon {
  width: 40px;
  height: 40px;
  min-width: 40px;
  margin-bottom: 10px;
  min-height: 40px;
}
@media all and (max-width: 500px) {
  .butt-general.butt-quiz-section .butt-icon {
    margin-bottom: 0;
  }
}
.butt-general.butt-quiz-section .butt-icon.career {
  background-color: #c0e5f7;
  background-image: url(../images/icons/icon-career.svg);
}
.butt-general.butt-quiz-section .butt-icon.strengths {
  background-color: #E4E0F7;
  background-image: url(../images/icons/icon-strengths.svg);
}
.butt-general.butt-quiz-section .butt-icon.work {
  background-color: #F8E9B9;
  background-image: url(../images/icons/icon-work.svg);
}
.butt-general.butt-quiz-section .butt-icon.training {
  background-color: #d5f2d4;
  background-image: url(../images/icons/icon-training.svg);
}
.butt-general.butt-quiz-section .butt-icon.life {
  background-color: #F7DADA;
  background-image: url(../images/icons/icon-life.svg);
}
.butt-general.butt-quiz-section .completed-icon {
  width: 30px;
  height: 30px;
  background: #ebebeb;
  border-radius: 30px;
}
.butt-general.butt-quiz-section.career {
  position: relative;
  overflow: initial;
}
.butt-general.butt-quiz-section.career::before {
  content: "Start Here";
  position: absolute;
  top: -45px;
  text-transform: uppercase;
  font-size: 0.88rem;
  color: #786ac1;
  background: #ffffff;
  padding: 5px 10px;
  border-radius: 20px;
}
.butt-general.butt-quiz-section.career::after {
  content: "";
  position: absolute;
  background: #ffffff;
  top: -22px;
  width: 10px;
  height: 10px;
  transform: rotate(45deg);
}
.butt-general.butt-quiz-section.completed {
  background: rgb(255, 255, 255);
  border: 2px solid white;
}
.butt-general.butt-quiz-section.completed .butt-icon.career {
  background-color: #59A3D0;
}
.butt-general.butt-quiz-section.completed .butt-icon.strengths {
  background-color: #7b68c8;
}
.butt-general.butt-quiz-section.completed .butt-icon.work {
  background-color: #F3C762;
}
.butt-general.butt-quiz-section.completed .butt-icon.training {
  background-color: #86CC88;
}
.butt-general.butt-quiz-section.completed .butt-icon.life {
  background-color: #EE9A95;
}
.butt-general.butt-quiz-section.completed .completed-icon {
  background-color: #ffffff;
  background-image: url(../images/icons/icon-tick.svg);
  background-repeat: no-repeat;
  background-position: center center;
}
.butt-general.butt-quiz-section.completed.career {
  background-color: #c0e5f7;
}
.butt-general.butt-quiz-section.completed.career::before {
  content: "";
  background: transparent;
}
.butt-general.butt-quiz-section.completed.career::after {
  background: transparent;
}
.butt-general.butt-quiz-section.completed.strengths {
  background-color: #E4E0F7;
}
.butt-general.butt-quiz-section.completed.work {
  background-color: #F8E9B9;
}
.butt-general.butt-quiz-section.completed.training {
  background-color: #d5f2d4;
}
.butt-general.butt-quiz-section.completed.life {
  background-color: #F7DADA;
}
.butt-general.butt-quiz-section.disable-category {
  opacity: 0.45;
  pointer-events: none;
}
.butt-general.butt-quiz-section .animation-bubble {
  background: white;
}
.butt-general.butt-goal-section-dark, .butt-general.butt-goal-section-light {
  height: 64px;
  width: 100%;
  margin: 0;
  color: #323333;
  font-size: 1.1rem;
  text-transform: unset;
  margin-bottom: 15px;
}
.butt-general.butt-goal-section-dark .title, .butt-general.butt-goal-section-light .title {
  text-align: left;
}
.butt-general.butt-goal-section-dark .butt-icon, .butt-general.butt-goal-section-light .butt-icon {
  width: 60px;
  height: 60px;
}
.butt-general.butt-goal-section-dark .butt-icon.goal, .butt-general.butt-goal-section-light .butt-icon.goal {
  background-image: url(../images/icons/icon-goal.svg);
}
.butt-general.butt-goal-section-dark .animation-bubble, .butt-general.butt-goal-section-light .animation-bubble {
  background: white;
}
.butt-general.butt-goal-section-dark {
  background: #f8d974;
}
.butt-general.butt-goal-section-dark .butt-icon.goal {
  background-color: #f8d974;
}
.butt-general.butt-goal-section-light {
  background: rgb(255, 255, 255);
}
.butt-general.butt-goal-section-light .butt-icon.goal {
  background-color: #f8eab6;
}
.butt-general.butt-previous, .butt-general.butt-next {
  margin: 0;
}
.butt-general.butt-next {
  float: right;
}
.butt-general.purple .butt-icon {
  background-color: #9e92d7;
}
.butt-general.blue .butt-icon {
  background-color: #5da5cd;
}
.butt-general.red .butt-icon {
  background-color: #ea8782;
}
.butt-general.green .butt-icon {
  background-color: #addfae;
}
.butt-general.orange .butt-icon {
  background-color: #f4c760;
}

.butt-category {
  height: auto;
  min-height: 36px;
  border-radius: 21px;
  border: 2px solid #E4E0F7;
  background: transparent;
  width: 100%;
  text-align: left;
  position: relative;
  outline: none;
  overflow: hidden;
  font-family: "Open Sans", sans-serif;
  color: #786ac1;
  font-size: 0.8em;
  margin: 0;
  padding: 0 0 0 10px;
  display: flex;
  flex-direction: row-reverse;
  align-items: center;
  transition: all 0.2s;
  margin-bottom: 5px;
  padding-top: 5px;
  padding-bottom: 5px;
}
.butt-category .animation-bubble {
  background-color: #E4E0F7;
}
.butt-category .butt-icon {
  display: inline-block;
  background-color: #ffffff;
  height: 22px;
  width: 22px;
  border-radius: 32px;
  z-index: 1;
  background-position: center center;
  background-repeat: no-repeat;
  background-size: 45%;
  margin-right: 5px;
  transform: scale(0) rotate(0);
  opacity: 0;
  transition: all 0.3s;
}
.butt-category .butt-icon.close {
  background-image: url(../images/icons/icon-close.svg);
}
.butt-category .title {
  flex: 1;
}
.butt-category.selected {
  background: #AA9EE3;
  color: #ffffff;
  border: none;
  animation-duration: 0.3s;
  animation-fill-mode: both;
  animation-name: pulseBig;
  font-weight: 600;
}
.butt-category.selected .butt-icon {
  transform: scale(1) rotate(360deg);
  opacity: 1;
}
.butt-category.hover {
  color: #786ac1;
}

.butt-scroll {
  -webkit-appearance: none;
  width: 45px;
  height: 45px;
  border-radius: 50px;
  background-color: transparent;
  border: 2px solid white;
  background-size: 60%;
  background-repeat: no-repeat;
  background-position: center 46%;
  outline: none;
  transition: all 0.3s;
  overflow: hidden;
  margin: 10px 0;
}
.butt-scroll.up {
  background-image: url(../images/icons/icon-arrow.svg);
}
.butt-scroll.down {
  background-image: url(../images/icons/icon-arrow-down.svg);
  background-position: center 54%;
}
.butt-scroll.left {
  background-image: url(../images/icons/icon-arrow-previous.svg);
  background-size: 35%;
  background-position: 42% 50%;
}
.butt-scroll.right {
  background-image: url(../images/icons/icon-arrow-next.svg);
  background-size: 35%;
  background-position: 55% 50%;
}
.butt-scroll:hover {
  cursor: pointer;
  animation-duration: 0.3s;
  animation-fill-mode: both;
  animation-name: pulseBig;
}

.butt-add-meeting {
  margin: 0;
}

.section-main {
  min-height: calc(100vh - 150px);
  height: calc(100vh - 150px);
  display: flex;
  justify-content: center;
  align-items: center;
}
@media all and (max-width: 900px) {
  .section-main {
    height: auto;
  }
}
@media all and (max-width: 768px) {
  .section-main {
    padding-top: 70px;
  }
}
.section-main.login, .section-main.contact-us, .section-main.partners {
  height: calc(100vh - 100px);
}
@media all and (max-width: 768px) {
  .section-main.login, .section-main.contact-us, .section-main.partners {
    height: calc(100vh - 70px);
  }
}
.section-main .side-blob {
  background: #ffffff;
  border-radius: 40px;
  box-shadow: 2px 2px 40px 1px rgba(36, 58, 71, 0.25);
  color: #323333;
  padding: 40px;
}
.section-main #block-mywayemployability-content {
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  max-width: 1320px;
  padding: 0 60px;
  flex-direction: column;
}
@media all and (max-width: 1300px) {
  .section-main #block-mywayemployability-content {
    max-width: 100%;
    padding: 0;
  }
}
.section-main.align-top {
  height: auto;
}

.section-main > div:first-child {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  height: 100%;
  width: 100%;
}

.webform-submission-form {
  margin-bottom: 70px;
}
.webform-submission-form h2 {
  font-size: 2rem;
  text-align: center;
}
.webform-submission-form p {
  text-align: center;
}
.webform-submission-form #edit-processed-text {
  margin-bottom: 40px;
}
.webform-submission-form .js-form-item {
  max-width: 600px;
}
.webform-submission-form .js-form-item textarea {
  background-color: hsla(0deg, 0%, 100%, 0.45);
}
.webform-submission-form .js-form-item.form-item-consent input[type=checkbox] {
  margin-right: 0;
}
.webform-submission-form .js-form-item.form-item-consent label {
  padding: 0 10px 0 5px;
}
@media all and (max-width: 800px) {
  .webform-submission-form .js-form-item {
    width: 100%;
    padding: 0 40px;
  }
}
.webform-submission-form #edit-actions {
  text-align: center;
}
.webform-submission-form #edit-actions input[type=submit] {
  text-align: center;
  padding: 0 42px;
  margin: 20px 0 50px;
}

@media all and (max-width: 1300px) {
  .user-register-form, .user-form {
    padding: 0 50px;
  }
}
@media all and (max-width: 900px) {
  .user-register-form, .user-form {
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 0 0 50px 0;
  }
}
.user-register-form h1.header-small, .user-form h1.header-small {
  display: none;
  margin: 40px 0 50px;
}
@media all and (max-width: 900px) {
  .user-register-form h1.header-small, .user-form h1.header-small {
    display: block;
  }
}
.user-register-form h1.header, .user-form h1.header {
  text-align: center;
  margin: 50px 0 0;
}
.user-register-form .hide, .user-form .hide {
  height: 0;
  width: 0;
  pointer-events: none;
  visibility: hidden;
}
.user-register-form .form-wrap, .user-form .form-wrap {
  display: flex;
  justify-content: center;
  max-width: 1320px;
  padding: 0 60px;
  width: 100%;
}
@media all and (max-width: 800px) {
  .user-register-form .form-wrap, .user-form .form-wrap {
    padding: 0 20px;
  }
}
.user-register-form .form-info-copy, .user-form .form-info-copy {
  text-align: center;
}
.user-register-form .profile-section, .user-form .profile-section {
  margin-bottom: 60px;
}
.user-register-form .profile-section h2, .user-form .profile-section h2 {
  font-size: 1.2rem;
  border-bottom: 1px solid;
  padding-bottom: 10px;
  margin-bottom: 30px;
}
.user-register-form .profile-section.email-preferences .field--type-mailchimp-lists-subscription [data-drupal-selector=edit-field-subscribers-0-value], .user-form .profile-section.email-preferences .field--type-mailchimp-lists-subscription [data-drupal-selector=edit-field-subscribers-0-value] {
  border: none;
  padding: 0;
  margin-bottom: 0;
}
.user-register-form .profile-section.email-preferences .field--type-mailchimp-lists-subscription [data-drupal-selector=edit-field-subscribers-0-value] .form-item-field-subscribers-0-value-subscribe, .user-form .profile-section.email-preferences .field--type-mailchimp-lists-subscription [data-drupal-selector=edit-field-subscribers-0-value] .form-item-field-subscribers-0-value-subscribe {
  display: none;
}
.user-register-form .profile-section.email-preferences .field--type-mailchimp-lists-subscription [data-drupal-selector=edit-field-subscribers-0-value-interest-groups], .user-form .profile-section.email-preferences .field--type-mailchimp-lists-subscription [data-drupal-selector=edit-field-subscribers-0-value-interest-groups] {
  border: none;
  padding: 0;
  margin-bottom: 0;
  display: block !important;
}
.user-register-form .profile-section.email-preferences .field--type-mailchimp-lists-subscription [data-drupal-selector=edit-field-subscribers-0-value-interest-groups] .js-form-item:last-child, .user-form .profile-section.email-preferences .field--type-mailchimp-lists-subscription [data-drupal-selector=edit-field-subscribers-0-value-interest-groups] .js-form-item:last-child {
  margin-bottom: 0;
}
.user-register-form .profile-section.email-preferences .field--type-mailchimp-lists-subscription input, .user-form .profile-section.email-preferences .field--type-mailchimp-lists-subscription input {
  margin-right: 0;
}
.user-register-form .profile-section.email-preferences .field--type-mailchimp-lists-subscription legend, .user-form .profile-section.email-preferences .field--type-mailchimp-lists-subscription legend {
  display: none;
}
.user-register-form .profile-section.email-preferences .field--type-mailchimp-lists-subscription #edit-field-subscribers-0-value--description, .user-form .profile-section.email-preferences .field--type-mailchimp-lists-subscription #edit-field-subscribers-0-value--description {
  font-size: 0.75rem;
  font-style: italic;
  text-align: center;
  margin-top: 20px;
}

.side-blob.register {
  width: 35%;
  min-width: 200px;
  padding-bottom: 20px;
  background: transparent;
  box-shadow: none;
}
@media all and (max-width: 900px) {
  .side-blob.register {
    width: 70%;
    height: auto;
  }
}
.side-blob.register p {
  margin: 0;
}
.side-blob.register .avatar-list {
  width: 100%;
  position: relative;
  display: flex;
  flex-flow: wrap;
  justify-content: space-around;
  padding: 20px 0;
}
@media all and (max-width: 900px) {
  .side-blob.register .avatar-list {
    height: auto;
    justify-content: center;
  }
}
.side-blob.register .avatar-list .avatar {
  width: 40%;
  height: 90px;
  display: inline-block;
  margin-bottom: 20px;
  border-radius: 30px;
  position: relative;
  overflow: hidden;
  background: white;
}
@media all and (max-width: 900px) {
  .side-blob.register .avatar-list .avatar {
    width: 20%;
    margin: 0 10px;
  }
}
.side-blob.register .avatar-list .avatar img {
  object-fit: contain;
  width: 105%;
  height: 105%;
  transition: all 0.4s;
}
.side-blob.register .avatar-list .avatar:hover {
  cursor: pointer;
  animation-duration: 0.4s;
  animation-fill-mode: both;
  animation-name: pulse;
}
.side-blob.register .avatar-list .avatar:hover img {
  transform: scale(1.3) rotate(8deg);
}
.side-blob.register .avatar-list .avatar.selected {
  animation-duration: 0.3s;
  animation-fill-mode: both;
  animation-name: pulseBig;
  border: 3px solid #ffc371;
}
.side-blob.register .image-upload {
  padding-top: 30px;
  text-align: center;
}
.side-blob.register .image-upload label {
  visibility: hidden;
}
.side-blob.register .image-upload input[name="files[user_picture_0]"] {
  visibility: hidden;
}
.side-blob.register .image-upload .image-widget {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 20px;
}
.side-blob.register .image-upload .image-widget img {
  border-radius: 20px;
  width: 70px;
  height: 70px;
}
.side-blob.register .image-upload .image-widget span {
  padding: 0 10px;
}
.side-blob.register .image-upload .image-widget span a {
  max-width: 100px;
  overflow: hidden;
  display: block;
  word-break: break-all;
}
.side-blob.register .image-upload .image-widget .file-size {
  display: none;
}
.side-blob.register .image-upload .image-widget input[name=user_picture_0_remove_button] {
  text-align: center;
  justify-content: center;
  margin: 0;
  padding: 0;
  width: 100px;
}

.register-content {
  flex: 1;
  width: 70%;
}
.register-content h1 {
  display: block;
}
@media all and (max-width: 900px) {
  .register-content h1 {
    display: none;
  }
}
.register-content .team-member-info {
  display: none;
}
.register-content #show-confirm-pw {
  margin: 30px 0;
}
.register-content #show-confirm-pw .butt-general {
  margin: 0;
  width: 100%;
  transition: transform 0.3s ease-out;
}
.register-content .user-profile-edit {
  justify-content: center !important;
}
.register-content .user-profile-edit .hide {
  width: auto !important;
}
.register-content .user-profile-edit input.button.button--primary.btn-show-confirm-pw {
  margin-left: 0px !important;
}
.register-content .user-profile-edit input[type=submit] {
  justify-content: center;
}
.register-content .user-profile-edit input.button.button--primary.btn-show-confirm-pw {
  margin-bottom: 20px;
}
.register-content .user-profile-edit .btn-show-confirm-pw {
  display: block;
  width: 100%;
  font-size: 0.88rem;
  text-decoration: underline;
  cursor: pointer;
  text-align: center;
  transition: all 0.2s;
}
.register-content .user-profile-edit .btn-show-confirm-pw:hover {
  transform: scale(1.05);
}
.register-content .user-profile-edit label {
  align-items: center;
  display: flex;
  font-size: 0.88rem;
  font-weight: 700;
  margin: 0 0 5px;
  max-height: 50px;
  padding: 0 0 0 20px;
  pointer-events: none;
  transition: all 0.3s;
  color: #323333;
}
.register-content .form-content {
  max-width: 390px;
  margin: 0 auto;
}
@media all and (max-width: 800px) {
  .register-content .form-content {
    padding: 30px 20px;
  }
}
.register-content .form-content .content-column {
  flex: 1;
  max-width: 48%;
}
.register-content .form-content .custom-field {
  position: relative;
}
.register-content .form-content .custom-field .show-parent-consent {
  position: absolute;
  width: 40px;
  height: 40px;
  left: -50px;
  top: 5px;
  border-radius: 40px;
  border: none;
  -webkit-appearance: none;
  pointer-events: cursor;
  cursor: pointer;
  background: #5da5cd url(../images/icons/icon-user.svg) center center no-repeat;
  display: none;
}
.register-content .form-content .custom-field .show-parent-consent.visible {
  display: block;
}
@media all and (max-width: 768px) {
  .register-content .form-content .custom-field .show-parent-consent {
    left: -40px;
  }
}
.register-content .form-content .custom-field p {
  margin-top: 40px;
  font-size: 0.9em;
}
.register-content .form-content .custom-field .description > a,
.register-content .form-content .custom-field .link-dark {
  color: #323333;
}
.register-content .form-content .custom-field.hide {
  visibility: hidden;
}
.register-content .form-content .custom-field .field--type-address summary {
  display: none;
}
.register-content .form-content #confirm-pw {
  height: 0;
  overflow: hidden;
  transition: height 0.3s;
}
.register-content .form-content #confirm-pw.visible {
  height: 250px;
}
@media all and (max-width: 800px) {
  .register-content .form-content #confirm-pw.visible {
    height: 300px;
  }
}
.register-content .form-content .form-actions {
  text-align: center;
  margin-top: 40px;
}
.register-content .form-content .form-actions #edit-submit {
  width: 220px;
  justify-content: center;
  padding: 0;
}
.register-content .form-content .form-actions .butt-general {
  margin: 0;
}
.register-content .form-content .form-actions .butt-general .butt-icon.register {
  background-image: url(../images/icons/icon-register-light.svg);
}
.register-content .form-content .already-registered {
  margin: 60px 0 30px;
  text-align: center;
}

.team-member .side-blob.register {
  display: none;
}
.team-member .register-content {
  padding: 0;
  max-width: 720px;
}
.team-member .register-content h1 {
  text-align: center;
}
.team-member .register-content h1.youth-member {
  display: none;
}
.team-member .register-content .team-member-info {
  display: block;
  text-align: center;
  color: #323333;
}

.parent-consent-popup {
  position: fixed;
  width: 100vw;
  height: 100%;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  margin: auto;
  background: rgba(93, 165, 205, 0.6);
  z-index: 2;
  display: none;
  justify-content: center;
  align-items: center;
}
.parent-consent-popup.visible {
  display: flex;
}
.parent-consent-popup .error-message {
  display: none;
  border-radius: 10px;
  font-size: 0.9em;
  background: #ea8782;
  color: #ffffff;
  padding: 10px;
  margin-top: 5px;
  text-align: center;
}
.parent-consent-popup .error-message.visible {
  display: block;
}
.parent-consent-popup .parent-consent-fields {
  color: #323333;
  width: 40%;
  background: white;
  padding: 20px 40px 40px;
  border-radius: 30px;
  min-width: 500px;
  text-align: center;
  position: relative;
}
@media all and (max-width: 768px) {
  .parent-consent-popup .parent-consent-fields {
    min-width: 90%;
    width: 90%;
  }
}
.parent-consent-popup .parent-consent-fields .consent-form {
  display: none;
}
.parent-consent-popup .parent-consent-fields .consent-form.visible {
  display: block;
}
.parent-consent-popup .parent-consent-fields .custom-field {
  text-align: left;
}
.parent-consent-popup .parent-consent-fields .custom-field #edit-field-parent-consent-wrapper {
  font-size: 0.75em;
}
.parent-consent-popup .parent-consent-fields p {
  padding-bottom: 20px;
}
.parent-consent-popup .parent-consent-fields .form-item input[type=text],
.parent-consent-popup .parent-consent-fields .form-item input[type=email] {
  background: #c0e5f7;
}
.parent-consent-popup .parent-consent-fields .form-item.js-form-type-checkbox {
  display: flex;
  align-items: flex-start;
}
.parent-consent-popup .parent-consent-fields .form-item.js-form-type-checkbox label {
  display: none;
}
.parent-consent-popup .butt-close {
  position: absolute;
  top: 0;
  right: -50px;
  width: 40px;
  height: 40px;
  border-radius: 40px;
  border: none;
  background: white;
  color: #59A3D0;
  font-size: 20px;
  line-height: 30px;
  padding: 0;
  margin: 0;
  padding-bottom: 5px;
  text-align: center;
  cursor: pointer;
}
@media all and (max-width: 768px) {
  .parent-consent-popup .butt-close {
    right: 5px;
    top: 5px;
  }
}

.social-auth .auth-icon {
  width: 18em !important;
}

.login-content {
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
}
.login-content .form-content {
  max-width: 390px;
}
.login-content .form-content .butt-general {
  margin: 0;
}
@media all and (max-width: 800px) {
  .login-content .form-content {
    padding: 30px 20px;
  }
}
.login-content p {
  margin: 40px 0;
  font-size: 0.9em;
}
.login-content .link-dark {
  color: #323333;
}

.section-main h1 {
  margin: 20px 0;
}
.section-main .form-wrap {
  display: flex;
  justify-content: center;
  padding: 30px 60px;
  width: 100%;
}
@media all and (max-width: 800px) {
  .section-main .form-wrap {
    padding: 30px 20px;
  }
}
.section-main .social-auth.auth-link {
  display: block;
}
.section-main .error-message {
  display: none;
  border-radius: 10px;
  font-size: 0.9em;
  background: #ea8782;
  color: #ffffff;
  padding: 10px;
  margin-bottom: 5px;
  margin-top: 5px;
  text-align: center;
}
.section-main .error-message.visible {
  display: block;
}

.resources-wrap {
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  width: 1200px;
  padding-top: 50px;
  min-height: 80vh;
}
@media all and (max-width: 1300px) {
  .resources-wrap {
    width: 100%;
    padding: 30px 30px 0 30px;
  }
}
@media all and (max-width: 800px) {
  .resources-wrap {
    max-width: 100%;
    padding: 30px 20px;
    flex-direction: column;
  }
}
.resources-wrap .side-blob.resources {
  width: 23%;
  min-width: 250px;
  text-align: center;
  overflow: hidden;
  position: relative;
}
@media all and (max-width: 800px) {
  .resources-wrap .side-blob.resources {
    width: 100%;
    margin-bottom: 30px;
    padding: 20px 40px;
  }
  .resources-wrap .side-blob.resources#resources-categories {
    height: 112px;
  }
}
.resources-wrap .side-blob.resources .heading {
  text-align: left;
}
.resources-wrap .side-blob.resources .heading h1 {
  margin-top: 0;
}
.resources-wrap .side-blob.resources .heading .butt-toggle-categories {
  position: absolute;
  top: 65px;
  right: 30px;
  width: 40px;
  height: 40px;
  background: transparent url(../images/icons/icon-arrow-purple.svg) center center no-repeat;
  transform: rotate(90deg);
  cursor: pointer;
  transition: all 0.2s;
  padding: 0;
  border: none;
  display: none;
}
.resources-wrap .side-blob.resources .heading .butt-toggle-categories:hover {
  transform: rotate(90deg) scale(1.1);
}
@media all and (max-width: 800px) {
  .resources-wrap .side-blob.resources .heading .butt-toggle-categories {
    display: block;
  }
}
.resources-wrap .side-blob.resources.show-all#resources-categories {
  height: auto;
}
.resources-wrap .side-blob.resources.show-all .butt-toggle-categories {
  transform: rotate(270deg);
}
.resources-wrap .side-blob.resources.show-all .butt-toggle-categories:hover {
  transform: rotate(270deg) scale(1.1);
}
.resources-wrap .side-blob.resources .categories {
  margin-bottom: 20px;
}
@media all and (max-width: 800px) {
  .resources-wrap .side-blob.resources .categories {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;
  }
  .resources-wrap .side-blob.resources .categories form {
    width: 100%;
  }
}
@media all and (max-width: 800px) {
  .resources-wrap .side-blob.resources .categories .butt-category .title {
    margin-right: 5px;
  }
}
.resources-wrap .side-blob.resources .categories legend {
  display: none;
}
.resources-wrap .side-blob.resources .categories .js-form-type-checkbox {
  height: auto;
  min-height: 36px;
  border-radius: 21px;
  border: 2px solid #E4E0F7;
  background: transparent;
  width: 100%;
  text-align: left;
  position: relative;
  outline: none;
  overflow: hidden;
  margin: 0;
  display: flex;
  flex-direction: row-reverse;
  align-items: center;
  transition: all 0.2s;
  margin-bottom: 5px;
  justify-content: space-between;
}
.resources-wrap .side-blob.resources .categories .js-form-type-checkbox:hover {
  cursor: pointer;
  animation-duration: 0.4s;
  animation-fill-mode: both;
  animation-name: pulse;
}
.resources-wrap .side-blob.resources .categories .form-item input[type=checkbox] {
  height: 22px !important;
  width: 22px !important;
  border-radius: 32px;
  margin-right: 5px;
  opacity: 0;
  transform: scale(0) rotate(0);
  transition: all 0.3s;
  padding: 0;
  border: none;
}
.resources-wrap .side-blob.resources .categories .form-item input[type=checkbox]:checked {
  background-color: #fff;
}
.resources-wrap .side-blob.resources .categories .form-item label {
  font-family: "Open Sans", sans-serif;
  color: #323333;
  font-size: 13px;
  font-weight: normal;
  align-items: left;
  display: inline-block;
  margin: 0;
  padding: 0;
  pointer-events: all;
  flex: 1;
  height: 100%;
  padding: 8px 0 8px 10px;
  line-height: 13px;
  cursor: pointer;
}
.resources-wrap .side-blob.resources .categories .form-item.highlight {
  animation-name: pulseBig;
  background: #AA9EE3;
  border: none;
}
.resources-wrap .side-blob.resources .categories .form-item.highlight label {
  color: #323333;
  font-weight: 600;
}
.resources-wrap .side-blob.resources .categories .form-item.highlight input[type=checkbox] {
  opacity: 1;
  transform: scale(1) rotate(1turn);
}
.resources-wrap .side-blob.resources .categories .js-form-submit {
  background: transparent;
  border: 2px solid #7b68c8;
  color: #7b68c8;
  margin: 0;
  max-width: 180px;
  width: 100%;
}
.resources-wrap .side-blob.resources .categories .js-form-submit .animation-bubble {
  background: #e4e0f7;
}
.resources-wrap .side-blob.resources .categories .js-form-submit .butt-icon {
  background-color: #7b68c8;
  background-image: url(../images/icons/icon-close-light.svg);
  background-size: 45%;
}
.resources-wrap .resources-content {
  flex: 1;
  width: 80%;
  display: flex;
  flex-direction: row;
}
@media all and (max-width: 800px) {
  .resources-wrap .resources-content {
    width: 100%;
  }
}
.resources-wrap .resources-content .resource-controls {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding-left: 20px;
  padding-bottom: 50px;
}
@media all and (max-width: 80px) {
  .resources-wrap .resources-content .resource-controls {
    display: none;
  }
}
.resources-wrap .resources-content .content-wrap {
  flex: 1;
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  justify-content: flex-start;
  max-height: 560px;
  height: 560px;
  overflow: hidden;
  padding: 0 0 0 50px;
  margin-top: -20px;
}
@media all and (max-width: 800px) {
  .resources-wrap .resources-content .content-wrap {
    padding: 0;
    overflow: visible;
    justify-content: space-around;
    max-height: none;
    height: auto;
  }
}
.resources-wrap .resources-content .vertical-content-wrap {
  padding: 0 0 0 50px;
  width: 100%;
}
@media all and (max-width: 800px) {
  .resources-wrap .resources-content .vertical-content-wrap {
    padding: 0;
  }
}
.resources-wrap .resources-content .vertical-content-wrap .resource {
  display: none;
  width: 100%;
  background: #ffffff;
  border-radius: 10px 10px 24px 24px;
  padding: 10px 20px 20px 10px;
  color: #323333;
  margin: 20px 18px 30px 0;
  overflow: hidden;
  text-decoration: none;
  cursor: initial;
}
.resources-wrap .resources-content .vertical-content-wrap .resource.show {
  display: flex;
}
@media all and (max-width: 800px) {
  .resources-wrap .resources-content .vertical-content-wrap .resource.show {
    flex-direction: column;
    padding: 20px;
  }
}
.resources-wrap .resources-content .vertical-content-wrap .resource .image {
  height: 100px;
  min-width: 180px;
  background: #c0e5f7;
  border-radius: 10px;
  transition: all 0.3s;
  overflow: hidden;
  aspect-ratio: 16/9;
  /*@media all and (max-width: 1300px) {
      height: 100px;
  }*/
}
@media all and (max-width: 800px) {
  .resources-wrap .resources-content .vertical-content-wrap .resource .image {
    margin-bottom: 10px;
    height: 150px;
  }
}
.resources-wrap .resources-content .vertical-content-wrap .resource .image a {
  display: block;
  width: 100%;
  height: 100%;
}
.resources-wrap .resources-content .vertical-content-wrap .resource .image img {
  object-fit: cover;
  width: 100%;
  height: 100%;
  transition: all 0.4s;
  transform: scale(1) rotate(0);
}
.resources-wrap .resources-content .vertical-content-wrap .resource .details {
  padding-left: 20px;
}
@media all and (max-width: 800px) {
  .resources-wrap .resources-content .vertical-content-wrap .resource .details {
    padding-left: 0;
  }
}
.resources-wrap .resources-content .vertical-content-wrap .resource .details .categories {
  color: #ffffff;
}
.resources-wrap .resources-content .vertical-content-wrap .resource .details .categories .category {
  display: inline-block;
  font-size: 0.75rem;
  background: #E4E0F7;
  width: fit-content;
  padding: 5px 10px;
  border-radius: 30px;
  color: #323333;
  text-decoration: none;
  margin-bottom: 5px;
  pointer-events: none;
}
.resources-wrap .resources-content .vertical-content-wrap .resource .details .categories ul {
  margin: 0;
  padding: 0;
  display: flex;
  list-style-type: none;
}
.resources-wrap .resources-content .vertical-content-wrap .resource .details .categories ul li {
  font-size: 0.75rem;
  background: #E4E0F7;
  width: fit-content;
  padding: 5px 10px;
  border-radius: 30px;
  color: #323333;
  text-decoration: none;
  margin-bottom: 5px;
  margin-right: 5px;
  pointer-events: none;
}
.resources-wrap .resources-content .vertical-content-wrap .resource .details h2 {
  margin: 0 0 5px 0;
}
.resources-wrap .resources-content .vertical-content-wrap .resource .details h2 a {
  color: #323333;
  text-decoration: none;
}
.resources-wrap .resources-content .vertical-content-wrap .resource .details p {
  margin: 0;
  padding: 0;
}
.resources-wrap .resources-content .vertical-content-wrap .resource .details .author {
  font-size: 0.88rem;
  font-style: italic;
  margin-top: 10px;
}
.resources-wrap .resources-content .vertical-content-wrap .resource:hover {
  cursor: pointer;
  animation-duration: 0.3s;
  animation-fill-mode: both;
  animation-name: pulse;
}
.resources-wrap .resources-content .vertical-content-wrap .resource:hover img {
  transform: scale(1.1) rotate(2deg);
}
@media all and (max-width: 768px) {
  .resources-wrap .resources-content .vertical-content-wrap .resource {
    width: 100%;
  }
}
.resources-wrap .resources-content .vertical-content-wrap .js-pager__items {
  display: flex;
  list-style-type: none;
  justify-content: center;
  align-items: center;
  padding: 30px 0;
  margin: 0;
}
.resources-wrap .resources-content .vertical-content-wrap .js-pager__items li {
  margin: 0 10px;
}
.resources-wrap .resources-content .vertical-content-wrap .js-pager__items li.pager__item--first,
.resources-wrap .resources-content .vertical-content-wrap .js-pager__items li.pager__item--previous,
.resources-wrap .resources-content .vertical-content-wrap .js-pager__items li.pager__item--next,
.resources-wrap .resources-content .vertical-content-wrap .js-pager__items li.pager__item--last,
.resources-wrap .resources-content .vertical-content-wrap .js-pager__items li.pager__item {
  padding: 0 10px;
  margin: 0;
}
@media all and (max-width: 800px) {
  .resources-wrap .resources-content .vertical-content-wrap .js-pager__items li.pager__item--first,
.resources-wrap .resources-content .vertical-content-wrap .js-pager__items li.pager__item--previous,
.resources-wrap .resources-content .vertical-content-wrap .js-pager__items li.pager__item--next,
.resources-wrap .resources-content .vertical-content-wrap .js-pager__items li.pager__item--last,
.resources-wrap .resources-content .vertical-content-wrap .js-pager__items li.pager__item {
    padding: 0 5px;
  }
}
.resources-wrap .resources-content .vertical-content-wrap .js-pager__items li.pager__item--first a,
.resources-wrap .resources-content .vertical-content-wrap .js-pager__items li.pager__item--previous a,
.resources-wrap .resources-content .vertical-content-wrap .js-pager__items li.pager__item--next a,
.resources-wrap .resources-content .vertical-content-wrap .js-pager__items li.pager__item--last a,
.resources-wrap .resources-content .vertical-content-wrap .js-pager__items li.pager__item a {
  height: 40px;
  width: 40px;
  background: #ffffff;
  border-radius: 40px;
  display: flex;
  justify-content: center;
  font-weight: bold;
  text-decoration: none;
  color: #323333;
  transition: all 0.2s;
  width: fit-content;
  min-width: 40px;
  padding: 8px 10px;
  white-space: nowrap;
}
.resources-wrap .resources-content .vertical-content-wrap .js-pager__items li.pager__item--first a:hover,
.resources-wrap .resources-content .vertical-content-wrap .js-pager__items li.pager__item--previous a:hover,
.resources-wrap .resources-content .vertical-content-wrap .js-pager__items li.pager__item--next a:hover,
.resources-wrap .resources-content .vertical-content-wrap .js-pager__items li.pager__item--last a:hover,
.resources-wrap .resources-content .vertical-content-wrap .js-pager__items li.pager__item a:hover {
  transform: scale(1.1);
}
@media all and (max-width: 800px) {
  .resources-wrap .resources-content .vertical-content-wrap .js-pager__items li.pager__item--first a,
.resources-wrap .resources-content .vertical-content-wrap .js-pager__items li.pager__item--previous a,
.resources-wrap .resources-content .vertical-content-wrap .js-pager__items li.pager__item--next a,
.resources-wrap .resources-content .vertical-content-wrap .js-pager__items li.pager__item--last a,
.resources-wrap .resources-content .vertical-content-wrap .js-pager__items li.pager__item a {
    font-size: 0.75rem;
    min-width: fit-content;
    padding: 10px;
  }
}
.resources-wrap .resources-content .vertical-content-wrap .js-pager__items li.pager__item--first.is-active a,
.resources-wrap .resources-content .vertical-content-wrap .js-pager__items li.pager__item--previous.is-active a,
.resources-wrap .resources-content .vertical-content-wrap .js-pager__items li.pager__item--next.is-active a,
.resources-wrap .resources-content .vertical-content-wrap .js-pager__items li.pager__item--last.is-active a,
.resources-wrap .resources-content .vertical-content-wrap .js-pager__items li.pager__item.is-active a {
  background: #7b68c8;
  color: #ffffff;
}

@media all and (max-width: 800px) {
  .resources-wrap#resource {
    flex-direction: column-reverse;
  }
}
.resources-wrap#resource .side-blob-wrap {
  width: 250px;
}
.resources-wrap#resource .side-blob-wrap .previous-next-wrap {
  display: none;
}
.resources-wrap#resource .side-blob-wrap .resource-breadcrumbs {
  margin-bottom: 20px;
  font-size: 0.88rem;
}
@media all and (max-width: 800px) {
  .resources-wrap#resource .side-blob-wrap {
    width: 100%;
    margin-top: 0;
  }
  .resources-wrap#resource .side-blob-wrap .previous-next-wrap {
    display: block;
  }
  .resources-wrap#resource .side-blob-wrap .previous-next-wrap .butt-general {
    width: 130px;
  }
  .resources-wrap#resource .side-blob-wrap .resource-breadcrumbs {
    display: none;
  }
}
.resources-wrap#resource .side-blob {
  margin-bottom: 40px;
  text-align: left;
  padding: 30px;
}
@media all and (max-width: 800px) {
  .resources-wrap#resource .side-blob {
    width: 100%;
  }
}
@media print {
  .resources-wrap#resource .side-blob {
    display: none !important;
  }
}
.resources-wrap#resource .side-blob .related-article-link {
  display: block;
  margin-bottom: 10px;
  font-size: 0.88rem;
  font-weight: bold;
  text-decoration: none;
}
.resources-wrap#resource .side-blob .author {
  margin-bottom: 20px;
}
.resources-wrap#resource .side-blob .author .author-detail {
  font-size: 0.88rem;
  font-style: italic;
  margin-top: 5px;
}
@media all and (max-width: 800px) {
  .resources-wrap#resource .side-blob .share {
    display: flex;
    justify-content: space-around;
  }
}
@media all and (max-width: 370px) {
  .resources-wrap#resource .side-blob .share {
    flex-direction: column;
  }
}
.resources-wrap#resource .side-blob .butt-share {
  background: none;
  font-family: "Open Sans", sans-serif;
  color: #323333;
  border: none;
  display: block;
  font-size: 0.88rem;
  padding: 0;
  cursor: pointer;
  text-decoration: none;
  color: #4C4180;
  font-weight: bold;
  background: url("../images/icons/icon-copy.svg") left center no-repeat;
  background-size: 18px;
  padding-left: 25px;
  margin-bottom: 5px;
  transition: all 0.2s;
}
.resources-wrap#resource .side-blob .butt-share.email {
  background: url("../images/icons/icon-email.svg") left center no-repeat;
  background-size: 18px;
}
.resources-wrap#resource .side-blob .butt-share.print {
  background: url("../images/icons/icon-print.svg") left center no-repeat;
  background-size: 18px;
}
@media all and (max-width: 800px) {
  .resources-wrap#resource .side-blob .butt-share {
    height: 40px;
    background-size: 22px;
    display: flex;
    justify-content: left;
    align-items: center;
    padding-left: 30px;
    margin-right: 10px;
  }
  .resources-wrap#resource .side-blob .butt-share.email, .resources-wrap#resource .side-blob .butt-share.print {
    background-size: 22px;
  }
}
.resources-wrap#resource .side-blob .butt-share:hover {
  color: #323333;
}
.resources-wrap#resource .side-blob h2 {
  font-size: 1.1rem;
  margin: 0 0 20px 0;
}
.resources-wrap#resource .side-back {
  margin-bottom: 20px;
}
.resources-wrap#resource .side-back .butt-general {
  margin: 0;
}

.resource-content {
  flex: 1;
  margin-top: 40px;
  padding: 0 0 0 50px;
  color: #323333;
  width: calc(100% - 360px);
}
@media all and (max-width: 800px) {
  .resource-content {
    padding: 0;
    width: 100%;
    margin-top: 0;
  }
}
.resource-content .resource-breadcrumbs {
  margin-bottom: 20px;
  font-size: 0.88rem;
  display: none;
}
@media all and (max-width: 800px) {
  .resource-content .resource-breadcrumbs {
    display: block;
  }
}
.resource-content .content-wrap {
  background: white;
  border-radius: 20px;
  padding: 20px 40px 0;
  margin-bottom: 30px;
}
.resource-content .subtitle {
  font-size: 1.2em;
}
.resource-content .image-wrap {
  width: 100%;
  height: 280px;
  overflow: hidden;
  position: relative;
}
.resource-content .image-wrap img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.resource-content .category-links {
  padding: 0 0 20px;
  display: flex;
  justify-content: flex-start;
}
.resource-content .category-links .category {
  display: inline-block;
  font-size: 0.75rem;
  background: #E4E0F7;
  width: fit-content;
  padding: 5px 10px;
  border-radius: 30px;
  color: #323333;
  text-decoration: none;
  margin-bottom: 5px;
  margin-right: 5px;
  pointer-events: none;
}
.resource-content .article {
  padding-bottom: 50px;
}
@media all and (max-width: 500px) {
  .resource-content .article {
    overflow: scroll;
  }
}
.resource-content .article table {
  max-width: 100% !important;
}
.resource-content .previous-next-wrap {
  height: 42px;
  margin: 0 0 20px;
}
.resource-content .previous-next-wrap .butt-general {
  width: 140px;
}
@media all and (max-width: 800px) {
  .resource-content .previous-next-wrap {
    display: none;
  }
}
.resource-content footer {
  width: 100%;
}

.contact-wrap {
  text-align: center;
}
@media all and (max-width: 768px) {
  .contact-wrap {
    width: 100%;
  }
}
.contact-wrap .contact-details {
  background: white;
  border-radius: 30px;
  padding: 50px 80px;
  margin: 20px 50px;
  min-width: 600px;
  color: #323333;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}
@media all and (max-width: 900px) {
  .contact-wrap .contact-details {
    padding: 50px;
  }
}
@media all and (max-width: 768px) {
  .contact-wrap .contact-details {
    min-width: 90%;
    width: 90%;
    margin: 20px auto;
    flex-direction: column;
  }
}
.contact-wrap .contact-details .logo {
  min-width: 200px;
  width: 200px;
  position: relative;
}
.contact-wrap .contact-details .logo img {
  object-fit: contain;
  width: 100%;
}
.contact-wrap .contact-details .details {
  padding-left: 20px;
}
.contact-wrap .contact-details .details .detail {
  display: flex;
  margin: 20px 0;
  align-items: center;
  text-align: left;
}
@media all and (max-width: 900px) {
  .contact-wrap .contact-details .details .detail {
    align-items: flex-start;
  }
}
@media all and (max-width: 768px) {
  .contact-wrap .contact-details .details .detail {
    padding: 0;
  }
}
.contact-wrap .contact-details .details .detail strong {
  color: #59A3D0;
  font-size: 0.9em;
  font-weight: 300;
  min-width: 100px;
  text-align: right;
  padding-right: 20px;
}

.privacy-terms-wrap {
  margin: 50px;
  color: #323333;
  background: #ffffff;
  border-radius: 20px;
  padding: 20px 40px 0;
}
.privacy-terms-wrap h1 {
  text-align: center;
  margin-bottom: 40px;
}
@media all and (max-width: 1300px) {
  .privacy-terms-wrap {
    margin: 30px;
  }
}
@media all and (max-width: 800px) {
  .privacy-terms-wrap {
    margin: 30px 20px;
    max-width: calc(100% - 40px);
  }
}
.privacy-terms-wrap img, .privacy-terms-wrap iframe {
  max-width: 100%;
  object-fit: cover;
}

.privacy-terms-form {
  margin: 0 50px 0 50px;
  max-width: 1000px;
  padding: 10px 40px 0;
}
.privacy-terms-form form {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.form-item-legal-terms-of-use {
  align-items: flex-start;
}
.form-item-legal-terms-of-use .checkbox__label-container label {
  display: block;
  padding-left: 0;
}

.partners-wrap {
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 1200px;
}
@media all and (max-width: 1300px) {
  .partners-wrap {
    width: 100vw;
  }
}
.partners-wrap .partner-content {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 50px;
}
.partners-wrap .partner-content .partner {
  width: 25%;
  background: #ffffff;
  border-radius: 10px 10px 24px 24px;
  padding: 10px;
  color: #323333;
  text-align: left;
  margin: 0 10px 20px;
  height: 230px;
  max-height: 230px;
  overflow: hidden;
  text-decoration: none;
}
@media all and (max-width: 768px) {
  .partners-wrap .partner-content .partner {
    width: 30%;
  }
}
.partners-wrap .partner-content .partner h2 {
  font-size: 1rem;
  margin: 15px 0 0;
}
.partners-wrap .partner-content .partner .image {
  width: 100%;
  height: 130px;
  background: #c0e5f7;
  border-radius: 10px;
  transition: all 0.3s;
  overflow: hidden;
}
.partners-wrap .partner-content .partner .image img {
  object-fit: contain;
  width: 100%;
  height: 100%;
  transition: all 0.4s;
  transform: scale(1) rotate(0);
}
@media all and (max-width: 1024px) {
  .partners-wrap .partner-content .partner .image {
    height: 110px;
  }
}
.partners-wrap .partner-content .partner p {
  margin-bottom: 5px;
  padding: 0 5px;
  font-size: 0.8em;
  overflow: hidden;
  overflow-wrap: break-word;
}
.partners-wrap .partner-content .partner:hover {
  cursor: pointer;
  animation-duration: 0.3s;
  animation-fill-mode: both;
  animation-name: pulseBig;
}
.partners-wrap .partner-content .partner:hover img {
  transform: scale(1.1) rotate(3deg);
}

.home-wrap {
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 1200px;
}
.home-wrap #pre-splash-header {
  margin-top: 50px;
  text-align: center;
}
.home-wrap #pre-splash-header h1 {
  color: #ffffff;
  margin: 0 auto 30px auto;
}
@media (max-width: 768px) {
  .home-wrap #pre-splash-header h2 {
    max-width: 20em;
  }
}
@media all and (max-width: 1300px) {
  .home-wrap {
    width: 100%;
    padding: 0 30px;
  }
}
@media all and (max-width: 800px) {
  .home-wrap {
    max-width: 100%;
    padding: 0 20px;
    overflow: hidden;
  }
}
.home-wrap .splash {
  width: 100%;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  padding: 0 0 3.5vh;
}
@media all and (max-width: 1300px) {
  .home-wrap .splash {
    flex-direction: column;
    height: auto;
    margin: 0;
    width: 90%;
    padding: 0;
    margin-bottom: 30px;
  }
}
.home-wrap .splash .splash-image,
.home-wrap .splash .splash-logo {
  flex: 1;
  text-align: center;
}
.home-wrap .splash .splash-image img,
.home-wrap .splash .splash-logo img {
  width: 80%;
  object-fit: contain;
}
.home-wrap .splash .splash-logo,
.home-wrap .splash .splash-text {
  max-width: 40%;
  font-weight: 600;
  color: #323333;
  text-align: center;
}
@media (max-width: 1300px) {
  .home-wrap .splash .splash-logo,
.home-wrap .splash .splash-text {
    max-width: 100%;
  }
}
.home-wrap .splash .splash-logo p,
.home-wrap .splash .splash-text p {
  margin-left: auto;
  margin-right: auto;
  max-width: 24em;
  font-size: 1.35em;
}
.home-wrap .splash .splash-image {
  flex: 1;
  text-align: center;
  max-width: 50%;
}
@media all and (max-width: 1300px) {
  .home-wrap .splash .splash-image {
    max-width: 450px;
    margin-top: 30px auto 0 auto;
  }
}
.home-wrap .splash .splash-image img {
  width: 100%;
  object-fit: contain;
}
.home-wrap section.first-actions {
  margin-bottom: 50px;
}
.home-wrap section.first-actions div {
  text-align: center;
}
.home-wrap .what-is {
  background: #ffffff;
  border-radius: 50px;
  text-align: center;
  max-width: 83%;
  padding: 20px 50px 40px;
  box-shadow: 2px 2px 40px 1px rgba(36, 58, 71, 0.25);
}
.home-wrap .what-is h2 {
  color: #323333;
}
@media all and (max-width: 1100px) {
  .home-wrap .what-is {
    max-width: 100%;
    padding: 20px;
  }
}
.home-wrap .what-is .what-is-content {
  display: flex;
  text-align: left;
  color: #323333;
  padding-top: 0;
}
@media all and (max-width: 1100px) {
  .home-wrap .what-is .what-is-content {
    flex-direction: column-reverse;
  }
}
.home-wrap .what-is .what-is-content .image-wrap {
  flex: 1;
  max-width: 30%;
  margin: 0 50px;
  min-width: 250px;
  align-self: flex-start;
}
@media all and (max-width: 1100px) {
  .home-wrap .what-is .what-is-content .image-wrap {
    min-width: auto;
    max-width: 100%;
    margin: auto;
  }
}
.home-wrap .what-is .what-is-content .image-wrap img {
  object-fit: contain;
  width: 100%;
}
@media all and (max-width: 1100px) {
  .home-wrap .what-is .what-is-content .image-wrap img {
    max-width: 450px;
    margin-bottom: 20px;
  }
}
.home-wrap .what-is .what-is-content .text-wrap {
  max-width: 110%;
}
@media all and (max-width: 1100px) {
  .home-wrap .what-is .what-is-content .text-wrap {
    max-width: 100%;
  }
}
.home-wrap .what-is .what-is-content > div {
  margin: 0 10px;
}
.home-wrap .what-is .what-is-content h2 {
  margin: 0;
  color: #323333;
}
.home-wrap .what-is .what-is-content ul {
  padding-left: 1.1rem;
}
.home-wrap .what-is .what-is-content ul li {
  margin-bottom: 0.5em;
}
.home-wrap .what-is .what-is-content ul li:first-child::marker {
  color: #59a3d0;
}
.home-wrap .what-is .what-is-content ul li:nth-child(2)::marker {
  color: #ea8782;
}
.home-wrap .what-is .what-is-content ul li:nth-child(3)::marker {
  color: #f4c760;
}
.home-wrap .what-is .what-is-content ul li:nth-child(4)::marker {
  color: #88cb88;
}
.home-wrap .what-is .what-is-content ul {
  padding-left: 1.1rem;
}
.home-wrap .what-is .what-is-content ul li {
  margin-bottom: 0.5em;
  font-size: 1.125rem;
}
.home-wrap .what-is .what-is-content ul li:first-child::marker {
  color: #59a3d0;
}
.home-wrap .what-is .what-is-content ul li:nth-child(2)::marker {
  color: #ea8782;
}
.home-wrap .what-is .what-is-content ul li:nth-child(3)::marker {
  color: #f4c760;
}
.home-wrap .what-is .what-is-content ul li:nth-child(4)::marker {
  color: #88cb88;
}
.home-wrap .whats-involved {
  padding: 100px 0;
  text-align: center;
  width: 100%;
}
.home-wrap .whats-involved .involved-content {
  text-align: left;
  display: flex;
  width: 100%;
  justify-content: center;
}
@media all and (max-width: 768px) {
  .home-wrap .whats-involved .involved-content {
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
}
.home-wrap .whats-involved .involved-content .involved-list {
  width: 30%;
  margin: 0 30px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
@media all and (max-width: 768px) {
  .home-wrap .whats-involved .involved-content .involved-list {
    width: 90%;
    margin-bottom: 50px;
  }
}
.home-wrap .whats-involved .involved-content .involved-list h2 {
  margin: 0;
  background: #59A3D0;
  border-radius: 50px;
  text-align: center;
  padding: 20px;
  font-size: 1.2em;
}
.home-wrap .whats-involved .involved-content .involved-list ul {
  color: #323333;
  margin: 20px 0 30px;
  line-height: 2.2em;
  list-style: none;
}
.home-wrap .whats-involved .involved-content .involved-list ul li::before {
  content: "•";
  color: #7b68c8;
  font-weight: bold;
  display: inline-block;
  width: 1rem;
  margin-left: -1rem;
}
.home-wrap .whats-involved .involved-content .involved-list .image-wrap {
  width: 80%;
  margin: 0 auto;
}
.home-wrap .whats-involved .involved-content .involved-list .image-wrap img {
  object-fit: cover;
  width: 100%;
}
@media all and (max-width: 768px) {
  .home-wrap .whats-involved .involved-content .involved-list .image-wrap {
    margin-top: 30px;
  }
}
.home-wrap .supporting-resources {
  padding: 95px 0 30px;
  text-align: center;
  width: 100%;
  max-width: 1000px;
}
@media all and (max-width: 768px) {
  .home-wrap .supporting-resources {
    padding: 30px 0;
    max-width: 100%;
  }
}
.home-wrap .supporting-resources .supporting-content {
  text-align: left;
  display: flex;
  width: 100%;
  justify-content: center;
}
@media all and (max-width: 1100px) {
  .home-wrap .supporting-resources .supporting-content {
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    margin: auto;
    text-align: center;
    margin-top: 30px;
  }
}
.home-wrap .supporting-resources .supporting-content .supporting-text {
  flex: 1;
  color: #323333;
  margin-bottom: 50px;
}
.home-wrap .supporting-resources .supporting-content .supporting-text h1 {
  margin-top: 0;
}
@media all and (max-width: 1100px) {
  .home-wrap .supporting-resources .supporting-content .supporting-text {
    margin-bottom: 20px;
  }
}
.home-wrap .supporting-resources .supporting-content .butt-resources {
  margin: 10px 0 20px -5px;
}
.home-wrap .supporting-resources .supporting-content .content-wrap {
  flex: 2;
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  justify-content: flex-start;
  width: 100%;
  padding: 0 0 0 50px;
}
@media all and (max-width: 1100px) {
  .home-wrap .supporting-resources .supporting-content .content-wrap {
    padding: 0;
    overflow: visible;
    justify-content: space-around;
    max-height: none;
    height: auto;
  }
}
.home-wrap .supporting-resources .supporting-content .content-wrap .resource {
  max-width: 50%;
  min-width: 300px;
  background: #ffffff;
  border-radius: 10px 10px 24px 24px;
  padding: 10px;
  color: #323333;
  text-align: center;
  max-height: 230px;
  overflow: hidden;
  text-decoration: none;
  flex: 1;
  margin: 0 8px 18px;
}
.home-wrap .supporting-resources .supporting-content .content-wrap .resource.show {
  display: block;
}
.home-wrap .supporting-resources .supporting-content .content-wrap .resource .image {
  width: 100%;
  height: 140px;
  background: #c0e5f7;
  border-radius: 10px;
  transition: all 0.3s;
  overflow: hidden;
}
@media all and (max-width: 768px) {
  .home-wrap .supporting-resources .supporting-content .content-wrap .resource .image {
    height: 160px;
  }
}
.home-wrap .supporting-resources .supporting-content .content-wrap .resource .image img {
  object-fit: cover;
  width: 105%;
  height: 105%;
  transition: all 0.4s;
  transform: scale(1) rotate(0);
}
.home-wrap .supporting-resources .supporting-content .content-wrap .resource p {
  margin-bottom: 5px;
  padding: 0 5px;
}
.home-wrap .supporting-resources .supporting-content .content-wrap .resource:hover {
  cursor: pointer;
  animation-duration: 0.3s;
  animation-fill-mode: both;
  animation-name: pulse;
}
.home-wrap .supporting-resources .supporting-content .content-wrap .resource:hover img {
  transform: scale(1.1) rotate(2deg);
}
@media all and (max-width: 768px) {
  .home-wrap .supporting-resources .supporting-content .content-wrap .resource {
    width: 45%;
  }
}
.home-wrap .for-me {
  text-align: center;
  padding-bottom: 100px;
  width: 100%;
}
@media all and (max-width: 768px) {
  .home-wrap .for-me {
    max-width: 90%;
  }
}
.home-wrap .for-me .for-me-content {
  background: #ffffff;
  border-radius: 50px;
  text-align: center;
  max-width: 67%;
  width: 67%;
  padding: 50px;
  box-shadow: 2px 2px 40px 1px rgba(36, 58, 71, 0.25);
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
@media all and (max-width: 1300px) {
  .home-wrap .for-me .for-me-content {
    max-width: 80%;
    width: 80%;
  }
}
@media all and (max-width: 768px) {
  .home-wrap .for-me .for-me-content {
    max-width: 100%;
    width: 100%;
    padding: 20px;
  }
}
.home-wrap .for-me .for-me-content .button-wrap {
  display: flex;
}
.home-wrap .for-me .for-me-content .button-wrap a.butt-general {
  font-size: 16px;
}
.home-wrap .for-me .for-me-content .video {
  width: 80%;
  max-width: 600px;
  height: 50%;
  margin-bottom: 30px;
}
.home-wrap .for-me .for-me-content .video .iframe-wrapper {
  position: relative;
  padding-top: 56.25%;
}
.home-wrap .for-me .for-me-content .video iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
.home-wrap .for-me .for-me-content .mobile-copy {
  color: #323333;
  font-weight: bold;
  display: none;
  padding-bottom: 10px;
}
@media all and (max-width: 767px) {
  .home-wrap .for-me .for-me-content .mobile-copy {
    display: block;
  }
}
@media all and (max-height: 412px) {
  .home-wrap .for-me .for-me-content .mobile-copy {
    display: block;
  }
}
@media all and (max-width: 768px) {
  .home-wrap .butt-find-out-more {
    margin: 0;
  }
}

#front-register {
  padding-bottom: 50px;
}

.dashboard-wrap,
#goals.dashboard-wrap,
#add-meeting.dashboard-wrap {
  flex: 1;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  width: 100%;
  padding-bottom: 50px;
  flex-direction: column;
  padding-top: 0 !important;
}
@media all and (max-width: 1300px) {
  .dashboard-wrap,
#goals.dashboard-wrap,
#add-meeting.dashboard-wrap {
    width: 100vw;
    padding: 0 30px;
  }
}
@media all and (max-width: 800px) {
  .dashboard-wrap,
#goals.dashboard-wrap,
#add-meeting.dashboard-wrap {
    width: 100vw;
    padding: 0 20px;
  }
}
.dashboard-wrap .dashboard-heading,
#goals.dashboard-wrap .dashboard-heading,
#add-meeting.dashboard-wrap .dashboard-heading {
  padding: 0;
  text-align: center;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.dashboard-wrap .dashboard-heading .dashboard-title,
#goals.dashboard-wrap .dashboard-heading .dashboard-title,
#add-meeting.dashboard-wrap .dashboard-heading .dashboard-title {
  width: 100%;
  text-align: center;
  padding: 0;
}
@media all and (max-width: 500px) {
  .dashboard-wrap .dashboard-heading .dashboard-title,
#goals.dashboard-wrap .dashboard-heading .dashboard-title,
#add-meeting.dashboard-wrap .dashboard-heading .dashboard-title {
    text-align: left;
  }
}
.dashboard-wrap .dashboard-heading .toggle,
#goals.dashboard-wrap .dashboard-heading .toggle,
#add-meeting.dashboard-wrap .dashboard-heading .toggle {
  display: flex;
  align-items: center;
}
.dashboard-wrap .dashboard-heading .toggle .label,
#goals.dashboard-wrap .dashboard-heading .toggle .label,
#add-meeting.dashboard-wrap .dashboard-heading .toggle .label {
  font-size: 0.63rem;
  color: #ffffff;
  font-weight: bold;
  text-transform: uppercase;
}
.dashboard-wrap .dashboard-heading .toggle .label.left,
#goals.dashboard-wrap .dashboard-heading .toggle .label.left,
#add-meeting.dashboard-wrap .dashboard-heading .toggle .label.left {
  text-align: right;
  margin-right: 10px;
}
.dashboard-wrap .dashboard-heading .toggle .label.right,
#goals.dashboard-wrap .dashboard-heading .toggle .label.right,
#add-meeting.dashboard-wrap .dashboard-heading .toggle .label.right {
  text-align: left;
  margin-left: 10px;
}
.dashboard-wrap .dashboard-heading .spacer,
#goals.dashboard-wrap .dashboard-heading .spacer,
#add-meeting.dashboard-wrap .dashboard-heading .spacer {
  display: block;
  min-width: 120px;
}
@media all and (max-width: 500px) {
  .dashboard-wrap .dashboard-heading .spacer,
#goals.dashboard-wrap .dashboard-heading .spacer,
#add-meeting.dashboard-wrap .dashboard-heading .spacer {
    min-width: 0;
  }
}
.dashboard-wrap#goals, .dashboard-wrap#add-meeting,
#goals.dashboard-wrap#goals,
#goals.dashboard-wrap#add-meeting,
#add-meeting.dashboard-wrap#goals,
#add-meeting.dashboard-wrap#add-meeting {
  flex-direction: row;
  padding-top: 50px !important;
}
.dashboard-wrap .dashboard-content,
#goals.dashboard-wrap .dashboard-content,
#add-meeting.dashboard-wrap .dashboard-content {
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  width: 100%;
}
.dashboard-wrap .dashboard-content.layout-reverse,
#goals.dashboard-wrap .dashboard-content.layout-reverse,
#add-meeting.dashboard-wrap .dashboard-content.layout-reverse {
  flex-direction: column-reverse;
}
.dashboard-wrap .dashboard-content h2,
#goals.dashboard-wrap .dashboard-content h2,
#add-meeting.dashboard-wrap .dashboard-content h2 {
  font-size: 1.3em;
  margin-bottom: 10px;
  text-align: center;
}
.dashboard-wrap .dashboard-content .section-tagline,
#goals.dashboard-wrap .dashboard-content .section-tagline,
#add-meeting.dashboard-wrap .dashboard-content .section-tagline {
  font-size: 0.88rem;
  margin-top: 0;
  margin-bottom: 10px;
  text-align: center;
  min-height: 40px;
}
.dashboard-wrap .dashboard-content .top-section,
#goals.dashboard-wrap .dashboard-content .top-section,
#add-meeting.dashboard-wrap .dashboard-content .top-section {
  margin-bottom: 40px;
}
@media all and (max-width: 800px) {
  .dashboard-wrap .dashboard-content .top-section,
#goals.dashboard-wrap .dashboard-content .top-section,
#add-meeting.dashboard-wrap .dashboard-content .top-section {
    margin-bottom: 0;
  }
}
.dashboard-wrap .dashboard-content .top-section .about-me,
#goals.dashboard-wrap .dashboard-content .top-section .about-me,
#add-meeting.dashboard-wrap .dashboard-content .top-section .about-me {
  width: 100%;
  margin-top: 0;
}
.dashboard-wrap .dashboard-content .top-section .about-me .quiz-section-wrap,
#goals.dashboard-wrap .dashboard-content .top-section .about-me .quiz-section-wrap,
#add-meeting.dashboard-wrap .dashboard-content .top-section .about-me .quiz-section-wrap {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}
@media all and (max-width: 800px) {
  .dashboard-wrap .dashboard-content .top-section .about-me .quiz-section-wrap,
#goals.dashboard-wrap .dashboard-content .top-section .about-me .quiz-section-wrap,
#add-meeting.dashboard-wrap .dashboard-content .top-section .about-me .quiz-section-wrap {
    padding-top: 30px;
  }
}
.dashboard-wrap .dashboard-content .bottom-section,
#goals.dashboard-wrap .dashboard-content .bottom-section,
#add-meeting.dashboard-wrap .dashboard-content .bottom-section {
  display: flex;
  justify-content: space-between;
  margin-bottom: 40px;
}
@media all and (max-width: 800px) {
  .dashboard-wrap .dashboard-content .bottom-section,
#goals.dashboard-wrap .dashboard-content .bottom-section,
#add-meeting.dashboard-wrap .dashboard-content .bottom-section {
    flex-direction: column;
    margin-bottom: 0;
  }
}
.dashboard-wrap .dashboard-content .bottom-section .goals-progress, .dashboard-wrap .dashboard-content .bottom-section .support-network,
#goals.dashboard-wrap .dashboard-content .bottom-section .goals-progress,
#goals.dashboard-wrap .dashboard-content .bottom-section .support-network,
#add-meeting.dashboard-wrap .dashboard-content .bottom-section .goals-progress,
#add-meeting.dashboard-wrap .dashboard-content .bottom-section .support-network {
  width: 50%;
}
.dashboard-wrap .dashboard-content .bottom-section .goals-progress,
#goals.dashboard-wrap .dashboard-content .bottom-section .goals-progress,
#add-meeting.dashboard-wrap .dashboard-content .bottom-section .goals-progress {
  margin-right: 50px;
}
.dashboard-wrap .dashboard-content .bottom-section .support-network,
#goals.dashboard-wrap .dashboard-content .bottom-section .support-network,
#add-meeting.dashboard-wrap .dashboard-content .bottom-section .support-network {
  margin-left: 50px;
}
@media all and (max-width: 800px) {
  .dashboard-wrap .dashboard-content .bottom-section .goals-progress, .dashboard-wrap .dashboard-content .bottom-section .support-network,
#goals.dashboard-wrap .dashboard-content .bottom-section .goals-progress,
#goals.dashboard-wrap .dashboard-content .bottom-section .support-network,
#add-meeting.dashboard-wrap .dashboard-content .bottom-section .goals-progress,
#add-meeting.dashboard-wrap .dashboard-content .bottom-section .support-network {
    width: 100%;
    margin: 0 0 50px;
  }
}
.dashboard-wrap.team-member,
#goals.dashboard-wrap.team-member,
#add-meeting.dashboard-wrap.team-member {
  flex-direction: column;
  padding-top: 50px !important;
}
@media all and (max-width: 768px) {
  .dashboard-wrap,
#goals.dashboard-wrap,
#add-meeting.dashboard-wrap {
    flex-direction: column;
    align-items: center;
  }
}
.dashboard-wrap .youth-switcher,
#goals.dashboard-wrap .youth-switcher,
#add-meeting.dashboard-wrap .youth-switcher {
  position: absolute;
  top: 120px;
  right: 50px;
}
.dashboard-wrap .young-person,
#goals.dashboard-wrap .young-person,
#add-meeting.dashboard-wrap .young-person {
  width: 100%;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-start;
  display: none;
}
.dashboard-wrap .young-person.visible,
#goals.dashboard-wrap .young-person.visible,
#add-meeting.dashboard-wrap .young-person.visible {
  display: flex;
}
.dashboard-wrap .about-me,
.dashboard-wrap .goals-progress,
.dashboard-wrap .support-network,
#goals.dashboard-wrap .about-me,
#goals.dashboard-wrap .goals-progress,
#goals.dashboard-wrap .support-network,
#add-meeting.dashboard-wrap .about-me,
#add-meeting.dashboard-wrap .goals-progress,
#add-meeting.dashboard-wrap .support-network {
  width: 30%;
}
@media all and (max-width: 768px) {
  .dashboard-wrap .about-me,
.dashboard-wrap .goals-progress,
.dashboard-wrap .support-network,
#goals.dashboard-wrap .about-me,
#goals.dashboard-wrap .goals-progress,
#goals.dashboard-wrap .support-network,
#add-meeting.dashboard-wrap .about-me,
#add-meeting.dashboard-wrap .goals-progress,
#add-meeting.dashboard-wrap .support-network {
    width: 80%;
    margin: 50px 0;
  }
}
.dashboard-wrap .about-me .quiz-complete,
#goals.dashboard-wrap .about-me .quiz-complete,
#add-meeting.dashboard-wrap .about-me .quiz-complete {
  margin-top: 15px;
  display: flex;
  justify-content: center;
}
.dashboard-wrap .about-me .quiz-complete .butt-results,
#goals.dashboard-wrap .about-me .quiz-complete .butt-results,
#add-meeting.dashboard-wrap .about-me .quiz-complete .butt-results {
  flex-direction: row-reverse;
  margin-left: 0;
  width: auto;
}
.dashboard-wrap .about-me .quiz-complete .butt-results .title,
#goals.dashboard-wrap .about-me .quiz-complete .butt-results .title,
#add-meeting.dashboard-wrap .about-me .quiz-complete .butt-results .title {
  margin-left: 10px;
}
.dashboard-wrap .about-me .quiz-complete .butt-results .results,
#goals.dashboard-wrap .about-me .quiz-complete .butt-results .results,
#add-meeting.dashboard-wrap .about-me .quiz-complete .butt-results .results {
  background-image: url(../images/icons/icon-results-blue.svg);
}
.dashboard-wrap .about-me .quiz-progress,
#goals.dashboard-wrap .about-me .quiz-progress,
#add-meeting.dashboard-wrap .about-me .quiz-progress {
  width: 100%;
  height: 42px;
  background: #ffffff;
  border-radius: 50px;
  margin-top: 15px;
  position: relative;
  padding: 0 4px 0 20px;
}
.dashboard-wrap .about-me .quiz-progress .progress-back,
#goals.dashboard-wrap .about-me .quiz-progress .progress-back,
#add-meeting.dashboard-wrap .about-me .quiz-progress .progress-back {
  background: #ebebeb;
  height: 6px;
  position: absolute;
  width: calc(100% - 24px);
  top: 0;
  bottom: 0;
  margin: auto;
  border-radius: 20px;
}
.dashboard-wrap .about-me .quiz-progress .progress-dots,
#goals.dashboard-wrap .about-me .quiz-progress .progress-dots,
#add-meeting.dashboard-wrap .about-me .quiz-progress .progress-dots {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.dashboard-wrap .about-me .quiz-progress .progress-dots .dot,
#goals.dashboard-wrap .about-me .quiz-progress .progress-dots .dot,
#add-meeting.dashboard-wrap .about-me .quiz-progress .progress-dots .dot {
  width: 16px;
  height: 16px;
  border-radius: 40px;
  z-index: 1;
}
.dashboard-wrap .about-me .quiz-progress .progress-dots .dot.career,
#goals.dashboard-wrap .about-me .quiz-progress .progress-dots .dot.career,
#add-meeting.dashboard-wrap .about-me .quiz-progress .progress-dots .dot.career {
  background: #D4E9F7;
}
.dashboard-wrap .about-me .quiz-progress .progress-dots .dot.career.completed,
#goals.dashboard-wrap .about-me .quiz-progress .progress-dots .dot.career.completed,
#add-meeting.dashboard-wrap .about-me .quiz-progress .progress-dots .dot.career.completed {
  background: #59A3D0;
}
.dashboard-wrap .about-me .quiz-progress .progress-dots .dot.strengths,
#goals.dashboard-wrap .about-me .quiz-progress .progress-dots .dot.strengths,
#add-meeting.dashboard-wrap .about-me .quiz-progress .progress-dots .dot.strengths {
  background: #E4E0F7;
}
.dashboard-wrap .about-me .quiz-progress .progress-dots .dot.strengths.completed,
#goals.dashboard-wrap .about-me .quiz-progress .progress-dots .dot.strengths.completed,
#add-meeting.dashboard-wrap .about-me .quiz-progress .progress-dots .dot.strengths.completed {
  background: #7b68c8;
}
.dashboard-wrap .about-me .quiz-progress .progress-dots .dot.work,
#goals.dashboard-wrap .about-me .quiz-progress .progress-dots .dot.work,
#add-meeting.dashboard-wrap .about-me .quiz-progress .progress-dots .dot.work {
  background: #F8E9B9;
}
.dashboard-wrap .about-me .quiz-progress .progress-dots .dot.work.completed,
#goals.dashboard-wrap .about-me .quiz-progress .progress-dots .dot.work.completed,
#add-meeting.dashboard-wrap .about-me .quiz-progress .progress-dots .dot.work.completed {
  background: #F3C762;
}
.dashboard-wrap .about-me .quiz-progress .progress-dots .dot.training,
#goals.dashboard-wrap .about-me .quiz-progress .progress-dots .dot.training,
#add-meeting.dashboard-wrap .about-me .quiz-progress .progress-dots .dot.training {
  background: #d5f2d4;
}
.dashboard-wrap .about-me .quiz-progress .progress-dots .dot.training.completed,
#goals.dashboard-wrap .about-me .quiz-progress .progress-dots .dot.training.completed,
#add-meeting.dashboard-wrap .about-me .quiz-progress .progress-dots .dot.training.completed {
  background: #86CC88;
}
.dashboard-wrap .about-me .quiz-progress .progress-dots .dot.life,
#goals.dashboard-wrap .about-me .quiz-progress .progress-dots .dot.life,
#add-meeting.dashboard-wrap .about-me .quiz-progress .progress-dots .dot.life {
  background: #F7DADA;
}
.dashboard-wrap .about-me .quiz-progress .progress-dots .dot.life.completed,
#goals.dashboard-wrap .about-me .quiz-progress .progress-dots .dot.life.completed,
#add-meeting.dashboard-wrap .about-me .quiz-progress .progress-dots .dot.life.completed {
  background: #EE9A95;
}
.dashboard-wrap .about-me .quiz-progress .progress-dots .dot.results,
#goals.dashboard-wrap .about-me .quiz-progress .progress-dots .dot.results,
#add-meeting.dashboard-wrap .about-me .quiz-progress .progress-dots .dot.results {
  background-color: #D4E9F7;
  background-image: url(../images/icons/icon-results.svg);
  background-repeat: no-repeat;
  background-position: center center;
  width: 34px;
  height: 34px;
}
.dashboard-wrap .goals-progress .add-goal-button,
#goals.dashboard-wrap .goals-progress .add-goal-button,
#add-meeting.dashboard-wrap .goals-progress .add-goal-button {
  display: flex;
  justify-content: center;
  margin: 30px 0 0 0;
}
.dashboard-wrap .goals-progress .add-goal-button .butt-general,
#goals.dashboard-wrap .goals-progress .add-goal-button .butt-general,
#add-meeting.dashboard-wrap .goals-progress .add-goal-button .butt-general {
  flex-direction: row-reverse;
  margin-left: 0;
}
.dashboard-wrap .goals-progress .add-goal-button .butt-general .butt-icon.add-goal,
#goals.dashboard-wrap .goals-progress .add-goal-button .butt-general .butt-icon.add-goal,
#add-meeting.dashboard-wrap .goals-progress .add-goal-button .butt-general .butt-icon.add-goal {
  background-image: url(../images/icons/icon-add-purple.svg);
}
.dashboard-wrap .goals-progress .goals-wrap,
#goals.dashboard-wrap .goals-progress .goals-wrap,
#add-meeting.dashboard-wrap .goals-progress .goals-wrap {
  background: #ffffff;
  border-radius: 40px;
  padding: 20px;
}
.dashboard-wrap .goals-progress .goals-wrap .small,
#goals.dashboard-wrap .goals-progress .goals-wrap .small,
#add-meeting.dashboard-wrap .goals-progress .goals-wrap .small {
  color: #786ac1;
  font-size: 0.8em;
  font-style: italic;
  text-align: center;
}
.dashboard-wrap .goals-progress .goals-wrap .goal,
#goals.dashboard-wrap .goals-progress .goals-wrap .goal,
#add-meeting.dashboard-wrap .goals-progress .goals-wrap .goal {
  background: #F9D87C;
  border-radius: 40px;
  padding: 10px;
  display: block;
  cursor: pointer;
  text-decoration: none;
  margin: 0 0 15px;
}
.dashboard-wrap .goals-progress .goals-wrap .goal .progress,
#goals.dashboard-wrap .goals-progress .goals-wrap .goal .progress,
#add-meeting.dashboard-wrap .goals-progress .goals-wrap .goal .progress {
  width: 100%;
  height: 16px;
  background: #E4E0F7;
  border-radius: 16px;
  padding: 0 5px;
}
.dashboard-wrap .goals-progress .goals-wrap .goal .progress .progress-fill,
#goals.dashboard-wrap .goals-progress .goals-wrap .goal .progress .progress-fill,
#add-meeting.dashboard-wrap .goals-progress .goals-wrap .goal .progress .progress-fill {
  background: #CAC2EC;
  height: 4px;
  position: absolute;
  width: calc(100% - 10px);
  top: 0;
  bottom: 0;
  margin: auto;
  border-radius: 20px;
  width: 0%;
}
.dashboard-wrap .goals-progress .goals-wrap .goal .progress .progress-dots,
#goals.dashboard-wrap .goals-progress .goals-wrap .goal .progress .progress-dots,
#add-meeting.dashboard-wrap .goals-progress .goals-wrap .goal .progress .progress-dots {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.dashboard-wrap .goals-progress .goals-wrap .goal .progress .progress-dots .dot,
#goals.dashboard-wrap .goals-progress .goals-wrap .goal .progress .progress-dots .dot,
#add-meeting.dashboard-wrap .goals-progress .goals-wrap .goal .progress .progress-dots .dot {
  width: 8px;
  height: 8px;
  border-radius: 20px;
  background: #CAC2EC;
}
.dashboard-wrap .goals-progress .goals-wrap .goal .progress .progress-dots .dot.completed,
#goals.dashboard-wrap .goals-progress .goals-wrap .goal .progress .progress-dots .dot.completed,
#add-meeting.dashboard-wrap .goals-progress .goals-wrap .goal .progress .progress-dots .dot.completed {
  background: #786ac1;
}
.dashboard-wrap .goals-progress .goals-wrap .goal .details,
#goals.dashboard-wrap .goals-progress .goals-wrap .goal .details,
#add-meeting.dashboard-wrap .goals-progress .goals-wrap .goal .details {
  display: flex;
  align-items: center;
}
.dashboard-wrap .goals-progress .goals-wrap .goal .details .icon,
#goals.dashboard-wrap .goals-progress .goals-wrap .goal .details .icon,
#add-meeting.dashboard-wrap .goals-progress .goals-wrap .goal .details .icon {
  width: 34px;
  height: 34px;
  min-width: 34px;
  border-radius: 50px;
  background: #F9D87C url(../images/icons/icon-goal.svg) no-repeat;
  background-size: 76%;
  background-position: 53% 53%;
}
.dashboard-wrap .goals-progress .goals-wrap .goal .details .title,
#goals.dashboard-wrap .goals-progress .goals-wrap .goal .details .title,
#add-meeting.dashboard-wrap .goals-progress .goals-wrap .goal .details .title {
  color: #323333;
  font-weight: 600;
  font-size: 0.85em;
  padding-left: 10px;
}
.dashboard-wrap .goals-progress .goals-wrap .goal.disabled .details,
#goals.dashboard-wrap .goals-progress .goals-wrap .goal.disabled .details,
#add-meeting.dashboard-wrap .goals-progress .goals-wrap .goal.disabled .details {
  opacity: 0.5;
}
.dashboard-wrap .goals-progress .goals-wrap .goal.active .details,
#goals.dashboard-wrap .goals-progress .goals-wrap .goal.active .details,
#add-meeting.dashboard-wrap .goals-progress .goals-wrap .goal.active .details {
  opacity: 1;
}
.dashboard-wrap .support-network .support-wrap,
#goals.dashboard-wrap .support-network .support-wrap,
#add-meeting.dashboard-wrap .support-network .support-wrap {
  background: #ffffff;
  border-radius: 40px;
  box-shadow: 2px 2px 40px 1px rgba(36, 58, 71, 0.25);
  color: #323333;
  padding: 20px;
}
.dashboard-wrap .support-network .support-wrap:last-child,
#goals.dashboard-wrap .support-network .support-wrap:last-child,
#add-meeting.dashboard-wrap .support-network .support-wrap:last-child {
  margin-top: 15px;
}
.dashboard-wrap .support-network .support-wrap h3,
#goals.dashboard-wrap .support-network .support-wrap h3,
#add-meeting.dashboard-wrap .support-network .support-wrap h3 {
  font-size: 1.1rem;
  color: #323333;
  margin: 0;
  margin-bottom: 20px;
}
.dashboard-wrap .support-network .support-wrap h3.slim,
#goals.dashboard-wrap .support-network .support-wrap h3.slim,
#add-meeting.dashboard-wrap .support-network .support-wrap h3.slim {
  font-weight: 300;
}
.dashboard-wrap .support-network .support-wrap .small,
#goals.dashboard-wrap .support-network .support-wrap .small,
#add-meeting.dashboard-wrap .support-network .support-wrap .small {
  color: #786ac1;
  font-size: 0.8em;
  font-style: italic;
}
.dashboard-wrap .support-network .support-wrap .meeting-wrap,
#goals.dashboard-wrap .support-network .support-wrap .meeting-wrap,
#add-meeting.dashboard-wrap .support-network .support-wrap .meeting-wrap {
  text-align: center;
}
.dashboard-wrap .support-network .support-wrap .meeting-wrap .butt-general,
#goals.dashboard-wrap .support-network .support-wrap .meeting-wrap .butt-general,
#add-meeting.dashboard-wrap .support-network .support-wrap .meeting-wrap .butt-general {
  margin: 0 auto;
  margin-top: 10px;
}
.dashboard-wrap .support-network .support-wrap .meeting-small-info,
#goals.dashboard-wrap .support-network .support-wrap .meeting-small-info,
#add-meeting.dashboard-wrap .support-network .support-wrap .meeting-small-info {
  display: flex;
  color: #786ac1;
  font-size: 0.8em;
  align-items: center;
  margin: 5px 0;
}
.dashboard-wrap .support-network .support-wrap .meeting-small-info .meeting-icon,
#goals.dashboard-wrap .support-network .support-wrap .meeting-small-info .meeting-icon,
#add-meeting.dashboard-wrap .support-network .support-wrap .meeting-small-info .meeting-icon {
  width: 34px;
  height: 34px;
  border-radius: 40px;
  border: 2px solid #786ac1;
  margin-right: 10px;
}
.dashboard-wrap .support-network .support-wrap .meeting-small-info .meeting-icon.date,
#goals.dashboard-wrap .support-network .support-wrap .meeting-small-info .meeting-icon.date,
#add-meeting.dashboard-wrap .support-network .support-wrap .meeting-small-info .meeting-icon.date {
  background: url(../images/icons/icon-addcal-purple.svg) center center no-repeat;
}
.dashboard-wrap .support-network .support-wrap .meeting-small-info .meeting-icon.time,
#goals.dashboard-wrap .support-network .support-wrap .meeting-small-info .meeting-icon.time,
#add-meeting.dashboard-wrap .support-network .support-wrap .meeting-small-info .meeting-icon.time {
  background: url(../images/icons/icon-time.svg) center center no-repeat;
}
.dashboard-wrap .support-network .support-wrap .meeting-small-info .meeting-icon.address,
#goals.dashboard-wrap .support-network .support-wrap .meeting-small-info .meeting-icon.address,
#add-meeting.dashboard-wrap .support-network .support-wrap .meeting-small-info .meeting-icon.address {
  background: url(../images/icons/icon-location.svg) center center no-repeat;
}
.dashboard-wrap .support-network .support-wrap .meeting-small-info .butt-save-to-calendar,
#goals.dashboard-wrap .support-network .support-wrap .meeting-small-info .butt-save-to-calendar,
#add-meeting.dashboard-wrap .support-network .support-wrap .meeting-small-info .butt-save-to-calendar {
  display: block;
  background: #E4E0F7;
  padding: 5px 10px;
  border-radius: 20px;
  text-decoration: none;
  margin: 10px 0;
}
.dashboard-wrap .support-network .support-wrap .meeting-small-info .butt-save-to-calendar:hover,
#goals.dashboard-wrap .support-network .support-wrap .meeting-small-info .butt-save-to-calendar:hover,
#add-meeting.dashboard-wrap .support-network .support-wrap .meeting-small-info .butt-save-to-calendar:hover {
  cursor: pointer;
  animation-duration: 0.4s;
  animation-fill-mode: both;
  animation-name: pulse;
  color: #4C4180;
}
.dashboard-wrap .support-network .support-wrap .team-wrap,
#goals.dashboard-wrap .support-network .support-wrap .team-wrap,
#add-meeting.dashboard-wrap .support-network .support-wrap .team-wrap {
  text-align: center;
}
.dashboard-wrap .support-network .support-wrap .team-wrap h2,
#goals.dashboard-wrap .support-network .support-wrap .team-wrap h2,
#add-meeting.dashboard-wrap .support-network .support-wrap .team-wrap h2 {
  text-align: left;
}
.dashboard-wrap .support-network .support-wrap .team-wrap .team,
#goals.dashboard-wrap .support-network .support-wrap .team-wrap .team,
#add-meeting.dashboard-wrap .support-network .support-wrap .team-wrap .team {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 20px 0;
}
.dashboard-wrap .support-network .support-wrap .team-wrap .team .me,
#goals.dashboard-wrap .support-network .support-wrap .team-wrap .team .me,
#add-meeting.dashboard-wrap .support-network .support-wrap .team-wrap .team .me {
  height: 72px;
  max-width: 95px;
  flex: 1;
  border: 1px solid #ebebeb;
  border-radius: 24px;
  min-width: 60px;
  overflow: hidden;
  background-image: url(../images/icons/icon-default-profile.svg);
  background-position: center center;
  background-size: 50%;
  background-repeat: no-repeat;
}
.dashboard-wrap .support-network .support-wrap .team-wrap .team .me.has-avatar,
#goals.dashboard-wrap .support-network .support-wrap .team-wrap .team .me.has-avatar,
#add-meeting.dashboard-wrap .support-network .support-wrap .team-wrap .team .me.has-avatar {
  background-image: none;
}
.dashboard-wrap .support-network .support-wrap .team-wrap .team .me img,
#goals.dashboard-wrap .support-network .support-wrap .team-wrap .team .me img,
#add-meeting.dashboard-wrap .support-network .support-wrap .team-wrap .team .me img {
  width: 100%;
  object-fit: cover;
  height: 100%;
}
@media all and (max-width: 1024px) and (min-width: 769px) {
  .dashboard-wrap .support-network .support-wrap .team-wrap .team .me,
#goals.dashboard-wrap .support-network .support-wrap .team-wrap .team .me,
#add-meeting.dashboard-wrap .support-network .support-wrap .team-wrap .team .me {
    height: 60px;
    width: 60px;
    max-width: 60px;
  }
}
.dashboard-wrap .support-network .support-wrap .team-wrap .team .member,
#goals.dashboard-wrap .support-network .support-wrap .team-wrap .team .member,
#add-meeting.dashboard-wrap .support-network .support-wrap .team-wrap .team .member {
  width: 34px;
  height: 34px;
  border: 1px solid #ebebeb;
  border-radius: 40px;
  margin-left: 5px;
  font-size: 0.8em;
  color: #ffffff;
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: 600;
}
.dashboard-wrap .support-network .support-wrap .team-wrap .team .member.filled,
#goals.dashboard-wrap .support-network .support-wrap .team-wrap .team .member.filled,
#add-meeting.dashboard-wrap .support-network .support-wrap .team-wrap .team .member.filled {
  border: none;
}
.dashboard-wrap .support-network .support-wrap .team-wrap .team .member.filled:nth-child(1),
#goals.dashboard-wrap .support-network .support-wrap .team-wrap .team .member.filled:nth-child(1),
#add-meeting.dashboard-wrap .support-network .support-wrap .team-wrap .team .member.filled:nth-child(1) {
  background: #468449;
}
.dashboard-wrap .support-network .support-wrap .team-wrap .team .member.filled:nth-child(2),
#goals.dashboard-wrap .support-network .support-wrap .team-wrap .team .member.filled:nth-child(2),
#add-meeting.dashboard-wrap .support-network .support-wrap .team-wrap .team .member.filled:nth-child(2) {
  background: #59A3D0;
}
.dashboard-wrap .support-network .support-wrap .team-wrap .team .member.filled:nth-child(3),
#goals.dashboard-wrap .support-network .support-wrap .team-wrap .team .member.filled:nth-child(3),
#add-meeting.dashboard-wrap .support-network .support-wrap .team-wrap .team .member.filled:nth-child(3) {
  background: #EE9A95;
}
.dashboard-wrap .support-network .support-wrap .team-wrap .team .member.filled:nth-child(4),
#goals.dashboard-wrap .support-network .support-wrap .team-wrap .team .member.filled:nth-child(4),
#add-meeting.dashboard-wrap .support-network .support-wrap .team-wrap .team .member.filled:nth-child(4) {
  background: #F3C762;
}
.dashboard-wrap .support-network .support-wrap .team-wrap .team .member.filled:nth-child(5),
#goals.dashboard-wrap .support-network .support-wrap .team-wrap .team .member.filled:nth-child(5),
#add-meeting.dashboard-wrap .support-network .support-wrap .team-wrap .team .member.filled:nth-child(5) {
  background: #468449;
}
@media all and (max-width: 1024px) and (min-width: 769px) {
  .dashboard-wrap .support-network .support-wrap .team-wrap .team .member,
#goals.dashboard-wrap .support-network .support-wrap .team-wrap .team .member,
#add-meeting.dashboard-wrap .support-network .support-wrap .team-wrap .team .member {
    width: 24px;
    height: 24px;
  }
}
.dashboard-wrap .support-network .support-wrap .team-wrap .butt-add-team,
#goals.dashboard-wrap .support-network .support-wrap .team-wrap .butt-add-team,
#add-meeting.dashboard-wrap .support-network .support-wrap .team-wrap .butt-add-team {
  margin: 0;
}
.dashboard-wrap .support-network .support-wrap .team-wrap .butt-general,
#goals.dashboard-wrap .support-network .support-wrap .team-wrap .butt-general,
#add-meeting.dashboard-wrap .support-network .support-wrap .team-wrap .butt-general {
  margin: 0 auto;
  margin-top: 10px;
}
.dashboard-wrap .complete-about,
#goals.dashboard-wrap .complete-about,
#add-meeting.dashboard-wrap .complete-about {
  text-align: center;
  font-style: italic;
  background: rgba(255, 255, 255, 0.45);
  border-radius: 20px;
  padding: 20px;
  font-size: 0.88rem;
}
@media all and (max-width: 768px) {
  .dashboard-wrap .goals-list-column,
.dashboard-wrap .task-list-column,
.dashboard-wrap .main-goal-column,
#goals.dashboard-wrap .goals-list-column,
#goals.dashboard-wrap .task-list-column,
#goals.dashboard-wrap .main-goal-column,
#add-meeting.dashboard-wrap .goals-list-column,
#add-meeting.dashboard-wrap .task-list-column,
#add-meeting.dashboard-wrap .main-goal-column {
    width: 80% !important;
    margin: 50px 0;
  }
}
.dashboard-wrap .goals-list-column h1,
.dashboard-wrap .goals-list-column h2,
.dashboard-wrap .task-list-column h1,
.dashboard-wrap .task-list-column h2,
.dashboard-wrap .main-goal-column h1,
.dashboard-wrap .main-goal-column h2,
#goals.dashboard-wrap .goals-list-column h1,
#goals.dashboard-wrap .goals-list-column h2,
#goals.dashboard-wrap .task-list-column h1,
#goals.dashboard-wrap .task-list-column h2,
#goals.dashboard-wrap .main-goal-column h1,
#goals.dashboard-wrap .main-goal-column h2,
#add-meeting.dashboard-wrap .goals-list-column h1,
#add-meeting.dashboard-wrap .goals-list-column h2,
#add-meeting.dashboard-wrap .task-list-column h1,
#add-meeting.dashboard-wrap .task-list-column h2,
#add-meeting.dashboard-wrap .main-goal-column h1,
#add-meeting.dashboard-wrap .main-goal-column h2 {
  color: #323333;
}
.dashboard-wrap .goals-list-column p,
.dashboard-wrap .task-list-column p,
.dashboard-wrap .main-goal-column p,
#goals.dashboard-wrap .goals-list-column p,
#goals.dashboard-wrap .task-list-column p,
#goals.dashboard-wrap .main-goal-column p,
#add-meeting.dashboard-wrap .goals-list-column p,
#add-meeting.dashboard-wrap .task-list-column p,
#add-meeting.dashboard-wrap .main-goal-column p {
  color: black;
}
.dashboard-wrap .goals-list-column .butt-wrap,
.dashboard-wrap .task-list-column .butt-wrap,
.dashboard-wrap .main-goal-column .butt-wrap,
#goals.dashboard-wrap .goals-list-column .butt-wrap,
#goals.dashboard-wrap .task-list-column .butt-wrap,
#goals.dashboard-wrap .main-goal-column .butt-wrap,
#add-meeting.dashboard-wrap .goals-list-column .butt-wrap,
#add-meeting.dashboard-wrap .task-list-column .butt-wrap,
#add-meeting.dashboard-wrap .main-goal-column .butt-wrap {
  width: 100%;
  display: flex;
}
.dashboard-wrap .goals-list-column .butt-wrap button.butt-edit,
.dashboard-wrap .task-list-column .butt-wrap button.butt-edit,
.dashboard-wrap .main-goal-column .butt-wrap button.butt-edit,
#goals.dashboard-wrap .goals-list-column .butt-wrap button.butt-edit,
#goals.dashboard-wrap .task-list-column .butt-wrap button.butt-edit,
#goals.dashboard-wrap .main-goal-column .butt-wrap button.butt-edit,
#add-meeting.dashboard-wrap .goals-list-column .butt-wrap button.butt-edit,
#add-meeting.dashboard-wrap .task-list-column .butt-wrap button.butt-edit,
#add-meeting.dashboard-wrap .main-goal-column .butt-wrap button.butt-edit {
  margin: 10px auto;
  width: 200px;
}
.dashboard-wrap .goals-list-column .butt-wrap button.butt-save-team,
.dashboard-wrap .task-list-column .butt-wrap button.butt-save-team,
.dashboard-wrap .main-goal-column .butt-wrap button.butt-save-team,
#goals.dashboard-wrap .goals-list-column .butt-wrap button.butt-save-team,
#goals.dashboard-wrap .task-list-column .butt-wrap button.butt-save-team,
#goals.dashboard-wrap .main-goal-column .butt-wrap button.butt-save-team,
#add-meeting.dashboard-wrap .goals-list-column .butt-wrap button.butt-save-team,
#add-meeting.dashboard-wrap .task-list-column .butt-wrap button.butt-save-team,
#add-meeting.dashboard-wrap .main-goal-column .butt-wrap button.butt-save-team {
  margin: 10px auto;
  width: 200px;
}
.dashboard-wrap .goals-creator-container,
#goals.dashboard-wrap .goals-creator-container,
#add-meeting.dashboard-wrap .goals-creator-container {
  flex: 1;
  flex-direction: column;
  display: flex;
  width: 100%;
}
.dashboard-wrap .goals-list-column,
#goals.dashboard-wrap .goals-list-column,
#add-meeting.dashboard-wrap .goals-list-column {
  width: 30%;
  max-width: 320px;
}
.dashboard-wrap .goals-list-column .light-bar,
#goals.dashboard-wrap .goals-list-column .light-bar,
#add-meeting.dashboard-wrap .goals-list-column .light-bar {
  width: calc(100% - 46px) !important;
}
.dashboard-wrap .goals-list-column .header-wrap,
#goals.dashboard-wrap .goals-list-column .header-wrap,
#add-meeting.dashboard-wrap .goals-list-column .header-wrap {
  display: flex;
  align-items: center;
}
.dashboard-wrap .goals-list-column .header-wrap .butt-help,
#goals.dashboard-wrap .goals-list-column .header-wrap .butt-help,
#add-meeting.dashboard-wrap .goals-list-column .header-wrap .butt-help {
  width: 25px;
  height: 25px;
  background: white;
  border-radius: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: bold;
  color: #323333;
  margin-left: 10px;
  text-decoration: none;
}
.dashboard-wrap .goals-list-column .goal-wrapper,
#goals.dashboard-wrap .goals-list-column .goal-wrapper,
#add-meeting.dashboard-wrap .goals-list-column .goal-wrapper {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  flex-direction: column;
}
.dashboard-wrap .goals-list-column .goal-wrapper .butt-goal,
.dashboard-wrap .goals-list-column .goal-wrapper .butt-add-goal,
#goals.dashboard-wrap .goals-list-column .goal-wrapper .butt-goal,
#goals.dashboard-wrap .goals-list-column .goal-wrapper .butt-add-goal,
#add-meeting.dashboard-wrap .goals-list-column .goal-wrapper .butt-goal,
#add-meeting.dashboard-wrap .goals-list-column .goal-wrapper .butt-add-goal {
  width: 100%;
  height: 74px;
  margin: 5px 0;
  flex-direction: row-reverse;
  padding: 0 10px;
}
.dashboard-wrap .goals-list-column .goal-wrapper .butt-goal .butt-icon,
.dashboard-wrap .goals-list-column .goal-wrapper .butt-add-goal .butt-icon,
#goals.dashboard-wrap .goals-list-column .goal-wrapper .butt-goal .butt-icon,
#goals.dashboard-wrap .goals-list-column .goal-wrapper .butt-add-goal .butt-icon,
#add-meeting.dashboard-wrap .goals-list-column .goal-wrapper .butt-goal .butt-icon,
#add-meeting.dashboard-wrap .goals-list-column .goal-wrapper .butt-add-goal .butt-icon {
  width: 54px;
  height: 54px;
  background-color: #F9D87C;
}
.dashboard-wrap .goals-list-column .goal-wrapper .butt-goal .title,
.dashboard-wrap .goals-list-column .goal-wrapper .butt-add-goal .title,
#goals.dashboard-wrap .goals-list-column .goal-wrapper .butt-goal .title,
#goals.dashboard-wrap .goals-list-column .goal-wrapper .butt-add-goal .title,
#add-meeting.dashboard-wrap .goals-list-column .goal-wrapper .butt-goal .title,
#add-meeting.dashboard-wrap .goals-list-column .goal-wrapper .butt-add-goal .title {
  color: #323333;
  font-size: 1.2em;
  text-transform: unset;
  text-align: left;
  padding-left: 10px;
  text-transform: initial;
}
.dashboard-wrap .goals-list-column .goal-wrapper .suggested-goal-dropdown,
#goals.dashboard-wrap .goals-list-column .goal-wrapper .suggested-goal-dropdown,
#add-meeting.dashboard-wrap .goals-list-column .goal-wrapper .suggested-goal-dropdown {
  width: 100%;
  background: white;
  border-radius: 40px;
  margin-top: -5px;
  margin-top: -80px;
  padding-top: 80px;
  color: #323333;
  height: 0;
  opacity: 0;
  transition: all 0.4s;
  overflow: hidden;
  pointer-events: none;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  padding: 80px 20px 0 20px;
  overflow: hidden;
}
.dashboard-wrap .goals-list-column .goal-wrapper .suggested-goal-dropdown.active,
#goals.dashboard-wrap .goals-list-column .goal-wrapper .suggested-goal-dropdown.active,
#add-meeting.dashboard-wrap .goals-list-column .goal-wrapper .suggested-goal-dropdown.active {
  height: 400px;
  opacity: 1;
}
.dashboard-wrap .goals-list-column .goal-wrapper .suggested-goal-dropdown .suggested-goal,
#goals.dashboard-wrap .goals-list-column .goal-wrapper .suggested-goal-dropdown .suggested-goal,
#add-meeting.dashboard-wrap .goals-list-column .goal-wrapper .suggested-goal-dropdown .suggested-goal {
  height: 44px;
  display: flex;
  align-items: center;
  border-radius: 50px;
  pointer-events: initial;
  cursor: pointer;
  text-align: left;
  width: 100%;
  -webkit-appearance: none;
  border: none;
  margin-bottom: 10px;
  font-family: "Open Sans", sans-serif;
}
.dashboard-wrap .goals-list-column .goal-wrapper .suggested-goal-dropdown .suggested-goal .title,
#goals.dashboard-wrap .goals-list-column .goal-wrapper .suggested-goal-dropdown .suggested-goal .title,
#add-meeting.dashboard-wrap .goals-list-column .goal-wrapper .suggested-goal-dropdown .suggested-goal .title {
  padding: 0 20px;
}
.dashboard-wrap .goals-list-column .goal-wrapper .suggested-goal-dropdown .suggested-goal.selected, .dashboard-wrap .goals-list-column .goal-wrapper .suggested-goal-dropdown .suggested-goal:hover,
#goals.dashboard-wrap .goals-list-column .goal-wrapper .suggested-goal-dropdown .suggested-goal.selected,
#goals.dashboard-wrap .goals-list-column .goal-wrapper .suggested-goal-dropdown .suggested-goal:hover,
#add-meeting.dashboard-wrap .goals-list-column .goal-wrapper .suggested-goal-dropdown .suggested-goal.selected,
#add-meeting.dashboard-wrap .goals-list-column .goal-wrapper .suggested-goal-dropdown .suggested-goal:hover {
  background: #F8E9B9;
}
.dashboard-wrap .goals-list-column .goal-wrapper .suggested-goal-dropdown .custom-goal,
#goals.dashboard-wrap .goals-list-column .goal-wrapper .suggested-goal-dropdown .custom-goal,
#add-meeting.dashboard-wrap .goals-list-column .goal-wrapper .suggested-goal-dropdown .custom-goal {
  width: 100%;
}
.dashboard-wrap .goals-list-column .goal-wrapper .suggested-goal-dropdown .custom-goal input,
#goals.dashboard-wrap .goals-list-column .goal-wrapper .suggested-goal-dropdown .custom-goal input,
#add-meeting.dashboard-wrap .goals-list-column .goal-wrapper .suggested-goal-dropdown .custom-goal input {
  pointer-events: initial;
  height: 44px;
  background: #ebebeb;
  border-radius: 44px;
  border: none;
  padding: 0 20px;
  font-size: 1rem;
  font-family: "Open Sans", sans-serif;
  width: 100%;
  margin-top: 10px;
  outline: none;
  color: #323333;
}
.dashboard-wrap .goals-list-column .goal-wrapper .suggested-goal-dropdown .butt-general,
#goals.dashboard-wrap .goals-list-column .goal-wrapper .suggested-goal-dropdown .butt-general,
#add-meeting.dashboard-wrap .goals-list-column .goal-wrapper .suggested-goal-dropdown .butt-general {
  pointer-events: initial;
  background: #7b68c8;
  margin: 20px 0;
  color: #ffffff;
}
.dashboard-wrap .goals-list-column .goal-wrapper .suggested-goal-dropdown .butt-general .butt-icon,
#goals.dashboard-wrap .goals-list-column .goal-wrapper .suggested-goal-dropdown .butt-general .butt-icon,
#add-meeting.dashboard-wrap .goals-list-column .goal-wrapper .suggested-goal-dropdown .butt-general .butt-icon {
  background-color: white;
}
.dashboard-wrap .goals-list-column .goal-wrapper .butt-add-goal,
#goals.dashboard-wrap .goals-list-column .goal-wrapper .butt-add-goal,
#add-meeting.dashboard-wrap .goals-list-column .goal-wrapper .butt-add-goal {
  background: rgba(255, 255, 255, 0.5);
  z-index: 4;
}
.dashboard-wrap .goals-list-column .goal-wrapper .butt-groups,
#goals.dashboard-wrap .goals-list-column .goal-wrapper .butt-groups,
#add-meeting.dashboard-wrap .goals-list-column .goal-wrapper .butt-groups {
  height: 90%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  position: absolute;
  right: -44px;
  opacity: 0;
  visibility: hidden;
  transform: scale(0);
  transition: transform 0.2s, opacity 0.2s;
}
.dashboard-wrap .goals-list-column .goal-wrapper .butt-groups .butt-icon,
#goals.dashboard-wrap .goals-list-column .goal-wrapper .butt-groups .butt-icon,
#add-meeting.dashboard-wrap .goals-list-column .goal-wrapper .butt-groups .butt-icon {
  z-index: 1;
  width: 34px;
  height: 34px;
  background-size: 80%;
  background-color: rgba(255, 255, 255, 0.45);
  border-radius: 32px;
  background-position: center center;
  background-repeat: no-repeat;
}
.dashboard-wrap .goals-list-column .goal-wrapper .butt-groups .butt-icon.add-team,
#goals.dashboard-wrap .goals-list-column .goal-wrapper .butt-groups .butt-icon.add-team,
#add-meeting.dashboard-wrap .goals-list-column .goal-wrapper .butt-groups .butt-icon.add-team {
  background-image: url(../images/icons/icon-team-transparent.svg);
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: 600;
  position: relative;
}
.dashboard-wrap .goals-list-column .goal-wrapper .butt-groups .butt-icon.add-calendar,
#goals.dashboard-wrap .goals-list-column .goal-wrapper .butt-groups .butt-icon.add-calendar,
#add-meeting.dashboard-wrap .goals-list-column .goal-wrapper .butt-groups .butt-icon.add-calendar {
  background-image: url(../images/icons/icon-addcal-purple.svg);
  background-size: 100%;
}
.dashboard-wrap .goals-list-column .goal-wrapper.active .butt-goal,
#goals.dashboard-wrap .goals-list-column .goal-wrapper.active .butt-goal,
#add-meeting.dashboard-wrap .goals-list-column .goal-wrapper.active .butt-goal {
  background: #F9D87C;
  border: 5px solid white;
}
.dashboard-wrap .goals-list-column .goal-wrapper.active .butt-groups,
#goals.dashboard-wrap .goals-list-column .goal-wrapper.active .butt-groups,
#add-meeting.dashboard-wrap .goals-list-column .goal-wrapper.active .butt-groups {
  opacity: 1;
  visibility: visible;
  transform: scale(1);
}
.dashboard-wrap .goals-list-column .goals-wrap,
#goals.dashboard-wrap .goals-list-column .goals-wrap,
#add-meeting.dashboard-wrap .goals-list-column .goals-wrap {
  width: 100%;
  margin-top: 15px;
  height: 74px;
  background: rgb(255, 255, 255);
  border-radius: 42px;
  border: none;
  outline: none;
}
.dashboard-wrap .goals-list-column .goals-wrap a,
#goals.dashboard-wrap .goals-list-column .goals-wrap a,
#add-meeting.dashboard-wrap .goals-list-column .goals-wrap a {
  padding: 0 10px 0 4px;
}
.dashboard-wrap .goals-list-column .goals-wrap .goals-creator-wrap,
#goals.dashboard-wrap .goals-list-column .goals-wrap .goals-creator-wrap,
#add-meeting.dashboard-wrap .goals-list-column .goals-wrap .goals-creator-wrap {
  height: 64px;
  padding: 5px;
  font-size: 1.1rem;
  text-transform: unset;
  border-radius: 42px;
  border: none;
  outline: none;
}
.dashboard-wrap .goals-list-column .goals-wrap .title-wrap,
#goals.dashboard-wrap .goals-list-column .goals-wrap .title-wrap,
#add-meeting.dashboard-wrap .goals-list-column .goals-wrap .title-wrap {
  flex: 1;
  display: flex;
  flex-direction: column;
}
.dashboard-wrap .goals-list-column .goals-wrap .title-wrap .body,
#goals.dashboard-wrap .goals-list-column .goals-wrap .title-wrap .body,
#add-meeting.dashboard-wrap .goals-list-column .goals-wrap .title-wrap .body {
  color: #5243a0;
  font-weight: 600;
  font-size: 0.65em;
  padding-left: 10px;
  padding-top: 5px;
}
.dashboard-wrap .goals-list-column .goals-wrap .title,
#goals.dashboard-wrap .goals-list-column .goals-wrap .title,
#add-meeting.dashboard-wrap .goals-list-column .goals-wrap .title {
  color: #323333;
  font-weight: 600;
  font-size: 0.85em;
  padding-left: 10px;
}
.dashboard-wrap .goals-list-column .goals-wrap .edit,
.dashboard-wrap .goals-list-column .goals-wrap .close-yellow,
#goals.dashboard-wrap .goals-list-column .goals-wrap .edit,
#goals.dashboard-wrap .goals-list-column .goals-wrap .close-yellow,
#add-meeting.dashboard-wrap .goals-list-column .goals-wrap .edit,
#add-meeting.dashboard-wrap .goals-list-column .goals-wrap .close-yellow {
  height: 40px;
  width: 40px;
  margin: 0 5px;
  background-color: rgba(255, 255, 255, 0.75);
}
.dashboard-wrap .task-list-column,
#goals.dashboard-wrap .task-list-column,
#add-meeting.dashboard-wrap .task-list-column {
  width: 40%;
  margin-top: 40px;
  background-color: #ffffff;
  border-radius: 40px;
  box-shadow: 2px 2px 40px 1px rgba(36, 58, 71, 0.25);
  color: #323333;
  padding: 40px;
}
.dashboard-wrap .task-list-column .form-actions,
#goals.dashboard-wrap .task-list-column .form-actions,
#add-meeting.dashboard-wrap .task-list-column .form-actions {
  text-align: center;
}
.dashboard-wrap .task-list-column .form-actions input[type=submit],
#goals.dashboard-wrap .task-list-column .form-actions input[type=submit],
#add-meeting.dashboard-wrap .task-list-column .form-actions input[type=submit] {
  background: #786ac1;
  text-align: center;
  margin: 0;
  justify-content: center;
  color: white;
  margin-top: 10px;
}
.dashboard-wrap .task-list-column ul,
#goals.dashboard-wrap .task-list-column ul,
#add-meeting.dashboard-wrap .task-list-column ul {
  list-style-type: none;
  padding: 0;
}
.dashboard-wrap .task-list-column ul li,
#goals.dashboard-wrap .task-list-column ul li,
#add-meeting.dashboard-wrap .task-list-column ul li {
  flex: 1;
  display: flex;
  flex-direction: row;
  align-items: center;
}
.dashboard-wrap .task-list-column ul li .task-wrap,
#goals.dashboard-wrap .task-list-column ul li .task-wrap,
#add-meeting.dashboard-wrap .task-list-column ul li .task-wrap {
  background-color: #fdf7e4;
  height: 55px;
  width: 100%;
  border-radius: 50px;
  padding: 10px;
  flex: 1;
  display: flex;
  flex-direction: row;
  align-items: center;
}
.dashboard-wrap .task-list-column ul li .task-wrap .circle,
#goals.dashboard-wrap .task-list-column ul li .task-wrap .circle,
#add-meeting.dashboard-wrap .task-list-column ul li .task-wrap .circle {
  height: 36px;
  width: 36px;
  border-radius: 36px;
  border: #f8d974 2px solid;
}
.dashboard-wrap .task-list-column ul li .task-wrap .circle.butt-icon.edit,
#goals.dashboard-wrap .task-list-column ul li .task-wrap .circle.butt-icon.edit,
#add-meeting.dashboard-wrap .task-list-column ul li .task-wrap .circle.butt-icon.edit {
  background-image: url(../images/icons/icon-edit.svg);
  background-size: 100%;
  background-position: 55% 50%;
}
.dashboard-wrap .task-list-column ul li .task-wrap p,
#goals.dashboard-wrap .task-list-column ul li .task-wrap p,
#add-meeting.dashboard-wrap .task-list-column ul li .task-wrap p {
  width: calc(100% - 76px);
  padding: 0 10px;
}
.dashboard-wrap .task-list-column ul li .butt-icon.add-team,
#goals.dashboard-wrap .task-list-column ul li .butt-icon.add-team,
#add-meeting.dashboard-wrap .task-list-column ul li .butt-icon.add-team {
  background-image: url(../images/icons/icon-team-transparent.svg);
  margin: 5px 0 5px 10px;
  background-color: #e4e0f8;
  background-size: 80%;
  height: 36px;
  width: 36px;
  border-radius: 32px;
  z-index: 1;
  background-position: center center;
  background-repeat: no-repeat;
}
.dashboard-wrap .task-list-column .form-item-field-dream label,
#goals.dashboard-wrap .task-list-column .form-item-field-dream label,
#add-meeting.dashboard-wrap .task-list-column .form-item-field-dream label {
  position: relative;
  left: 0;
  width: 100%;
  display: block;
  margin-bottom: 10px;
}
.dashboard-wrap .task-list-column .form-item-field-dream textarea,
#goals.dashboard-wrap .task-list-column .form-item-field-dream textarea,
#add-meeting.dashboard-wrap .task-list-column .form-item-field-dream textarea {
  width: 100%;
  background: #E4E0F7;
  border: none;
  border-radius: 10px;
  outline: none;
  padding: 20px;
  font-size: 1rem;
  font-family: "Open Sans", sans-serif;
  color: #323333;
}
.dashboard-wrap .main-goal-column,
#goals.dashboard-wrap .main-goal-column,
#add-meeting.dashboard-wrap .main-goal-column {
  width: 20%;
  margin-top: 40px;
}
.dashboard-wrap .main-goal-column .goal-content-box,
#goals.dashboard-wrap .main-goal-column .goal-content-box,
#add-meeting.dashboard-wrap .main-goal-column .goal-content-box {
  width: 100%;
  margin: 0 0 15px !important;
  height: 100%;
  background: rgb(255, 255, 255);
  border-radius: 42px;
  border: none;
  outline: none;
  padding: 15px 30px;
}
.dashboard-wrap .main-goal-column .goal-content-box h2,
#goals.dashboard-wrap .main-goal-column .goal-content-box h2,
#add-meeting.dashboard-wrap .main-goal-column .goal-content-box h2 {
  word-wrap: break-word;
}
.dashboard-wrap .main-goal-column .goal-content-box .small,
#goals.dashboard-wrap .main-goal-column .goal-content-box .small,
#add-meeting.dashboard-wrap .main-goal-column .goal-content-box .small {
  color: #786ac1;
  font-size: 0.8em;
  font-style: italic;
}
.dashboard-wrap .main-goal-column img,
#goals.dashboard-wrap .main-goal-column img,
#add-meeting.dashboard-wrap .main-goal-column img {
  width: 100%;
  margin: 15px 0;
  border-radius: 42px;
  border: none;
  outline: none;
}
.dashboard-wrap .my-team,
.dashboard-wrap .support-network-meeting,
#goals.dashboard-wrap .my-team,
#goals.dashboard-wrap .support-network-meeting,
#add-meeting.dashboard-wrap .my-team,
#add-meeting.dashboard-wrap .support-network-meeting {
  margin: 10px;
}
@media all and (max-width: 768px) {
  .dashboard-wrap .my-team,
.dashboard-wrap .support-network-meeting,
#goals.dashboard-wrap .my-team,
#goals.dashboard-wrap .support-network-meeting,
#add-meeting.dashboard-wrap .my-team,
#add-meeting.dashboard-wrap .support-network-meeting {
    width: 80%;
    margin: 50px 0;
  }
}
.dashboard-wrap .my-team,
#goals.dashboard-wrap .my-team,
#add-meeting.dashboard-wrap .my-team {
  width: 30%;
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
}
.dashboard-wrap .my-team .team-member,
#goals.dashboard-wrap .my-team .team-member,
#add-meeting.dashboard-wrap .my-team .team-member {
  width: 100%;
  margin: 15px 0;
  height: 74px;
  background: rgb(255, 255, 255);
  padding: 5px;
  border-radius: 42px;
  border: none;
  outline: none;
}
.dashboard-wrap .my-team .team-member .title-wrap,
#goals.dashboard-wrap .my-team .team-member .title-wrap,
#add-meeting.dashboard-wrap .my-team .team-member .title-wrap {
  padding: 0 15px;
  flex: 1;
  display: flex;
  flex-direction: column;
}
.dashboard-wrap .my-team .team-member .title-wrap .body,
#goals.dashboard-wrap .my-team .team-member .title-wrap .body,
#add-meeting.dashboard-wrap .my-team .team-member .title-wrap .body {
  color: black;
  font-weight: normal;
  font-size: 0.8em;
  padding-top: 5px;
}
.dashboard-wrap .my-team .team-member .name-avatar,
#goals.dashboard-wrap .my-team .team-member .name-avatar,
#add-meeting.dashboard-wrap .my-team .team-member .name-avatar {
  display: inline-block;
  background-color: #337ca5;
  height: 55px;
  width: 55px;
  border-radius: 45px;
  z-index: 1;
  background-position: center center;
  background-repeat: no-repeat;
  background-size: 60%;
  text-align: center;
}
.dashboard-wrap .my-team .team-member .name-avatar span,
#goals.dashboard-wrap .my-team .team-member .name-avatar span,
#add-meeting.dashboard-wrap .my-team .team-member .name-avatar span {
  color: white;
  line-height: 55px;
}
.dashboard-wrap .my-team .butt-add-meeting,
#goals.dashboard-wrap .my-team .butt-add-meeting,
#add-meeting.dashboard-wrap .my-team .butt-add-meeting {
  margin: 15px 0;
}
.dashboard-wrap .support-network-meeting,
#goals.dashboard-wrap .support-network-meeting,
#add-meeting.dashboard-wrap .support-network-meeting {
  width: 70%;
  margin-top: 15px;
  height: 100%;
  background: rgb(255, 255, 255);
  border-radius: 42px;
  border: none;
  outline: none;
  padding: 15px 30px;
}
.dashboard-wrap .support-network-meeting h3,
#goals.dashboard-wrap .support-network-meeting h3,
#add-meeting.dashboard-wrap .support-network-meeting h3 {
  color: #323333;
}
.dashboard-wrap .support-network-meeting p,
#goals.dashboard-wrap .support-network-meeting p,
#add-meeting.dashboard-wrap .support-network-meeting p {
  color: black;
}
.dashboard-wrap .support-network-meeting .title,
#goals.dashboard-wrap .support-network-meeting .title,
#add-meeting.dashboard-wrap .support-network-meeting .title {
  font-weight: normal;
  padding: 0 10px;
}
.dashboard-wrap .support-network-meeting .butt-guide,
#goals.dashboard-wrap .support-network-meeting .butt-guide,
#add-meeting.dashboard-wrap .support-network-meeting .butt-guide {
  width: 100%;
  margin: 10px 0;
}
.dashboard-wrap .support-network-meeting .butt-guide .title,
#goals.dashboard-wrap .support-network-meeting .butt-guide .title,
#add-meeting.dashboard-wrap .support-network-meeting .butt-guide .title {
  text-align: left;
  color: black;
  margin: auto;
}
.dashboard-wrap .support-network-meeting .butt-guide .thick-text,
#goals.dashboard-wrap .support-network-meeting .butt-guide .thick-text,
#add-meeting.dashboard-wrap .support-network-meeting .butt-guide .thick-text {
  font-weight: bold;
}
.dashboard-wrap .support-network-meeting .support-network-meeting-content,
#goals.dashboard-wrap .support-network-meeting .support-network-meeting-content,
#add-meeting.dashboard-wrap .support-network-meeting .support-network-meeting-content {
  flex: 1;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
@media all and (max-width: 1024px) {
  .dashboard-wrap .support-network-meeting .support-network-meeting-content,
#goals.dashboard-wrap .support-network-meeting .support-network-meeting-content,
#add-meeting.dashboard-wrap .support-network-meeting .support-network-meeting-content {
    flex-direction: column;
  }
}
.dashboard-wrap .support-network-meeting .support-network-meeting-content .calendar-wrap,
#goals.dashboard-wrap .support-network-meeting .support-network-meeting-content .calendar-wrap,
#add-meeting.dashboard-wrap .support-network-meeting .support-network-meeting-content .calendar-wrap {
  width: 45%;
  height: 100%;
}
@media all and (max-width: 1024px) {
  .dashboard-wrap .support-network-meeting .support-network-meeting-content .calendar-wrap,
#goals.dashboard-wrap .support-network-meeting .support-network-meeting-content .calendar-wrap,
#add-meeting.dashboard-wrap .support-network-meeting .support-network-meeting-content .calendar-wrap {
    width: 100%;
  }
}
.dashboard-wrap .support-network-meeting .support-network-meeting-content .calendar-wrap .ui-datepicker,
#goals.dashboard-wrap .support-network-meeting .support-network-meeting-content .calendar-wrap .ui-datepicker,
#add-meeting.dashboard-wrap .support-network-meeting .support-network-meeting-content .calendar-wrap .ui-datepicker {
  height: 100%;
  border-radius: 25px;
}
.dashboard-wrap .support-network-meeting .support-network-meeting-content .calendar-wrap .ui-widget-header,
#goals.dashboard-wrap .support-network-meeting .support-network-meeting-content .calendar-wrap .ui-widget-header,
#add-meeting.dashboard-wrap .support-network-meeting .support-network-meeting-content .calendar-wrap .ui-widget-header {
  border: 0px;
  background-color: transparent;
}
.dashboard-wrap .support-network-meeting .support-network-meeting-content .calendar-wrap .ui-widget.ui-widget-content,
#goals.dashboard-wrap .support-network-meeting .support-network-meeting-content .calendar-wrap .ui-widget.ui-widget-content,
#add-meeting.dashboard-wrap .support-network-meeting .support-network-meeting-content .calendar-wrap .ui-widget.ui-widget-content {
  border: 1px solid lightgrey;
}
.dashboard-wrap .support-network-meeting .support-network-meeting-content .calendar-wrap .ui-datepicker th,
#goals.dashboard-wrap .support-network-meeting .support-network-meeting-content .calendar-wrap .ui-datepicker th,
#add-meeting.dashboard-wrap .support-network-meeting .support-network-meeting-content .calendar-wrap .ui-datepicker th {
  text-transform: uppercase;
  font-size: 8pt;
  font-weight: normal;
  color: #5243a0;
  /*text-shadow: 1px 0px 0px #fff;*/
  /*filter: dropshadow(color=#fff, offx=1, offy=0);*/
}
.dashboard-wrap .support-network-meeting .support-network-meeting-content .calendar-wrap .ui-datepicker tbody,
#goals.dashboard-wrap .support-network-meeting .support-network-meeting-content .calendar-wrap .ui-datepicker tbody,
#add-meeting.dashboard-wrap .support-network-meeting .support-network-meeting-content .calendar-wrap .ui-datepicker tbody {
  border-top: 1px #5243a0 solid;
  border-bottom: 1px #5243a0 solid;
}
.dashboard-wrap .support-network-meeting .support-network-meeting-content .calendar-wrap .ui-datepicker tbody td,
#goals.dashboard-wrap .support-network-meeting .support-network-meeting-content .calendar-wrap .ui-datepicker tbody td,
#add-meeting.dashboard-wrap .support-network-meeting .support-network-meeting-content .calendar-wrap .ui-datepicker tbody td {
  padding: 0;
}
.dashboard-wrap .support-network-meeting .support-network-meeting-content .calendar-wrap .ui-datepicker tbody td a,
#goals.dashboard-wrap .support-network-meeting .support-network-meeting-content .calendar-wrap .ui-datepicker tbody td a,
#add-meeting.dashboard-wrap .support-network-meeting .support-network-meeting-content .calendar-wrap .ui-datepicker tbody td a {
  height: 37px;
  width: 37px;
}
.dashboard-wrap .support-network-meeting .support-network-meeting-content .calendar-wrap .ui-widget-content,
#goals.dashboard-wrap .support-network-meeting .support-network-meeting-content .calendar-wrap .ui-widget-content,
#add-meeting.dashboard-wrap .support-network-meeting .support-network-meeting-content .calendar-wrap .ui-widget-content {
  background: transparent;
  border: 0px;
  text-align: center;
  padding: 15px 10px;
  width: 80%;
}
.dashboard-wrap .support-network-meeting .support-network-meeting-content .calendar-wrap .ui-datepicker-calendar .ui-state-active,
#goals.dashboard-wrap .support-network-meeting .support-network-meeting-content .calendar-wrap .ui-datepicker-calendar .ui-state-active,
#add-meeting.dashboard-wrap .support-network-meeting .support-network-meeting-content .calendar-wrap .ui-datepicker-calendar .ui-state-active {
  height: 37px;
  text-align: center;
  line-height: 37px;
  padding: 0;
  border: 0;
}
.dashboard-wrap .support-network-meeting .support-network-meeting-content .calendar-wrap .ui-datepicker-calendar .ui-state-default,
#goals.dashboard-wrap .support-network-meeting .support-network-meeting-content .calendar-wrap .ui-datepicker-calendar .ui-state-default,
#add-meeting.dashboard-wrap .support-network-meeting .support-network-meeting-content .calendar-wrap .ui-datepicker-calendar .ui-state-default {
  background: transparent;
  border: 0px;
  padding: 0;
  text-align: center;
  line-height: 37px;
}
.dashboard-wrap .support-network-meeting .support-network-meeting-content .calendar-wrap .ui-datepicker-calendar .ui-state-hover,
#goals.dashboard-wrap .support-network-meeting .support-network-meeting-content .calendar-wrap .ui-datepicker-calendar .ui-state-hover,
#add-meeting.dashboard-wrap .support-network-meeting .support-network-meeting-content .calendar-wrap .ui-datepicker-calendar .ui-state-hover {
  opacity: 0.6;
}
.dashboard-wrap .support-network-meeting .support-network-meeting-content .calendar-wrap .ui-datepicker-today > a,
#goals.dashboard-wrap .support-network-meeting .support-network-meeting-content .calendar-wrap .ui-datepicker-today > a,
#add-meeting.dashboard-wrap .support-network-meeting .support-network-meeting-content .calendar-wrap .ui-datepicker-today > a {
  color: #5243a0;
}
.dashboard-wrap .support-network-meeting .support-network-meeting-content .calendar-wrap .ui-datepicker-current-day,
#goals.dashboard-wrap .support-network-meeting .support-network-meeting-content .calendar-wrap .ui-datepicker-current-day,
#add-meeting.dashboard-wrap .support-network-meeting .support-network-meeting-content .calendar-wrap .ui-datepicker-current-day {
  font-weight: bold;
  color: #323333;
  border-radius: 50%;
  background: #5243a0 !important;
}
.dashboard-wrap .support-network-meeting .support-network-meeting-content .calendar-wrap .ui-datepicker-current-day a,
#goals.dashboard-wrap .support-network-meeting .support-network-meeting-content .calendar-wrap .ui-datepicker-current-day a,
#add-meeting.dashboard-wrap .support-network-meeting .support-network-meeting-content .calendar-wrap .ui-datepicker-current-day a {
  color: #ffffff !important;
}
.dashboard-wrap .support-network-meeting .support-network-meeting-content .calendar-wrap .ui-widget-header .ui-icon,
#goals.dashboard-wrap .support-network-meeting .support-network-meeting-content .calendar-wrap .ui-widget-header .ui-icon,
#add-meeting.dashboard-wrap .support-network-meeting .support-network-meeting-content .calendar-wrap .ui-widget-header .ui-icon {
  background-image: url(../images/icons/icon-arrow-next-black.svg);
  background-size: 35%;
  background-position: 58% 50%;
}
.dashboard-wrap .support-network-meeting .support-network-meeting-content .calendar-wrap .ui-datepicker .ui-datepicker-prev,
#goals.dashboard-wrap .support-network-meeting .support-network-meeting-content .calendar-wrap .ui-datepicker .ui-datepicker-prev,
#add-meeting.dashboard-wrap .support-network-meeting .support-network-meeting-content .calendar-wrap .ui-datepicker .ui-datepicker-prev {
  transform: rotate(180deg);
}
.dashboard-wrap .support-network-meeting .support-network-meeting-content .guide-wrap,
#goals.dashboard-wrap .support-network-meeting .support-network-meeting-content .guide-wrap,
#add-meeting.dashboard-wrap .support-network-meeting .support-network-meeting-content .guide-wrap {
  width: 55%;
}
@media all and (max-width: 1024px) {
  .dashboard-wrap .support-network-meeting .support-network-meeting-content .guide-wrap,
#goals.dashboard-wrap .support-network-meeting .support-network-meeting-content .guide-wrap,
#add-meeting.dashboard-wrap .support-network-meeting .support-network-meeting-content .guide-wrap {
    width: 100%;
  }
}
.dashboard-wrap .support-network-meeting .support-network-meeting-content .guide-wrap .butt-icon,
#goals.dashboard-wrap .support-network-meeting .support-network-meeting-content .guide-wrap .butt-icon,
#add-meeting.dashboard-wrap .support-network-meeting .support-network-meeting-content .guide-wrap .butt-icon {
  background-color: transparent;
  border: 1px solid #f8d974;
}
.dashboard-wrap .support-network-meeting .meeting-info,
#goals.dashboard-wrap .support-network-meeting .meeting-info,
#add-meeting.dashboard-wrap .support-network-meeting .meeting-info {
  flex: 1;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
.dashboard-wrap .support-network-meeting .meeting-info .meeting-desc,
#goals.dashboard-wrap .support-network-meeting .meeting-info .meeting-desc,
#add-meeting.dashboard-wrap .support-network-meeting .meeting-info .meeting-desc {
  text-align: left;
}
.dashboard-wrap .support-network-meeting .meeting-info .meeting-time,
.dashboard-wrap .support-network-meeting .meeting-info .meeting-address,
#goals.dashboard-wrap .support-network-meeting .meeting-info .meeting-time,
#goals.dashboard-wrap .support-network-meeting .meeting-info .meeting-address,
#add-meeting.dashboard-wrap .support-network-meeting .meeting-info .meeting-time,
#add-meeting.dashboard-wrap .support-network-meeting .meeting-info .meeting-address {
  display: flex;
  height: auto;
}
.dashboard-wrap .support-network-meeting .meeting-info .meeting-time .meeting-desc,
.dashboard-wrap .support-network-meeting .meeting-info .meeting-address .meeting-desc,
#goals.dashboard-wrap .support-network-meeting .meeting-info .meeting-time .meeting-desc,
#goals.dashboard-wrap .support-network-meeting .meeting-info .meeting-address .meeting-desc,
#add-meeting.dashboard-wrap .support-network-meeting .meeting-info .meeting-time .meeting-desc,
#add-meeting.dashboard-wrap .support-network-meeting .meeting-info .meeting-address .meeting-desc {
  height: auto;
  color: #7b68c8;
  margin: 2.5px 0;
  width: 80%;
}
.dashboard-wrap .support-network-meeting .meeting-info .meeting-time .butt-icon,
.dashboard-wrap .support-network-meeting .meeting-info .meeting-address .butt-icon,
#goals.dashboard-wrap .support-network-meeting .meeting-info .meeting-time .butt-icon,
#goals.dashboard-wrap .support-network-meeting .meeting-info .meeting-address .butt-icon,
#add-meeting.dashboard-wrap .support-network-meeting .meeting-info .meeting-time .butt-icon,
#add-meeting.dashboard-wrap .support-network-meeting .meeting-info .meeting-address .butt-icon {
  width: 30px;
  height: 30px;
  border-radius: 30px;
}
.dashboard-wrap .support-network-meeting .meeting-info .meeting-time .butt-icon.clock-purple,
.dashboard-wrap .support-network-meeting .meeting-info .meeting-address .butt-icon.clock-purple,
#goals.dashboard-wrap .support-network-meeting .meeting-info .meeting-time .butt-icon.clock-purple,
#goals.dashboard-wrap .support-network-meeting .meeting-info .meeting-address .butt-icon.clock-purple,
#add-meeting.dashboard-wrap .support-network-meeting .meeting-info .meeting-time .butt-icon.clock-purple,
#add-meeting.dashboard-wrap .support-network-meeting .meeting-info .meeting-address .butt-icon.clock-purple {
  background-image: url(../images/icons/icon-clock-purple.svg);
  background-size: 100%;
}
.dashboard-wrap .support-network-meeting .meeting-info .meeting-time .butt-icon.location-purple,
.dashboard-wrap .support-network-meeting .meeting-info .meeting-address .butt-icon.location-purple,
#goals.dashboard-wrap .support-network-meeting .meeting-info .meeting-time .butt-icon.location-purple,
#goals.dashboard-wrap .support-network-meeting .meeting-info .meeting-address .butt-icon.location-purple,
#add-meeting.dashboard-wrap .support-network-meeting .meeting-info .meeting-time .butt-icon.location-purple,
#add-meeting.dashboard-wrap .support-network-meeting .meeting-info .meeting-address .butt-icon.location-purple {
  background-image: url(../images/icons/icon-location-purple.svg);
  background-size: 100%;
}
.dashboard-wrap .support-network-meeting .meeting-info .meeting-time,
#goals.dashboard-wrap .support-network-meeting .meeting-info .meeting-time,
#add-meeting.dashboard-wrap .support-network-meeting .meeting-info .meeting-time {
  width: 20%;
}
.dashboard-wrap .support-network-meeting .meeting-info .meeting-address,
#goals.dashboard-wrap .support-network-meeting .meeting-info .meeting-address,
#add-meeting.dashboard-wrap .support-network-meeting .meeting-info .meeting-address {
  width: 40%;
}

.image-widget {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 20px;
}
.image-widget img {
  border-radius: 20px;
  width: 70px;
  height: 70px;
}
.image-widget span {
  padding: 0 10px;
}
.image-widget span a {
  max-width: 100px;
  overflow: hidden;
  display: block;
  height: 70px;
}
.image-widget .file-size {
  display: none;
}
.image-widget input[name=field_photo_remove_button] {
  text-align: center;
  justify-content: center;
  margin: 0;
  padding: 0;
  width: 100px;
}

@media all and (max-width: 1300px) {
  #goals.dashboard-wrap {
    width: 100vw;
    padding: 50px;
  }
}

.node-goal-form,
.node-goal-edit-form {
  width: 100%;
}

.zzdashboard-wrap {
  display: flex;
}
@media all and (max-width: 1300px) {
  .zzdashboard-wrap {
    width: 100vw;
    padding: 0 30px;
  }
}
@media all and (max-width: 800px) {
  .zzdashboard-wrap {
    width: 100vw;
    padding: 0 20px;
    flex-direction: column;
  }
}
.zzdashboard-wrap .header-wrap {
  display: flex;
  align-items: center;
}
.zzdashboard-wrap .header-wrap h1 {
  margin: 20px 10px 20px 0;
}
@media all and (max-width: 800px) {
  .zzdashboard-wrap .header-wrap h1 {
    display: none;
  }
  .zzdashboard-wrap .header-wrap h1.team-member {
    display: block;
    flex: 1;
    text-align: center;
  }
}
@media all and (max-width: 800px) {
  .zzdashboard-wrap .header-wrap {
    justify-content: space-between;
    padding-top: 20px;
  }
}
.zzdashboard-wrap .header-wrap .butt-dashboard {
  display: none;
}
@media all and (max-width: 800px) {
  .zzdashboard-wrap .header-wrap .butt-dashboard {
    display: flex;
    width: 42px;
    padding-left: 5px;
  }
  .zzdashboard-wrap .header-wrap .butt-dashboard .butt-icon {
    min-width: 32px;
  }
  .zzdashboard-wrap .header-wrap .butt-dashboard .title {
    display: none;
  }
}
.zzdashboard-wrap .header-wrap .butt-help,
.zzdashboard-wrap .header-wrap .butt-add-goal {
  height: 25px;
  min-width: 110px;
  border-radius: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: bold;
  color: #323333;
  margin-left: 10px;
  text-decoration: none;
  margin-top: 5px;
  transition: all 0.2s;
  animation: none;
  padding: 10px;
  font-size: 12px;
  text-transform: uppercase;
  background: #F9D87C;
}
.zzdashboard-wrap .header-wrap .butt-help:hover,
.zzdashboard-wrap .header-wrap .butt-add-goal:hover {
  transform: scale(1.1);
}
@media all and (max-width: 800px) {
  .zzdashboard-wrap .header-wrap .butt-help,
.zzdashboard-wrap .header-wrap .butt-add-goal {
    display: none;
  }
}
.zzdashboard-wrap .goals-creator-container.team-member details {
  border-radius: 20px;
  background: #ffffff;
  padding: 0 20px;
}
.zzdashboard-wrap .goals-creator-container.team-member details summary {
  padding: 10px 0;
  font-family: "Open Sans", sans-serif;
  font-weight: bold;
  display: revert;
}
.zzdashboard-wrap .goals-creator-container.team-member details summary::marker {
  margin-right: 10px;
}
.zzdashboard-wrap .goals-creator-container.team-member details .butt-goal {
  border: none;
}
.zzdashboard-wrap .goals-creator-container.team-member details .goal-wrapper:last-child {
  padding-bottom: 20px;
}
@media all and (max-width: 800px) {
  .zzdashboard-wrap .goals-creator-container {
    display: none;
  }
  .zzdashboard-wrap .goals-creator-container.team-member {
    display: block;
    margin: 40px 0;
  }
}
.zzdashboard-wrap .template-selection {
  padding-top: 10px;
}
.zzdashboard-wrap .zzgoals-list-column {
  margin-right: 65px;
  width: 30%;
  max-width: 340px;
  min-width: 340px;
}
.zzdashboard-wrap .zzgoals-list-column h2 {
  margin-bottom: 15px;
  margin-top: 30px;
  font-size: 0.88rem;
}
@media all and (max-width: 800px) {
  .zzdashboard-wrap .zzgoals-list-column {
    width: 100%;
    max-width: 100%;
  }
}
.zzdashboard-wrap .goal-wrapper {
  margin-bottom: 10px;
}
.zzdashboard-wrap .goal-wrapper .butt-icon {
  order: -1;
}
.zzdashboard-wrap .goal-wrapper .title {
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.zzdashboard-wrap .goal-wrapper .title .title_inner {
  text-align: left;
  margin-left: 10px;
  line-height: 1.3em;
  max-height: 2.6em;
  overflow: hidden;
}
.zzdashboard-wrap .goal-wrapper .butt-goal {
  height: 50px;
  border: 5px solid #ffffff;
}
.zzdashboard-wrap .goal-wrapper.active .butt-goal {
  background: #F9D87C;
  color: #323333;
}
.zzdashboard-wrap .goal-wrapper.active .butt-goal .butt-icon {
  background-color: #F9D87C;
}
.zzdashboard-wrap .goal-wrapper.active .butt-groups {
  opacity: 1;
  visibility: visible;
  transform: scale(1);
}
.zzdashboard-wrap .butt-general {
  width: 100%;
  margin: 0;
  text-transform: none;
}
.zzdashboard-wrap .zztask-list-column .goal-info {
  margin: 50px 0 30px 0;
  text-align: center;
}
@media all and (max-width: 800px) {
  .zzdashboard-wrap .zztask-list-column .goal-info {
    margin-top: 0;
  }
}
.zzdashboard-wrap .zztask-list-column .goal-definition-wrap {
  margin-top: 50px;
  background: #ffffff;
  border-radius: 50px;
  padding: 20px 40px;
  box-shadow: 2px 2px 40px 1px rgba(36, 58, 71, 0.25);
}
@media all and (max-width: 800px) {
  .zzdashboard-wrap .zztask-list-column .goal-definition-wrap {
    padding: 20px;
  }
}
.zzdashboard-wrap .zztask-list-column .goal-definition-wrap h3 {
  text-align: center;
}
.zzdashboard-wrap .zztask-list-column .goal-definition-wrap .goal-field {
  padding-bottom: 20px;
  display: flex;
}
.zzdashboard-wrap .zztask-list-column .goal-definition-wrap .goal-field h4 {
  width: 100px;
  margin: 0;
  font-size: 25px;
  margin-top: 30px;
  color: #337ca5;
  text-align: right;
  padding-right: 20px;
}
@media all and (max-width: 800px) {
  .zzdashboard-wrap .zztask-list-column .goal-definition-wrap .goal-field h4 {
    font-size: 18px;
    width: 75px;
    padding: 5px 10px 0 0;
  }
}
.zzdashboard-wrap .zztask-list-column .goal-definition-wrap .goal-field .fields {
  flex: 1;
}
.zzdashboard-wrap .zztask-list-column .goal-definition-wrap .written-goal h4 {
  text-align: center;
  font-size: 1.17em;
  color: #323333;
}
.zzdashboard-wrap .zztask-list-column .goal-actions-wrap {
  margin-top: 50px;
  background: #ffffff;
  border-radius: 50px;
  padding: 20px 40px;
  box-shadow: 2px 2px 40px 1px rgba(36, 58, 71, 0.25);
}
.zzdashboard-wrap .zztask-list-column .goal-actions-wrap h4 {
  font-size: 1.17em;
}
.zzdashboard-wrap .zztask-list-column .goal-actions-wrap p {
  font-weight: normal;
  margin-bottom: 40px;
}
@media all and (max-width: 800px) {
  .zzdashboard-wrap .zztask-list-column .goal-actions-wrap {
    padding: 20px;
  }
}
.zzdashboard-wrap .zztask-list-column .goal-actions-wrap .field-multiple-drag {
  padding: 0;
}
.zzdashboard-wrap .zztask-list-column .goal-actions-wrap td {
  padding: 0;
}
.zzdashboard-wrap .zztask-list-column .goal-actions-wrap .paragraphs-subform {
  align-items: center;
}
.zzdashboard-wrap .zztask-list-column .goal-actions-wrap .paragraphs-subform > div {
  flex: 1;
}
.zzdashboard-wrap .zztask-list-column .goal-actions-wrap .paragraphs-subform > div:nth-child(2) {
  flex: 0;
}
.zzdashboard-wrap .zztask-list-column .goal-actions-wrap .paragraphs-subform .field--widget-boolean-checkbox {
  order: -1;
}
.zzdashboard-wrap .zztask-list-column .goal-actions-wrap .paragraphs-subform .js-form-type-textfield label {
  display: none;
}
.zzdashboard-wrap .zztask-list-column .goal-notify-wrap {
  margin: 50px;
  text-align: center;
}
@media all and (max-width: 800px) {
  .zzdashboard-wrap .zztask-list-column .goal-notify-wrap {
    margin: 50px 0;
  }
}
.zzdashboard-wrap .zztask-list-column .goal-notify-wrap .js-form-type-checkbox {
  justify-content: flex-start;
  text-align: left;
  margin: 0 auto;
  width: fit-content;
}
.zzdashboard-wrap .zztask-list-column .goal-notify-wrap .js-form-type-checkbox label {
  display: block !important;
  font-weight: normal;
}
.zzdashboard-wrap .zztask-list-column .goal-status-wrap {
  margin: 50px 0;
  background: #ffffff;
  text-align: center;
  border-radius: 50px;
  padding: 20px 40px;
  box-shadow: 2px 2px 40px 1px rgba(36, 58, 71, 0.25);
}
.zzdashboard-wrap .zztask-list-column .goal-status-wrap #edit-feedback {
  margin-top: 50px;
}
.zzdashboard-wrap .zztask-list-column .form-actions {
  text-align: center;
  margin-bottom: 50px;
}
.zzdashboard-wrap .zztask-list-column .form-actions .butt-general {
  text-transform: uppercase;
  width: 180px;
}
.zzdashboard-wrap .zztask-list-column .form-actions .butt-dashboard {
  position: absolute;
  left: 30px;
}
@media all and (max-width: 800px) {
  .zzdashboard-wrap .zztask-list-column .form-actions .butt-dashboard {
    left: 20px;
    width: 42px;
  }
  .zzdashboard-wrap .zztask-list-column .form-actions .butt-dashboard .title {
    display: none;
  }
  .zzdashboard-wrap .zztask-list-column .form-actions .butt-dashboard .butt-icon {
    min-width: 32px;
  }
}
.zzdashboard-wrap .zztask-list-column .form-item-field-notify-team-members-value {
  display: flex;
}
.zzdashboard-wrap .zztask-list-column #edit-field-assigned-to--description {
  font-size: 0.8em;
  text-align: right;
}
@media all and (max-width: 800px) {
  .zzdashboard-wrap .zztask-list-column #edit-field-assigned-to--description {
    padding-top: 10px;
  }
}

.confirm-remove-user-form {
  text-align: center;
}
@media all and (max-width: 600px) {
  .confirm-remove-user-form {
    width: 80%;
  }
}
.confirm-remove-user-form .form-actions {
  display: flex;
  align-items: center;
  justify-content: center;
}
@media all and (max-width: 600px) {
  .confirm-remove-user-form .form-actions {
    flex-direction: column-reverse;
  }
}
.confirm-remove-user-form .butt-general {
  margin: 20px;
}
.confirm-remove-user-form .butt-general[name=support-remove-user-back] .butt-icon {
  transform: rotate(180deg);
}
.confirm-remove-user-form .butt-general[name=support-remove-user-remove] {
  background: #dc3830;
  color: #ffffff;
}
.confirm-remove-user-form .butt-general[name=support-remove-user-remove] .butt-icon {
  background: #dc3830 url(../images/icons/icon-close-light.svg);
  background-position: 58% 50%;
  background-repeat: no-repeat;
}

.quiz-category-wrap {
  position: relative;
}
.quiz-category-wrap h1 {
  margin-top: 50px;
  text-align: center;
}
.quiz-category-wrap .quiz-info-wrap {
  background: #ffffff;
  border-radius: 40px;
  box-shadow: 2px 2px 40px 1px rgba(36, 58, 71, 0.25);
  color: #323333;
  padding: 40px;
  width: 82%;
  max-width: 1200px;
  min-width: 700px;
  margin: 50px auto;
  text-align: center;
}
.quiz-category-wrap .quiz-info-wrap .video {
  width: 80%;
  max-width: 600px;
  height: 50%;
  margin: 0 auto;
}
.quiz-category-wrap .quiz-info-wrap .video img {
  object-fit: cover;
  width: 100%;
}
.quiz-category-wrap .quiz-info-wrap .video .iframe-wrapper {
  position: relative;
  padding-top: 56.25%;
}
.quiz-category-wrap .quiz-info-wrap .video iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
.quiz-category-wrap .quiz-info-wrap h2 {
  font-size: 1.4em;
  color: #786ac1;
}
.quiz-category-wrap .quiz-info-wrap .butt-start-quiz {
  background: #7b68c8;
  color: #fff;
}
.quiz-category-wrap .quiz-info-wrap .butt-start-quiz .butt-icon {
  background-color: #fff;
}
.quiz-category-wrap .quiz-info-wrap .butt-start-quiz .animation-bubble {
  background: #493d76;
}
.quiz-category-wrap .button-wrap {
  text-align: center;
  margin-bottom: 50px;
}
.quiz-category-wrap .button-wrap .butt-start-quiz {
  margin: 0;
}

.quiz-wrapper {
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  width: 1200px;
  padding-top: 50px;
  padding-bottom: 50px;
  overflow: hidden;
}
@media all and (max-width: 1300px) {
  .quiz-wrapper {
    width: 100%;
    padding: 0 30px;
  }
}
@media all and (max-width: 800px) {
  .quiz-wrapper {
    width: 100%;
    padding: 0 20px;
  }
}
.quiz-wrapper h1 {
  margin: 0 0 50px 0;
}
.quiz-wrapper .hidden-questions {
  opacity: 0;
  visibility: 0;
  height: 0;
  width: 0;
}
.quiz-wrapper .progress {
  width: 100%;
  height: 24px;
  background: #ffffff;
  border-radius: 24px;
  padding: 0 10px;
  z-index: 0;
  box-shadow: 2px 2px 40px 1px rgba(36, 58, 71, 0.25);
}
.quiz-wrapper .progress .progress-fill {
  background: #CAC2EC;
  height: 8px;
  position: absolute;
  width: calc(100% - 20px);
  top: 0;
  bottom: 0;
  margin: auto;
  border-radius: 20px;
  width: 0%;
  z-index: -1;
  transition: width 0.5s;
}
.quiz-wrapper .progress .progress-dots {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  z-index: 1;
}
.quiz-wrapper .progress .progress-dots .dot {
  width: 10px;
  height: 10px;
  border-radius: 20px;
  background: #E4E0F7;
}
.quiz-wrapper .progress .progress-dots .dot.complete {
  background: #786ac1;
}
.quiz-wrapper .progress .progress-dots .dot.current {
  background: #ffffff;
  border: 3px solid #786ac1;
  width: 13px;
  height: 13px;
  animation-duration: 0.4s;
  animation-fill-mode: both;
  animation-name: pulseBigger;
}
.quiz-wrapper .questions-wrap {
  margin-top: 40px;
  background: #ffffff;
  border-radius: 40px;
  box-shadow: 2px 2px 40px 1px rgba(36, 58, 71, 0.25);
  color: #323333;
  padding: 40px;
  width: 77%;
  max-width: 920px;
  min-width: 600px;
}
@media all and (max-width: 800px) {
  .quiz-wrapper .questions-wrap {
    width: 100%;
    min-width: 100%;
    padding: 20px;
  }
}
.quiz-wrapper .questions-wrap #no-next-message {
  text-align: center;
  padding: 10px;
  background: #eeeeee;
  border-radius: 30px;
  font-size: 0.8em;
}
.quiz-wrapper .questions-wrap .question {
  text-align: center;
}
.quiz-wrapper .questions-wrap .question h2 {
  font-size: 1.4em;
  color: #786ac1;
  margin-top: 0;
}
.quiz-wrapper .questions-wrap .question h2.lifealt {
  color: #323333;
  margin-bottom: 36px;
}
.quiz-wrapper .questions-wrap .question h3 {
  color: #786ac1;
}
.quiz-wrapper .questions-wrap .question .subtitle {
  font-size: 0.9em;
  padding: 10px 0;
  color: #786ac1;
}
@media all and (max-width: 767px) {
  .quiz-wrapper .questions-wrap .question .subtitle {
    font-size: 0.75rem;
  }
}
.quiz-wrapper .questions-wrap .question .dropzone {
  width: 100%;
  height: 34px;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 40px;
}
.quiz-wrapper .questions-wrap .question .dropzone .drop-line {
  width: 100%;
  height: 8px;
  background: #c5c5c5;
  border-radius: 20px;
}
.quiz-wrapper .questions-wrap .question .dropzone .draggable-option {
  width: 34px;
  height: 34px;
  overflow: hidden;
}
.quiz-wrapper .questions-wrap .question .dropzone .draggable-option.place {
  animation-duration: 0.4s;
  animation-fill-mode: both;
  animation-name: pulseBig;
}
.quiz-wrapper .questions-wrap .question .dropzone .draggable-option .icon {
  width: 34px;
  height: 34px;
  background: #59a3d0;
  border-radius: 34px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #323333;
  font-weight: 600;
  cursor: grab;
}
.quiz-wrapper .questions-wrap .question .dropzone .draggable-option .icon:after {
  content: "A";
}
.quiz-wrapper .questions-wrap .question .dropzone .draggable-option .title, .quiz-wrapper .questions-wrap .question .dropzone .draggable-option .animation-bubble {
  display: none;
}
.quiz-wrapper .questions-wrap .question .dropzone .draggable-option.option1 .icon {
  background: #EB8782;
}
.quiz-wrapper .questions-wrap .question .dropzone .draggable-option.option1 .icon:after {
  content: "B";
}
.quiz-wrapper .questions-wrap .question .dropzone .draggable-option.option2 .icon {
  background: #F3C762;
}
.quiz-wrapper .questions-wrap .question .dropzone .draggable-option.option2 .icon:after {
  content: "C";
}
.quiz-wrapper .questions-wrap .question .dropzone .draggable-option.option3 .icon {
  background: #86CC88;
}
.quiz-wrapper .questions-wrap .question .dropzone .draggable-option.option3 .icon:after {
  content: "D";
}
.quiz-wrapper .questions-wrap .question .dropzone-labels {
  display: flex;
  justify-content: space-between;
  font-size: 0.75em;
  margin-top: 5px;
}
.quiz-wrapper .questions-wrap .question .answer-options {
  padding: 40px 0;
}
@media all and (max-width: 767px) {
  .quiz-wrapper .questions-wrap .question .answer-options {
    padding: 20px 0;
  }
}
.quiz-wrapper .questions-wrap .question .answer-options .select-buttons {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 95%;
  margin: 0 auto;
}
.quiz-wrapper .questions-wrap .question .answer-options .select-buttons .option {
  width: 26px;
  height: 26px;
  border: 2px solid #AA9EE3;
  background: transparent;
  border-radius: 30px;
  outline: none;
  padding: 0;
}
.quiz-wrapper .questions-wrap .question .answer-options .select-buttons .option .inner {
  width: 18px;
  height: 18px;
  background: #AA9EE3;
  border-radius: 20px;
  margin: auto;
  display: block;
  opacity: 0;
  transition: all 0.4s;
}
.quiz-wrapper .questions-wrap .question .answer-options .select-buttons .option:hover {
  cursor: pointer;
  animation-duration: 0.4s;
  animation-fill-mode: both;
  animation-name: pulseBig;
}
.quiz-wrapper .questions-wrap .question .answer-options .select-buttons .option:hover .inner {
  opacity: 1;
}
.quiz-wrapper .questions-wrap .question .answer-options .select-buttons .option.selected {
  animation-duration: 0.3s;
  animation-fill-mode: both;
  animation-name: pulseBigger;
}
.quiz-wrapper .questions-wrap .question .answer-options .select-buttons .option.selected .inner {
  opacity: 1;
}
.quiz-wrapper .questions-wrap .question .answer-options .draggable-options {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
}
.quiz-wrapper .questions-wrap .question .answer-options .draggable-options .draggable-option {
  background: #D4E9F7;
  border: 5px solid #D4E9F7;
  height: 64px;
  width: 48%;
  font-weight: normal;
  color: #323333;
  margin-bottom: 10px;
  border-radius: 40px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding: 0 20px 0 10px;
  cursor: grab;
  text-align: left;
  transition: all 0.2s;
  overflow: hidden;
  font-size: 0.9em;
}
@media all and (max-width: 767px) {
  .quiz-wrapper .questions-wrap .question .answer-options .draggable-options .draggable-option {
    font-size: 0.6rem;
    padding: 0 5px;
    height: 80px;
    border-radius: 20px;
  }
}
.quiz-wrapper .questions-wrap .question .answer-options .draggable-options .draggable-option .animation-bubble {
  z-index: -1;
}
.quiz-wrapper .questions-wrap .question .answer-options .draggable-options .draggable-option .icon {
  width: 34px;
  height: 34px;
  background: white;
  border-radius: 34px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #59A3D0;
  font-weight: 600;
  margin-right: 15px;
  min-width: 34px;
  min-height: 34px;
}
.quiz-wrapper .questions-wrap .question .answer-options .draggable-options .draggable-option .icon:after {
  content: "A";
}
@media all and (max-width: 767px) {
  .quiz-wrapper .questions-wrap .question .answer-options .draggable-options .draggable-option .icon {
    font-size: 0.9rem;
    margin-right: 5px;
    min-width: 20px;
    min-height: 20px;
    width: 20px;
    height: 20px;
    line-height: 20px;
  }
}
.quiz-wrapper .questions-wrap .question .answer-options .draggable-options .draggable-option.option1 {
  background: #F7DADA;
  border: 5px solid #F7DADA;
}
.quiz-wrapper .questions-wrap .question .answer-options .draggable-options .draggable-option.option1 .icon {
  color: #EB8782;
}
.quiz-wrapper .questions-wrap .question .answer-options .draggable-options .draggable-option.option1 .icon:after {
  content: "B";
}
.quiz-wrapper .questions-wrap .question .answer-options .draggable-options .draggable-option.option1 .animation-bubble {
  background: #EE9A95;
}
.quiz-wrapper .questions-wrap .question .answer-options .draggable-options .draggable-option.option2 {
  background: #F8E9B9;
  border: 5px solid #F8E9B9;
}
.quiz-wrapper .questions-wrap .question .answer-options .draggable-options .draggable-option.option2 .icon {
  color: #F3C762;
}
.quiz-wrapper .questions-wrap .question .answer-options .draggable-options .draggable-option.option2 .icon:after {
  content: "C";
}
.quiz-wrapper .questions-wrap .question .answer-options .draggable-options .draggable-option.option2 .animation-bubble {
  background: #F3C762;
}
.quiz-wrapper .questions-wrap .question .answer-options .draggable-options .draggable-option.option3 {
  background: #d5f2d4;
  border: 5px solid #d5f2d4;
}
.quiz-wrapper .questions-wrap .question .answer-options .draggable-options .draggable-option.option3 .icon {
  color: #86CC88;
}
.quiz-wrapper .questions-wrap .question .answer-options .draggable-options .draggable-option.option3 .icon:after {
  content: "D";
}
.quiz-wrapper .questions-wrap .question .answer-options .draggable-options .draggable-option.option3 .animation-bubble {
  background: #86CC88;
}
.quiz-wrapper .questions-wrap .question .answer-options .draggable-options .draggable-option.selected {
  animation-duration: 0.4s;
  animation-fill-mode: both;
  animation-name: pulseBig;
  background: #59a3d0;
  color: #323333;
  border-width: 5px;
  border-style: solid;
}
.quiz-wrapper .questions-wrap .question .answer-options .draggable-options .draggable-option.selected.option1 {
  background: #EE9A95;
  border-color: #F7DADA;
}
.quiz-wrapper .questions-wrap .question .answer-options .draggable-options .draggable-option.selected.option2 {
  background: #F3C762;
  border-color: #F8E9B9;
}
.quiz-wrapper .questions-wrap .question .answer-options .draggable-options .draggable-option.selected.option3 {
  background: #86CC88;
  border-color: #d5f2d4;
}
@media all and (max-width: 767px) {
  .quiz-wrapper .questions-wrap .question .answer-options .draggable-options .draggable-option.selected {
    border-width: 2px;
  }
}
.quiz-wrapper .questions-wrap .question .answer-options .draggable-options .draggable-option.squish {
  animation-duration: 1.5s;
  animation-fill-mode: both;
  animation-name: shake;
}
.quiz-wrapper .questions-wrap .question .answer-options .multi-select-options {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}
@media all and (max-width: 767px) {
  .quiz-wrapper .questions-wrap .question .answer-options .multi-select-options {
    flex-direction: column;
  }
}
.quiz-wrapper .questions-wrap .question .answer-options .multi-select-options .multi-option {
  width: 48%;
  min-height: 64px;
  height: auto;
  background: #D4E9F7;
  border: 5px solid #D4E9F7;
  border-radius: 33px;
  color: inherit;
  font-weight: normal;
  text-transform: inherit;
  padding: 0 10px 0 5px;
  margin: 0 0 10px 0;
}
@media all and (max-width: 767px) {
  .quiz-wrapper .questions-wrap .question .answer-options .multi-select-options .multi-option {
    width: 100%;
  }
}
.quiz-wrapper .questions-wrap .question .answer-options .multi-select-options .multi-option.selected {
  animation-duration: 0.4s;
  animation-fill-mode: both;
  animation-name: pulseBig;
  background: #59A3D0;
  color: #ffffff;
  outline: 2px solid #323333 !important;
}
.quiz-wrapper .questions-wrap .question .answer-options .multi-select-options .multi-option:nth-child(2) {
  background: #F7DADA;
  border: 5px solid #F7DADA;
}
.quiz-wrapper .questions-wrap .question .answer-options .multi-select-options .multi-option:nth-child(2) .butt-icon {
  background-color: #EE9A95;
}
.quiz-wrapper .questions-wrap .question .answer-options .multi-select-options .multi-option:nth-child(2) .animation-bubble {
  background: #EE9A95;
}
.quiz-wrapper .questions-wrap .question .answer-options .multi-select-options .multi-option:nth-child(2).selected {
  background: #EE9A95;
  outline: 2px solid #323333 !important;
}
.quiz-wrapper .questions-wrap .question .answer-options .multi-select-options .multi-option:nth-child(3) {
  background: #F8E9B9;
  border: 5px solid #F8E9B9;
}
.quiz-wrapper .questions-wrap .question .answer-options .multi-select-options .multi-option:nth-child(3) .butt-icon {
  background-color: #F3C762;
}
.quiz-wrapper .questions-wrap .question .answer-options .multi-select-options .multi-option:nth-child(3) .animation-bubble {
  background: #F3C762;
}
.quiz-wrapper .questions-wrap .question .answer-options .multi-select-options .multi-option:nth-child(3).selected {
  background: #F3C762;
  outline: 2px solid #323333 !important;
}
.quiz-wrapper .questions-wrap .question .answer-options .multi-select-options .multi-option:nth-child(4) {
  background: #d5f2d4;
  border: 5px solid #d5f2d4;
}
.quiz-wrapper .questions-wrap .question .answer-options .multi-select-options .multi-option:nth-child(4) .butt-icon {
  background-color: #86CC88;
}
.quiz-wrapper .questions-wrap .question .answer-options .multi-select-options .multi-option:nth-child(4) .animation-bubble {
  background: #86CC88;
}
.quiz-wrapper .questions-wrap .question .answer-options .multi-select-options .multi-option:nth-child(4).selected {
  background: #86CC88;
  outline: 2px solid #323333 !important;
}
.quiz-wrapper .questions-wrap .question .answer-options .multi-select-options .multi-option:nth-child(5) {
  background: #ebebeb;
  border: 5px solid #ebebeb;
}
.quiz-wrapper .questions-wrap .question .answer-options .multi-select-options .multi-option:nth-child(5) .butt-icon {
  background-color: #9C9E9E;
}
.quiz-wrapper .questions-wrap .question .answer-options .multi-select-options .multi-option:nth-child(5) .animation-bubble {
  background: #9C9E9E;
}
.quiz-wrapper .questions-wrap .question .answer-options .multi-select-options .multi-option:nth-child(5).selected {
  background: #9C9E9E;
  outline: 2px solid #323333 !important;
}
.quiz-wrapper .questions-wrap .question .answer-options .multi-select-options .multi-option .butt-icon {
  width: 44px;
  height: 44px;
  background-color: #59A3D0;
}
.quiz-wrapper .questions-wrap .question .answer-options .multi-select-options .multi-option .butt-icon.UNIVERSITY {
  background-image: url(../images/icons/icon-university.svg);
}
.quiz-wrapper .questions-wrap .question .answer-options .multi-select-options .multi-option .butt-icon.TRAINING_CENTRE {
  background-image: url(../images/icons/icon-trainingcentre3.svg);
}
.quiz-wrapper .questions-wrap .question .answer-options .multi-select-options .multi-option .butt-icon.HANDS_ON_TRAINING {
  background-image: url(../images/icons/icon-handson.svg);
}
.quiz-wrapper .questions-wrap .question .answer-options .multi-select-options .multi-option .butt-icon.WORK {
  background-image: url(../images/icons/icon-straighttowork.svg);
}
.quiz-wrapper .questions-wrap .question .answer-options .multi-select-options .multi-option .butt-icon.OTHER {
  background-image: url(../images/icons/icon-gapyear.svg);
}
.quiz-wrapper .questions-wrap .question .answer-options .multi-select-options .multi-option .butt-icon.VISUAL {
  background-image: url(../images/icons/icon-looking.svg);
}
.quiz-wrapper .questions-wrap .question .answer-options .multi-select-options .multi-option .butt-icon.READ_WRITE {
  background-image: url(../images/icons/icon-reading.svg);
}
.quiz-wrapper .questions-wrap .question .answer-options .multi-select-options .multi-option .butt-icon.AUDITORY {
  background-image: url(../images/icons/icon-listening.svg);
}
.quiz-wrapper .questions-wrap .question .answer-options .multi-select-options .multi-option .butt-icon.KINAESTHETIC {
  background-image: url(../images/icons/icon-doing.svg);
}
.quiz-wrapper .questions-wrap .question .answer-options .multi-select-options .multi-option .title {
  font-size: 1.05em;
  text-align: left;
  padding: 10px 0 10px 10px;
  color: #323333;
}
.quiz-wrapper .questions-wrap .question .answer-options .multi-select-options .multi-option.OTHER textarea {
  border: none;
  border-radius: 10px;
  height: 55px;
  width: 100%;
  margin: 10px 0;
  -webkit-appearance: none;
  padding: 10px;
  font-size: 0.9em;
  font-family: inherit;
  color: #323333;
}
.quiz-wrapper .questions-wrap .question .answer-options .this-or-that-options {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
@media all and (max-width: 767px) {
  .quiz-wrapper .questions-wrap .question .answer-options .this-or-that-options {
    flex-direction: column;
    position: relative;
    padding-bottom: 50px;
  }
}
.quiz-wrapper .questions-wrap .question .answer-options .this-or-that-options .this-or-that-option {
  height: 74px;
  background: #E4E0F7;
  border: 5px solid #E4E0F7;
  margin: 0;
  width: 260px;
  color: #323333;
  text-transform: none;
  font-size: 0.8em;
  padding: 0 10px 0 5px;
  margin: 0 5px;
}
@media all and (max-width: 767px) {
  .quiz-wrapper .questions-wrap .question .answer-options .this-or-that-options .this-or-that-option {
    margin: 5px;
  }
  .quiz-wrapper .questions-wrap .question .answer-options .this-or-that-options .this-or-that-option.dont-mind {
    position: absolute;
    bottom: 0;
    height: 40px;
  }
}
.quiz-wrapper .questions-wrap .question .answer-options .this-or-that-options .this-or-that-option.selected {
  animation-duration: 0.4s;
  animation-fill-mode: both;
  animation-name: pulseBig;
  background: #7b68c8;
}
.quiz-wrapper .questions-wrap .question .answer-options .this-or-that-options .this-or-that-option .butt-icon {
  width: 54px;
  height: 54px;
  background-color: #7b68c8;
  background-position: center center;
}
.quiz-wrapper .questions-wrap .question .answer-options .this-or-that-options .this-or-that-option .title {
  text-align: left;
  padding-left: 10px;
}
.quiz-wrapper .questions-wrap .question .answer-options .this-or-that-options .this-or-that-option .title.centre {
  text-align: center;
}
.quiz-wrapper .questions-wrap .question .answer-options .this-or-that-options .this-or-that-option .animation-bubble {
  background: #7b68c8;
}
.quiz-wrapper .questions-wrap .question .answer-options .this-or-that-options .this-or-that-option.butt-lifeskillsquiz {
  max-width: 23%;
  background: transparent;
}
@media all and (max-width: 767px) {
  .quiz-wrapper .questions-wrap .question .answer-options .this-or-that-options .this-or-that-option.butt-lifeskillsquiz {
    max-width: 100%;
    width: 100%;
  }
}
.quiz-wrapper .questions-wrap .question .answer-options .this-or-that-options .this-or-that-option.butt-lifeskillsquiz:nth-child(1) {
  border: 2px solid #88cb88;
}
.quiz-wrapper .questions-wrap .question .answer-options .this-or-that-options .this-or-that-option.butt-lifeskillsquiz:nth-child(1):hover .animation-bubble {
  background: #88cb88;
}
.quiz-wrapper .questions-wrap .question .answer-options .this-or-that-options .this-or-that-option.butt-lifeskillsquiz:nth-child(1).selected {
  background: #88cb88;
}
.quiz-wrapper .questions-wrap .question .answer-options .this-or-that-options .this-or-that-option.butt-lifeskillsquiz:nth-child(2) {
  border: 2px solid #ea8782;
}
.quiz-wrapper .questions-wrap .question .answer-options .this-or-that-options .this-or-that-option.butt-lifeskillsquiz:nth-child(2):hover .animation-bubble {
  background: #ea8782;
}
.quiz-wrapper .questions-wrap .question .answer-options .this-or-that-options .this-or-that-option.butt-lifeskillsquiz:nth-child(2).selected {
  background: #ea8782;
}
.quiz-wrapper .questions-wrap .question .answer-options .this-or-that-options .this-or-that-option.butt-lifeskillsquiz:nth-child(3) {
  border: 2px solid #f4c760;
}
.quiz-wrapper .questions-wrap .question .answer-options .this-or-that-options .this-or-that-option.butt-lifeskillsquiz:nth-child(3):hover .animation-bubble {
  background: #f4c760;
}
.quiz-wrapper .questions-wrap .question .answer-options .this-or-that-options .this-or-that-option.butt-lifeskillsquiz:nth-child(3).selected {
  background: #f4c760;
}
.quiz-wrapper .questions-wrap .question .answer-options .this-or-that-options .this-or-that-option.butt-lifeskillsquiz:nth-child(4) {
  border: 2px solid #59a3d0;
}
.quiz-wrapper .questions-wrap .question .answer-options .this-or-that-options .this-or-that-option.butt-lifeskillsquiz:nth-child(4):hover .animation-bubble {
  background: #59a3d0;
}
.quiz-wrapper .questions-wrap .question .answer-options .this-or-that-options .this-or-that-option.butt-lifeskillsquiz:nth-child(4).selected {
  background: #59a3d0;
}
.quiz-wrapper .questions-wrap .question .answer-options .this-or-that-options .this-or-that-option[data-index=SENSORY_01] .negative {
  background-image: url(../images/icons/icon-sensory01-negative.svg);
}
.quiz-wrapper .questions-wrap .question .answer-options .this-or-that-options .this-or-that-option[data-index=SENSORY_01] .positive {
  background-image: url(../images/icons/icon-sensory01-positive.svg);
}
.quiz-wrapper .questions-wrap .question .answer-options .this-or-that-options .this-or-that-option[data-index=SENSORY_02] .negative {
  background-image: url(../images/icons/icon-sensory02-negative.svg);
}
.quiz-wrapper .questions-wrap .question .answer-options .this-or-that-options .this-or-that-option[data-index=SENSORY_02] .positive {
  background-image: url(../images/icons/icon-sensory02-positive.svg);
}
.quiz-wrapper .questions-wrap .question .answer-options .this-or-that-options .this-or-that-option[data-index=SENSORY_03] .negative {
  background-image: url(../images/icons/icon-sensory03-negative.svg);
}
.quiz-wrapper .questions-wrap .question .answer-options .this-or-that-options .this-or-that-option[data-index=SENSORY_03] .positive {
  background-image: url(../images/icons/icon-sensory03-positive.svg);
}
.quiz-wrapper .questions-wrap .question .answer-options .this-or-that-options .this-or-that-option[data-index=SENSORY_04] .negative {
  background-image: url(../images/icons/icon-sensory04-negative.svg);
}
.quiz-wrapper .questions-wrap .question .answer-options .this-or-that-options .this-or-that-option[data-index=SENSORY_04] .positive {
  background-image: url(../images/icons/icon-sensory04-positive.svg);
}
.quiz-wrapper .questions-wrap .question .answer-options .this-or-that-options .this-or-that-option[data-index=SENSORY_05] .negative {
  background-image: url(../images/icons/icon-sensory05-negative.svg);
}
.quiz-wrapper .questions-wrap .question .answer-options .this-or-that-options .this-or-that-option[data-index=SENSORY_05] .positive {
  background-image: url(../images/icons/icon-sensory05-positive.svg);
}
.quiz-wrapper .questions-wrap .question .answer-options .this-or-that-options .this-or-that-option[data-index=SENSORY_06] .negative {
  background-image: url(../images/icons/icon-sensory06-negative.svg);
}
.quiz-wrapper .questions-wrap .question .answer-options .this-or-that-options .this-or-that-option[data-index=SENSORY_06] .positive {
  background-image: url(../images/icons/icon-sensory06-positive.svg);
}
.quiz-wrapper .questions-wrap .question .answer-options .this-or-that-options .this-or-that-option[data-index=SENSORY_07] .negative {
  background-image: url(../images/icons/icon-sensory07-negative.svg);
}
.quiz-wrapper .questions-wrap .question .answer-options .this-or-that-options .this-or-that-option[data-index=SENSORY_07] .positive {
  background-image: url(../images/icons/icon-sensory07-positive.svg);
}
.quiz-wrapper .questions-wrap .question .answer-options .this-or-that-options .this-or-that-option[data-index=SENSORY_08] .negative {
  background-image: url(../images/icons/icon-sensory08-negative.svg);
}
.quiz-wrapper .questions-wrap .question .answer-options .this-or-that-options .this-or-that-option[data-index=SENSORY_08] .positive {
  background-image: url(../images/icons/icon-sensory08-positive.svg);
}
.quiz-wrapper .questions-wrap .question .answer-options .this-or-that-options .this-or-that-option[data-index=SENSORY_09] .negative {
  background-image: url(../images/icons/icon-sensory09-negative.svg);
}
.quiz-wrapper .questions-wrap .question .answer-options .this-or-that-options .this-or-that-option[data-index=SENSORY_09] .positive {
  background-image: url(../images/icons/icon-sensory09-positive.svg);
}
.quiz-wrapper .questions-wrap .question .answer-options .answer-line {
  height: 18px;
  position: relative;
  margin: 20px 0 10px;
  display: flex;
  justify-content: flex-end;
  flex-direction: column;
  overflow: hidden;
  border-radius: 5px;
}
.quiz-wrapper .questions-wrap .question .answer-options .answer-line .line {
  height: 8px;
  background: #E4E0F7;
  border-radius: 20px;
  width: 100%;
}
.quiz-wrapper .questions-wrap .question .answer-options .answer-line .answer-swoosh {
  height: 18px;
  position: absolute;
  bottom: 0;
  transition: left 0.3s ease-in-out;
  left: calc(0% - 248px);
}
.quiz-wrapper .questions-wrap .question .answer-options .text-answers {
  display: flex;
  justify-content: space-between;
  font-size: 0.8em;
  margin-top: 10px;
}
.quiz-wrapper .questions-wrap .question.hide-question {
  opacity: 0;
  visibility: hidden;
  height: 0;
}
.quiz-wrapper .questions-wrap .question.current {
  animation-duration: 0.4s;
  animation-fill-mode: both;
  animation-name: pulse;
}
.quiz-wrapper .button-wrap {
  width: 77%;
  margin: 40px 0;
  display: flex;
  justify-content: space-between;
}
@media all and (max-width: 800px) {
  .quiz-wrapper .button-wrap {
    width: 100%;
  }
}
.quiz-wrapper .button-wrap .butt-save {
  margin: 0;
}
.quiz-wrapper .button-wrap .butt-next {
  flex-direction: row-reverse;
  padding: 0 4px 0 15px;
}
@media all and (max-width: 800px) {
  .quiz-wrapper .button-wrap {
    position: relative;
    margin: 20px 0 130px;
    /*.butt-next, .butt-previous {
        width: 42px;
        padding-left: 5px;

        .butt-icon {
            min-width: 32px;
        }

        .title {
            display: none;
        }
    }*/
  }
  .quiz-wrapper .button-wrap .butt-next, .quiz-wrapper .button-wrap .butt-previous {
    width: 130px;
  }
  .quiz-wrapper .button-wrap .butt-save {
    position: absolute;
    top: 80px;
    left: 0;
    right: 0;
    margin: auto;
  }
}

.quiz-results-wrap {
  width: 1200px;
}
@media all and (max-width: 1300px) {
  .quiz-results-wrap {
    width: 100vw;
    padding: 0 30px;
  }
}
@media all and (max-width: 800px) {
  .quiz-results-wrap {
    padding: 0 20px;
  }
}
.quiz-results-wrap h1 {
  text-align: center;
}
.quiz-results-wrap .title-section {
  display: flex;
  flex: 1;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  align-items: flex-start;
}
.quiz-results-wrap .title-section .side-back {
  width: 180px;
}
.quiz-results-wrap .title-section h1 {
  flex: 1;
  width: 100%;
}
.quiz-results-wrap .title-section .main-title {
  width: 100%;
}
@media all and (max-width: 800px) {
  .quiz-results-wrap .title-section {
    padding-top: 20px;
  }
  .quiz-results-wrap .title-section .butt-general {
    width: 42px;
    padding-left: 5px;
  }
  .quiz-results-wrap .title-section .butt-general .butt-icon {
    min-width: 32px;
  }
  .quiz-results-wrap .title-section .butt-general .title {
    display: none;
  }
  .quiz-results-wrap .title-section .side-back {
    width: 42px;
  }
}
.quiz-results-wrap .details-wrap {
  color: #323333;
  width: 64%;
  margin: 0 auto;
  padding: 40px 0 0;
  text-align: center;
}
.quiz-results-wrap .details-wrap p {
  margin: 0;
}
@media all and (max-width: 1300px) {
  .quiz-results-wrap .details-wrap {
    width: 80%;
  }
}
.quiz-results-wrap .no-results {
  text-align: center;
  padding: 100px 0;
  color: #323333;
}
.quiz-results-wrap .result-options-wrap {
  overflow: hidden;
  margin: 30px 0 50px;
}
.quiz-results-wrap .result-options-wrap .result-content {
  flex: 1;
  max-width: 1200px;
  display: flex;
  flex-direction: column;
}
.quiz-results-wrap .result-options-wrap .result-content .outer-wrap {
  padding: 0 !important;
}
.quiz-results-wrap .result-options-wrap .result-content .content-wrap {
  flex: 1;
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  justify-content: center;
  height: 100%;
}
.quiz-results-wrap .result-options-wrap .result-content .content-wrap ul {
  color: #323333;
  margin: 20px 0 20px;
  line-height: 2.2em;
  padding: 0 25px;
  list-style: none;
  background: transparent !important;
}
.quiz-results-wrap .result-options-wrap .result-content .content-wrap ul li::before {
  content: "•";
  font-weight: 900;
  display: inline-block;
  width: 1rem;
  margin-left: -1rem;
}
.quiz-results-wrap .result-options-wrap .result-content .content-wrap ul.work li::before, .quiz-results-wrap .result-options-wrap .result-content .content-wrap ul.list-node-yellow li::before {
  color: #f4c760;
}
.quiz-results-wrap .result-options-wrap .result-content .content-wrap ul.life li::before {
  color: #ea8782;
}
.quiz-results-wrap .result-options-wrap .result-content .content-wrap ul.training li::before {
  color: #88cb88;
}
.quiz-results-wrap .result-options-wrap .result-content .content-wrap ul.strengths li::before {
  color: #9e92d7;
}
.quiz-results-wrap .result-options-wrap .result-content .content-wrap ul.career li::before {
  color: #59a3d0;
}
.quiz-results-wrap .result-options-wrap .result-content .content-wrap .title-bar-grey, .quiz-results-wrap .result-options-wrap .result-content .content-wrap .Organised {
  background-color: #ebebeb;
}
.quiz-results-wrap .result-options-wrap .result-content .content-wrap .title-bar-circle-grey, .quiz-results-wrap .result-options-wrap .result-content .content-wrap .Organised-circle {
  background-color: #9c9e9d;
}
.quiz-results-wrap .result-options-wrap .result-content .content-wrap .title-bar-pink, .quiz-results-wrap .result-options-wrap .result-content .content-wrap .Helping, .quiz-results-wrap .result-options-wrap .result-content .content-wrap .life {
  background-color: #f8dada;
}
.quiz-results-wrap .result-options-wrap .result-content .content-wrap .title-bar-circle-pink, .quiz-results-wrap .result-options-wrap .result-content .content-wrap .Helping-circle {
  background-color: #ea8782;
}
.quiz-results-wrap .result-options-wrap .result-content .content-wrap .title-bar-yellow, .quiz-results-wrap .result-options-wrap .result-content .content-wrap .Artistic, .quiz-results-wrap .result-options-wrap .result-content .content-wrap .work {
  background-color: #f7e9b8;
}
.quiz-results-wrap .result-options-wrap .result-content .content-wrap .title-bar-circle-yellow, .quiz-results-wrap .result-options-wrap .result-content .content-wrap .Artistic-circle {
  background-color: #f4c760;
}
.quiz-results-wrap .result-options-wrap .result-content .content-wrap .title-bar-blue, .quiz-results-wrap .result-options-wrap .result-content .content-wrap .Practical, .quiz-results-wrap .result-options-wrap .result-content .content-wrap .career {
  background-color: #d3e9f7;
}
.quiz-results-wrap .result-options-wrap .result-content .content-wrap .title-bar-circle-blue, .quiz-results-wrap .result-options-wrap .result-content .content-wrap .Practical-circle {
  background-color: #59a3d0;
}
.quiz-results-wrap .result-options-wrap .result-content .content-wrap .title-bar-purple, .quiz-results-wrap .result-options-wrap .result-content .content-wrap .Investigative, .quiz-results-wrap .result-options-wrap .result-content .content-wrap .strengths {
  background-color: #e4e0f7;
}
.quiz-results-wrap .result-options-wrap .result-content .content-wrap .title-bar-circle-purple, .quiz-results-wrap .result-options-wrap .result-content .content-wrap .Investigative-circle {
  background-color: #9e92d7;
}
.quiz-results-wrap .result-options-wrap .result-content .content-wrap .title-bar-green, .quiz-results-wrap .result-options-wrap .result-content .content-wrap .Enterprising, .quiz-results-wrap .result-options-wrap .result-content .content-wrap .training {
  background-color: #d5f2d4;
}
.quiz-results-wrap .result-options-wrap .result-content .content-wrap .title-bar-circle-green, .quiz-results-wrap .result-options-wrap .result-content .content-wrap .Enterprising-circle {
  background-color: #88cb88;
}
.quiz-results-wrap .result-options-wrap .result-content .content-wrap .icon-butt {
  float: right;
  border-radius: 50%;
  width: 40px;
  height: 40px;
  background-color: aliceblue;
  line-height: 40px;
  font-weight: bold;
  min-width: 40px;
}
.quiz-results-wrap .result-options-wrap .result-content .content-wrap .icon-butt.career {
  background-color: #59a3d0;
  background-image: url(../images/icons/icon-career.svg);
  background-repeat: no-repeat !important;
  background-size: 26px !important;
  background-position: 7px !important;
}
.quiz-results-wrap .result-options-wrap .result-content .content-wrap .icon-butt.strengths {
  background-color: #9e92d7;
  background-image: url(../images/icons/icon-strengths.svg);
  background-repeat: no-repeat !important;
  background-size: 26px !important;
  background-position: 7px !important;
}
.quiz-results-wrap .result-options-wrap .result-content .content-wrap .icon-butt.work {
  background-color: #f4c760;
  background-image: url(../images/icons/icon-work.svg);
  background-repeat: no-repeat !important;
  background-size: 26px !important;
  background-position: 7px !important;
}
.quiz-results-wrap .result-options-wrap .result-content .content-wrap .icon-butt.training {
  background-color: #88cb88;
  background-image: url(../images/icons/icon-training.svg);
  background-repeat: no-repeat !important;
  background-size: 26px !important;
  background-position: 7px !important;
}
.quiz-results-wrap .result-options-wrap .result-content .content-wrap .icon-butt.life {
  background-color: #ea8782;
  background-image: url(../images/icons/icon-life.svg);
  background-repeat: no-repeat !important;
  background-size: 26px !important;
  background-position: 7px !important;
}
.quiz-results-wrap .result-options-wrap .result-content .content-wrap .inner-content-wrap {
  flex: 1;
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  height: 100%;
}
.quiz-results-wrap .result-options-wrap .result-content .content-wrap .inner-content-wrap .person {
  width: 185px;
  height: auto;
  mask-image: url("../images/mask-bg.svg");
  mask-mode: alpha;
  mask-repeat: no-repeat;
  mask-size: contain;
  mask-position: center;
  max-height: 380px;
  position: relative;
}
.quiz-results-wrap .result-options-wrap .result-content .content-wrap .inner-content-wrap .person .fill {
  width: 100%;
}
.quiz-results-wrap .result-options-wrap .result-content .content-wrap .inner-content-wrap .person .fill.Practical {
  background: #59a3d0;
}
.quiz-results-wrap .result-options-wrap .result-content .content-wrap .inner-content-wrap .person .fill.Investigative {
  background: #9e92d7;
}
.quiz-results-wrap .result-options-wrap .result-content .content-wrap .inner-content-wrap .person .fill.Helping {
  background: #ea8782;
}
.quiz-results-wrap .result-options-wrap .result-content .content-wrap .inner-content-wrap .person .fill.Organised {
  background: #9c9e9d;
}
.quiz-results-wrap .result-options-wrap .result-content .content-wrap .inner-content-wrap .person .fill.Artistic {
  background: #f4c760;
}
.quiz-results-wrap .result-options-wrap .result-content .content-wrap .inner-content-wrap .person .fill.Enterprising {
  background: #88cb88;
}
@media all and (max-width: 800px) {
  .quiz-results-wrap .result-options-wrap .result-content .content-wrap .inner-content-wrap .person {
    display: none;
  }
}
.quiz-results-wrap .result-options-wrap .result-content .content-wrap .inner-content-wrap img {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
.quiz-results-wrap .result-options-wrap .result-content .content-wrap .inner-content-wrap .result-container-wrap, .quiz-results-wrap .result-options-wrap .result-content .content-wrap .inner-content-wrap .result-content-wrap {
  flex: 1;
  display: flex;
  flex-wrap: wrap;
  height: 100%;
  justify-content: center;
}
.quiz-results-wrap .result-options-wrap .result-content .content-wrap .inner-content-wrap .result-container-wrap {
  flex-direction: column;
}
.quiz-results-wrap .result-options-wrap .result-content .content-wrap .inner-content-wrap .result-container-wrap .button-wrap {
  display: flex;
  justify-content: center;
  margin-top: 50px;
  flex-direction: row-reverse;
}
.quiz-results-wrap .result-options-wrap .result-content .content-wrap .inner-content-wrap .result-container-wrap .button-wrap .butt-dashboard {
  position: absolute;
  left: 30px;
}
@media all and (max-width: 800px) {
  .quiz-results-wrap .result-options-wrap .result-content .content-wrap .inner-content-wrap .result-container-wrap .button-wrap .butt-dashboard {
    left: 20px;
    width: 42px;
  }
  .quiz-results-wrap .result-options-wrap .result-content .content-wrap .inner-content-wrap .result-container-wrap .button-wrap .butt-dashboard .title {
    display: none;
  }
  .quiz-results-wrap .result-options-wrap .result-content .content-wrap .inner-content-wrap .result-container-wrap .button-wrap .butt-dashboard .butt-icon {
    min-width: 32px;
  }
}
.quiz-results-wrap .result-options-wrap .result-content .content-wrap .inner-content-wrap .result-content-wrap {
  flex-direction: row;
}
.quiz-results-wrap .result-options-wrap .result-content .content-wrap .result, .quiz-results-wrap .result-options-wrap .result-content .content-wrap .learner-content {
  background: #fff;
  border-radius: 37.5px 37.5px 16px 16px;
  padding: 10px;
  color: #323333;
  text-align: left;
  margin: 15px;
  height: auto;
  overflow: hidden;
  text-decoration: none;
  min-width: 220px;
}
.quiz-results-wrap .result-options-wrap .result-content .content-wrap .result .header, .quiz-results-wrap .result-options-wrap .result-content .content-wrap .learner-content .header {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 54px;
  border-radius: 30px;
  padding: 0 20px;
  text-align: center;
  height: auto;
}
.quiz-results-wrap .result-options-wrap .result-content .content-wrap .result h2, .quiz-results-wrap .result-options-wrap .result-content .content-wrap .learner-content h2 {
  margin: 0;
  font-size: 1rem;
  margin: 20px 0;
}
.quiz-results-wrap .result-options-wrap .result-content .content-wrap .result p, .quiz-results-wrap .result-options-wrap .result-content .content-wrap .learner-content p {
  padding: 0 10px;
}
.quiz-results-wrap .result-options-wrap .result-content .content-wrap .transparent-box {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: transparent;
  height: auto;
  padding: 0;
  border-radius: 0;
  text-align: center;
}
.quiz-results-wrap .result-options-wrap .result-content .content-wrap .transparent-box h4 {
  text-align: center;
  height: auto;
  padding: 0;
}
.quiz-results-wrap .result-options-wrap .result-content .content-wrap .transparent-box button {
  margin: 10px 0 15px;
}
.quiz-results-wrap .result-options-wrap .result-content .content-wrap .transparent-box #strat-goals, .quiz-results-wrap .result-options-wrap .result-content .content-wrap .transparent-box #edit-actions > .butt-general {
  margin: 10px 0;
}
.quiz-results-wrap .result-options-wrap .result-content .content-wrap .training-box {
  width: 17.5%;
  margin: 10px;
}
.quiz-results-wrap .result-options-wrap .result-content .content-wrap .training-box p {
  max-height: 200px;
  overflow: hidden;
}
.quiz-results-wrap .result-options-wrap .result-content .content-wrap .training-box .more {
  padding: 10px;
}
@media screen and (max-width: 1024px) {
  .quiz-results-wrap .result-options-wrap .result-content .content-wrap .training-box {
    width: 30%;
  }
}
@media all and (max-width: 800px) {
  .quiz-results-wrap .result-options-wrap .result-content .content-wrap .training-box {
    width: 100%;
  }
}
.quiz-results-wrap .result-options-wrap .result-content .content-wrap .life-skill-box {
  width: 30%;
}
.quiz-results-wrap .result-options-wrap .result-content .content-wrap .content-box {
  height: 100%;
  overflow: visible;
  width: 25%;
  margin: 15px;
}
@media screen and (max-width: 768px) {
  .quiz-results-wrap .result-options-wrap .result-content .content-wrap .content-box {
    width: 30%;
  }
}
.quiz-results-wrap .result-options-wrap .result-content .content-wrap .learner-box {
  width: 64%;
  margin: auto;
}
@media all and (max-width: 800px) {
  .quiz-results-wrap .result-options-wrap .result-content .content-wrap .learner-box {
    width: 100%;
  }
}
.quiz-results-wrap .result-options-wrap .result-content .content-wrap .learner-box .learner-content {
  height: auto;
}
.quiz-results-wrap .result-options-wrap .result-content .content-wrap .career-exploration-box {
  width: 30%;
  margin: 10px !important;
}
.quiz-results-wrap .result-options-wrap .result-content .content-wrap .career-exploration-box:hover {
  cursor: pointer;
  animation-duration: 0.3s;
  animation-fill-mode: both;
  animation-name: pulse;
}
.quiz-results-wrap .result-options-wrap .result-content .content-wrap .career-exploration-box .link {
  color: #786ac1;
  font-weight: 600;
  font-size: 0.9em;
  text-align: left;
  padding: 0 10px;
}
@media screen and (max-width: 768px) {
  .quiz-results-wrap .result-options-wrap .result-content .content-wrap .career-exploration-box {
    width: auto;
  }
}
.quiz-results-wrap .result-options-wrap .result-content .content-wrap .career-exploration-bar {
  width: 25%;
  border-radius: 35px;
  min-height: 70px;
}
.quiz-results-wrap .result-options-wrap .result-content .content-wrap .career-exploration-bar:hover {
  cursor: pointer;
  animation-duration: 0.3s;
  animation-fill-mode: both;
  animation-name: pulse;
}
.quiz-results-wrap .result-options-wrap .result-content .content-wrap .career-exploration-bar .link {
  color: #786ac1;
  font-weight: 600;
  font-size: 0.9em;
  text-align: left;
  padding: 10px 10px 0;
}
@media screen and (max-width: 1031px) {
  .quiz-results-wrap .result-options-wrap .result-content .content-wrap .career-exploration-bar {
    margin: 10px !important;
  }
}
.quiz-results-wrap .result-options-wrap .result-content .content-wrap .my-results-box {
  width: 30%;
}
.quiz-results-wrap .result-options-wrap .result-content .content-wrap .my-results-box .my-results-icon {
  width: auto;
  height: auto;
  background-color: transparent;
  padding: 0;
  margin: 0;
}
@media screen and (max-width: 768px) {
  .quiz-results-wrap .result-options-wrap .result-content .content-wrap .my-results-box {
    width: 40%;
  }
}
@media all and (max-width: 767px) {
  .quiz-results-wrap .result-options-wrap .result-content .content-wrap .my-results-box {
    width: 100%;
    margin-top: 0;
  }
}
.quiz-results-wrap .result-options-wrap .result-content .content-wrap .work-preferences-box {
  max-width: 250px;
  margin: 10px;
}
@media all and (max-width: 800px) {
  .quiz-results-wrap .result-options-wrap .result-content .content-wrap .work-preferences-box {
    width: 100%;
    max-width: 100%;
  }
}
.quiz-results-wrap .result-options-wrap .result-content .content-wrap .percentage-circle {
  text-align: center;
  height: 50px;
  width: 100%;
  padding: 0 6px 0 15px;
  border-radius: 30px;
  margin: 0;
  line-height: 30px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  line-height: 1rem;
}
.quiz-results-wrap .result-options-wrap .result-content .content-wrap .percentage-circle h2 {
  margin: 0;
  padding: 0;
  text-align: left;
  font-size: 1rem;
}
.quiz-results-wrap .result-options-wrap .result-content .content-wrap .percentage-circle span {
  float: right;
  border-radius: 50%;
  width: 40px;
  height: 40px;
  color: #323333;
  line-height: 40px;
  font-weight: bold;
  font-size: 0.88rem;
}
.quiz-results-wrap .result-options-wrap .result-content .content-wrap .percentage-circle a {
  font-weight: normal;
  font-size: 0.6rem;
  color: #323333;
}
.quiz-results-wrap .result-options-wrap .visible-options {
  width: 200%;
  display: flex;
  justify-content: space-evenly;
  align-items: self-start;
  transition: all 1s ease-in-out;
}
@media all and (max-width: 767px) {
  .quiz-results-wrap .result-options-wrap .visible-options {
    width: 100%;
  }
}
.quiz-results-wrap .result-options-wrap .visible-options.scroll {
  transform: translateX(-50%);
}
.quiz-results-wrap .result-options-wrap .visible-options.unlimited {
  width: auto;
  display: block;
  white-space: nowrap;
  text-align: center;
}
.quiz-results-wrap .result-options-wrap .visible-options.unlimited p {
  margin: 0;
  max-height: 98px;
  overflow: hidden;
}
@media all and (max-width: 767px) {
  .quiz-results-wrap .result-options-wrap .visible-options.unlimited {
    white-space: normal;
  }
}
.quiz-results-wrap .result-options-wrap .visible-options .three-wrap {
  white-space: initial;
  display: inline-flex;
  justify-content: space-evenly;
  width: 100%;
  vertical-align: bottom;
}
@media all and (max-width: 767px) {
  .quiz-results-wrap .result-options-wrap .visible-options .three-wrap {
    flex-wrap: wrap;
  }
}
.quiz-results-wrap .result-options-wrap .visible-options.mobile {
  display: none;
}
@media all and (max-width: 767px) {
  .quiz-results-wrap .result-options-wrap .visible-options.mobile {
    display: block;
  }
}
.quiz-results-wrap .result-options-wrap .result-option {
  width: 30%;
  background: #ffffff;
  border-radius: 46px 46px 16px 16px;
  padding: 10px;
  color: #323333;
  text-align: center;
  margin: 0 10px;
  height: 250px;
  max-height: 250px;
  max-width: 340px;
  overflow: hidden;
  text-decoration: none;
  display: inline-block;
  white-space: initial;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
@media all and (max-width: 1300px) {
  .quiz-results-wrap .result-options-wrap .result-option {
    max-height: 270px;
    height: 270px;
  }
}
@media all and (max-width: 767px) {
  .quiz-results-wrap .result-options-wrap .result-option {
    width: 100%;
    max-width: 100%;
    margin: 10px 0;
    height: fit-content;
  }
}
.quiz-results-wrap .result-options-wrap .result-option .title {
  min-height: 74px;
  background: #E4E0F7;
  border-radius: 74px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0 20px;
}
.quiz-results-wrap .result-options-wrap .result-option .title h2 {
  font-size: 1.06em;
  color: #323333;
  margin: 0;
  padding: 10px 0;
}
.quiz-results-wrap .result-options-wrap .result-option .blurb {
  padding: 20px 10px;
  font-size: 0.9em;
  text-align: left;
  max-height: 135px;
  overflow: hidden;
}
.quiz-results-wrap .result-options-wrap .result-option .link {
  color: #786ac1;
  font-weight: 600;
  font-size: 0.9em;
  text-align: left;
  padding: 0 10px;
}
.quiz-results-wrap .result-options-wrap .result-option.life-skills {
  min-width: 350px;
  max-height: 400px;
  height: 400px;
  display: none;
}
.quiz-results-wrap .result-options-wrap .result-option.life-skills.on {
  display: block;
}
.quiz-results-wrap .result-options-wrap .result-option:hover {
  cursor: pointer;
  animation-duration: 0.5s;
  animation-fill-mode: both;
  animation-name: pulse;
}
.quiz-results-wrap .result-controls {
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  margin: auto;
  flex-direction: column;
  margin-bottom: 50px;
}
.quiz-results-wrap .result-controls .butt-scroll {
  margin: 0 10px 30px 10px;
}
@media all and (max-width: 768px) {
  .quiz-results-wrap .result-controls .butt-scroll {
    display: none;
  }
}
.quiz-results-wrap .result-controls .button-wrap .butt-dashboard {
  position: absolute;
  left: 20px;
}
@media all and (max-width: 800px) {
  .quiz-results-wrap .result-controls .button-wrap .butt-dashboard {
    left: 0;
    width: 42px;
  }
  .quiz-results-wrap .result-controls .button-wrap .butt-dashboard .title {
    display: none;
  }
  .quiz-results-wrap .result-controls .button-wrap .butt-dashboard .butt-icon {
    min-width: 32px;
  }
}
.quiz-results-wrap #training {
  /*width: 62%;
  @media screen and (max-width:768px) {
      width: 70%;
  }*/
}
.quiz-results-wrap #work-preferences {
  width: 100%;
  padding: 30px 10px;
  margin-top: 30px;
  justify-content: flex-end;
}
.quiz-results-wrap #work-preferences .butt-update {
  width: 180px;
}
.quiz-results-wrap #life-skill-controls {
  width: calc(90% + 60px);
  top: 20px;
}
.quiz-results-wrap #life-skill-controls #life-skill {
  width: 180px;
}
.quiz-results-wrap #career-exploration {
  width: 180px;
  margin: 40px auto 0 auto;
}
.quiz-results-wrap #career-exploration .butt-update {
  margin-top: 20px;
  width: 180px;
}
.quiz-results-wrap .butt-start-goals {
  background-image: url(../images/icons/icon-start.svg);
  background-repeat: no-repeat !important;
}
.quiz-results-wrap .butt-export {
  background-image: url(../images/icons/icon-upload-blue.svg);
  background-repeat: no-repeat !important;
}

.quiz-complete-wrap {
  width: 1200px;
  padding-top: 50px;
  padding-bottom: 50px;
}
@media all and (max-width: 1300px) {
  .quiz-complete-wrap {
    width: 100vw;
    padding: 50px;
  }
}
.quiz-complete-wrap .quiz-complete-box {
  background: #ffffff;
  border-radius: 50px;
  text-align: center;
  max-width: 67%;
  width: 67%;
  padding: 50px;
  box-shadow: 2px 2px 40px 1px rgba(36, 58, 71, 0.25);
  margin: 0 auto;
  margin-top: 50px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-image: url(../images/confetti.svg);
  background-repeat: no-repeat;
  background-position: center 0;
  background-size: 100% auto;
}
@media all and (max-width: 1300px) {
  .quiz-complete-wrap .quiz-complete-box {
    max-width: 80%;
    width: 80%;
  }
}
@media all and (max-width: 768px) {
  .quiz-complete-wrap .quiz-complete-box {
    max-width: 100%;
    width: 100%;
  }
}
.quiz-complete-wrap .quiz-complete-box h1 {
  margin: 10px;
}
.quiz-complete-wrap .quiz-complete-box .complete-content-wrap {
  display: flex;
  flex: 1;
  flex-direction: row;
}
.quiz-complete-wrap .quiz-complete-box .complete-content-wrap .complete-content {
  width: 50%;
  padding: 15px;
}
.quiz-complete-wrap .quiz-complete-box .complete-content-wrap .complete-content p {
  color: #323333;
  text-align: left;
}
.quiz-complete-wrap .quiz-complete-box .complete-content-wrap .complete-content a {
  float: left;
  margin: 15px 0;
}
.quiz-complete-wrap .quiz-complete-box .complete-content-wrap .complete-content img {
  width: 100%;
}

.job-wrap {
  width: 1200px;
  padding-top: 50px;
  padding-bottom: 50px;
}
@media all and (max-width: 1300px) {
  .job-wrap {
    width: 100%;
    padding: 0 30px;
  }
}
@media all and (max-width: 800px) {
  .job-wrap {
    max-width: 100%;
    padding: 30px 20px;
    overflow: hidden;
  }
}
.job-wrap .title-section {
  display: flex;
  flex: 1;
  flex-direction: row;
}
.job-wrap .title-section .side-back {
  width: 180px;
}
.job-wrap .title-section .side-back .butt-general {
  margin: 0;
}
.job-wrap .title-section .main-title {
  width: calc(100% - 360px);
}
@media all and (max-width: 800px) {
  .job-wrap .title-section {
    flex-direction: column;
  }
  .job-wrap .title-section .main-title {
    width: 100%;
    margin-top: 30px;
  }
}
.job-wrap h1 {
  text-align: center;
}
.job-wrap .details-wrap {
  color: #323333;
  width: 84%;
  margin: 0 auto;
  padding: 40px 0 0;
}
.job-wrap .details-wrap p {
  margin: 0;
}
@media all and (max-width: 1300px) {
  .job-wrap .details-wrap {
    width: 80%;
  }
}
.job-wrap .result-options-wrap {
  padding: 50px 0 20px 0;
}
.job-wrap .result-options-wrap .result-content {
  flex: 1;
  max-width: 1200px;
  display: flex;
  flex-direction: column;
}
.job-wrap .result-options-wrap .result-content .outer-wrap {
  padding: 0 !important;
}
@media screen and (max-width: 768px) {
  .job-wrap .result-options-wrap .result-content .two-columns {
    flex-direction: column !important;
  }
  .job-wrap .result-options-wrap .result-content .two-columns .left-content, .job-wrap .result-options-wrap .result-content .two-columns .right-content {
    width: 100% !important;
  }
}
.job-wrap .result-options-wrap .result-content .content-wrap {
  flex: 1;
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  justify-content: center;
  height: 100%;
}
.job-wrap .result-options-wrap .result-content .content-wrap ul {
  color: #323333;
  margin: 10px 0 10px;
  padding: 0 25px;
  list-style: none;
}
.job-wrap .result-options-wrap .result-content .content-wrap ul li {
  font-size: 0.9em;
  padding-bottom: 8px;
}
.job-wrap .result-options-wrap .result-content .content-wrap ul li::before {
  content: "•";
  font-weight: 900;
  display: inline-block;
  width: 1rem;
  margin-left: -1rem;
}
.job-wrap .result-options-wrap .result-content .content-wrap ul.list-node-yellow li::before {
  color: #f4c760;
}
.job-wrap .result-options-wrap .result-content .content-wrap ul.list-node-pink li::before {
  color: #ea8782;
}
.job-wrap .result-options-wrap .result-content .content-wrap ul.list-node-green li::before {
  color: #88cb88;
}
.job-wrap .result-options-wrap .result-content .content-wrap ul.list-node-blue li::before {
  color: #59a3d0;
}
.job-wrap .result-options-wrap .result-content .content-wrap .title-bar-pink {
  background-color: #f4bdba;
}
.job-wrap .result-options-wrap .result-content .content-wrap .title-bar-yellow {
  background-color: #f8d87b;
}
.job-wrap .result-options-wrap .result-content .content-wrap .title-sub-bar-yellow {
  background-color: #fcf7e4;
}
.job-wrap .result-options-wrap .result-content .content-wrap .title-bar-green {
  background-color: #addfae;
}
.job-wrap .result-options-wrap .result-content .content-wrap .title-sub-bar-green {
  background-color: #d5f2d4;
}
.job-wrap .result-options-wrap .result-content .content-wrap .result {
  background: #fff;
  border-radius: 35px 35px 20px 20px;
  padding: 10px;
  color: #323333;
  text-align: left;
  margin: 15px;
  height: auto;
  overflow: hidden;
  text-decoration: none;
}
.job-wrap .result-options-wrap .result-content .content-wrap .result h2 {
  text-align: center;
  width: 100%;
  padding: 15px 10px;
  border-radius: 30px;
  margin: 0;
  font-size: 1.2rem;
}
@media screen and (max-width: 1300px) {
  .job-wrap .result-options-wrap .result-content .content-wrap .result h2 {
    padding: 10px;
  }
}
.job-wrap .result-options-wrap .result-content .content-wrap .result h3 {
  text-align: left;
  width: 100%;
  padding: 5px 10px;
  border-radius: 30px;
  margin: 10px 0 0 0;
  font-size: 0.88rem;
}
.job-wrap .result-options-wrap .result-content .content-wrap .result p {
  padding: 0 10px;
  font-size: 0.9em;
}
.job-wrap .result-options-wrap .result-content .content-wrap .job-box {
  width: 28%;
  margin: 20px;
}
@media screen and (max-width: 800px) {
  .job-wrap .result-options-wrap .result-content .content-wrap .job-box {
    width: 100%;
  }
}
.job-wrap .result-options-wrap .visible-options {
  width: 200%;
  display: flex;
  justify-content: space-evenly;
  align-items: self-start;
  transition: all 1s ease-in-out;
}
.job-wrap .result-options-wrap .visible-options.scroll {
  transform: translateX(-50%);
}
.job-wrap .result-options-wrap .result-option {
  width: 30%;
  background: #ffffff;
  border-radius: 46px 46px 16px 16px;
  padding: 10px;
  color: #323333;
  text-align: center;
  margin: 0 10px;
  height: 250px;
  max-height: 250px;
  max-width: 340px;
  overflow: hidden;
  text-decoration: none;
  display: inline-block;
}
@media all and (max-width: 1300px) {
  .job-wrap .result-options-wrap .result-option {
    max-height: 270px;
    height: 270px;
  }
}
.job-wrap .result-options-wrap .result-option .title {
  height: 74px;
  background: #E4E0F7;
  border-radius: 74px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0 20px;
}
.job-wrap .result-options-wrap .result-option .title h2 {
  font-size: 1.06em;
  color: #323333;
  margin: 0;
}
.job-wrap .result-options-wrap .result-option .blurb {
  padding: 20px 10px;
  font-size: 0.9em;
  text-align: left;
}
.job-wrap .result-options-wrap .result-option .link {
  color: #786ac1;
  font-weight: 600;
  font-size: 0.9em;
  text-align: left;
  padding: 0 10px;
}
.job-wrap .result-options-wrap .result-option:hover {
  cursor: pointer;
  animation-duration: 0.5s;
  animation-fill-mode: both;
  animation-name: pulse;
}

.supportlayout {
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  width: 1200px;
  position: relative;
  padding-bottom: 100px;
}
@media all and (max-width: 1300px) {
  .supportlayout {
    width: 100%;
    padding: 0 30px;
  }
}
@media all and (max-width: 768px) {
  .supportlayout {
    flex-direction: column;
    align-items: center;
    max-width: 100%;
    padding: 0 20px;
  }
}
.supportlayout .back-button-wrap {
  padding-bottom: 0;
  margin-bottom: 20px;
}
.supportlayout .row {
  text-align: center;
  margin-top: 20px;
}
.supportlayout .butt-remove-meeting {
  margin: 0 auto;
  background: #7b68c8;
  padding: 0 15px;
  color: white;
  margin-top: 40px;
}
.supportlayout .supportlayout-left {
  flex: 1;
  max-width: 300px;
}
.supportlayout .supportlayout-left .header-wrap {
  display: flex;
  align-items: center;
}
.supportlayout .supportlayout-left .header-wrap .butt-help {
  width: 25px;
  height: 25px;
  background: white;
  border-radius: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: bold;
  color: #323333;
  margin-left: 10px;
  text-decoration: none;
}
.supportlayout .supportlayout-center {
  flex: 1;
  width: 500px;
  max-width: 500px;
  margin: 65px 40px 0;
  display: flex;
  flex-direction: column;
}
.supportlayout .supportlayout-center .side-blob {
  margin-right: 0;
}
.supportlayout .supportlayout-center label.right {
  color: #7b68c8;
}
.supportlayout .supportlayout-center .form-item-relationship {
  height: 0;
}
.supportlayout .supportlayout-center .form-item-relationship.give-height {
  height: 50px;
}
.supportlayout .supportlayout-center .invite-wrap {
  display: flex;
}
.supportlayout .supportlayout-center .invite-wrap form > .js-form-item:first-of-type {
  margin-left: 60px;
  margin-top: 5px;
}
.supportlayout .supportlayout-center .invite-wrap .teammember-email {
  padding-right: 110px;
}
.supportlayout .supportlayout-center .invite-wrap form > .form-item-name:first-child {
  margin-bottom: 0;
}
.supportlayout .supportlayout-center .invite-wrap .existing-team-member-name {
  border: none;
  background: none;
  border-radius: 0;
  font-weight: bold;
  font-size: 1.5em;
  margin-bottom: 0;
  height: auto;
  padding-left: 10px;
  padding-right: 0 !important;
  color: black;
}
.supportlayout .supportlayout-center .invite-wrap .existing-team-member-email {
  border: none;
  border-radius: 0;
  background: none;
  padding-left: 5px;
  padding-right: 0;
  margin-left: 65px;
  height: auto;
  color: black;
}
.supportlayout .supportlayout-center .invite-wrap .pending-team-member-email {
  border: none;
  border-radius: 0;
  background: none;
  padding-left: 5px;
  padding-right: 0px;
  height: 50px;
  color: black;
  font-size: 16px;
  font-weight: bold;
}
.supportlayout .supportlayout-center.no-note .note-copy {
  display: none;
}
.supportlayout .supportlayout-center .note-copy {
  text-align: center;
  padding-top: 20px;
}
.supportlayout .supportlayout-center input, .supportlayout .supportlayout-center textarea {
  border: 1px solid #AA9EE3;
}
.supportlayout .supportlayout-center input:focus, .supportlayout .supportlayout-center input:active, .supportlayout .supportlayout-center textarea:focus, .supportlayout .supportlayout-center textarea:active {
  background: #E4E0F7;
}
.supportlayout .supportlayout-center input[type=submit], .supportlayout .supportlayout-center textarea[type=submit] {
  border: none;
  background: #7b68c8;
  height: 42px;
  margin: 0;
  justify-content: center;
  padding: 0;
  float: right;
  color: white;
  width: 175px;
}
.supportlayout .supportlayout-center select {
  border: 1px solid #AA9EE3;
  margin-left: 60px;
  width: 360px;
  padding-left: 20px;
  padding-right: 20px;
  font-size: 1rem;
  appearance: none;
  color: black;
  -moz-appearance: none;
  -webkit-appearance: none;
  overflow: hidden;
  position: relative;
  background: url("../images/icons/icon-down-purple.svg") 95% no-repeat;
}
.supportlayout .supportlayout-center .form-actions input:first-child {
  margin: 0 0 10px 10px;
}
.supportlayout .supportlayout-center img {
  width: 100%;
}
.supportlayout .supportlayout-center .team-circle-icon {
  width: 50px;
  height: 50px;
  border-radius: 50px;
  color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  min-width: 50px;
  font-weight: bold;
  font-size: 1.2em;
  position: absolute;
  margin-top: 5px;
}
.supportlayout .supportlayout-center[data-state=member1] .team-circle-icon {
  background: #59A3D0;
}
.supportlayout .supportlayout-center[data-state=member2] .team-circle-icon {
  background: #EE9A95;
}
.supportlayout .supportlayout-center[data-state=member3] .team-circle-icon {
  background: #F3C762;
}
.supportlayout .supportlayout-center[data-state=member4] .team-circle-icon {
  background: #86CC88;
}
.supportlayout .supportlayout-right {
  flex: 1;
  max-width: 200px;
}
.supportlayout .team-members-wrap {
  margin-top: 30px;
}
.supportlayout .team-members-wrap .butt-add-member {
  width: 100%;
  height: 64px;
  margin: 0;
  padding: 0 10px;
  margin-bottom: 20px;
  background: rgba(255, 255, 255, 0.5);
}
.supportlayout .team-members-wrap .butt-add-member .title {
  text-align: left;
  padding-left: 10px;
  font-size: 1.2em;
  text-transform: none;
  font-weight: normal;
  color: #323333;
}
.supportlayout .team-members-wrap .butt-add-member .butt-icon {
  width: 34px;
  height: 34px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: normal;
  font-size: 1.1rem;
  font-weight: bold;
}
@media all and (max-width: 767px) {
  .supportlayout .supportlayout-center.side-blob {
    padding: 20px;
  }
}
.supportlayout .supportlayout-center h2 {
  font-size: 1rem;
  margin-bottom: 10px;
}
.supportlayout .supportlayout-center .add-meeting-step {
  margin-top: 20px;
  display: none;
}
.supportlayout .supportlayout-center .add-meeting-step.visible {
  display: block;
}
.supportlayout .supportlayout-center .add-meeting-step label {
  font-size: 1rem;
}
.supportlayout .supportlayout-center .add-meeting-step .no-next-message-involved {
  width: 100%;
  text-align: center;
  padding: 10px 20px;
  background: #eee;
  border-radius: 30px;
  font-size: 0.8em;
  margin-top: 30px;
  margin-bottom: 30px;
}
.supportlayout .supportlayout-center .add-meeting-step .options-wrap {
  margin-top: 30px;
}
.supportlayout .supportlayout-center .add-meeting-step .options-wrap .columns .options {
  width: 48%;
}
@media all and (max-width: 600px) {
  .supportlayout .supportlayout-center .add-meeting-step .options-wrap .columns .options {
    width: 100%;
  }
}
.supportlayout .supportlayout-center .add-meeting-step .columns {
  display: flex;
  justify-content: space-between;
}
@media all and (max-width: 600px) {
  .supportlayout .supportlayout-center .add-meeting-step .columns {
    flex-direction: column;
  }
}
.supportlayout .supportlayout-center .add-meeting-step .columns.with-padding {
  padding-top: 20px;
}
.supportlayout .supportlayout-center .add-meeting-step .columns.with-padding .options {
  width: 48%;
}
@media all and (max-width: 600px) {
  .supportlayout .supportlayout-center .add-meeting-step .columns.with-padding .options {
    width: 100%;
  }
}
.supportlayout .supportlayout-center .add-meeting-step .columns.with-padding .time-address {
  align-items: flex-start;
  margin-top: 0;
}
.supportlayout .supportlayout-center .add-meeting-step .columns .left, .supportlayout .supportlayout-center .add-meeting-step .columns .right {
  flex: 1;
}
.supportlayout .supportlayout-center .add-meeting-step .columns .left label, .supportlayout .supportlayout-center .add-meeting-step .columns .right label {
  height: 40px;
  display: block;
  font-size: 1rem;
}
.supportlayout .supportlayout-center .add-meeting-step .columns .left {
  margin-right: 20px;
}
@media all and (max-width: 600px) {
  .supportlayout .supportlayout-center .add-meeting-step .columns .left {
    margin: 0;
    margin-bottom: 30px;
  }
}
.supportlayout .supportlayout-center .add-meeting-step .columns .right {
  margin-left: 20px;
}
@media all and (max-width: 600px) {
  .supportlayout .supportlayout-center .add-meeting-step .columns .right {
    margin: 0;
  }
}
.supportlayout .supportlayout-center .add-meeting-step .columns .time-address {
  flex-direction: column;
}
.supportlayout .supportlayout-center .add-meeting-step textarea {
  border-radius: 10px;
  background: #E4E0F7;
  margin-left: 20%;
  height: 100px;
  width: calc(100% - 50px);
  padding: 10px;
  font-size: 0.9em;
  color: #786ac1;
  margin-left: 50px;
  font-family: "Open Sans", sans-serif;
  color: #323333;
  outline: none;
}
.supportlayout .supportlayout-center .add-meeting-step .time-address {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  margin-top: 10px;
  align-items: flex-end;
}
.supportlayout .supportlayout-center .add-meeting-step .time-address .fields {
  display: flex;
  flex-direction: column;
  width: 100%;
  justify-content: space-between;
}
.supportlayout .supportlayout-center .add-meeting-step .time-address .fields.buttons {
  flex-direction: row;
}
.supportlayout .supportlayout-center .add-meeting-step .time-address .butt-general {
  margin-left: 0;
}
.supportlayout .supportlayout-center .add-meeting-step .time-address .time-address-fields {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding: 5px;
  width: 100%;
}
.supportlayout .supportlayout-center .add-meeting-step .time-address .time-address-fields .time-address-label {
  color: #7b68c8;
}
.supportlayout .supportlayout-center .add-meeting-step .time-address .time-address-fields .time-address-icon {
  width: 34px;
  min-width: 34px;
  height: 34px;
  border-radius: 40px;
  margin-right: 10px;
}
.supportlayout .supportlayout-center .add-meeting-step .time-address .time-address-fields .time-address-icon.time {
  background-image: url(../images/icons/icon-clock-purple.svg);
  background-size: 100%;
}
.supportlayout .supportlayout-center .add-meeting-step .time-address .time-address-fields .time-address-icon.address {
  background-image: url(../images/icons/icon-location-purple.svg);
  background-size: 100%;
}
.supportlayout .supportlayout-center .add-meeting-step .time-address .time-address-fields .time-address-icon.calendar {
  background-image: url(../images/icons/icon-addcal-purple.svg);
  background-size: 100%;
  border: 1px solid #786ac1;
}
.supportlayout .supportlayout-center .add-meeting-step .time-address .time-address-fields input {
  background: #E4E0F7;
  height: 42px;
  border: none;
  outline: none;
  border-radius: 42px;
  font-size: 0.9em;
  font-family: "Open Sans", sans-serif;
  color: #786ac1;
  padding-left: 10px;
  margin-right: 10px;
  transition: all 0.2s;
  width: 100%;
}
@media all and (max-width: 600px) {
  .supportlayout .supportlayout-center .add-meeting-step .time-address .time-address-fields input {
    margin-right: 0;
  }
}
.supportlayout .supportlayout-center .add-meeting-step .time-address .time-address-fields input.invalid {
  border: 1px solid crimson;
}
.supportlayout .supportlayout-center .add-meeting-step .time-address .time-address-fields input::placeholder {
  color: grey;
}
.supportlayout .supportlayout-center .add-meeting-step .involvement-option,
.supportlayout .supportlayout-center .add-meeting-step .involvement-option-disabled,
.supportlayout .supportlayout-center .add-meeting-step .assistance-option,
.supportlayout .supportlayout-center .add-meeting-step .assistance-option-disabled {
  display: flex;
  align-items: center;
  margin: 0 0 15px;
  font-size: 0.9em;
  min-height: 57px;
}
.supportlayout .supportlayout-center .add-meeting-step .involvement-option .option-icon,
.supportlayout .supportlayout-center .add-meeting-step .involvement-option-disabled .option-icon,
.supportlayout .supportlayout-center .add-meeting-step .assistance-option .option-icon,
.supportlayout .supportlayout-center .add-meeting-step .assistance-option-disabled .option-icon {
  width: 44px;
  height: 44px;
  background: transparent;
  border-radius: 50px;
  margin-right: 10px;
  min-width: 44px;
}
.supportlayout .supportlayout-center .add-meeting-step .involvement-option .option-icon.on-my-own,
.supportlayout .supportlayout-center .add-meeting-step .involvement-option-disabled .option-icon.on-my-own,
.supportlayout .supportlayout-center .add-meeting-step .assistance-option .option-icon.on-my-own,
.supportlayout .supportlayout-center .add-meeting-step .assistance-option-disabled .option-icon.on-my-own {
  background-image: url(../images/icons/icon-on-my-own.svg);
  background-size: 100%;
}
.supportlayout .supportlayout-center .add-meeting-step .involvement-option .option-icon.with-someone-else,
.supportlayout .supportlayout-center .add-meeting-step .involvement-option-disabled .option-icon.with-someone-else,
.supportlayout .supportlayout-center .add-meeting-step .assistance-option .option-icon.with-someone-else,
.supportlayout .supportlayout-center .add-meeting-step .assistance-option-disabled .option-icon.with-someone-else {
  background-image: url(../images/icons/icon-group.svg);
  background-size: 100%;
}
.supportlayout .supportlayout-center .add-meeting-step .involvement-option .option-icon.some-talking,
.supportlayout .supportlayout-center .add-meeting-step .involvement-option-disabled .option-icon.some-talking,
.supportlayout .supportlayout-center .add-meeting-step .assistance-option .option-icon.some-talking,
.supportlayout .supportlayout-center .add-meeting-step .assistance-option-disabled .option-icon.some-talking {
  background-image: url(../images/icons/icon-talk-myself.svg);
  background-size: 100%;
}
.supportlayout .supportlayout-center .add-meeting-step .involvement-option .option-icon.speak-for-me,
.supportlayout .supportlayout-center .add-meeting-step .involvement-option-disabled .option-icon.speak-for-me,
.supportlayout .supportlayout-center .add-meeting-step .assistance-option .option-icon.speak-for-me,
.supportlayout .supportlayout-center .add-meeting-step .assistance-option-disabled .option-icon.speak-for-me {
  background-image: url(../images/icons/icon-group-talking.svg);
  background-size: 100%;
}
.supportlayout .supportlayout-center .add-meeting-step .involvement-option .option-icon.just-listening,
.supportlayout .supportlayout-center .add-meeting-step .involvement-option-disabled .option-icon.just-listening,
.supportlayout .supportlayout-center .add-meeting-step .assistance-option .option-icon.just-listening,
.supportlayout .supportlayout-center .add-meeting-step .assistance-option-disabled .option-icon.just-listening {
  background-image: url(../images/icons/icon-hear.svg);
  background-size: 100%;
}
.supportlayout .supportlayout-center .add-meeting-step .involvement-option.selected .on-my-own,
.supportlayout .supportlayout-center .add-meeting-step .involvement-option-disabled.selected .on-my-own,
.supportlayout .supportlayout-center .add-meeting-step .assistance-option.selected .on-my-own,
.supportlayout .supportlayout-center .add-meeting-step .assistance-option-disabled.selected .on-my-own {
  background-image: url(../images/icons/icon-on-my-ow-selected.svg);
  background-size: 100%;
}
.supportlayout .supportlayout-center .add-meeting-step .involvement-option.selected .with-someone-else,
.supportlayout .supportlayout-center .add-meeting-step .involvement-option-disabled.selected .with-someone-else,
.supportlayout .supportlayout-center .add-meeting-step .assistance-option.selected .with-someone-else,
.supportlayout .supportlayout-center .add-meeting-step .assistance-option-disabled.selected .with-someone-else {
  background-image: url(../images/icons/icon-group-selected.svg);
  background-size: 100%;
}
.supportlayout .supportlayout-center .add-meeting-step .involvement-option.selected .some-talking,
.supportlayout .supportlayout-center .add-meeting-step .involvement-option-disabled.selected .some-talking,
.supportlayout .supportlayout-center .add-meeting-step .assistance-option.selected .some-talking,
.supportlayout .supportlayout-center .add-meeting-step .assistance-option-disabled.selected .some-talking {
  background-image: url(../images/icons/icon-group-talking-selected.svg);
  background-size: 100%;
}
.supportlayout .supportlayout-center .add-meeting-step .involvement-option.selected .speak-for-me,
.supportlayout .supportlayout-center .add-meeting-step .involvement-option-disabled.selected .speak-for-me,
.supportlayout .supportlayout-center .add-meeting-step .assistance-option.selected .speak-for-me,
.supportlayout .supportlayout-center .add-meeting-step .assistance-option-disabled.selected .speak-for-me {
  background-image: url(../images/icons/icon-speakforme-selected.svg);
  background-size: 100%;
}
.supportlayout .supportlayout-center .add-meeting-step .involvement-option.selected .just-listening,
.supportlayout .supportlayout-center .add-meeting-step .involvement-option-disabled.selected .just-listening,
.supportlayout .supportlayout-center .add-meeting-step .assistance-option.selected .just-listening,
.supportlayout .supportlayout-center .add-meeting-step .assistance-option-disabled.selected .just-listening {
  background-image: url(../images/icons/icon-hear-selected.svg);
  background-size: 100%;
}
.supportlayout .supportlayout-center .add-meeting-step .involvement-option .title p,
.supportlayout .supportlayout-center .add-meeting-step .involvement-option-disabled .title p,
.supportlayout .supportlayout-center .add-meeting-step .assistance-option .title p,
.supportlayout .supportlayout-center .add-meeting-step .assistance-option-disabled .title p {
  margin: 0;
}
.supportlayout .supportlayout-center .add-meeting-step .assistance-option .option-icon,
.supportlayout .supportlayout-center .add-meeting-step .assistance-option-disabled .option-icon {
  border: 2px solid #5243a0;
}
.supportlayout .supportlayout-center .add-meeting-step .assistance-option.selected .option-icon,
.supportlayout .supportlayout-center .add-meeting-step .assistance-option-disabled.selected .option-icon {
  background-image: url(../images/icons/icon-complete.svg);
  background-size: 100%;
}
.supportlayout .supportlayout-center .add-meeting-step .involvement-option-disabled {
  display: none;
}
.supportlayout .supportlayout-center .add-meeting-step .involvement-option-disabled.selected {
  display: flex;
}
.supportlayout .supportlayout-center .add-meeting-step .butt-meeting-next,
.supportlayout .supportlayout-center .add-meeting-step .butt-meeting-confirm {
  background: #7b68c8;
  color: white;
  flex-direction: row-reverse;
  padding: 0 4px 0 15px;
}
.supportlayout .supportlayout-center .add-meeting-step .butt-meeting-next .butt-icon,
.supportlayout .supportlayout-center .add-meeting-step .butt-meeting-confirm .butt-icon {
  background-color: white;
}
.supportlayout .supportlayout-center .add-meeting-step .butt-meeting-back {
  background: transparent;
  color: #7b68c8;
  border: 1px solid #7b68c8;
}
.supportlayout .supportlayout-center .add-meeting-step .butt-meeting-back .butt-icon {
  background-color: #7b68c8;
}
@media all and (max-width: 600px) {
  .supportlayout .supportlayout-center .add-meeting-step .butt-meeting-next,
.supportlayout .supportlayout-center .add-meeting-step .butt-meeting-back,
.supportlayout .supportlayout-center .add-meeting-step .butt-meeting-confirm {
    width: 120px;
  }
  .supportlayout .supportlayout-center .add-meeting-step .butt-meeting-next .butt-icon,
.supportlayout .supportlayout-center .add-meeting-step .butt-meeting-back .butt-icon,
.supportlayout .supportlayout-center .add-meeting-step .butt-meeting-confirm .butt-icon {
    min-width: 32px;
  }
}
.supportlayout .supportlayout-center .add-meeting-step #meeting-date {
  margin-top: 15px;
  margin-bottom: 30px;
}
@media all and (max-width: 600px) {
  .supportlayout .supportlayout-center .add-meeting-step #meeting-date {
    width: fit-content;
    margin: 15px auto 30px;
  }
}
.supportlayout .supportlayout-center .add-meeting-step #meeting-date .ui-datepicker-inline {
  width: 265px;
}
@media screen and (max-width: 1200px) {
  .supportlayout .supportlayout-center .add-meeting-step #meeting-date .ui-datepicker-inline {
    width: 230px;
  }
}
.supportlayout .supportlayout-center .add-meeting-step .ui-datepicker {
  height: 100%;
  border-radius: 25px;
}
.supportlayout .supportlayout-center .add-meeting-step .ui-widget-header {
  border: 0px;
  background-color: transparent;
}
.supportlayout .supportlayout-center .add-meeting-step .ui-widget.ui-widget-content {
  border: 1px solid lightgrey;
}
.supportlayout .supportlayout-center .add-meeting-step .ui-datepicker th {
  text-transform: uppercase;
  font-size: 8pt;
  font-weight: normal;
  color: #5243a0;
  /*text-shadow: 1px 0px 0px #fff;*/
  /*filter: dropshadow(color=#fff, offx=1, offy=0);*/
}
.supportlayout .supportlayout-center .add-meeting-step .ui-datepicker tbody {
  border-top: 1px #5243a0 solid;
  border-bottom: 1px #5243a0 solid;
}
.supportlayout .supportlayout-center .add-meeting-step .ui-datepicker tbody td {
  padding: 2px 0;
  width: 35px;
  height: 35px;
}
@media screen and (max-width: 1200px) {
  .supportlayout .supportlayout-center .add-meeting-step .ui-datepicker tbody td {
    width: 29px;
    height: 29px;
  }
}
.supportlayout .supportlayout-center .add-meeting-step .ui-widget-content {
  background: transparent;
  border: 0px;
  text-align: center;
  padding: 10px 10px;
  width: 100%;
}
.supportlayout .supportlayout-center .add-meeting-step .ui-datepicker-calendar .ui-state-active {
  height: 35px;
  text-align: center;
  line-height: 35px;
  padding: 0;
  border: 0;
}
@media screen and (max-width: 1200px) {
  .supportlayout .supportlayout-center .add-meeting-step .ui-datepicker-calendar .ui-state-active {
    line-height: 29px;
    height: 29px;
  }
}
.supportlayout .supportlayout-center .add-meeting-step .ui-datepicker-calendar .ui-state-default {
  background: transparent;
  border: 0px;
  padding: 0;
  text-align: center;
  line-height: 35px;
}
@media screen and (max-width: 1200px) {
  .supportlayout .supportlayout-center .add-meeting-step .ui-datepicker-calendar .ui-state-default {
    line-height: 29px;
  }
}
.supportlayout .supportlayout-center .add-meeting-step .ui-datepicker-calendar .ui-state-hover {
  opacity: 0.6;
}
.supportlayout .supportlayout-center .add-meeting-step .ui-datepicker-today > a {
  color: #5243a0;
}
.supportlayout .supportlayout-center .add-meeting-step .ui-datepicker-current-day {
  font-weight: bold;
  color: #323333;
}
.supportlayout .supportlayout-center .add-meeting-step .ui-datepicker-current-day a {
  color: #ffffff !important;
  border-radius: 50%;
  background: #5243a0 !important;
}
.supportlayout .supportlayout-center .add-meeting-step .ui-widget-header .ui-icon {
  background-image: url(../images/icons/icon-arrow-next-black.svg);
  background-size: 35%;
  background-position: 58% 50%;
}
.supportlayout .supportlayout-center .add-meeting-step .ui-datepicker .ui-datepicker-prev {
  transform: rotate(180deg);
}
.supportlayout [data-state=initial], .supportlayout [data-state=member1], .supportlayout [data-state=member2], .supportlayout [data-state=member3], .supportlayout [data-state=member4], .supportlayout [data-state=support-network-add-meeting-1] {
  display: none;
}
.supportlayout[data-state=initial] [data-state=initial], .supportlayout[data-state=member1] [data-state=member1], .supportlayout[data-state=member2] [data-state=member2], .supportlayout[data-state=member3] [data-state=member3], .supportlayout[data-state=member4] [data-state=member4], .supportlayout[data-state=support-network-add-meeting-1] [data-state=support-network-add-meeting-1] {
  display: flex;
}
.supportlayout[data-state=support-network-add-meeting-1] [data-state=support-network-add-meeting-1] {
  flex-direction: column;
  max-width: 1000px;
  width: 100%;
  margin: 100px auto 50px;
}
.supportlayout[data-state=support-network-add-meeting-1] [data-state=support-network-add-meeting-1] h1 {
  margin: 0;
}
@media all and (max-width: 768px) {
  .supportlayout[data-state=support-network-add-meeting-1] [data-state=support-network-add-meeting-1] {
    width: 100%;
    max-width: auto;
    margin: 50px auto;
  }
}
.supportlayout [data-showwhen=userrequested] {
  display: none;
}
.supportlayout[data-userrequested=true] [data-showwhen=userrequested] {
  display: block;
}

.dashboard-wrap .goals-list-column .goal-wrapper.active .butt-goal .title {
  padding-left: 0;
}
.dashboard-wrap .goals-list-column .goal-wrapper .form-item {
  margin: 0;
}
.dashboard-wrap .goals-list-column .goal-wrapper .form-item input {
  background: transparent;
  padding: 0 20px 0 10px;
}
.dashboard-wrap .goals-list-column .goal-wrapper .form-item label {
  display: none;
}
.dashboard-wrap .goals-list-column .goal-wrapper .form-item.js-form-type-date {
  height: auto;
}
.dashboard-wrap .goals-list-column .goal-wrapper .field--type-datetime h4 {
  display: none;
}
.dashboard-wrap .goals-list-column .js-form-item-field-assigned-to {
  height: 100%;
  width: 100%;
  background: transparent;
  color: transparent;
}
.dashboard-wrap .goals-list-column .js-form-item-field-assigned-to #edit-field-assigned-to {
  width: 100%;
  height: 100%;
  margin: 0;
  color: transparent;
}
.dashboard-wrap .goals-list-column input[type=date] {
  width: 34px;
  height: 34px;
  position: relative;
  background-color: hsla(0deg, 0%, 100%, 0.45) !important;
  border: none;
  border-radius: 40px;
  color: transparent;
  background-image: url(../images/icons/icon-addcal-purple.svg) !important;
  background-size: 100%;
  outline: none;
}
.dashboard-wrap .goals-list-column input[type=date]::-webkit-inner-spin-button, .dashboard-wrap .goals-list-column input[type=date]::-webkit-calendar-picker-indicator {
  position: absolute;
  width: 34px;
  height: 34px;
  right: 0;
  -webkit-appearance: none;
  background: transparent;
}
.dashboard-wrap .goals-list-column input[type=date]::-webkit-datetime-edit-year-field:not([aria-valuenow]), .dashboard-wrap .goals-list-column input[type=date]::-webkit-datetime-edit-month-field:not([aria-valuenow]), .dashboard-wrap .goals-list-column input[type=date]::-webkit-datetime-edit-day-field:not([aria-valuenow]) {
  color: transparent;
}
.dashboard-wrap .goals-list-column input[type=date]:focus, .dashboard-wrap .goals-list-column input[type=date]:active {
  background-color: hsla(0deg, 0%, 100%, 0.45);
  border: none;
  border-radius: 40px;
  color: transparent !important;
  background-image: url(../images/icons/icon-addcal-purple.svg);
  background-size: 100%;
  outline: none;
}
.dashboard-wrap .goals-list-column input[type=date]:focus::-webkit-datetime-edit {
  color: transparent !important;
}
.dashboard-wrap .task-list-column {
  margin-left: 30px;
}
.dashboard-wrap .task-list-column .feedback-wrapper {
  display: none;
}
.dashboard-wrap .task-list-column.completed {
  background-image: url(../images/confetti.svg);
  background-position: top center;
  background-repeat: no-repeat;
}
.dashboard-wrap .task-list-column.completed .feedback-wrapper {
  display: block;
}
.dashboard-wrap .task-list-column.completed .tasks-wrapper {
  display: none;
}
.dashboard-wrap .task-list-column #edit-actions {
  text-align: center;
  margin-top: 20px;
}
.dashboard-wrap .task-list-column #edit-actions input {
  background: #7b68c8;
  margin: 20px auto 0 !important;
  text-align: center;
  justify-content: center;
  color: white;
  padding: 0;
}
@media all and (min-width: 1211px) {
  .dashboard-wrap .task-list-column #edit-actions {
    display: flex;
    flex-direction: row-reverse;
  }
}
.dashboard-wrap .task-list-column .feedback-wrapper label {
  position: relative;
  width: 100%;
  left: 0;
  font-weight: bold;
}
.dashboard-wrap .task-list-column .feedback-wrapper textarea {
  width: 100%;
  background: #E4E0F7;
  border: none;
  border-radius: 10px;
  margin-top: 10px;
  outline: none;
  padding: 20px;
  font-size: 1rem;
  font-family: "Open Sans", sans-serif;
  color: #323333;
}
.dashboard-wrap .task-list-column .feedback-wrapper .feel-icons {
  width: 100%;
  display: flex;
  margin: 10px 0;
}
.dashboard-wrap .task-list-column .feedback-wrapper .feel-icons .feel-icon {
  width: 26px;
  height: 26px;
  margin: 0 10px 0 0;
  background-size: contain;
  cursor: pointer;
  transition: all 0.2s;
  opacity: 0.5;
}
.dashboard-wrap .task-list-column .feedback-wrapper .feel-icons .feel-icon:hover {
  animation-duration: 0.3s;
  animation-fill-mode: both;
  animation-name: pulseBig;
  opacity: 1;
}
.dashboard-wrap .task-list-column .feedback-wrapper .feel-icons .feel-icon.selected {
  opacity: 1;
}
.dashboard-wrap .task-list-column .feedback-wrapper .feel-icons .feel-icon.bad {
  background: url(../images/icons/icon-bad.svg) center center no-repeat;
}
.dashboard-wrap .task-list-column .feedback-wrapper .feel-icons .feel-icon.ok {
  background: url(../images/icons/icon-ok.svg) center center no-repeat;
}
.dashboard-wrap .task-list-column .feedback-wrapper .feel-icons .feel-icon.good {
  background: url(../images/icons/icon-good.svg) center center no-repeat;
}
.dashboard-wrap .task-list-column .tasks-wrapper .tabledrag-toggle-weight-wrapper,
.dashboard-wrap .task-list-column .tasks-wrapper .tableresponsive-toggle-columns,
.dashboard-wrap .task-list-column .tasks-wrapper .field-multiple-drag,
.dashboard-wrap .task-list-column .tasks-wrapper .field-multiple-table thead {
  display: none;
}
.dashboard-wrap .task-list-column .tasks-wrapper .paragraphs-dropbutton-wrapper {
  text-align: center;
}
.dashboard-wrap .task-list-column .tasks-wrapper .paragraphs-dropbutton-wrapper .button {
  width: 34px;
  height: 34px;
  background-color: transparent;
  margin: 0;
  border-radius: 50px;
  color: transparent;
  border: 2px solid #F9D87C;
  background-image: url(../images/icons/icon-goal-remove.svg);
  background-repeat: no-repeat;
  background-position: center center;
}
.dashboard-wrap .task-list-column .tasks-wrapper .paragraphs-dropbutton-wrapper .field-add-more-submit {
  width: 180px;
  background: #F9D87C;
  color: #323333;
  padding: 0;
  justify-content: center;
  margin: 15px auto 0;
  display: block;
}
.dashboard-wrap .task-list-column .tasks-wrapper .paragraphs-dropbutton-wrapper .ajax-progress {
  padding-top: 20px;
}
.dashboard-wrap .task-list-column .tasks-wrapper .js-form-item {
  margin-bottom: 0;
}
.dashboard-wrap .task-list-column .tasks-wrapper .field-multiple-table {
  margin-bottom: 0;
}
.dashboard-wrap .task-list-column .tasks-wrapper .field-multiple-table .draggable {
  /*.ajax-new-content {
      padding: 0!important;
  }*/
}
.dashboard-wrap .task-list-column .tasks-wrapper .field-multiple-table .draggable td > div:first-child {
  background-color: #FCF7E5;
  border-radius: 50px;
  height: 50px;
  padding: 0 10px;
  margin-right: 34px;
}
.dashboard-wrap .task-list-column .tasks-wrapper .field-multiple-table .draggable td > div:first-child div:first-child {
  display: flex;
  flex-direction: row-reverse;
  justify-content: center;
  align-items: center;
  height: 100%;
}
.dashboard-wrap .task-list-column .tasks-wrapper .field-multiple-table .draggable .paragraphs-dropbutton-wrapper {
  display: none;
}
.dashboard-wrap .task-list-column .tasks-wrapper .field-multiple-table .draggable td {
  padding: 0 0 7px 0;
}
.dashboard-wrap .task-list-column .tasks-wrapper .field-multiple-table .draggable td:nth-child(2) {
  display: flex;
  flex-direction: row-reverse;
  justify-content: center;
  align-items: center;
  height: 100%;
  position: relative;
}
.dashboard-wrap .task-list-column .tasks-wrapper .field-multiple-table .draggable td .paragraph-type-title {
  display: none !important;
}
.dashboard-wrap .task-list-column .tasks-wrapper .field-multiple-table .draggable td .paragraph-type-top {
  height: 34px;
  padding: 0 !important;
}
.dashboard-wrap .task-list-column .tasks-wrapper .field-multiple-table .draggable td .paragraphs-subform {
  display: flex;
  flex-direction: row-reverse;
}
.dashboard-wrap .task-list-column .tasks-wrapper .field-multiple-table .draggable td .field--name-field-task-notes {
  position: absolute;
  left: 0;
  background: #fcf7e5;
  border-radius: 25px;
  margin-right: 35px;
  padding-top: 55px;
  z-index: 0;
  display: none;
}
.dashboard-wrap .task-list-column .tasks-wrapper .field-multiple-table .draggable td .field--name-field-task-notes label {
  display: none;
}
.dashboard-wrap .task-list-column .tasks-wrapper .field-multiple-table .draggable td .field--name-field-task-notes .js-form-item:first-child {
  padding: 0 10px 10px 50px;
}
.dashboard-wrap .task-list-column .tasks-wrapper .field-multiple-table .draggable td .field--name-field-task-notes textarea {
  width: 100%;
  border-radius: 10px;
  border: 1px solid #CFD2D2;
  outline: none;
  font-family: "Open Sans", sans-serif;
  padding: 10px;
}
.dashboard-wrap .task-list-column .tasks-wrapper .field-multiple-table .draggable td .show-notes {
  margin-bottom: 130px;
}
.dashboard-wrap .task-list-column .tasks-wrapper .field-multiple-table .draggable td .show-notes .field--name-field-task-notes {
  display: block;
}
.dashboard-wrap .task-list-column .tasks-wrapper .field-multiple-table .draggable td .field--name-field-assigned-to {
  position: absolute;
  right: -6px;
  width: 34px;
  height: 34px;
  top: 10px;
  bottom: 0;
  border-radius: 50px;
  background: #E4E0F7 url(../images/icons/icon-team-transparent.svg) center center no-repeat;
}
.dashboard-wrap .task-list-column .tasks-wrapper .field-multiple-table .draggable td .field--name-field-assigned-to label {
  display: none;
}
.dashboard-wrap .task-list-column .tasks-wrapper .field-multiple-table .draggable td .field--name-field-assigned-to select {
  background: transparent;
  height: 100%;
  opacity: 0 !important;
  margin: 0 !important;
  width: 100% !important;
}
.dashboard-wrap .task-list-column .tasks-wrapper .field-multiple-table .draggable td .field--name-field-assigned-to .js-form-type-entity-autocomplete {
  position: absolute;
  background: white;
  box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.41);
  margin-left: 40px;
  padding: 0 0 0 10px;
  visibility: hidden;
}
.dashboard-wrap .task-list-column .tasks-wrapper .field-multiple-table .draggable td .field--name-field-assigned-to .js-form-type-entity-autocomplete.show-users {
  visibility: visible;
}
.dashboard-wrap .task-list-column .tasks-wrapper .field-multiple-table .draggable td .field--name-field-assigned-to .js-form-type-entity-autocomplete input {
  background: transparent;
  padding: 0;
  width: 250px;
}
.dashboard-wrap .task-list-column .tasks-wrapper .field-multiple-table .draggable td .field--name-field-task-name {
  background: transparent;
  height: 50px;
  margin: 0;
  z-index: 1;
}
.dashboard-wrap .task-list-column .tasks-wrapper .field-multiple-table .draggable td .field--name-field-task-name .js-form-item {
  margin: 0;
  background: none;
}
.dashboard-wrap .task-list-column .tasks-wrapper .field-multiple-table .draggable td .field--name-field-task-name .js-form-item input {
  background: transparent;
  padding: 0 40px 0 10px;
  font-size: 0.9em;
}
.dashboard-wrap .task-list-column .tasks-wrapper .field-multiple-table .draggable td .field--name-field-task-name .js-form-item label {
  display: none;
}
.dashboard-wrap .task-list-column .tasks-wrapper .field-multiple-table .draggable td .js-form-type-checkbox {
  background: none;
  margin: 0;
  width: 34px;
  height: 34px;
  z-index: 1;
}
.dashboard-wrap .task-list-column .tasks-wrapper .field-multiple-table .draggable td .js-form-type-checkbox input[type=checkbox] {
  width: 34px !important;
  height: 34px;
  padding: 15px;
  background: transparent;
  border: 2px solid #f3c762;
  margin: 0;
}
.dashboard-wrap .task-list-column .tasks-wrapper .field-multiple-table .draggable td .js-form-type-checkbox input[type=checkbox]:checked {
  background: #F9D87C url(../images/icons/icon-checked.svg) center center no-repeat;
  background-size: 50%;
}
.dashboard-wrap .task-list-column .tasks-wrapper .field-multiple-table .draggable td .js-form-type-checkbox label {
  display: none;
}
.dashboard-wrap .task-list-column .tasks-wrapper .field-multiple-table .draggable td .field--name-field-show-notes {
  position: absolute;
  top: 8px;
  right: 44px;
}
.dashboard-wrap .task-list-column .tasks-wrapper .field-multiple-table .draggable td .field--name-field-show-notes .js-form-type-checkbox input[type=checkbox] {
  background: transparent url(../images/icons/icon-edit.svg) center center no-repeat;
}
.dashboard-wrap .task-list-column .tasks-wrapper .field-multiple-table .draggable td .field--name-field-show-notes .js-form-type-checkbox input[type=checkbox]:checked {
  background: transparent url(../images/icons/icon-edit.svg) center center no-repeat;
}
.dashboard-wrap .task-list-column .field--name-field-notify-team-members {
  margin-top: 30px;
  padding-left: 12px;
}
.dashboard-wrap .task-list-column .field--name-field-notify-team-members .form-item-field-notify-team-members-value {
  display: flex;
}
.dashboard-wrap .task-list-column .field--name-field-notify-team-members .form-item input[type=checkbox] {
  width: 30px !important;
  height: 30px;
  padding: 15px;
  margin: 0;
}
.dashboard-wrap .task-list-column .field--name-field-notify-team-members label.right {
  left: 20px;
}
.dashboard-wrap .task-list-column .goal-task {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
}
.dashboard-wrap .task-list-column .goal-task .task-wrap {
  height: 50px;
  background: #FCF7E5;
  border-radius: 50px;
  display: flex;
  padding: 0 10px;
  flex: 1;
  margin-right: 5px;
  font-size: 0.9em;
  flex-direction: column;
  min-height: 50px;
  height: auto;
  justify-content: flex-start;
  border-radius: 25px;
  align-items: flex-end;
}
.dashboard-wrap .task-list-column .goal-task .task-wrap .top-line-wrap {
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding-top: 10px;
}
.dashboard-wrap .task-list-column .goal-task .task-wrap .notes {
  min-height: 100px;
  overflow: hidden;
  border: 1px solid #CFD2D2;
  width: 80%;
  margin: 20px 10px;
  border-radius: 10px;
  padding: 10px;
  background: white;
}
.dashboard-wrap .task-list-column .goal-task .task-wrap .icon-completed {
  border: 2px solid #F9D87C;
  width: 34px;
  height: 34px;
  min-width: 34px;
  border-radius: 34px;
  margin-right: 10px;
}
.dashboard-wrap .task-list-column .goal-task .task-wrap .icon-completed.completed {
  background: #F9D87C url(../images/icons/icon-checked.svg) center center no-repeat;
  background-size: 50%;
}
.dashboard-wrap .task-list-column .goal-task .assigned-to {
  width: 34px;
  height: 34px;
  border-radius: 34px;
  background: #59A3D0;
  color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: 600;
  font-size: 0.8em;
}
.dashboard-wrap .task-list-column .butt-wrap .butt-edit {
  background: #7b68c8;
  margin: 0 auto;
}
.dashboard-wrap .selected-name {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  margin: auto;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background: #59A3D0;
  border-radius: 50px;
  pointer-events: none;
  color: #ffffff;
  font-weight: 600;
  font-size: 0.8em;
}
.dashboard-wrap .back-button-wrap {
  position: absolute;
  right: 0;
  bottom: 0;
  padding-bottom: 0;
}

.back-button-wrap-goals {
  padding: 0 0 40px;
  display: flex;
  justify-content: space-between;
}
.back-button-wrap-goals .form-submit {
  justify-content: center;
}
@media all and (max-width: 1300px) {
  .back-button-wrap-goals {
    width: 100vw;
    padding: 0 30px 40px 30px;
  }
}
.tabledrag-toggle-weight-wrapper {
  display: none;
}

.tabledrag-handle {
  display: none;
}

.field--widget-myway-goal-setting-inline-paragraphs-widget .paragraphs-subform {
  display: flex;
}

/**
 * Disabled state
 */
input#edit-submit.disabled,
input#edit-submit[disabled] {
  box-shadow: none;
  cursor: not-allowed;
  opacity: 0.5;
  pointer-events: none;
}

#field-goal-task-values th.field-label p {
  font-weight: normal;
}

.two-factor-form {
  width: 70%;
}
.two-factor-form .header-small {
  margin-bottom: 40px;
  text-align: center;
}
.two-factor-form label[for=edit-code] {
  width: 250px;
  max-height: 50px;
}
.two-factor-form label[for=edit-code].right {
  left: calc(100% - 270px);
}
.two-factor-form input[type=text] {
  padding: 0 250px 0 20px;
  margin-bottom: 20px;
}
.two-factor-form .description {
  width: 90%;
  text-align: center;
  margin: 0 auto;
  font-size: 0.9em;
}
.two-factor-form fieldset {
  border: 1px solid white;
  margin-top: 50px;
  padding: 10px 20px;
}
.two-factor-form fieldset .fieldset-legend {
  font-weight: bold;
  color: #323333;
}
.two-factor-form fieldset .fieldset-wrapper {
  font-size: 0.9em;
}
.two-factor-form #edit-actions {
  text-align: center;
}
.two-factor-form #edit-actions input[type=submit] {
  margin: 40px 0;
  padding: 0;
  display: inline-block;
}

@media all and (max-width: 1300px) {
  .profile-content {
    width: 100%;
    padding: 0 30px;
  }
}
@media all and (max-width: 800px) {
  .profile-content {
    max-width: 100%;
    padding: 0 20px;
    overflow: hidden;
  }
}
.profile-content .ajax-progress-throbber {
  display: none;
}
.profile-content h1 {
  font-size: 32px;
}
@media only screen and (max-width: 1300px) {
  .profile-content h1 {
    font-size: 26px;
  }
}
.profile-content h1.about-title {
  word-break: break-all;
  margin-right: 50px;
}
@media all and (max-width: 800px) {
  .profile-content h1.about-title {
    margin-right: 0;
  }
}
.profile-content h2 {
  font-size: 24px;
}
.profile-content h3 {
  font-size: 20px;
}
.profile-content p {
  font-size: 16px;
}
.profile-content a {
  text-decoration: none;
  font-weight: bold;
  font-size: 14px;
  color: #7662c6;
}
.profile-content a:hover {
  color: #323333;
}
.profile-content .profile-header {
  display: flex;
  justify-content: space-between;
  margin-top: 100px;
}
@media all and (max-width: 800px) {
  .profile-content .profile-header {
    flex-direction: column;
    margin-top: 50px;
  }
}
.profile-content .profile-header .profile-person-details {
  color: #323333;
}
.profile-content .profile-header .profile-person-details .employment-status {
  margin-top: 25px;
}
.profile-content .profile-header .profile-person-details .education-status,
.profile-content .profile-header .profile-person-details .email {
  margin-top: 20px;
}
.profile-content .profile-header .profile-person-details .details-section-title {
  margin-bottom: 0;
}
.profile-content .profile-header .profile-person-details .details-section-content {
  margin: 0;
}
@media all and (max-width: 800px) {
  .profile-content .profile-header .profile-person-details {
    margin-bottom: 30px;
    text-align: center;
  }
}
.profile-content .profile-header .profile-support-information {
  background: #ffffff;
  border-radius: 40px;
  color: #323333;
  padding: 0 33px 17px 33px;
  width: 60%;
  align-self: flex-start;
}
@media all and (max-width: 800px) {
  .profile-content .profile-header .profile-support-information {
    width: 100%;
  }
}
.profile-content .profile-header .profile-support-information .profile-support-information-title {
  margin-top: 20px;
  display: flex;
  align-items: flex-end;
}
.profile-content .profile-header .profile-support-information .profile-support-information-title .profile-support-logo {
  width: 180px;
  margin-right: 20px;
}
.profile-content .profile-quiz-section {
  margin-top: 45px;
}
.profile-content .profile-quiz-section .profile-results-section {
  background: #ffffff;
  border-radius: 40px;
  color: #323333;
  padding: 26px 25px 30px 25px;
  margin-bottom: 50px;
}
.profile-content .profile-quiz-section .profile-results-section .butt-more-for-support-wrap {
  display: flex;
  justify-content: center;
}
.profile-content .profile-quiz-section .profile-results-section .profile-results-title {
  display: flex;
  align-items: center;
}
.profile-content .profile-quiz-section .profile-results-section .profile-results-title .icon-category {
  border-radius: 34px;
  background-position: 50%;
  background-repeat: no-repeat;
  background-size: 60%;
  width: 68px;
  height: 68px;
  margin-right: 15px;
}
.profile-content .profile-quiz-section .profile-results-section .profile-results-title .icon-category.career {
  background-color: #59A3D0;
  background-image: url(../images/icons/icon-career.svg);
}
.profile-content .profile-quiz-section .profile-results-section .profile-results-title .icon-category.work {
  background-color: #f3c762;
  background-image: url(../images/icons/icon-work.svg);
}
.profile-content .profile-quiz-section .profile-results-section .profile-results-title .icon-category.life {
  background-color: #ee9a95;
  background-image: url(../images/icons/icon-life.svg);
}
.profile-content .profile-quiz-section .profile-results-section .profile-results-title .icon-category.training {
  background-color: #86cc88;
  background-image: url(../images/icons/icon-training.svg);
}
.profile-content .profile-quiz-section .profile-results-section .profile-results-title .icon-category.strengths {
  background-color: #7b68c8;
  background-image: url(../images/icons/icon-strengths.svg);
}
.profile-content .profile-quiz-section .profile-results-section .profile-results-subtitle {
  margin: 30px 30px 0 30px;
}
@media all and (max-width: 800px) {
  .profile-content .profile-quiz-section .profile-results-section .profile-results-subtitle {
    margin: 30px 0 0;
  }
}
.profile-content .profile-quiz-section .profile-results-section .empty-section {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 60px;
}
.profile-content .profile-quiz-section .profile-results-section .empty-section .icon-empty {
  width: 36px;
  height: 36px;
  background-image: url(../images/icons/icon-exclamation.svg);
}
.profile-content .profile-quiz-section .profile-results-section .empty-section p {
  width: 65%;
  text-align: center;
  margin-bottom: 90px;
  font-size: 20px;
}
.profile-content .profile-quiz-section .profile-results-section ul {
  padding-left: 15px;
  padding-right: 40px;
}
.profile-content .profile-quiz-section .profile-results-section ul li {
  margin-bottom: 20px;
}
.profile-content .profile-quiz-section .profile-results-section ul li::marker {
  margin-right: 11px;
  vertical-align: middle;
  color: #40a3d4;
}
.profile-content .profile-quiz-section .profile-results-section .career-top-three-heading {
  margin-left: 30px;
  margin-top: 40px;
}
@media all and (max-width: 800px) {
  .profile-content .profile-quiz-section .profile-results-section .career-top-three-heading {
    margin-left: 0;
  }
}
.profile-content .profile-quiz-section .profile-results-section .grid-results {
  display: grid;
  grid-template-columns: 25% 25% 25% 25%;
  margin: 30px 30px 0 30px;
}
.profile-content .profile-quiz-section .profile-results-section .grid-results.life {
  grid-template-columns: 100%;
}
.profile-content .profile-quiz-section .profile-results-section .grid-results.strengths {
  grid-template-columns: 100%;
}
.profile-content .profile-quiz-section .profile-results-section .grid-results.strengths ul {
  column-count: 2;
  column-gap: 20px;
  margin-top: 0;
}
@media all and (max-width: 800px) {
  .profile-content .profile-quiz-section .profile-results-section .grid-results.strengths ul {
    column-count: 1;
  }
}
.profile-content .profile-quiz-section .profile-results-section .grid-results.training {
  grid-template-columns: 50% 50%;
}
.profile-content .profile-quiz-section .profile-results-section .grid-results.career {
  grid-template-rows: 1fr 0.5fr;
  grid-template-columns: repeat(3, 1fr);
  margin-top: 32px;
}
@media all and (max-width: 800px) {
  .profile-content .profile-quiz-section .profile-results-section .grid-results.career {
    grid-template-rows: 1fr 0.5fr;
    grid-template-columns: repeat(1, 1fr);
  }
}
.profile-content .profile-quiz-section .profile-results-section .grid-results .grid-item {
  padding: 0 20px;
  border-left: 1px solid #c3c3c3;
}
.profile-content .profile-quiz-section .profile-results-section .grid-results .grid-item h3 {
  margin-top: 0;
}
.profile-content .profile-quiz-section .profile-results-section .grid-results .grid-item:first-child {
  border-left: none;
  padding-left: 0;
}
.profile-content .profile-quiz-section .profile-results-section .grid-results .grid-item:last-child {
  padding-right: 0;
}
.profile-content .profile-quiz-section .profile-results-section .grid-results .grid-item .not-available {
  margin-top: 30px;
}
@media all and (max-width: 800px) {
  .profile-content .profile-quiz-section .profile-results-section .grid-results.work, .profile-content .profile-quiz-section .profile-results-section .grid-results.training {
    grid-template-columns: 100%;
  }
}
.profile-content .profile-quiz-section .profile-results-section .grid-results.work .grid-item, .profile-content .profile-quiz-section .profile-results-section .grid-results.training .grid-item {
  border: none;
  padding: 0;
}
.profile-content .profile-quiz-section .profile-results-section .grid-results .career-grid-item {
  padding-bottom: 30px;
}
.profile-content .profile-quiz-section .profile-results-section .grid-results .career-grid-item.career-bottom-three {
  padding-top: 23px;
  border-top: solid 1px #c3c3c3;
}
@media all and (max-width: 800px) {
  .profile-content .profile-quiz-section .profile-results-section .grid-results .career-grid-item {
    padding-top: 23px;
    border-top: none;
    border-bottom: solid 1px #c3c3c3;
  }
  .profile-content .profile-quiz-section .profile-results-section .grid-results .career-grid-item.career-bottom-three {
    border-top: none;
  }
}
.profile-content .profile-quiz-section .profile-results-section .grid-results .career-grid-item .career-grid-item-content-wrap .career-type-heading {
  display: flex;
  flex-direction: row;
}
.profile-content .profile-quiz-section .profile-results-section .grid-results .career-grid-item .career-grid-item-content-wrap .career-type-heading .career-percentage {
  border-radius: 32px;
  background-repeat: no-repeat;
  width: 65px;
  height: 65px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.profile-content .profile-quiz-section .profile-results-section .grid-results .career-grid-item .career-grid-item-content-wrap .career-type-heading .career-percentage.career-top-three {
  background-color: #d4e9f7;
}
.profile-content .profile-quiz-section .profile-results-section .grid-results .career-grid-item .career-grid-item-content-wrap .career-type-heading .career-percentage.career-bottom-three {
  background-color: #dae2e8;
}
.profile-content .profile-quiz-section .profile-results-section .grid-results .career-grid-item .career-grid-item-content-wrap .career-type-heading .career-type-title {
  padding-left: 10px;
}
.profile-content .profile-quiz-section .profile-results-section .grid-results .career-grid-item .career-grid-item-content-wrap .career-type-blurb {
  width: 90%;
  margin-top: 15px;
}
@media all and (max-width: 800px) {
  .profile-content .profile-quiz-section .profile-results-section .grid-results .career-grid-item .career-grid-item-content-wrap .career-type-blurb {
    width: 100%;
  }
}
.profile-content .profile-quiz-section .profile-results-section .grid-results .career-grid-item .career-grid-item-content-wrap .link-career-suggestions {
  text-decoration: none;
  font-weight: bold;
  display: block;
}
.profile-content .profile-quiz-section .profile-results-section .grid-results .career-grid-item .career-grid-item-content-wrap .link-career-suggestions.career-bottom-three {
  padding-top: 15px;
}

.dashboard-spe-wrap {
  flex: 1;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  flex-direction: column;
  margin-top: 50px;
  margin-bottom: 50px;
}
@media all and (max-width: 1300px) {
  .dashboard-spe-wrap {
    width: 100%;
    padding: 0 30px;
  }
}
@media all and (max-width: 800px) {
  .dashboard-spe-wrap {
    max-width: 100%;
    padding: 0 20px;
    overflow: hidden;
  }
}
.dashboard-spe-wrap .dashboard-spe-title {
  width: 100%;
  text-align: center;
  align-self: center;
  margin-bottom: 40px;
}
.dashboard-spe-wrap .dashboard-spe-title h1 {
  font-size: 32px;
  margin-bottom: 40px;
}
@media only screen and (max-width: 1300px) {
  .dashboard-spe-wrap .dashboard-spe-title h1 {
    font-size: 26px;
  }
}
.dashboard-spe-wrap .dashboard-spe-title p {
  font-size: 18px;
  color: #323333;
}
.dashboard-spe-wrap .dashboard-spe-title .PUG-invite {
  background: #f8eab6;
  border-radius: 20px;
  font-size: 0.88rem;
  margin-top: 10px;
  padding: 20px;
}
.dashboard-spe-wrap .dashboard-spe-content {
  display: flex;
  flex-direction: column;
  align-self: center;
  width: 100%;
}
.dashboard-spe-wrap .dashboard-spe-content h2 {
  align-self: flex-start;
  font-size: 1.5em;
  color: #323333;
}
.dashboard-spe-wrap .dashboard-spe-content .young-people-table-wrap {
  background: #ffffff;
  border-radius: 50px;
  text-align: center;
  align-items: flex-start;
  padding: 20px 50px 50px;
  box-shadow: 2px 2px 40px 1px rgba(36, 58, 71, 0.25);
  flex-grow: 1;
}
@media all and (max-width: 600px) {
  .dashboard-spe-wrap .dashboard-spe-content .young-people-table-wrap {
    padding: 0 20px;
  }
}
.dashboard-spe-wrap .dashboard-spe-content .young-people-table-wrap table {
  text-align: left;
  table-layout: auto;
  width: 100%;
  border-spacing: 0;
  overflow-x: hidden;
}
@media all and (max-width: 1024px) {
  .dashboard-spe-wrap .dashboard-spe-content .young-people-table-wrap table thead {
    border: none;
    clip: rect(0 0 0 0);
    height: 1px;
    margin: -1px;
    overflow: hidden;
    padding: 0;
    position: absolute;
    width: 1px;
  }
}
.dashboard-spe-wrap .dashboard-spe-content .young-people-table-wrap table th {
  border-bottom: 1px solid #e3e3e3;
}
.dashboard-spe-wrap .dashboard-spe-content .young-people-table-wrap table td {
  border-bottom: 1px solid #e3e3e3;
  font-size: 0.8em;
}
@media all and (max-width: 1024px) {
  .dashboard-spe-wrap .dashboard-spe-content .young-people-table-wrap table td {
    display: block;
    height: 40px;
    display: flex;
    align-items: center;
    background: #c0e5f7;
    padding: 20px;
    border-bottom: 1px solid #ffffff;
  }
  .dashboard-spe-wrap .dashboard-spe-content .young-people-table-wrap table td:before {
    content: attr(data-label);
    font-weight: bold;
    text-transform: uppercase;
    width: 100px;
  }
  .dashboard-spe-wrap .dashboard-spe-content .young-people-table-wrap table td.button-cell {
    justify-content: center;
    height: 80px;
    border-bottom: 2px solid #e3e3e3;
    margin-bottom: 20px;
  }
  .dashboard-spe-wrap .dashboard-spe-content .young-people-table-wrap table td.button-cell:before {
    width: 0px;
  }
  .dashboard-spe-wrap .dashboard-spe-content .young-people-table-wrap table td:first-child {
    border-radius: 20px 20px 0 0;
  }
  .dashboard-spe-wrap .dashboard-spe-content .young-people-table-wrap table td:last-child {
    border-radius: 0 0 20px 20px;
    border-bottom: 0;
    padding: 0;
  }
}
.dashboard-spe-wrap .dashboard-spe-content .young-people-table-wrap table a {
  text-decoration: none;
  color: #323333;
}
.dashboard-spe-wrap .dashboard-spe-content .young-people-table-wrap table a:visited {
  color: #323333;
}
.dashboard-spe-wrap .dashboard-spe-content .young-people-table-wrap table a:hover {
  cursor: pointer;
  font-size: 1.5em;
}
.dashboard-spe-wrap .dashboard-spe-content .young-people-table-wrap table h2 {
  font-size: 18px;
  color: #323333;
}
.dashboard-spe-wrap .dashboard-spe-content .young-people-table-wrap table .young-person-name {
  font-weight: bold;
}
.dashboard-spe-wrap .dashboard-spe-content .young-people-table-wrap table .young-person-email {
  color: #323333;
}
.dashboard-spe-wrap .dashboard-spe-content .young-people-table-wrap table .button-cell {
  padding-right: 0;
  text-align: right;
  display: flex;
  justify-content: right;
}
.dashboard-spe-wrap .dashboard-spe-content .young-people-table-wrap table .ellipsis {
  display: block;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  font-size: 16px;
}
@media all and (max-width: 600px) {
  .dashboard-spe-wrap .dashboard-spe-content .young-people-table-wrap table .ellipsis {
    font-size: 0.8rem;
    white-space: initial;
    word-break: break-word;
  }
}

.butt-to-yp-details {
  -webkit-appearance: none;
  background: none;
  border: none;
  outline: none;
  transition: all 0.3s;
  overflow: hidden;
  margin: 10px 0;
  font-family: "Open Sans", sans-serif;
}
.butt-to-yp-details .icon {
  width: 45px;
  height: 45px;
  border-radius: 50px;
  background-color: #337ca5;
  border: 2px solid white;
  background-size: 60%;
  background-repeat: no-repeat;
  background-position: center 46%;
  margin: 0 auto;
  background-position: 50% 50%;
}
.butt-to-yp-details .title {
  text-transform: uppercase;
  font-size: 0.63rem;
  font-weight: bold;
  color: #337ca5;
  text-align: center;
}
.butt-to-yp-details.next .icon {
  background-image: url(../images/icons/icon-user.svg);
  background-size: 50%;
}
.butt-to-yp-details.goals .icon {
  background-image: url(../images/icons/icon-goal-light.svg);
  background-size: 70%;
}
.butt-to-yp-details.team .icon {
  background-image: url(../images/icons/icon-team-white.svg);
  background-size: 70%;
}
.butt-to-yp-details.meetings .icon {
  background-image: url(../images/icons/icon-calendar-white.svg);
  background-size: 60%;
}
.butt-to-yp-details.remove .icon {
  background-color: white;
  background-image: url(../images/icons/icon-close-red.svg);
  background-size: 40%;
}
.butt-to-yp-details.remove .title {
  color: #ea8782;
}
.butt-to-yp-details:hover {
  cursor: pointer;
  animation-duration: 0.3s;
  animation-fill-mode: both;
  animation-name: pulseBig;
}

.ui-widget-overlay {
  opacity: 0.7 !important;
  background: black !important;
}

.evidence-dialog {
  border: 0 !important;
  background: transparent;
  padding-left: 0px;
  overflow: hidden auto;
  height: 100vh !important;
}
.evidence-dialog .ui-dialog-titlebar {
  display: none;
}

.evidence-dialog > #drupal-modal {
  margin: 1.75rem auto;
  transform: translate(0, 0);
  max-width: 1200px;
  min-height: auto !important;
  max-height: none !important;
  padding: 0.5em 0;
}
@media only screen and (max-width: 1300px) {
  .evidence-dialog > #drupal-modal {
    padding: 0.5em 30px;
  }
}
@media all and (max-width: 600px) {
  .evidence-dialog > #drupal-modal {
    padding: 10px;
  }
}

.evidence-container {
  font-family: "Open Sans", "sans-serif";
  padding: 30px 48px;
  background-color: white;
  border-radius: 15px;
  position: relative;
}
@media only screen and (max-width: 1300px) {
  .evidence-container {
    padding: 17px 28px 17px 25px;
  }
}
@media all and (max-width: 600px) {
  .evidence-container {
    padding: 15px;
  }
}
.evidence-container a {
  color: #7662c6;
}
.evidence-container a:hover {
  color: #323333;
}
.evidence-container .evidence-header {
  display: flex;
  flex-direction: row;
  align-items: center;
}
@media all and (max-width: 600px) {
  .evidence-container .evidence-header {
    align-items: flex-start;
  }
  .evidence-container .evidence-header h1 {
    line-height: 1.6rem;
    margin-bottom: 10px;
  }
}
.evidence-container .evidence-header .close-container {
  display: flex;
  flex-direction: column;
  margin-left: auto;
  align-items: center;
}
@media all and (max-width: 600px) {
  .evidence-container .evidence-header .close-container {
    transform: scale(0.8);
    margin-top: -10px;
  }
}
.evidence-container .evidence-header .close-container span {
  font-size: 16px;
  font-weight: bold;
}
.evidence-container .evidence-header .close-container button {
  -webkit-appearance: none;
  width: 56px;
  height: 56px;
  border-radius: 51.7px;
  background-color: #337ca5;
  border: 2px solid white;
  background-size: 46%;
  background-repeat: no-repeat;
  background-position: center 46%;
  outline: none;
  transition: all 0.3s;
  overflow: hidden;
  margin: 10px 0 5px 0;
}
@media all and (max-width: 600px) {
  .evidence-container .evidence-header .close-container button {
    margin-top: 0;
  }
}
.evidence-container .evidence-header .close-container button.next {
  background-image: url(../images/icons/icon-close-light.svg);
  background-size: 46%;
  background-position: 50% 50%;
}
.evidence-container .evidence-header .close-container button:focus {
  outline: none !important;
}
@media only screen and (max-width: 1300px) {
  .evidence-container .evidence-header .close-container button {
    width: 50px;
    height: 50px;
  }
}
.evidence-container .evidence-header .close-container:hover {
  cursor: pointer;
  animation-duration: 0.3s;
  animation-fill-mode: both;
  animation-name: pulseBig;
}
.evidence-container .evidence-sub-header {
  padding: 20px;
  /*img {
      width: 180px;
      margin: 15px 20px 0 15px;
  }

  h2 {
      margin-bottom: -5px;
  }*/
}
@media all and (max-width: 600px) {
  .evidence-container .evidence-sub-header {
    padding: 20px 0;
  }
}
.evidence-container .evidence-sub-header .evidence-note {
  margin-top: 10px;
  padding: 20px;
  background: #f8eab6;
  border-radius: 20px;
  font-size: 0.88rem;
}
.evidence-container .header-icon {
  margin-right: 10px;
  margin-left: 15px;
  width: 64px;
  min-width: 64px;
  height: 64px;
  border-radius: 32px;
  background-repeat: no-repeat;
  background-position-x: 50%;
  background-position-y: center;
}
@media all and (max-width: 600px) {
  .evidence-container .header-icon {
    margin-left: 0;
    width: 50px;
    height: 50px;
    min-width: 50px;
  }
}
.evidence-container .header-icon.icon-work {
  background-color: #f4c760;
  background-image: url(../images/icons/icon-work.svg);
}
.evidence-container .header-icon.icon-life {
  background-color: #ee9a95;
  background-image: url(../images/icons/icon-life.svg);
}
.evidence-container .header-icon.icon-strengths {
  background-color: #7b68c8;
  background-image: url(../images/icons/icon-strengths.svg);
}
.evidence-container .header-icon.icon-career {
  background-color: #59A3D0;
  background-image: url(../images/icons/icon-career.svg);
}
.evidence-container .header-icon.icon-training {
  background-color: #86cc88;
  background-image: url(../images/icons/icon-training.svg);
}
.evidence-container .evidence-mapping-empty-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  color: #323333;
  margin: 85px 0;
}
.evidence-container .evidence-mapping-empty-container img {
  width: 30px;
  margin-bottom: 10px;
}
.evidence-container .evidence-mapping-empty-container span {
  font-size: 1.1rem;
}
.evidence-container .evidence-mapping-container {
  color: #323333;
  padding: 20px;
}
@media all and (max-width: 600px) {
  .evidence-container .evidence-mapping-container {
    padding: 20px 0;
  }
}
.evidence-container .evidence-mapping-container .mapping-area {
  margin-bottom: 40px;
}
.evidence-container .evidence-mapping-container .mapping-heading {
  font-size: 24px;
  border-radius: 5px;
  padding: 10px 20px;
  background-color: #f4f5f6;
  margin-top: 0;
  margin-bottom: 36px;
}
@media only screen and (max-width: 1300px) {
  .evidence-container .evidence-mapping-container .mapping-heading {
    font-size: 20px;
  }
}
@media all and (max-width: 600px) {
  .evidence-container .evidence-mapping-container .mapping-heading {
    margin-bottom: 20px;
  }
}
.evidence-container .evidence-mapping-container h3 {
  font-size: 20px;
}
.evidence-container .evidence-mapping-container ul {
  padding-left: 20px;
}
.evidence-container .evidence-mapping-container li::marker {
  color: #59a3d0;
}
.evidence-container .evidence-mapping-container li {
  margin-bottom: 15px;
  line-height: 22px;
}
.evidence-container .evidence-mapping-container p {
  line-height: 150%;
}
.evidence-container .evidence-mapping-container .mapping-body {
  padding: 0 12px;
}
@media all and (max-width: 600px) {
  .evidence-container .evidence-mapping-container .mapping-body {
    padding: 0;
  }
}
.evidence-container .evidence-mapping-container .mapping-body p:has(+ ul) {
  background-color: #c0e5f7;
  border-top-left-radius: 20px;
  border-top-right-radius: 20px;
  margin-bottom: 0;
  padding: 20px 20px 10px;
}
.evidence-container .evidence-mapping-container .mapping-body p ~ ul {
  background-color: #c0e5f7;
  border-bottom-left-radius: 20px;
  border-bottom-right-radius: 20px;
  margin: 0;
  padding: 0 40px 10px 40px;
}
.evidence-container .evidence-mapping-container .mapping-body .strength {
  background-color: #c0e5f7;
  border-radius: 20px;
  padding: 20px;
  margin-bottom: 30px;
  display: flex;
  align-items: center;
}
@media all and (max-width: 600px) {
  .evidence-container .evidence-mapping-container .mapping-body .strength {
    align-items: flex-start;
  }
}
.evidence-container .evidence-mapping-container .mapping-body .strength .number {
  font-size: 1.88rem;
  font-weight: bold;
  color: #59a3d0;
  margin-right: 20px;
}
.evidence-container .evidence-mapping-container .mapping-body .strength h3 {
  font-size: 1rem;
  margin: 0;
}
.evidence-container .evidence-mapping-container .mapping-body .strength .strength-detail p {
  margin: 10px 0 0 0;
}
.evidence-container .evidence-mapping-container .mapping-body .strength .image-wrap {
  width: 100px;
}
@media all and (max-width: 600px) {
  .evidence-container .evidence-mapping-container .mapping-body .strength .image-wrap {
    width: 50px;
    dislay: none;
  }
}
.evidence-container .evidence-mapping-container .mapping-body .strength .image-wrap img {
  width: 100px;
  height: 100px;
  object-fit: contain;
}
@media all and (max-width: 600px) {
  .evidence-container .evidence-mapping-container .mapping-body .strength .image-wrap img {
    width: 50px;
    height: 50px;
  }
}
@media all and (max-width: 600px) {
  .evidence-container .evidence-mapping-container .mapping-body .strength .image-wrap {
    text-align: center;
    margin-bottom: 10px;
  }
}
.evidence-container .evidence-mapping-container .mapping-body.sensory {
  display: flex;
}
.evidence-container .evidence-mapping-container .mapping-body.sensory .body-wrap {
  margin-left: 20px;
}
@media all and (max-width: 600px) {
  .evidence-container .evidence-mapping-container .mapping-body.sensory .body-wrap {
    margin-left: 0;
    padding: 0 10px;
  }
}
.evidence-container .evidence-mapping-container .mapping-body.sensory .image-wrap img {
  width: 100px;
  margin: 20px;
}
@media all and (max-width: 600px) {
  .evidence-container .evidence-mapping-container .mapping-body.sensory .image-wrap {
    display: none;
  }
  .evidence-container .evidence-mapping-container .mapping-body.sensory .image-wrap img {
    width: 50px;
    margin-left: 0;
  }
}
.evidence-container .evidence-mapping-container .mapping-body .mapping-answer {
  margin-bottom: 50px;
}
.evidence-container .evidence-mapping-container .mapping-body .mapping-answer.postschool {
  display: flex;
}
@media all and (max-width: 600px) {
  .evidence-container .evidence-mapping-container .mapping-body .mapping-answer.postschool {
    flex-direction: column;
  }
}
.evidence-container .evidence-mapping-container .mapping-body .mapping-answer.postschool .body-wrap {
  margin-left: 20px;
}
@media all and (max-width: 600px) {
  .evidence-container .evidence-mapping-container .mapping-body .mapping-answer.postschool .body-wrap {
    margin-left: 0;
    padding: 0 20px;
  }
}
@media all and (max-width: 600px) {
  .evidence-container .evidence-mapping-container .mapping-body .mapping-answer.postschool .image-wrap {
    display: none;
  }
}
.evidence-container .evidence-mapping-container .mapping-body .mapping-answer.postschool img {
  width: 100px;
  margin: 20px;
}

.update-whyareyouhere-form h1 {
  text-align: center;
}
.update-whyareyouhere-form .whyareyouhere-block {
  padding: 30px 0;
}
.update-whyareyouhere-form .whyareyouhere-block .js-form-type-checkbox {
  justify-content: flex-start;
  text-align: left;
}
.update-whyareyouhere-form .whyareyouhere-block .js-form-type-checkbox label {
  display: block !important;
  padding-left: 0;
  font-weight: normal;
}
.update-whyareyouhere-form .form-info-copy {
  text-align: center;
}
.update-whyareyouhere-form .content-info {
  display: none;
  padding: 15px;
  text-align: center;
  margin-bottom: 0;
}
.update-whyareyouhere-form .content-info.visible {
  display: block;
}
.update-whyareyouhere-form .message-block {
  max-width: 390px;
}
.update-whyareyouhere-form .form-actions {
  text-align: center;
  margin-bottom: 30px;
}
.update-whyareyouhere-form .form-actions .butt-general {
  margin: 0;
}

@media all and (max-width: 640px) {
  .sending-invites-form {
    width: 100vw;
    padding: 0 20px;
  }
}
.sending-invites-form #team-member-mail-fieldset-wrapper,
.sending-invites-form #young-person-mail-fieldset-wrapper {
  max-width: 600px;
  margin-bottom: 60px;
  text-align: center;
}
.sending-invites-form #edit-team-member-mail-fieldset,
.sending-invites-form #edit-young-person-mail-fieldset,
.sending-invites-form [data-drupal-selector=edit-young-person-mail-fieldset],
.sending-invites-form [data-drupal-selector=edit-team-member-mail-fieldset] {
  margin: 0;
  padding: 0;
  border: none;
}
.sending-invites-form .js-form-submit {
  background: #7b68c8;
  color: #ffffff;
  margin-top: 20px;
}
.sending-invites-form .js-form-submit .butt-icon {
  background: #ffffff url(../images/icons/icon-arrow-next-purple.svg);
  background-position: 58% 50%;
  background-repeat: no-repeat;
}
.sending-invites-form .js-form-submit#destructive {
  background: #dc3830;
}
.sending-invites-form .js-form-submit#destructive .butt-icon {
  background: #dc3830 url(../images/icons/icon-close-light.svg);
  background-position: 58% 50%;
  background-repeat: no-repeat;
}
.sending-invites-form .js-form-submit:focus {
  outline: 1px auto -webkit-focus-ring-color !important;
}
.sending-invites-form .inner-fieldset {
  background: #ffffff;
  margin: 40px 0;
  border-radius: 50px;
  padding: 20px 40px;
  box-shadow: 2px 2px 40px 1px rgba(36, 58, 71, 0.25);
}
@media all and (max-width: 640px) {
  .sending-invites-form .inner-fieldset {
    padding: 20px;
  }
}
.sending-invites-form .inner-fieldset .member-details {
  text-align: center;
  margin-bottom: 30px;
}
.sending-invites-form .inner-fieldset .member-details .member-name {
  font-weight: bold;
}
.sending-invites-form .inner-fieldset .member-details.pending {
  font-style: italic;
}
.sending-invites-form .inner-fieldset .member-email-wrap {
  display: flex;
  align-items: flex-end;
}
.sending-invites-form .inner-fieldset .member-email-wrap .member-icon {
  height: 50px;
  width: 50px;
  min-width: 50px;
  border-radius: 50px;
  margin-right: 10px;
  color: #ffffff;
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: 600;
  font-size: 1.1rem;
}
.sending-invites-form .inner-fieldset .member-email-wrap .member-icon.pending {
  background: none;
  border: 1px solid #ebebeb;
}
.sending-invites-form .inner-fieldset .form-item {
  margin: 0;
}
.sending-invites-form .inner-fieldset:nth-child(1) .member-icon {
  background: #86CC88;
}
.sending-invites-form .inner-fieldset:nth-child(2) .member-icon {
  background: #59A3D0;
}
.sending-invites-form .inner-fieldset:nth-child(3) .member-icon {
  background: #EE9A95;
}
.sending-invites-form #young-person-mail-fieldset-wrapper .fieldset-wrapper {
  margin-top: 50px;
}
.sending-invites-form #young-person-mail-fieldset-wrapper .fieldset-wrapper .js-form-submit {
  background: #ffffff;
  color: #337ca5;
}
.sending-invites-form #young-person-mail-fieldset-wrapper .fieldset-wrapper .js-form-submit .butt-icon {
  background: #337ca5 url(../images/icons/icon-arrow-next.svg);
  background-position: 58% 50%;
  background-repeat: no-repeat;
}
.sending-invites-form #young-person-mail-fieldset-wrapper .fieldset-wrapper .js-form-submit#destructive {
  background: #ea8782;
}
.sending-invites-form .message-block.no-members {
  margin-top: 50px;
}
.sending-invites-form .consent-disclaimer {
  font-size: 0.75rem;
  padding: 20px;
}
.sending-invites-form .all-invites-wrap {
  display: flex;
  flex-direction: column-reverse;
}
.sending-invites-form .all-invites-wrap .inner-fieldset {
  margin-top: 0;
}
.sending-invites-form .invite-members {
  margin-top: 50px;
}
.sending-invites-form .invite-members .js-form-type-checkbox {
  justify-content: center;
}
.sending-invites-form .invite-members .js-form-type-checkbox label {
  display: inline-block !important;
  padding: 0;
}