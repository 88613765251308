.supportlayout {
	flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    width: 1200px;
	position: relative;
	padding-bottom: 100px;

    @media all and (max-width: 1300px) {
        width: 100%;
        padding: 0 30px;
    }

    @media all and (max-width: 768px) {
		flex-direction: column;
		align-items: center;
		max-width: 100%;
        padding: 0 20px;
	}

	.back-button-wrap {
		//position: absolute;
		//bottom: 0;
		padding-bottom: 0;
    	margin-bottom: 20px;
	}

	.row {
		text-align: center;
		margin-top: 20px;
	}

	.butt-remove-meeting {
		margin: 0 auto;
		background: $bgColourThree;
		padding: 0 15px;
		color: white;
		margin-top: 40px;
	}

	.supportlayout-left {
		flex: 1;
		max-width: 300px;

		.header-wrap {
            display: flex;
            align-items: center;

            .butt-help {
                width: 25px;
                height: 25px;
                background: white;
                border-radius: 30px;
                display: flex;
                justify-content: center;
                align-items: center;
                font-weight: bold;
                color: $fontColourDark;
                margin-left: 10px;
                text-decoration: none;
            }
        }
	}

	.supportlayout-center {
		flex: 1;
		width: 500px;
		max-width: 500px;
		margin: 65px 40px 0;
		display: flex;
		flex-direction: column;

		.side-blob {
			margin-right: 0;	
		}

		label {
			&.right {
				color: $bgColourThree;
			}
		}

		.form-item-relationship {
			height: 0;

			&.give-height {
				height: 50px;
			}
		}

		.invite-wrap {
			display: flex;

      form > .js-form-item:first-of-type {
        margin-left: 60px;
        margin-top: 5px;
      }
      .teammember-email {
        padding-right: 110px;
      }

      form > .form-item-name:first-child {
        margin-bottom: 0;
      }

      .existing-team-member-name {
        border: none;
        background: none;
        border-radius: 0;
        font-weight: bold;
        font-size: 1.5em;
        margin-bottom: 0;
        height: auto;
        padding-left: 10px;
        padding-right: 0 !important;
        color: black
      }
      .existing-team-member-email {
        border: none;
        border-radius: 0;
        background: none;
        padding-left: 5px;
        padding-right: 0;
        margin-left: 65px;
        height: auto;
        color: black;
      }
      .pending-team-member-email {
        border: none;
        border-radius: 0;
        background: none;
        padding-left: 5px;
        padding-right: 0px;
        height: 50px;
        color: black;
        font-size: 16px;
        font-weight: bold;
      }

		}

		&.no-note {
			.note-copy {
				display: none;
			}
		}

		.note-copy {
			text-align: center;
			padding-top: 20px;
		}

		input, textarea {
			border: 1px solid $bgColourSix;

			&:focus, &:active {
				background: $bgColourFive;
				//border: none;
			}

			&[type="submit"] {
				border: none;
				background: $bgColourThree;
				height: 42px;
				margin: 0;
				justify-content: center;
				padding: 0;
				float: right;
				color: white;
				width: 175px;
			}

		}

    select {
      border: 1px solid $bgColourSix;
      margin-left: 60px;
      width: 360px;
      padding-left: 20px;
      padding-right: 20px;
      font-size: $font16;
      appearance: none;
      color: black;
      -moz-appearance: none;
      -webkit-appearance: none;
      overflow: hidden;
      position: relative;
      background: url("../images/icons/icon-down-purple.svg")  95% no-repeat;
    }

		.form-actions {

			input:first-child {
				margin: 0 0 10px 10px;
			}
		}

		img {
			width: 100%;
		}

		.team-circle-icon {
			width: 50px;
			height: 50px;
			border-radius: 50px;
			color: white;
			display: flex;
			justify-content: center;
			align-items: center;
			min-width: 50px;
			font-weight: bold;
      font-size: 1.2em;
      position: absolute;
      margin-top: 5px;

		}

		&[data-state="member1"] {
			.team-circle-icon { background: $bgColourEight; }
		}

		&[data-state="member2"] {
			.team-circle-icon { background: #EE9A95; }
		}

		&[data-state="member3"] {
			.team-circle-icon { background: #F3C762; }
		}

		&[data-state="member4"] {
			.team-circle-icon { background: #86CC88; }
		}

	}

	.supportlayout-right {
		flex: 1;
		max-width: 200px;
	}

	.team-members-wrap {
		margin-top: 30px;

		.butt-add-member {
			width: 100%;
			height: 64px;
			margin: 0;
			padding: 0 10px;
			margin-bottom: 20px;
			background: rgba(255,255,255,0.5);

			.title {
				text-align: left;
				padding-left: 10px;
				font-size: 1.2em;
				text-transform: none;
				font-weight: normal;
				color: $fontColourDark;
			}

			.butt-icon {
				width: 34px;
				height: 34px;
				display: flex;
				justify-content: center;
				align-items: center;
				font-weight: normal;
				font-size: $font18;
				font-weight: bold;
			}
		}
	}

	.supportlayout-center {

		&.side-blob {

			@media all and (max-width: 767px) {
				padding: 20px;
			}

		}

		h2 {
			font-size: $font16;
			margin-bottom: 10px;
		}

		.add-meeting-step {
			margin-top: 20px;
			display: none;

			&.visible {
				display: block;
			}

			label {
				font-size: $font16;
			}

			.no-next-message-involved {
				width: 100%;
				text-align: center;
				padding: 10px 20px;
				background: #eee;
				border-radius: 30px;
				font-size: .8em;
				margin-top: 30px;
				margin-bottom: 30px;
			}

			.options-wrap {
				margin-top: 30px;

				.columns {
					.options {
						width: 48%;

						@media all and (max-width: 600px) {
							width: 100%;
						}
					}
				}
			}

			.columns {
				display: flex;
				justify-content: space-between;

				@media all and (max-width: 600px) {
					flex-direction: column;
				}

				&.with-padding {
					padding-top: 20px;

					.options {
						width: 48%;

						@media all and (max-width: 600px) {
							width: 100%;
						}
					}

					.time-address {
						align-items: flex-start;
						margin-top: 0;
					}
				}

				.left, .right {
					flex: 1;

					label {
						height: 40px;
						display: block;
						font-size: $font16;
					}
				}

				.left {
					margin-right: 20px;
					
					@media all and (max-width: 600px) {
						margin: 0;
						margin-bottom: 30px;
					}
				}

				.right {
					margin-left: 20px;

					@media all and (max-width: 600px) {
						margin: 0;
					}
				}

				.time-address {
					flex-direction: column;
				}
			}

			textarea {
				border-radius: 10px;
				background: $bgColourFive;
				margin-left: 20%;
				height: 100px;
				width: calc(100% - 50px);
				padding: 10px;
				font-size: 0.9em;
				color: $bgColourFour;
				margin-left: 50px;
				font-family: 'Open Sans', sans-serif;
				color: $fontColourDark;
				outline: none;
			}

			.time-address {
				display: flex;
				flex-direction: column;
				justify-content: flex-end;
				margin-top: 10px;
				align-items: flex-end;

				.fields {
					display: flex;
					flex-direction: column;
					width: 100%;
					justify-content: space-between;

					&.buttons {
						flex-direction: row;
					}
				}

				.butt-general {
					margin-left: 0;
				}

				.time-address-fields {
					display: flex;
					justify-content: flex-start;
					align-items: center;
					padding: 5px;
					width: 100%;


					.time-address-label {
						color: $bgColourThree;
					}

					.time-address-icon {
						width: 34px;
						min-width: 34px;
						height: 34px;
						// border: 2px solid $bgColourThree;
						border-radius: 40px;
						margin-right: 10px;

						&.time {
							background-image: url(../images/icons/icon-clock-purple.svg);
							background-size: 100%;
						}

						&.address {
							background-image: url(../images/icons/icon-location-purple.svg);
							background-size: 100%;
						}

						&.calendar {
							background-image: url(../images/icons/icon-addcal-purple.svg);
							background-size: 100%;
							border: 1px solid $bgColourFour;
						}
					}

					input {
						background: $bgColourFive;
						height: 42px;
						border: none;
						outline: none;
						border-radius: 42px;
						font-size: 0.9em;
						font-family: 'Open Sans', sans-serif;
						color: $bgColourFour;
						padding-left: 10px;
						margin-right: 10px;
						transition: all 0.2s;
						width: 100%;

						@media all and (max-width: 600px) {
							margin-right: 0;
						}

						&.invalid {
							border: 1px solid crimson;
						}

						&::placeholder {
							color: grey;
						}
					}

				}
			}

			.involvement-option,
			.involvement-option-disabled,
			.assistance-option,
			.assistance-option-disabled {
				display: flex;
				align-items: center;
				margin: 0 0 15px;
				font-size: 0.9em;
				min-height: 57px;

				.option-icon {
					width: 44px;
					height: 44px;
					// border: 2px solid #F9D87C;
					background: transparent;
					border-radius: 50px;
					margin-right: 10px;
					min-width: 44px;

					&.on-my-own {
						background-image: url(../images/icons/icon-on-my-own.svg);
						background-size: 100%;
					}

					&.with-someone-else {
						background-image: url(../images/icons/icon-group.svg);
						background-size: 100%;
					}

					&.some-talking {
						background-image: url(../images/icons/icon-talk-myself.svg);
						background-size: 100%;
					}

					&.speak-for-me {
						background-image: url(../images/icons/icon-group-talking.svg);
						background-size: 100%;
					}

					&.just-listening {
						background-image: url(../images/icons/icon-hear.svg);
						background-size: 100%;
					}

				}

				&.selected {
					.on-my-own {
						background-image: url(../images/icons/icon-on-my-ow-selected.svg);
						background-size: 100%;
					}

					.with-someone-else {
						background-image: url(../images/icons/icon-group-selected.svg);
						background-size: 100%;
					}

					.some-talking {
						background-image: url(../images/icons/icon-group-talking-selected.svg);
						background-size: 100%;
					}

					.speak-for-me {
						background-image: url(../images/icons/icon-speakforme-selected.svg);
						background-size: 100%;
					}

					.just-listening {
						background-image: url(../images/icons/icon-hear-selected.svg);
						background-size: 100%;
					}
				}

				.title {
					p {
						margin: 0;
					}
				}

			}

			.assistance-option,
			.assistance-option-disabled {
				.option-icon {
					border: 2px solid $fontColourPurple;
				}

				&.selected {
					.option-icon {
						background-image: url(../images/icons/icon-complete.svg);
						background-size: 100%;
					}
				}
			}

			.involvement-option-disabled {
				display: none;

				&.selected {
					display: flex;
				}
			}

			.butt-meeting-next,
			.butt-meeting-confirm {
				background: $bgColourThree;
				color: white;
				flex-direction: row-reverse;
				padding: 0 4px 0 15px;

				.butt-icon {
					background-color: white;
				}
			}

			.butt-meeting-back {
				background: transparent;
				color: $bgColourThree;
				border: 1px solid $bgColourThree;

				.butt-icon {
					background-color: $bgColourThree;
				}
			}

			.butt-meeting-next,
			.butt-meeting-back,
			.butt-meeting-confirm {

				@media all and (max-width: 600px) {
					width: 120px;
					//width: 42px;
					//padding: 5px;

					.butt-icon {
						min-width: 32px;
					}

					.title {
						//display: none;
					}
				}

			}

			#meeting-date {
				margin-top: 15px;
				margin-bottom: 30px;

				@media all and (max-width: 600px) {
					width: fit-content;
					margin: 15px auto 30px;
				}

				.ui-datepicker-inline {
					width: 265px;

					@media screen and (max-width: 1200px) {
						width: 230px;
					}
				}
			}

			.ui-datepicker {
				height: 100%;
				border-radius: 25px;
			}

			.ui-widget-header {
				border: 0px;
				background-color: transparent;
			}

			.ui-widget.ui-widget-content {
				border: 1px solid lightgrey;
			}

			.ui-datepicker th {
				text-transform: uppercase;
				font-size: 8pt;
				font-weight: normal;
				color: $fontColourPurple;
				/*text-shadow: 1px 0px 0px #fff;*/
				/*filter: dropshadow(color=#fff, offx=1, offy=0);*/
			}

			.ui-datepicker tbody {
				border-top: 1px $fontColourPurple solid;
				border-bottom: 1px $fontColourPurple solid;
			}

			.ui-datepicker tbody td {
				padding: 2px 0;
				width: 35px;
				height: 35px;

				@media screen and (max-width: 1200px) {
					width: 29px;
					height: 29px;
				}
			}

			.ui-widget-content {
				background: transparent;
				border: 0px;
				text-align: center;
				padding: 10px 10px;
				width: 100%;
			}

			.ui-datepicker-calendar .ui-state-active {
				height: 35px;
				text-align: center;
				line-height: 35px;
				padding: 0;
				border: 0;
				@media screen and (max-width: 1200px) {
					line-height: 29px;
					height: 29px;
				}
			}

			.ui-datepicker-calendar .ui-state-default {
				background: transparent;
				border: 0px;
				padding: 0;
				text-align: center;
				line-height: 35px;
				@media screen and (max-width: 1200px) {
					line-height: 29px;
				}
			}

			.ui-datepicker-calendar .ui-state-hover {
				opacity: 0.6;
			}

			.ui-datepicker-today > a{
				color: $fontColourPurple;
			}

			.ui-datepicker-current-day {
				font-weight: bold;
				color: $fontColourDark;
				// border-radius: 50%;
				// background: $bgColourDarkYellow !important;

				a {
					color: $fontColourLight !important;
					border-radius: 50%;
					background: $fontColourPurple !important;
				}
			}

			.ui-widget-header .ui-icon {
				background-image: url(../images/icons/icon-arrow-next-black.svg);
				background-size: 35%;
				background-position: 58% 50%;
			}

			.ui-datepicker .ui-datepicker-prev {
				transform: rotate(180deg);
			}

		}

	}

	& [data-state="initial"],
	& [data-state="member1"],
	& [data-state="member2"],
	& [data-state="member3"],
	& [data-state="member4"],
	& [data-state="support-network-add-meeting-1"] {
		display:none;
	}

	&[data-state="initial"] [data-state="initial"],
	&[data-state="member1"] [data-state="member1"],
	&[data-state="member2"] [data-state="member2"],
	&[data-state="member3"] [data-state="member3"],
	&[data-state="member4"] [data-state="member4"],
	&[data-state="support-network-add-meeting-1"] [data-state="support-network-add-meeting-1"]  {
		display:flex;
	}

	&[data-state="support-network-add-meeting-1"] [data-state="support-network-add-meeting-1"]  {
		flex-direction: column;
		max-width: 1000px;
		width: 100%;
		margin: 100px auto 50px;

		h1 {
			margin: 0;
		}

		@media all and (max-width: 768px) {
			width: 100%;
			max-width: auto;
			margin: 50px auto;
		}
	}

	// Fields that should display only if a user lookup has occurred
	& [data-showwhen="userrequested"] {
		display:none;
	}
	&[data-userrequested="true"] [data-showwhen="userrequested"] {
		display:block;
	}
}
