.dialog-off-canvas-main-canvas {
    min-height: 100vh;
}

// Registration form
#edit-user-picture-0--label,
#edit-field-avatar-0--label,
#edit-field-avatar-0--description,
#edit-user-picture-0--description,
#edit-name--description,
#edit-mail--description,
div.password-confirm,
.form-item-user-picture-0 .description {
    display: none;
}

.form-item-field-terms-privacy-value, .form-item-field-marketing-opt-in-value, .js-form-type-checkbox {
    display: flex;
    // justify-content: space-between;
    align-items: center;
    font-size: 0.9em;
    line-height: 1.5em;

    // Disable hiding of the checkbox label (want to show entity_legal's checkbox label)
    // label {
    //     display: none!important;
    // }

    #edit-field-marketing-opt-in-value--description {
        flex: 1;
        text-align: left;
    }
}

.form-item-field-terms-privacy-value,
.field-goal-task-values .js-form-type-checkbox {
    label {
        display: block!important;
        position: absolute!important;
        top: -25px;
        opacity: 0;
    }
}

.butt-update > input[type=submit] {
    height: $buttHeight;
    width: auto;
    border-radius: $buttHeight;
    background: transparent;
    border: none;
    outline: none; // TODO: accessibility
    font-family: 'Open Sans', sans-serif;
    font-size: 0.8rem;
    font-weight: 600;
    text-transform: uppercase;
    color: $lightColour;
    padding: 0 15px 0 4px;
    display: inline-flex;
    justify-content: flex-start;
    align-items: center;
    transition: all 0.2s;
    position: relative;
    overflow: hidden;
    margin-left: 20px;
    -webkit-appearance: none;

    &:hover {
        cursor: pointer;
        animation-duration: 0.4s;
        animation-fill-mode: both;
        animation-name: pulse;

        .animation-bubble {
            opacity: 0.2;
            transform: scale(20, 20);
            transition: transform 0.6s ease-out;
        }
    }
}

input[type=submit] {
    height: $buttHeight;
    width: 180px;
    border-radius: $buttHeight;
    background: $lightColour;
    border: none;
    outline: none; // TODO: accessibility
    font-family: 'Open Sans', sans-serif;
    font-size: 0.8em;
    font-weight: 600;
    text-transform: uppercase;
    color: $buttColour;
    padding: 0 15px 0 4px;
    display: inline-flex;
    justify-content: flex-start;
    align-items: center;
    transition: all 0.2s;
    position: relative;
    overflow: hidden;
    margin-left: 20px;
    -webkit-appearance: none;

    &:hover {
        cursor: pointer;
        animation-duration: 0.4s;
        animation-fill-mode: both;
        animation-name: pulse;

        .animation-bubble {
            opacity: 0.2;
            transform: scale(20, 20);
            transition: transform 0.6s ease-out;
        }
    }
}

    .form-item.js-form-item-field-notify-team-members-value {
        label {
            position: relative;
            pointer-events: none;
            margin: 0;
            display: flex;
            justify-content: flex-start !important;
            align-items: center;
            padding: 0;
            transition: all 0.3s;
            width: 200px;
            font-size: $font16;
            color: $fontColourDark;
    }
}


.form-item {
    position: relative;
    margin: 0 0 20px 0;

    input[type=text],
    input[type=email],
    input[type=password],
    input[type=number],
    input[type=date],
    select {
        appearance: none; // Reset the default appearance of the input.
        height: 50px;
        border-radius: 50px;
        border: none;
        background-color: rgba(255,255,255, 0.45);
        outline: none; // TODO: accessibility
        width: 100%;
        padding: 0 40px 0 20px;
        font-size: $font16;
        font-family: 'Open Sans', sans-serif;
        // Label has been removed from the right side of the input, so this is no longer needed.
        //padding-right: 150px;
        transition: all 0.2s;
        color: $fontColourDark;

        &:focus {
            background-color: rgba(255,255,255, 1);
            // TODO: accessibility
        }

        &.error {
            border: 1px solid #ea8782;
        }
    }


    // Select dropdown arrow
    // See https://moderncss.dev/custom-select-styles-with-pure-css/
    // Plus 'pointer-events: none;' so that the dropdown arrow is clicakble.
    .select {
        display: grid; // Use css grid for positioning the dropdown arrow
        grid-template-areas: "select";
        align-items: center;
    }
    .select::after {
        content: "";
        width: 0.8em;
        height: 0.5em;
        background-color: $fontColourDark;
        clip-path: polygon(100% 0%, 0 0%, 50% 100%);
        justify-self: end;
        margin-right: 17px;
        pointer-events: none; // Make the dropdown arrow clicakble
    }
    select,
    .select:after {
        grid-area: select;
    }

    #edit-mail, #edit-name {
        // padding-right: 80px;
    }

    input[type=checkbox] {
        width: 40px!important;
        height: 40px;
        border-radius: 10px;
        -webkit-appearance: none;
        background: white;
        padding: 20px;
        margin: 0 20px 0 0;
        outline: none; // TODO: accessibility
        border: 1px solid $bgColourEight;

        &:hover {
            cursor: pointer;
            animation-duration: 0.4s;
            animation-fill-mode: both;
            animation-name: pulseBig;
            background: white url(../images/icons/icon-tick.svg) center center no-repeat;
            background-size: 50%;
        }

        &:checked {
            background: white url(../images/icons/icon-tick.svg) center center no-repeat;
            background-size: 50%;

        }

        &:not(:checked) {
            background: white center center no-repeat;
            background-size: 50%;
        }

        &:disabled {
            opacity: 0.5;
        }

        &:focus {
            outline: 1px solid $bgColourEight!important;
            outline-offset: 3px;
        }
    }

    input[type=password] {
        padding-right: 50px;
    }

    &.js-form-type-select {
        // Border radius has been moved to the select element, so this is no longer needed.
        //height: 50px;
        //border-radius: 50px;
        //border: none;
        // background-color: rgba(255,255,255, 0.45);
        outline: none; // TODO: accessibility
        width: 100%;

        select {
            // background-color: transparent;
            // width: 55%;
            // margin-left: 15px;
            // padding: 0;
            // cursor: pointer;
            // -moz-appearance: none;

            &.not-visible {
                opacity: 0;
            }

            @media all and (max-width: 1300px) {
                // width: 40%;
            }
        }
    }

    &.js-form-type-date {
        /*height: 50px;
        border-radius: 50px;
        border: none;
        background-color: rgba(255,255,255, 0.45);
        outline: none; // TODO: accessibility
        width: 100%;*/

        input {
            &.not-visible {
                opacity: 0;
            }
        }

    }

    &.js-form-type-textarea {

        width: 100%;

        textarea {
            border: none;
            background-color: rgba(255,255,255, 1);
            resize: none;
            padding: 20px;
            border-radius: 25px;
            font-size: $font18;
            font-family: 'Open Sans', sans-serif;
            font-weight: bold;
            width: 100%;
        }
    }

    label {
        // position: absolute;
        pointer-events: none;
        // top: 0;
        // bottom: 0;
        // left: 20px;
        margin: 0 0 5px 0;
        display: flex;
        justify-content: flex-start;
        align-items: center;
        padding: 0 0 0 20px;
        transition: all 0.3s;
        //width: 160px;
        font-size: $font14;
        font-weight: bold;
        color: $fontColourDark;
        max-height: 50px;

        &.right {
            justify-content: flex-start;
            //font-size: 0.8em;
            color: $fontColourDark;

            // Do not force right.
            // To-do: find out how .right is being added.
           // left: calc(100% - 170px);
        }

        &.form-required {

            &:after {
                content: "*";
                color: $bgColourThree;
                padding-left: 4px;
            }

        }
    }

    .eye-close, .eye-open {
        width:20px !important;
        position: absolute;
        bottom: 17px;
        right: 15px;
    }
}

.custom-field {
    .field--type-datetime {
        position: relative;

        h4 {
            position: relative;
            pointer-events: none;
            top: 0;
            bottom: 0;
            left: 20px;
            margin: 0 0 5px;
            display: flex;
            justify-content: flex-start;
            align-items: center;
            padding: 0;
            transition: all 0.3s;
            width: 150px;
            font-size: $font14;
            font-weight: bold;
            color: $fontColourDark;
            z-index: 1;

            &:after {
                content: "*";
                color: $bgColourThree;
                padding-left: 4px;
            }

            &.right {
                justify-content: flex-end;
                font-size: 0.8em;
                color: $fontColourDark;
                left: calc(100% - 170px);
            }
        }
    }
}


div[role=contentinfo] {
    width: 100%;
    margin: 15px auto;
    text-align: left;
    //margin-bottom: 40px;
    background: rgba(51, 56, 85, 0.3);
    min-height: 50px;
    border-radius: 50px;
    color: $fontColourLight;
    font-size: 0.8em;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0 20px;

    a {
        margin: 0 15px;
        color: white;
    }

    @media all and (max-width: 800px) {
        width: calc(100% - 40px);
    }

    //display:none;
}

.sitemap {
    background: #fff;
    border-radius: 20px;
    padding: 20px 40px 0;

    h1 {
        margin: 0 0 50px;
    }

    h2 {
        color: $fontColourDark;
        font-size: 1.3em;
    }

    .content {
        margin-bottom: 40px;
    }

}

#block-mywayemployability-footer, #block-mobilefootermenu {
    display: none;
}

#block-styleswitcher {
    h2 {
        display: none;
    }
}

.js {
    input {
        &.form-autocomplete {
            background-position: 96%;

            &.ui-autocomplete-loading {
                background-position: 96%;
            }

        }
    }
}

.message-block {
    border-radius: 10px;
    padding: 10px;
    font-size: $font12;
    margin-bottom: 40px;
    background: rgba(51, 56, 85, 0.3);
    min-height: 50px;
    border-radius: 25px;
    color: $fontColourLight;
    padding: 20px;

    ul {
        li {
            margin-left: -15px;
        }
    }

    &.light {
        background: $lightColour;
        color: $fontColourDark;
    }
}

.colour-form {
    .form-item {
        input[type=text],
        input[type=email],
        input[type=password],
        input[type=number],
        input[type=date],
        select {
            background-color: $bgColourOne;

            &:focus {
                background-color: $bgColourTwo;
            }

        }

        &.js-form-type-textarea {
            textarea {
                background-color: $bgColourOne;
            }
        }
    }
}

