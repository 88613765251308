header {
    height: $headerHeight;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    max-width: 1200px;
    margin: 0 auto;

    @media all and (max-width: 1300px) {
        max-width: 100vw;
        padding: 0 30px;
    }

    @media all and (max-width: 800px) {
        padding: 0 20px;
        height: 70px;
    }

    @media all and (max-width: 768px) {
        position: fixed;
        width: 100vw;
        background: #7ebada;
        z-index: 1;
    }

    .site-logo {
        max-width: 150px;
        margin-right: 40px;
        min-width: 70px;
        flex: 1;
        transition: all 0.2s;

        &:hover {
            cursor: pointer;
            animation-duration: 1s;
            animation-fill-mode: both;
            animation-name: bouncey;
        }

        @media all and (max-width: 767px) {
            max-width: 100px;
            margin-right: 10px;
        }

        @media all and (max-width: 768px) {
            &.logged-in {
                display: none;
            }
        }

        

    }

    .dashboard-link {
        display: none;
        padding-left: 25px;
        background: $lightColour url('./../images/icons/icon-dashboard.svg') 5px no-repeat;
        background-size: 25px;
        padding: 7px 10px 7px 35px;
        height: 40px;
        border-radius: 40px;

        a {
            text-decoration: none;
            color: #343853;
            text-transform: uppercase;
            font-weight: bold;
            font-size: $font12;
            display: inline-block;

        }

        @media all and (max-width: 768px) {
            display: block;
        }
    }

    .header-links {
        flex: 1;
        //padding: 0 40px;

        .link {
            padding: 0;
            margin: 0 15px;
            display: inline-block;
            position: relative;
            text-align: center;

            @media all and (max-width: 1300px) {
                margin: 0 10px;
            }

            @media all and (max-width: 768px) {
                margin: 0 !important;
            }

            a {
                text-decoration: none;
                font-size: 0.8em;
                text-transform: uppercase;
                font-weight: 600;
                color: $fontColourDark;
                transition: all 0.2s ease-in-out;
                transform: scale(1);
                padding: 0 15px;

                @media all and (max-width: 768px) {
                    padding: 0 10px;
                }
            }

            .underline {
                position: absolute;
                width: 100%;
                height: 2px;
                background: $lightColour;
                top: calc(100% + 2px);
                /* transform: translateX(-50%); */
                left: 0;
                right: 0;
                margin: auto;
                border-radius: 10px;
                transition: all 0.3s ease-in-out;
                transform: scaleX(0);
            }

            &:hover {

                a {
                    color: $lightColour;
                    transform: scale(1.04);
                    transition: all 0.2s ease-in-out;
                }

                .underline {
                    transform: scaleX(1);
                    transition: all 0.3s ease-in-out;
                }

            }

        }

        &.desktop {

            @media all and (max-width: 767px) {
                display: none!important;
            }

        }

        &.mobile {
            display: flex;
            background: $bgColourTwo;
            width: 70vw;
            height: 100svh;
            position: fixed;
            z-index: 1;
            right: calc(-70vw + -40px);
            top: 0;
            box-shadow: 2px 2px 40px 1px rgba(36,58,71,0.25);
            transition: all 0.3s;
            flex-direction: column;
            align-items: flex-end;
            padding: 20px;
            @include background-gradient-reverse();
            background-image: url(../images/bg-wave.svg), linear-gradient(45deg, rgba(192, 229, 247, 1) 0%, rgba(93, 165, 205, 1) 100%);
            background-size: 500%;
            background-repeat: no-repeat;
            background-position: left bottom;

            #close-overlay {
                opacity: 0;
                background: rgba(255,255,255,0.1);
                bottom: 0;
                width: 30vw;
                left: 0;
                margin: auto;
                position: absolute;
                right: 0;
                top: 0;
                z-index: 0;
                transition: all 0.3s;
            }
            

            .butt-close {
                -webkit-appearance: none;
                border: none;
                width: 40px;
                height: 40px;
                border-radius: 50px;
                background: $lightColour url('../images/icons/icon-close.svg') center center no-repeat;
                background-size: 50%;
            }

            .navigation-links {
                flex: 1;
                display: flex;
                flex-direction: column;
                align-items: flex-end;
                padding: 40px 0;

                .link {
                    height: 40px;

                    a {
                        padding: 0;
                    }

                    .underline {
                        top: 25px;
                    }

                }
                
            }

            .login-buttons {
                display: flex;
                flex-direction: column;
                align-items: flex-end;
                width: 100%;

                .butt-general {
                    margin-left: 0;
                    width: 100%;

                    &:first-child {
                        margin-bottom: 10px;
                    }

                    &.butt-small {
                        .title {
                            display: block;
                        }
                    }
                }
            }

            &.show {
                transform: translateX(calc(-70vw + -40px));

                #close-overlay {
                    transform: translateX(-50vw);
                }
            }


        }

    }

    .button-wrap {

        :first-child {
            margin-right: 10px;
        }

        @media all and (max-width: 767px) {
            display: none!important;
        }

        @media all and (max-width: 985px) and (min-width: 768px) {
            .butt-general {
                width: 130px;

                &.butt-small {
                    width: 44px;
                }

                &.butt-logout {
                    width: 44px;

                    .butt-icon {
                        min-width: 32px;
                    }

                    .title {
                        display: none;
                    }
                }
            }

        }

    }

    .button-menu-wrap {
        display: none;

        .butt-menu {
            -webkit-appearance: none;
            border: none;
            width: 40px;
            height: 40px;
            border-radius: 50px;
            background: $lightColour url('../images/icons/icon-menu.svg') center center no-repeat;
            background-size: 50%;
        }
        @media all and (max-width: 767px) {
            display: block!important;
        }

    }

    @media all and (max-width: 985px) and (min-width: 768px) {
        .site-logo {
            max-width: 110px;
            margin-right: 10px;
        }

    }
}
