.dashboard-wrap {

    .goals-list-column {
        .goal-wrapper {

            &.active {

                .butt-goal {

                    .title {
                        padding-left: 0;
                    }
                }

            }

            .form-item {
                margin: 0;

                input {
                    background: transparent;
                    padding: 0 20px 0 10px;
                }

                label {
                    display: none;
                }

                &.js-form-type-date {
                    height: auto;
                }

            }

            .field--type-datetime {
                h4 {
                    display: none;
                }
            }


        }

        .js-form-item-field-assigned-to {
            height: 100%;
            width: 100%;
            background: transparent;
            color: transparent;

            #edit-field-assigned-to {
                width: 100%;
                height: 100%;
                margin: 0;
                color: transparent;
            }
        }



        input[type="date"] {
            width: 34px;
            height: 34px;
            position: relative;
            background-color: hsla(0,0%,100%,.45)!important;
            border: none;
            border-radius: 40px;
            color: transparent;
            background-image: url(../images/icons/icon-addcal-purple.svg)!important;
            background-size: 100%;
            outline: none;

            &::-webkit-inner-spin-button,
            &::-webkit-calendar-picker-indicator {
                position: absolute;
                width: 34px;
                height: 34px;
                right: 0;
                -webkit-appearance: none;
                background: transparent;
            }

            &::-webkit-datetime-edit-year-field:not([aria-valuenow]),
            &::-webkit-datetime-edit-month-field:not([aria-valuenow]),
            &::-webkit-datetime-edit-day-field:not([aria-valuenow]) {
                color: transparent;
            }

            &:focus, &:active {
                background-color: hsla(0,0%,100%,.45);
                border: none;
                border-radius: 40px;
                color: transparent!important;
                background-image: url(../images/icons/icon-addcal-purple.svg);
                background-size: 100%;
                outline: none;
            }

            &:focus::-webkit-datetime-edit {
                color: transparent !important;
            }
        }
    }

    .task-list-column {
        margin-left: 30px;

        .feedback-wrapper {
            display: none;
        }

        &.completed {
            background-image: url(../images/confetti.svg);
            background-position: top center;
            background-repeat: no-repeat;

            .feedback-wrapper {
                display: block;
            }

            .tasks-wrapper {
                display: none;
            }
        }

        #edit-actions {
            text-align: center;
            margin-top: 20px;

            input {
                background: $bgColourThree;
                margin: 20px auto 0 !important;
                text-align: center;
                justify-content: center;
                color: white;
                padding: 0;
            }

            @media all and (min-width: 1211px) {
                display: flex;
                flex-direction: row-reverse;
            }


        }

        .feedback-wrapper {

            label {
                position: relative;
                width: 100%;
                left: 0;
                font-weight: bold;
            }

            textarea {
                width: 100%;
                background: $bgColourFive;
                border: none;
                border-radius: 10px;
                margin-top: 10px;
                outline: none;
                padding: 20px;
                font-size: $font16;
                font-family: 'Open Sans', sans-serif;
                color: $fontColourDark;
            }

            .feel-icons {
                width: 100%;
                display: flex;
                margin: 10px 0;

                .feel-icon {
                    width: 26px;
                    height: 26px;
                    margin: 0 10px 0 0;
                    background-size: contain;
                    cursor: pointer;
                    transition: all 0.2s;
                    opacity: 0.5;

                    &:hover {
                        animation-duration: 0.3s;
                        animation-fill-mode: both;
                        animation-name: pulseBig;
                        opacity: 1;
                    }

                    &.selected {
                        opacity: 1;
                    }

                    &.bad {
                        background: url(../images/icons/icon-bad.svg) center center no-repeat;
                    }

                    &.ok {
                        background: url(../images/icons/icon-ok.svg) center center no-repeat;
                    }

                    &.good {
                        background: url(../images/icons/icon-good.svg) center center no-repeat;
                    }
                }
            }
        }

        .tasks-wrapper {
            .tabledrag-toggle-weight-wrapper,
            .tableresponsive-toggle-columns,
            .field-multiple-drag,
            .field-multiple-table thead {
                display: none;
            }


            .paragraphs-dropbutton-wrapper {
                text-align: center;


                .button {
                    width: 34px;
                    height: 34px;
                    background-color: transparent;
                    margin: 0;
                    border-radius: 50px;
                    color: transparent;
                    border: 2px solid $buttBgColourYellow;
                    background-image: url(../images/icons/icon-goal-remove.svg);
                    background-repeat: no-repeat;
                    background-position: center center;
                }

                .field-add-more-submit {
                    width: 180px;
                    background: $buttBgColourYellow;
                    color: $fontColourDark;
                    padding: 0;
                    justify-content: center;
                    margin: 15px auto 0;
                    display: block;
                }

                .ajax-progress {
                    padding-top: 20px;
                }

            }

            .js-form-item {
                margin-bottom: 0;
            }

            .field-multiple-table {
                margin-bottom: 0;

                .draggable {

                    td > div:first-child {
                        background-color: #FCF7E5;
                        border-radius: 50px;
                        height: 50px;
                        padding: 0 10px;
                        margin-right: 34px;

                        div:first-child {
                            display: flex;
                            flex-direction: row-reverse;
                            justify-content: center;
                            align-items: center;
                            height: 100%;
                        }
                    }

                    .paragraphs-dropbutton-wrapper {
                        display: none;
                    }

                    td {
                        padding: 0 0 7px 0;

                        &:nth-child(2) {
                            display: flex;
                            flex-direction: row-reverse;
                            justify-content: center;
                            align-items: center;
                            height: 100%;
                            position: relative;
                        }

                        .paragraph-type-title {
                            display: none!important;
                        }

                        .paragraph-type-top {
                            height: 34px;
                            padding: 0!important;
                        }

                        .paragraphs-subform {
                            display: flex;
                            flex-direction: row-reverse;
                        }

                        .field--name-field-task-notes {
                            position: absolute;
                            left: 0;
                            //bottom: calc(-95px + 76px);
                            background: #fcf7e5;
                            border-radius: 25px;
                            margin-right: 35px;
                            padding-top: 55px;
                            z-index: 0;
                            display: none;

                            label {
                                display: none;
                            }

                            .js-form-item:first-child {
                                padding: 0 10px 10px 50px;
                            }

                            textarea {
                                width: 100%;
                                border-radius: 10px;
                                border: 1px solid #CFD2D2;
                                outline: none;
                                font-family: 'Open Sans', sans-serif;
                                padding: 10px;
                                //margin: 20px 0;
                            }
                        }

                        .show-notes {
                            margin-bottom: 130px;

                            .field--name-field-task-notes {
                                display: block;
                            }
                        }

                        .field--name-field-assigned-to {
                            position: absolute;
                            right: -6px;
                            width: 34px;
                            height: 34px;
                            top: 10px;
                            bottom: 0;
                            border-radius: 50px;
                            background: $bgColourFive url(../images/icons/icon-team-transparent.svg) center center no-repeat;

                            label {
                                display: none;
                            }

                            select {
                                background: transparent;
                                height: 100%;
                                opacity: 0!important;
                                margin: 0!important;
                                width: 100%!important;
                            }


                            .js-form-type-entity-autocomplete {
                                position: absolute;
                                background: white;
                                box-shadow: 0px 0px 10px 0px rgba(0,0,0,0.41);
                                margin-left: 40px;
                                padding: 0 0 0 10px;
                                visibility: hidden;

                                &.show-users {
                                    visibility: visible;
                                }

                                input {
                                    background: transparent;
                                    padding: 0;
                                    width: 250px;
                                }
                            }
                        }

                        .field--name-field-task-name {
                            background: transparent;
                            height: 50px;
                            margin: 0;
                            z-index: 1;

                            .js-form-item {
                                margin: 0;
                                background: none;

                                input {
                                    background: transparent;
                                    padding: 0 40px 0 10px;
                                    font-size: 0.9em;
                                }

                                label {
                                    display: none;
                                }
                            }
                        }

                        .js-form-type-checkbox {
                            background: none;
                            margin: 0;
                            width: 34px;
                            height: 34px;
                            z-index: 1;

                            input[type=checkbox] {
                                width: 34px!important;
                                height: 34px;
                                padding: 15px;
                                background: transparent;
                                border: 2px solid #f3c762;
                                margin: 0;

                                &:checked {
                                    background: $buttBgColourYellow url(../images/icons/icon-checked.svg) center center no-repeat;
                                    background-size: 50%;
                                }
                            }

                            label {
                                display: none;
                            }
                        }

                        .field--name-field-show-notes {
                            position: absolute;
                            top: 8px;
                            right: 44px;

                            .js-form-type-checkbox {
                                input[type=checkbox] {
                                    background: transparent url(../images/icons/icon-edit.svg) center center no-repeat;

                                    &:checked {
                                        background: transparent url(../images/icons/icon-edit.svg) center center no-repeat;
                                    }
                                }
                            }
                        }

                    }

                    /*.ajax-new-content {
                        padding: 0!important;
                    }*/

                }

            }
        }

        .field--name-field-notify-team-members {
            margin-top: 30px;
            padding-left: 12px;

            .form-item-field-notify-team-members-value {
                // width: 55%;
                display: flex;
            }

            .form-item input[type=checkbox] {
                width: 30px!important;
                height: 30px;
                padding: 15px;
                margin: 0;
            }

            label.right {
                left: 20px;
            }
        }


        .goal-task {
            display: flex;
            align-items: center;
            margin-bottom: 10px;

            .task-wrap {
                height: 50px;
                background: #FCF7E5;
                border-radius: 50px;
                display: flex;
                padding: 0 10px;
                flex: 1;
                margin-right: 5px;
                font-size: 0.9em;
                flex-direction: column;
                min-height: 50px;
                height: auto;
                justify-content: flex-start;
                border-radius: 25px;
                align-items: flex-end;

                .top-line-wrap {
                    width: 100%;
                    display: flex;
                    justify-content: flex-start;
                    align-items: center;
                    padding-top: 10px;
                }

                .notes {
                    min-height: 100px;
                    overflow: hidden;
                    border: 1px solid #CFD2D2;
                    width: 80%;
                    margin: 20px 10px;
                    border-radius: 10px;
                    padding: 10px;
                    background: white;
                }

                .icon-completed {
                    border: 2px solid $buttBgColourYellow;
                    width: 34px;
                    height: 34px;
                    min-width: 34px;
                    border-radius: 34px;
                    margin-right: 10px;

                    &.completed {
                        background: $buttBgColourYellow url(../images/icons/icon-checked.svg) center center no-repeat;
                        background-size: 50%;
                    }
                }
            }

            .assigned-to {
                width: 34px;
                height: 34px;
                border-radius: 34px;
                background: $bgColourEight;
                color: white;
                display: flex;
                justify-content: center;
                align-items: center;
                font-weight: 600;
                font-size: 0.8em;
            }

        }

        .butt-wrap {
            .butt-edit {
                background: $bgColourThree;
                margin: 0 auto;
            }
        }

    }

    .selected-name {
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        margin: auto;
        width: 100%;
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        background: $bgColourEight;
        border-radius: 50px;
        pointer-events: none;
        color: $fontColourLight;
        font-weight: 600;
        font-size: 0.8em;
    }

    .back-button-wrap {
        position: absolute;
        right: 0;
        bottom: 0;
        padding-bottom: 0;
    }

}

.back-button-wrap-goals {
    padding: 0 0 40px;
    display: flex;
    justify-content: space-between;

    .form-submit {
        justify-content: center;
    }

    @media all and (max-width: 1300px) {
        width: 100vw;
        padding: 0 30px 40px 30px;
    }

    @media all and (max-width: 800px) {
        //width: 100vw;
        //padding: 0 20px;
        //display: none;
        
    }
}

// Hide the tabledrag toggle button.
.tabledrag-toggle-weight-wrapper {
    display: none;
}

// Hide the tabledrag handle.
.tabledrag-handle {
    display: none;
}

.field--widget-myway-goal-setting-inline-paragraphs-widget {
    .paragraphs-subform {
        display: flex;
    }
}

/**
 * Disabled state
 */
input#edit-submit.disabled,
input#edit-submit[disabled] {
     box-shadow: none;
     cursor: not-allowed;
     opacity: 0.5;
     pointer-events: none;
 }

// Description text under 'Actions' label.
#field-goal-task-values {
    th.field-label p {
        font-weight: normal;
    }
}