.privacy-terms-wrap {
    margin: 50px;
    //max-width: 740px;
    //max-width: 1000px;
    color: $fontColourDark;
    background: $lightColour;
    border-radius: 20px;
    padding: 20px 40px 0;

    h1 {
        text-align: center;
        margin-bottom: 40px;
    }

    @media all and (max-width: 1300px) {
        margin: 30px;
    }

    @media all and (max-width: 800px) {
        margin: 30px 20px;
        max-width: calc(100% - 40px);
    }

    img, iframe {
        max-width: 100%;
        object-fit: cover;
    }
    
}

// Wrapper around the entity_legal form containing the checkbox.
.privacy-terms-form {
    margin: 0 50px 0 50px;
    max-width: 1000px;
    padding: 10px 40px 0;

    form {
        display: flex;
        flex-direction: column;
        align-items: center;
    }
}

.form-item-legal-terms-of-use {
    align-items: flex-start;
    .checkbox__label-container {

        label {
            // Override the flexbox styling on the label.
            display: block;
            //font-weight: normal;
            padding-left: 0;
        }
    }
}

