.two-factor-form {
    width: 70%;

    .header-small {
        margin-bottom: 40px;
        text-align: center;
    }

    label[for="edit-code"] {
        width: 250px;
        max-height: 50px;

        &.right {
            left: calc(100% - 270px);
        }
    }

    input[type="text"] {
        padding: 0 250px 0 20px;
        margin-bottom: 20px;
    }

    .description {
        width: 90%;
        text-align: center;
        margin: 0 auto;
        font-size: 0.9em;
    }

    fieldset {
        border: 1px solid white;
        margin-top: 50px;
        padding: 10px 20px;

        .fieldset-legend {
            font-weight: bold;
            color: $fontColourDark;
        }

        .fieldset-wrapper {
            font-size: 0.9em;
        }
    }

    #edit-actions {
        text-align: center;

        input[type="submit"] {
            margin: 40px 0;
            padding: 0;
            display: inline-block;
        }
    }

}