* {
    box-sizing: border-box;
    outline: none !important;

    a:focus,
    button:focus {
        outline: -webkit-focus-ring-color auto 1px !important;
    }

}

body,
html {
    padding: 0;
    margin: 0;
    //color: $fontColourLight;
    color: $fontColourDark;
    font-family: 'Open Sans', sans-serif;
    box-sizing: border-box;
    @include background-gradient();
    width: 100%;
    min-height: 100%;
    background-image: url(../images/bg-wave.svg), linear-gradient(45deg, rgba(192, 229, 247, 1) 0%, rgba(93, 165, 205, 1) 100%);
    background-size: 125%;
    background-position: bottom;
    //background-position: 0% calc(100% - 250px);
    background-repeat: no-repeat;

    &.team-member {
        background-image: url(../images/bg-wave-purple.svg), linear-gradient(45deg, rgba(228, 224, 247, 1) 0%, rgba(124, 110, 195, 1) 100%);
    
        header {

            @media all and (max-width: 768px) {
                background: #7c6ec3;
            }

        }
    }

    &.greyscale {
        background-image: url(../images/bg-wave-greyscale.svg), linear-gradient(45deg, rgba(251, 251, 251, 1) 0%, rgba(203, 203, 203, 1) 100%);

        &.team-member {
            background-image: url(../images/bg-wave-greyscale.svg), linear-gradient(45deg, rgba(251, 251, 251, 1) 0%, rgba(203, 203, 203, 1) 100%);
        }

        .dialog-off-canvas-main-canvas {
            filter: grayscale(100%);
        }
    }

    &.resource {
        .portrait-overlay {
            display: none !important;
        }
    }

    &[data-once="form-single-submit detailsAria"] {
        background-position: bottom;
    }

    &.maintenance-page {
        text-align: center;
        background-position: 0 100%;

        .dialog-off-canvas-main-canvas {
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;

            header[role="banner"] {
                flex-direction: column;
                height: auto;
                padding-bottom: 50px;
                margin-top: -100px;

                a {
                    img {
                        width: 200px;
                    }
                }

                h1 {
                    //margin-top: 40px;
                    //font-size: $font30;
                    display: none;
                }
            }

            main {
                width: 1000px;
                margin: 0 auto;

                h1 {
                    margin-bottom: 20px;
                }
            }

        }

    }
}

a {
    color: $fontColourPurple;
    transition: all 0.2s;

    &:hover {
        color: $fontColourDark;
    }
}

h1 {
    margin: 0;
    font-size: 2em;
    color: $fontColourDark;

    @media all and (max-width: 1300px) {
        font-size: 1.6em;
    }
}

ul {
    margin-bottom: 5px !important;
}

ul.bold>li {
    font-size: 0.8rem;
    font-weight: bold;
    line-height: 1.5rem;
}

.portrait-overlay {
    width: 100vw;
    height: 100%;
    background: $bgColourTwo;
    position: fixed;
    left: 0;
    top: 0;
    display: none;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    z-index: 3;

    h1,
    p,
    img {
        text-align: center;
        max-width: 60%;
    }

    @media all and (max-width: 1023px) {
        display: flex;
    }
}

.switch {
    position: relative;
    display: inline-block;
    width: 60px;
    height: 34px;
    min-width: 60px;

    input {
        opacity: 0;
        width: 0;
        height: 0;
    }

    .slider {
        border-radius: 34px;
        position: absolute;
        cursor: pointer;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background-color: $lightColour;
        -webkit-transition: .4s;
        transition: .4s;
        border: 2px solid $lightColour;

        &::before {
            border-radius: 50%;
            position: absolute;
            content: "";
            height: 24px;
            width: 24px;
            left: 3px;
            bottom: 3px;
            background-color: $bgColourFour;
            -webkit-transition: .4s;
            transition: .4s;
        }
    }

    input:checked+.slider {
        //background-color: $bgColourFour;
        border: 2px solid $lightColour;
    }

    input:focus+.slider {
        box-shadow: 0 0 1px #2196F3;
    }

    input:checked+.slider:before {
        transform: translateX(26px);
        //background-color: $lightColour;
    }

    &.portrait {
        width: 34px;
        height: 60px;

        .slider {
            &::before {
                top: 3px;
                bottom: initial;
            }
        }

        input:checked+.slider:before {
            transform: translateY(26px);
        }
    }

}