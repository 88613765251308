.resources-wrap#resource {

    @media all and (max-width: 800px) {
        flex-direction: column-reverse;
    }

    .side-blob-wrap {
        width: 250px;
        //margin-top: 40px;

        .previous-next-wrap {
            display: none;
        }

        .resource-breadcrumbs {
            margin-bottom: 20px;
            font-size: $font14;
        }

        @media all and (max-width: 800px) {
            width: 100%;
            margin-top: 0;

            .previous-next-wrap {
                display: block;

                .butt-general {
                    width: 130px;
                }
            }

            .resource-breadcrumbs {
                display: none;
            }
        }
    }

    .side-blob {
        margin-bottom: 40px;
        text-align: left;
        padding: 30px;

        @media all and (max-width: 800px) {
           // display: none;
           width: 100%;
        }

        @media print {    
            display: none!important;
        }

        .related-article-link {
            display: block;
            margin-bottom: 10px;
            font-size: $font14;
            font-weight: bold;
            text-decoration: none;
        }

        .author {
            margin-bottom: 20px;

            .author-detail {
                font-size: $font14;
                font-style: italic;
                margin-top: 5px;
            }
        }

        .share {
            @media all and (max-width: 800px) {
                display: flex;
                justify-content: space-around;
            }

            @media all and (max-width: 370px) {
                flex-direction: column;
            }
        }

        .butt-share {
            background: none;
            font-family: 'Open Sans', sans-serif;
            color: $fontColourDark;
            border: none;
            display: block;
            font-size: $font14;
            padding: 0;
            cursor: pointer;
            text-decoration: none;
            color: $bgColourSeven;
            font-weight: bold;
            background: url('../images/icons/icon-copy.svg') left center no-repeat;
            background-size: 18px;
            padding-left: 25px;
            margin-bottom: 5px;
            transition: all 0.2s;

            &.email {
                background: url('../images/icons/icon-email.svg') left center no-repeat;
                background-size: 18px;
            }

            &.print {
                background: url('../images/icons/icon-print.svg') left center no-repeat;
                background-size: 18px;
            }

            @media all and (max-width: 800px) {
                height: 40px;
                background-size: 22px;
                display: flex;
                justify-content: left;
                align-items: center;
                padding-left: 30px;
                margin-right: 10px;

                &.email, &.print {
                    background-size: 22px;
                }
            }

            &:hover {
                color: $fontColourDark;
            }

        }

        h2 {
            font-size: $font18;
            margin: 0 0 20px 0;
        }
    }

    .side-back {
        margin-bottom: 20px;

        .butt-general {
            margin: 0;
        }
    }

}

.resource-content {
    flex: 1;
    margin-top: 40px;
    padding: 0 0 0 50px;
    color: $fontColourDark;
    width: calc(100% - 360px);

    @media all and (max-width: 800px) {
        padding: 0;
        width: 100%;
        margin-top: 0;
    }

    .resource-breadcrumbs {
        margin-bottom: 20px;
        font-size: $font14;
        display: none;

        @media all and (max-width: 800px) {
            display: block;
        }
    }

    .content-wrap {
        background: white;
        border-radius: 20px;
        padding: 20px 40px 0;
        margin-bottom: 30px;
    }

    .subtitle {
        font-size: 1.2em;
    }

    .image-wrap {
        width: 100%;
        height: 280px;
        overflow: hidden;
        position: relative;

        img {
            width: 100%;
            height: 100%;
            object-fit: cover;
        }
    }

    .category-links {
        padding: 0 0 20px;
        display: flex;
        justify-content: flex-start;

        .category {
            display: inline-block;
            font-size: $font12;
            background: $bgColourFive;
            width: fit-content;
            padding: 5px 10px;
            border-radius: 30px;
            color: $fontColourDark;
            text-decoration: none;
            margin-bottom: 5px;
            margin-right: 5px;
            pointer-events: none;
        }
    }

    .article {
        padding-bottom: 50px;

        @media all and (max-width: 500px) {
            overflow: scroll;
        }

        table {
            max-width: 100%!important;
        }
    }

    .previous-next-wrap {
        height: 42px;
        margin: 0 0 20px;

        .butt-general {
            width: 140px;
        }

        @media all and (max-width: 800px) {
            display: none;
        }
    }

    footer {
        width: 100%
    }
}