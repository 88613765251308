.dashboard-spe-wrap {
    flex: 1;
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    flex-direction: column;
    margin-top: 50px;
    margin-bottom: 50px;

    @media all and (max-width: 1300px) {
        width: 100%;
        padding: 0 30px;
    }

    @media all and (max-width: 800px) {
        max-width: 100%;
        padding: 0 20px;
        overflow: hidden;
    }

    .dashboard-spe-title {
        width: 100%;
        text-align: center;
        align-self: center;
        margin-bottom: 40px;

        h1 {
            font-size: 32px;
            margin-bottom: 40px;

            @media only screen and (max-width: 1300px) {
                font-size: 26px;
            }
        }

        p {
            font-size: 18px;
            color: $fontColourDark;
        }

        .PUG-invite {
            background: $buttBgColourLightYellow;
            border-radius: 20px;
            font-size: .88rem;
            margin-top: 10px;
            padding: 20px;
        }
    }

    .dashboard-spe-content {
        display: flex;
        flex-direction: column;
        align-self: center;
        width: 100%;

        h2 {
            align-self: flex-start;
            font-size: 1.5em;
            color: $fontColourDark;
        }

        .young-people-table-wrap {
            background: $lightColour;
            border-radius: 50px;
            text-align: center;
            align-items: flex-start;
            padding: 20px 50px 50px;
            box-shadow: 2px 2px 40px 1px rgba(36, 58, 71, 0.25);
            flex-grow: 1;

            @media all and (max-width: 600px) {
                padding: 0 20px;
            }

            table {
                text-align: left;
                table-layout: auto;
                width: 100%;
                border-spacing: 0;
                overflow-x: hidden;

                thead {
                    @media all and (max-width: 1024px) {
                        border: none;
                        clip: rect(0 0 0 0);
                        height: 1px;
                        margin: -1px;
                        overflow: hidden;
                        padding: 0;
                        position: absolute;
                        width: 1px;
                    }
                }


                th {
                    border-bottom: 1px solid #e3e3e3;
                }

                td {
                    border-bottom: 1px solid #e3e3e3;
                    font-size: 0.8em;

                    @media all and (max-width: 1024px) {
                        display: block;
                        //text-align: right;
                        height: 40px;
                        display: flex;
                        align-items: center;
                        background: $bgColourOne;
                        padding: 20px;
                        border-bottom: 1px solid $lightColour;

                        &:before {
                            content: attr(data-label);
                            //float: left;
                            font-weight: bold;
                            text-transform: uppercase;
                            width: 100px;
                        }

                        &.button-cell {
                            justify-content: center;
                            height: 80px;
                            border-bottom: 2px solid #e3e3e3;
                            margin-bottom: 20px;

                            &:before {
                                width: 0px;
                            }
                        }

                        &:first-child {
                            border-radius: 20px 20px 0 0;
                        }

                        &:last-child {
                            border-radius: 0 0 20px 20px;
                            border-bottom: 0;
                            padding: 0;
                        }
                    }
                }

                a {
                    text-decoration: none;
                    color: $fontColourDark;

                    &:visited {
                        color: $fontColourDark;
                    }

                    &:hover {
                        cursor: pointer;
                        font-size: 1.5em;
                    }
                }

                h2 {
                    font-size: 18px;
                    color: $fontColourDark;
                }

                .young-person-name {
                    font-weight: bold;
                }

                .young-person-email {
                    color: $fontColourDark;
                }

                .button-cell {
                    padding-right: 0;
                    text-align: right;
                    display: flex;
                    justify-content: right;
                }

                .ellipsis {
                    display: block;
                    text-overflow: ellipsis;
                    white-space: nowrap;
                    overflow: hidden;
                    font-size: 16px;

                    @media all and (max-width: 600px) {
                        font-size: 0.8rem;
                        white-space: initial;
                        word-break: break-word;
                    }
                }
            }
        }
    }
}

.butt-to-yp-details {
    -webkit-appearance: none;
    background: none;
    border: none;
    outline: none;
    transition: all 0.3s;
    overflow: hidden;
    margin: 10px 0;
    font-family: 'Open Sans', sans-serif;

    .icon {
        width: 45px;
        height: 45px;
        border-radius: 50px;
        background-color: $buttColour;
        border: 2px solid white;
        background-size: 60%;
        background-repeat: no-repeat;
        background-position: center 46%;
        margin: 0 auto;
        background-position: 50% 50%;
    }

    .title {
        text-transform: uppercase;
        font-size: $font10;
        font-weight: bold;
        color: $buttColour;
        text-align: center;
    }


    &.next {
        .icon {
            background-image: url(../images/icons/icon-user.svg);
            background-size: 50%;
        }

    }

    &.goals {
        .icon {
            background-image: url(../images/icons/icon-goal-light.svg);
            background-size: 70%;
        }

    }

    &.team {
        .icon {
            background-image: url(../images/icons/icon-team-white.svg);
            background-size: 70%;
        }
    }

    &.meetings {
        .icon {
            background-image: url(../images/icons/icon-calendar-white.svg);
            background-size: 60%;
        }
    }

    &.remove {

        .icon {
            background-color: white;
            background-image: url(../images/icons/icon-close-red.svg);
            background-size: 40%;
        }

        .title {
            color: $listDotColourPink;
        }

    }

    &:hover {
        cursor: pointer;
        animation-duration: 0.3s;
        animation-fill-mode: both;
        animation-name: pulseBig;
    }
}