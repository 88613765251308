.quiz-wrapper {
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    width: 1200px;
    //padding: 0 20px;
    padding-top: 50px;
    padding-bottom: 50px;
    overflow: hidden;

    @media all and (max-width: 1300px) {
        width: 100%;
        padding: 0 30px;
    }

    @media all and (max-width: 800px) {
        width: 100%;
        padding: 0 20px;
    }

    h1 {
        margin: 0 0 50px 0;
    }

    .hidden-questions {
        opacity: 0;
        visibility: 0;
        height: 0;
        width: 0;
    }

    .progress {
        width: 100%;
        height: 24px;
        background: $lightColour;
        border-radius: 24px;
        padding: 0 10px;
        z-index: 0;
        box-shadow: 2px 2px 40px 1px rgba(36,58,71,0.25);

        .progress-fill {
            background: #CAC2EC;
            height: 8px;
            position: absolute;
            width: calc(100% - 20px);
            top: 0;
            bottom: 0;
            margin: auto;
            border-radius: 20px;
            width: 0%; // dynamic
            z-index: -1;
            transition: width 0.5s;
        }

        .progress-dots {
            width: 100%;
            height: 100%;
            display: flex;
            justify-content: space-between;
            align-items: center;
            z-index: 1;

            .dot {
                width: 10px;
                height: 10px;
                border-radius: 20px;
                background: $bgColourFive;

                &.complete {
                    background: $bgColourFour;
                }

                &.current {
                    background: $lightColour;
                    border: 3px solid $bgColourFour;
                    width: 13px;
                    height: 13px;
                    animation-duration: 0.4s;
                    animation-fill-mode: both;
                    animation-name: pulseBigger;
                }
            }
        }
    }

    .questions-wrap {
        margin-top: 40px;
        background: $lightColour;
        border-radius: 40px;
        box-shadow: 2px 2px 40px 1px rgba(36,58,71,0.25);
        color: $fontColourDark;
        padding: 40px;
        width: 77%;
        max-width: 920px;
        min-width: 600px;

        @media all and (max-width: 800px) {
            width: 100%;
            min-width: 100%;
            padding: 20px;
        }

        #no-next-message {
            text-align: center;
            padding: 10px;
            background: #eeeeee;
            border-radius: 30px;
            font-size: 0.8em;
        }

        .question {
            text-align: center;
            //transition: all 1s;

            h2 {
                font-size: 1.4em;
                color: $bgColourFour;
                margin-top: 0;

				&.lifealt {
					color:$fontColourDark;
					margin-bottom:36px;
				}
            }

			h3 {
				color:$bgColourFour;
			}

            .subtitle {
                font-size: 0.9em;
                padding: 10px 0;
                color: $bgColourFour;

                @media all and (max-width: 767px) {
                    font-size: $font12;
                }
            }

            .dropzone {
                width: 100%;
                height: 34px;
                position: relative;
                display: flex;
                justify-content: center;
                align-items: center;
                margin-top: 40px;

                .drop-line {
                    width: 100%;
                    height: 8px;
                    background: #c5c5c5;
                    border-radius: 20px;
                }

                .draggable-option {
                    width: 34px;
                    height: 34px;
                    overflow: hidden;

                    &.place {
                        animation-duration: 0.4s;
                        animation-fill-mode: both;
                        animation-name: pulseBig;
                    }

                    .icon {
                        width: 34px;
                        height: 34px;
                        background: $listDotColourBlue;
                        border-radius: 34px;
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        color: $fontColourDark;
                        font-weight: 600;
                        cursor: grab;
                        &:after {
                            content: 'A';
                        }

                    }

                    .title, .animation-bubble {
                        display: none;
                    }

                    &.option1 {
                        .icon {
                            background: #EB8782;
                            &:after {
                                content: 'B';
                            }
                        }
                    }

                    &.option2 {
                        .icon {
                            background: #F3C762;
                            &:after {
                                content: 'C';
                            }
                        }
                    }

                    &.option3 {
                        .icon {
                            background: #86CC88;
                            &:after {
                                content: 'D';
                            }
                        }
                    }
                }
            }

            .dropzone-labels {
                display: flex;
                justify-content: space-between;
                font-size: 0.75em;
                margin-top: 5px;
            }

            .answer-options {
                padding: 40px 0;

                @media all and (max-width: 767px) {
                    padding: 20px 0;
                }

                &.career-exporation-options {
                   // display: flex;
                }

                .select-buttons {
                    display: flex;
                    justify-content: space-between;
                    align-items: center;
                    width: 95%;
                    margin: 0 auto;

                    .option {
                        width: 26px;
                        height: 26px;
                        border: 2px solid $bgColourSix;
                        background: transparent;
                        border-radius: 30px;
                        outline: none;
                        padding: 0;

                        .inner {
                            width: 18px;
                            height: 18px;
                            background: $bgColourSix;
                            border-radius: 20px;
                            margin: auto;
                            display: block;
                            opacity: 0;
                            transition: all 0.4s;
                        }

                        &:hover {
                            cursor: pointer;
                            animation-duration: 0.4s;
                            animation-fill-mode: both;
                            animation-name: pulseBig;

                            .inner {
                                opacity: 1;
                            }
                        }

                        &.selected {
                            animation-duration: 0.3s;
                            animation-fill-mode: both;
                            animation-name: pulseBigger;

                            .inner {
                                opacity: 1;
                            }
                        }
                    }

                }

                .draggable-options {
                    display: flex;
                    justify-content: space-between;
                    align-items: center;
                    flex-wrap: wrap;

                    .draggable-option {
                        background: #D4E9F7;
                        border: 5px solid #D4E9F7;
                        height: 64px;
                        width: 48%;
                        font-weight: normal;
                        color: $fontColourDark;
                        margin-bottom: 10px;
                        border-radius: 40px;
                        display: flex;
                        justify-content: flex-start;
                        align-items: center;
                        padding: 0 20px 0 10px;
                        cursor: grab;
                        text-align: left;
                        transition: all 0.2s;
                        overflow: hidden;
                        font-size: 0.9em;

                        @media all and (max-width: 767px) {
                            font-size: 0.6rem;
                            padding: 0 5px;
                            height: 80px;
                            border-radius: 20px;
                        }

                        .animation-bubble {
                            z-index: -1;
                        }

                        .icon {
                            width: 34px;
                            height: 34px;
                            background: white;
                            border-radius: 34px;
                            display: flex;
                            justify-content: center;
                            align-items: center;
                            color: $bgColourEight;
                            font-weight: 600;
                            margin-right: 15px;
                            min-width: 34px;
                            min-height: 34px;
                            &:after {
                                content: 'A';
                            }

                            @media all and (max-width: 767px) {
                                font-size: 0.9rem;
                                margin-right: 5px;
                                min-width: 20px;
                                min-height: 20px;
                                width: 20px;
                                height: 20px;
                                line-height: 20px;
                            }

                        }

                        &.option1 {
                            background: #F7DADA;
                            border: 5px solid #F7DADA;

                            .icon {
                                color: #EB8782;
                                &:after {
                                    content: 'B';
                                }
                            }

                            .animation-bubble {
                                background: #EE9A95;
                            }

                        }

                        &.option2 {
                            background: #F8E9B9;
                            border: 5px solid #F8E9B9;

                            .icon {
                                color: #F3C762;
                                &:after {
                                    content: 'C';
                                }
                            }

                            .animation-bubble {
                                background: #F3C762;
                            }
                        }

                        &.option3 {
                            background: $bgColourNine;
                            border: 5px solid $bgColourNine;

                            .icon {
                                color: #86CC88;
                                &:after {
                                    content: 'D';
                                }
                            }

                            .animation-bubble {
                                background: #86CC88;
                            }
                        }

                        &.selected {
                            animation-duration: 0.4s;
                            animation-fill-mode: both;
                            animation-name: pulseBig;
                            background: $listDotColourBlue;
                            color: $fontColourDark;
                            border-width: 5px;
                            border-style: solid;

                            &.option1 {
                                background: #EE9A95;
                                border-color: #F7DADA;
                            }

                            &.option2 {
                                background: #F3C762;
                                border-color: #F8E9B9;
                            }

                            &.option3 {
                                background: #86CC88;
                                border-color: $bgColourNine;
                            }

                            @media all and (max-width: 767px) {
                                border-width: 2px;
                            }
                        }

                        &.squish {
                            animation-duration: 1.5s;
                            animation-fill-mode: both;
                            animation-name: shake;
                        }
                    }

                }

                .multi-select-options {
                    display: flex;
                    flex-wrap: wrap;
                    justify-content: space-between;

                    @media all and (max-width: 767px) {
                        flex-direction: column;
                    }

                    .multi-option {
                        width: 48%;
                        min-height: 64px;
                        height: auto;
                        background: #D4E9F7;
                        border: 5px solid #D4E9F7;
                        border-radius: 33px;
                        color: inherit;
                        font-weight: normal;
                        text-transform: inherit;
                        padding: 0 10px 0 5px;
                        margin: 0 0 10px 0;

                        @media all and (max-width: 767px) {
                            width: 100%;
                        }

                        &.selected {
                            animation-duration: 0.4s;
                            animation-fill-mode: both;
                            animation-name: pulseBig;
                            background: $bgColourEight;
                            color: $fontColourLight;
                            //border: 5px solid $fontColourDark;
                            outline: 2px solid $fontColourDark!important;
                        }

                        &:nth-child(2) {
                            background: #F7DADA;
                            border: 5px solid #F7DADA;

                            .butt-icon {
                                background-color: #EE9A95;
                            }

                            .animation-bubble {
                                background: #EE9A95;
                            }

                            &.selected {
                                background: #EE9A95;
                                //border: 5px solid $fontColourDark;
                                outline: 2px solid $fontColourDark!important;
                            }
                        }

                        &:nth-child(3) {
                            background: #F8E9B9;
                            border: 5px solid #F8E9B9;

                            .butt-icon {
                                background-color: #F3C762;
                            }

                            .animation-bubble {
                                background: #F3C762;
                            }

                            &.selected {
                                background: #F3C762;
                                //border: 5px solid $fontColourDark;
                                outline: 2px solid $fontColourDark!important;
                            }
                        }

                        &:nth-child(4) {
                            background: $bgColourNine;
                            border: 5px solid $bgColourNine;

                            .butt-icon {
                                background-color: #86CC88;
                            }

                            .animation-bubble {
                                background: #86CC88;
                            }

                            &.selected {
                                background: #86CC88;
                                //border: 5px solid $fontColourDark;
                                outline: 2px solid $fontColourDark!important;
                            }
                        }

                        &:nth-child(5) {
                            background: $bgColourFourteen;
                            border: 5px solid $bgColourFourteen;

                            .butt-icon {
                                background-color: #9C9E9E;
                            }

                            .animation-bubble {
                                background: #9C9E9E;
                            }

                            &.selected {
                                background: #9C9E9E;
                                outline: 2px solid $fontColourDark!important;
                            }
                        }

                        .butt-icon {
                            width: 44px;
                            height: 44px;
                            background-color: $bgColourEight;

                            &.UNIVERSITY {
                                background-image: url(../images/icons/icon-university.svg);
                            }

                            &.TRAINING_CENTRE {
                                background-image: url(../images/icons/icon-trainingcentre3.svg);
                            }

                            &.HANDS_ON_TRAINING {
                                background-image: url(../images/icons/icon-handson.svg);
                            }

                            &.WORK {
                                background-image: url(../images/icons/icon-straighttowork.svg);
                            }

                            &.OTHER {
                                background-image: url(../images/icons/icon-gapyear.svg);
                            }

                            &.VISUAL {
                                background-image: url(../images/icons/icon-looking.svg);
                            }

                            &.READ_WRITE {
                                background-image: url(../images/icons/icon-reading.svg);
                            }

                            &.AUDITORY {
                                background-image: url(../images/icons/icon-listening.svg);
                            }

                            &.KINAESTHETIC {
                                background-image: url(../images/icons/icon-doing.svg);
                            }
                        }

                        .title {
                            font-size: 1.05em;
                            text-align: left;
                            padding: 10px 0 10px 10px;
                            color: $fontColourDark;
                        }

                        &.OTHER {

                            textarea {
                                border: none;
                                border-radius: 10px;
                                height: 55px;
                                width: 100%;
                                margin: 10px 0;
                                -webkit-appearance: none;
                                padding: 10px;
                                font-size: 0.9em;
                                font-family: inherit;
                                color: $fontColourDark;
                            }

                        }


                    }

                }

                .this-or-that-options {
                    display: flex;
                    justify-content: space-between;
                    align-items: center;

                    @media all and (max-width: 767px) {
                        flex-direction: column;
                        position: relative;
                        padding-bottom: 50px;
                    }

                    .this-or-that-option {
                        height: 74px;
                        background: $bgColourFive;
                        border: 5px solid $bgColourFive;
                        margin: 0;
                        width: 260px;
                        color: $fontColourDark;
                        text-transform: none;
                        font-size: 0.8em;
                        padding: 0 10px 0 5px;
                        margin: 0 5px;

                        @media all and (max-width: 767px) {
                            margin: 5px;

                            &.dont-mind {
                                position: absolute;
                                bottom: 0;
                                height: 40px;
                            }
                        }

                        &.selected {
                            animation-duration: 0.4s;
                            animation-fill-mode: both;
                            animation-name: pulseBig;
                            background: $bgColourThree;
                            //color: $fontColourLight;
                        }

                        .butt-icon {
                            width: 54px;
                            height: 54px;
                            background-color: $bgColourThree;
                            background-position: center center;
                        }

                        .title {
                            text-align: left;
                            padding-left: 10px;

                            &.centre {
                                //padding-left: 0;
                                text-align: center;
                            }
                        }

                        .animation-bubble {
                            background: $bgColourThree;
                        }

						&.butt-lifeskillsquiz {
							max-width:23%;
                            background:transparent;

                            @media all and (max-width: 767px) {
                                max-width: 100%;
                                width: 100%;
                            }

                            &:nth-child(1) {
                                border:2px solid $listDotColourGreen;

                                &:hover .animation-bubble {
                                    background:$listDotColourGreen;
                                }

                                &.selected {
                                    background:$listDotColourGreen;
                                }
                            }

                            &:nth-child(2) {
                                border:2px solid $listDotColourPink;

                                &:hover .animation-bubble {
                                    background:$listDotColourPink;
                                }
                                &.selected {
                                    background:$listDotColourPink;
                                }
                            }

                            &:nth-child(3) {
                                border:2px solid $listDotColourYellow;

                                &:hover .animation-bubble {
                                    background:$listDotColourYellow;
                                }

                                &.selected {
                                    background:$listDotColourYellow;
                                }
                            }

							&:nth-child(4) {
                                border:2px solid $listDotColourBlue;

                                &:hover .animation-bubble {
                                    background:$listDotColourBlue;
                                }

                                &.selected {
                                    background:$listDotColourBlue;
                                }
                            }

                        }

                        &[data-index="SENSORY_01"] {

                            .negative {
                                background-image: url(../images/icons/icon-sensory01-negative.svg);
                            }

                            .positive {
                                background-image: url(../images/icons/icon-sensory01-positive.svg);
                            }

                        }

                        &[data-index="SENSORY_02"] {

                            .negative {
                                background-image: url(../images/icons/icon-sensory02-negative.svg);
                            }

                            .positive {
                                background-image: url(../images/icons/icon-sensory02-positive.svg);
                            }

                        }

                        &[data-index="SENSORY_03"] {

                            .negative {
                                background-image: url(../images/icons/icon-sensory03-negative.svg);
                            }

                            .positive {
                                background-image: url(../images/icons/icon-sensory03-positive.svg);
                            }

                        }

                        &[data-index="SENSORY_04"] {

                            .negative {
                                background-image: url(../images/icons/icon-sensory04-negative.svg);
                            }

                            .positive {
                                background-image: url(../images/icons/icon-sensory04-positive.svg);
                            }

                        }

                        &[data-index="SENSORY_05"] {

                            .negative {
                                background-image: url(../images/icons/icon-sensory05-negative.svg);
                            }

                            .positive {
                                background-image: url(../images/icons/icon-sensory05-positive.svg);
                            }

                        }

                        &[data-index="SENSORY_06"] {

                            .negative {
                                background-image: url(../images/icons/icon-sensory06-negative.svg);
                            }

                            .positive {
                                background-image: url(../images/icons/icon-sensory06-positive.svg);
                            }

                        }

                        &[data-index="SENSORY_07"] {

                            .negative {
                                background-image: url(../images/icons/icon-sensory07-negative.svg);
                            }

                            .positive {
                                background-image: url(../images/icons/icon-sensory07-positive.svg);
                            }

                        }

                        &[data-index="SENSORY_08"] {

                            .negative {
                                background-image: url(../images/icons/icon-sensory08-negative.svg);
                            }

                            .positive {
                                background-image: url(../images/icons/icon-sensory08-positive.svg);
                            }

                        }

                        &[data-index="SENSORY_09"] {

                            .negative {
                                background-image: url(../images/icons/icon-sensory09-negative.svg);
                            }

                            .positive {
                                background-image: url(../images/icons/icon-sensory09-positive.svg);
                            }

                        }
                    }

                }

                .answer-line {
                    height: 18px;
                    position: relative;
                    margin: 20px 0 10px;
                    display: flex;
                    justify-content: flex-end;
                    flex-direction: column;
                    overflow: hidden;
                    border-radius: 5px;

                    .line {
                        height: 8px;
                        background: $bgColourFive;
                        border-radius: 20px;
                        width: 100%;
                    }

                    .answer-swoosh {
                        height: 18px;
                        position: absolute;
                        bottom: 0;
                        transition: left 0.3s ease-in-out;
                        left: calc(0% - 248px);
                    }
                }

                .text-answers {
                    display: flex;
                    justify-content: space-between;
                    font-size: 0.8em;
                    margin-top: 10px;
                }

            }

            &.hide-question {
                opacity: 0;
                visibility: hidden;
                height: 0;
            }

            &.current {
                animation-duration: 0.4s;
                animation-fill-mode: both;
                animation-name: pulse;
            }

        }

    }

    .button-wrap {
        width: 77%;
        margin: 40px 0;
        display: flex;
        justify-content: space-between;

        @media all and (max-width: 800px) {
            width: 100%;
        }

        .butt-save {
            margin: 0;
        }

        .butt-next {
            flex-direction: row-reverse;
            padding: 0 4px 0 15px
        }

        @media all and (max-width: 800px) {
            position: relative;
            margin: 20px 0 130px;

            .butt-next, .butt-previous {
                width: 130px;
            }

            .butt-save {
                position: absolute;
                top: 80px;
                left: 0;
                right: 0;
                margin: auto;
            }

            /*.butt-next, .butt-previous {
                width: 42px;
                padding-left: 5px;
    
                .butt-icon {
                    min-width: 32px;
                }
    
                .title {
                    display: none;
                }
            }*/
        }

        
    }
}