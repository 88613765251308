.butt-animate {

    .animation-bubble {
        position: absolute;
        pointer-events: none;
        background: $bgColourTwo;
        width: 20px;
        height: 20px;
        opacity: 0;
        transform: scale(0, 0);
        left: calc(50% - 10px);
        top: 75%;
        border-radius: 20px;
        transition: transform 0.6s ease-out;
    }

    &:hover {
        cursor: pointer;
        animation-duration: 0.4s;
        animation-fill-mode: both;
        animation-name: pulse;

        .animation-bubble {
            opacity: 0.2;
            transform: scale(20, 20);
            transition: transform 0.6s ease-out;
        }
    }

}

.butt-general {
    height: $buttHeight;
    width: 180px;
    border-radius: $buttHeight;
    background: $lightColour;
    border: none;
    outline: none; // TODO: accessibility
    font-family: 'Open Sans', sans-serif;
    font-size: 0.8em;
    font-weight: 600;
    text-transform: uppercase;
    color: $buttColour;
    padding: 0 15px 0 4px;
    display: inline-flex;
    justify-content: flex-start;
    align-items: center;
    transition: all 0.2s;
    position: relative;
    overflow: hidden;
    //margin-left: 20px;
    backface-visibility: hidden;
    transform: translateZ(0);
    font-smoothing: antialiased !important;
    text-decoration: none;

    &:focus {
        outline: 1px solid $lightColour;
        outline-offset: 3px;
    }

    .butt-icon {
        display: inline-block;
        background-color: $buttColour;
        height: $buttHeight - 10px;
        width: $buttHeight - 10px;
        border-radius: $buttHeight - 10px;
        z-index: 1;
        background-position: center center;
        background-repeat: no-repeat;
        background-size: 60%;

        &.login, &.Account {
            background-image: url(../images/icons/icon-user.svg);
        }

        &.register {
            background-image: url(../images/icons/icon-register.svg);
        }

        &.register-yellow {
            background-image: url(../images/icons/icon-register-yellow.svg);
        }

        &.logout {
            background-image: url(../images/icons/icon-logout.svg);
        }

        &.user {
            background-image: url(../images/icons/icon-user-yellow.svg);
        }

        &.add-calendar {
            background-image: url(../images/icons/icon-addcal.svg);
            background-size: 100%;
        }

        &.edit {
            background-size: 90%;
            background-image: url(../images/icons/icon-edit.svg);
        }

        &.upload {
            background-image: url(../images/icons/icon-upload.svg);
        }

        &.close {
            background-image: url(../images/icons/icon-close-light.svg);
            background-size: 45%;
        }

        &.close-yellow {
            background-image: url(../images/icons/icon-close-yellow.svg);
            background-size: 40%;
        }

        &.started {
            background-image: url(../images/icons/icon-arrow-purple.svg);
            background-size: 35%;
            background-position: 55% 50%;
        }

        &.more {
            background-image: url(../images/icons/icon-arrow-green.svg);
            background-size: 35%;
            background-position: 55% 50%;
        }

        &.add-team {
            background-image: url(../images/icons/icon-team.svg);
            background-size: 80%;
        }

        &.add-meeting {
            background-image: url(../images/icons/icon-addcal-purple.svg);
            background-size: 100%;
        }

        &.previous {
            background-image: url(../images/icons/icon-arrow-previous.svg);
            background-size: 35%;
            background-position: 40% 50%;
        }

        &.next {
            background-image: url(../images/icons/icon-arrow-next.svg);
            background-size: 35%;
            background-position: 58% 50%;
        }

        &.next-purple {
            background-image: url(../images/icons/icon-arrow-next-purple.svg);
            background-size: 35%;
            background-position: 58% 50%;
        }

        &.next-light-blue {
          background-image: url(../images/icons/icon-arrow-back-blue.svg);
          transform: rotate(180deg);
          background-size: 35%;
          background-position: 50% 50%;
        }

        &.back {
            background-image: url(../images/icons/icon-arrow-back-blue.svg);
            background-size: 35%;
            background-position: 43% 50%;
        }

        &.back-white {
            background-image: url(../images/icons/icon-arrow-back.svg);
            background-size: 35%;
            background-position: 43% 50%;
        }

        &.start-quiz {
            background-image: url(../images/icons/icon-start.svg);
        }

        &.save {
            background-image: url(../images/icons/icon-save.svg);
        }

        &.save-purple {
            background-image: url(../images/icons/icon-save-purple.svg);
        }

        &.save-white {
            background-image: url(../images/icons/icon-save-white.svg);
        }

        &.group-talking {
            background-image: url(../images/icons/icon-group-talking.svg);
            background-size: 110%;
        }

        &.group-talking.selected {
            background-image: url(../images/icons/icon-group-talking-bg-yellow.svg);
            background-size: 110%;
        }

        &.group {
            background-image: url(../images/icons/icon-group.svg);
            background-size: 110%;
        }

        &.hear {
            background-image: url(../images/icons/icon-hear.svg);
            background-size: 110%;
        }

		&.butt-icon-text {
			color:#fff;
			font-size:1.5em;
			line-height:32px;
        }

        &.add-goal {
            background-image: url(../images/icons/icon-add.svg);
            background-size: 60%;
        }

        &.goal {
            background-image: url(../images/icons/icon-goal.svg);
        }

        &.due-date {
            background-image: url(../images/icons/icon-addcal-purple.svg);
        }
    }

    .title {
        flex: 1;
        z-index: 1;
        text-align: center;
    }

    &:hover {
        color: $buttColour;
    }

    &.butt-small {
        width: 44px;
        padding: 0 6px;

        .title {
            display: none;
        }
    }

    &.butt-register,
    &.butt-logout,
    &.butt-back,
    &.butt-save,
    &.butt-update,
    &.butt-edit {
        background: transparent;
        border: 2px solid $lightColour;
        color: $lightColour;

        .butt-icon {
            background-color: $lightColour;
        }

        .animation-bubble {
            background: #403379;
        }

        &:hover {
            .animation-bubble {
                opacity: 0.2;
            }
        }

    }

    &.butt-profile-report-back {
      margin-left: 0;
      margin-bottom: 50px;
      color: $buttColour;

      .butt-icon {
        background-color: $buttColour;
      }

      &:hover {
        color: $bgColourTwo;
      }
    }

    &.butt-dashboard {
        background: $fontColourLight;
        color: $buttColour;
        border: none;

        .butt-icon {
            background-color: $buttColour;
        }

        .animation-bubble {
            background: $bgColourTwo;
        }
    }

    &.butt-upload, &.butt-consent {
        background: $bgColourThree;
        color: $fontColourLight!important;
        margin: 0;

        .butt-icon {
            background-color: $lightColour;
        }

        .animation-bubble {
            background: #493D76;
        }
    }

    &.butt-meeting-time,
    &.butt-meeting-address {
        background: $lightColour;
        color: $bgColourThree;
        margin: 0;

        .butt-icon {
            background-color: $lightColour;
        }
    }

    &.butt-more-way-to-support {
      background: $buttColour;
      color: $fontColourLight;
      height: 43px;
      width: 380px;
      border-radius: 72px;
      font-size: 13px;
      padding-left: 15px;
      margin-top: 50px;
      margin-bottom: 20px;

      .animation-bubble {
        background: #493D76;
      }

      &:hover {
        color: white;
      }
    }

    &.butt-get-started {
        background: $bgColourThree;
        color: $fontColourLight;
        height: 62px;
        width: 250px;
        border-radius: 72px;
        font-size: $font16;
        padding-left: 10px;

        .butt-icon {
            background-color: $lightColour;
            height: 42px;
            width: 42px;
        }

        .animation-bubble {
            background: #493D76;
        }
    }

    &.butt-find-out-more {
        background: $listDotColourGreen;
        color: $fontColourLight;
        height: 62px;
        width: 250px;
        border-radius: 72px;
        font-size: $font16;
        padding-left: 10px;

        .butt-icon {
            background-color: $lightColour;
            height: 42px;
            width: 42px;
        }

        .animation-bubble {
            background: #493D76;
        }
    }

    &.butt-see-results {
        background: $bgColourThree;
        color: $fontColourLight;

        .butt-icon {
            background-color: $lightColour;
        }

        .animation-bubble {
            background: #493D76;
        }
    }

    &.butt-add-team, &.butt-save-team {
        background: $bgColourThree;
        color: $fontColourLight;

        .butt-icon {
            background-color: $lightColour;
        }

        .animation-bubble {
            background: #493D76;
        }
    }

    &.butt-clear {
        background: transparent;
        color: $bgColourFour;
        margin: 0;
        border: 2px solid $bgColourThree;
        width: 100%;
        max-width: 180px;

        &:focus {
            outline: 1px solid $bgColourFour!important;
            outline-offset: 3px;
        }
        

        .butt-icon {
            background-color: $bgColourFour;
        }

        .animation-bubble {
            background-color: $bgColourFive;
        }
        
    }

    &.butt-add-cal {
        background: white;
        color: $bgColourFour;
        margin: 0;
        width: 100%;
        max-width: 180px;

        .butt-icon {
            background-color: $bgColourFour;
        }

        .animation-bubble {
            background-color: $bgColourFive;
        }
    }

    &.butt-quiz-section {
        height: 74px;
        width: 100%;
        //background: rgba(255,255,255,0.45);
        background: $lightColour;
        margin: 0;
        color: $fontColourDark;
        font-size: $font18;
        text-transform: unset;
        padding: 0 10px 0 10px;
        margin-bottom: 15px;
        //width: 100%;
        min-width: 210px;
        width: 210px;
        height: 165px;
        display: flex;
        flex-direction: column-reverse;
        margin: 10px;
        padding: 10px 10px;
        border-radius: 30px;

        @media all and (max-width: 500px) {
            width: 100%;
            min-width: auto;
            flex-direction: row;
            height: auto;
            justify-content: center;
            margin-top: 0;
        }

        .copy {
            flex: 1;
            //padding-right: 15px;
            text-align: center;
            line-height: 1.3rem;

            @media all and (max-width: 500px) {
                text-align: left;
                padding: 0 15px;
            }

            .description {
                font-size: $font10;
                line-height: $font14;
                display: block;
                padding-top: 2px;
                font-weight: 400;
                font-style: italic;
            }

        }

        .title {
            text-align: left;
            font-size: 1rem;
        }

        &.team-member {
            min-height: 74px;
            height: auto;
        }

        .results {
            flex: 1;
            display: flex;
            flex-direction: column;
            position: relative;

            .result {
                padding-left: 15px;
                font-weight: normal;
                font-size: 0.9em;
                height: 30px;
                display: flex;
                align-items: flex-start;
                justify-content: flex-start;
                height: auto;
                padding-bottom: 10px;
                position: relative;
                padding-top: 5px;

                &:before {
                    content: '';
                    position: absolute;
                    background: url(../images/icons/icon-list-blue.svg) center center no-repeat;
                    width: 6px;
                    height: 12px;
                    left: 0;
                    top: 10px;
                }
            }

            .title {
                padding-top: 10px;
            }
        }

        .butt-icon {
            width: 40px;
            height: 40px;
            min-width: 40px;
            margin-bottom: 10px;
            min-height: 40px;

            @media all and (max-width: 500px) {
                margin-bottom: 0;
            }

            &.career {
                background-color: $bgColourOne;
                background-image: url(../images/icons/icon-career.svg);
            }

            &.strengths {
                background-color: $bgColourFive;
                background-image: url(../images/icons/icon-strengths.svg);
            }

            &.work {
                background-color: #F8E9B9;
                background-image: url(../images/icons/icon-work.svg);
            }

            &.training {
                background-color: $bgColourNine;
                background-image: url(../images/icons/icon-training.svg);
            }

            &.life {
                background-color: #F7DADA;
                background-image: url(../images/icons/icon-life.svg);
            }
        }

        .completed-icon {
            width: 30px;
            height: 30px;
            background: $bgColourFourteen;
            border-radius: 30px;
        }

        &.career {
            position: relative;
            overflow: initial;

            &::before {
                content: "Start Here";
                position: absolute;
                top: -45px;
                text-transform: uppercase;
                font-size: $font14;
                color: $bgColourFour;
                background: $lightColour;
                padding: 5px 10px;
                border-radius: 20px;
            }

            &::after {
                content: '';
                position: absolute;
                background: $lightColour;
                top: -22px;
                width: 10px;
                height: 10px;
                transform: rotate(45deg);
            }
        }

        &.completed {
            background: rgba(255,255,255,1);
            border: 2px solid white;

            .butt-icon {

                &.career { background-color: $bgColourEight; }
                &.strengths { background-color: $bgColourThree; }
                &.work { background-color: #F3C762; }
                &.training { background-color: #86CC88; }
                &.life { background-color: #EE9A95; }

            }

            .completed-icon {
                background-color:$lightColour;
                background-image: url(../images/icons/icon-tick.svg);
                background-repeat: no-repeat;
                background-position: center center;
            }

            &.career {
                background-color: $bgColourOne;
               // border: 2px solid $bgColourEight;

               &::before {
                    content: '';
                    background: transparent;
                }

                &::after {
                    background: transparent;
                }

            }

            &.strengths {
                background-color: $bgColourFive;
               // border: 2px solid $bgColourThree;
            }

            &.work {
                background-color: #F8E9B9;
                //border: 2px solid #F3C762;
            }

            &.training {
                background-color: $bgColourNine;
              //  border: 2px solid #86CC88;
            }

            &.life {
                background-color: #F7DADA;
              //  border: 2px solid  #EE9A95;
            }
        }

        &.disable-category {
            opacity: 0.45;
            pointer-events: none;
        }

        .animation-bubble {
            background: white;
        }

    }

    &.butt-goal-section-dark,
    &.butt-goal-section-light {
        height: 64px;
        width: 100%;
        margin: 0;
        color: $fontColourDark;
        font-size: $font18;
        text-transform: unset;
        margin-bottom: 15px;

        .title {
            text-align: left;
        }

        .butt-icon {
            width: 60px;
            height: 60px;

            &.goal {
                background-image: url(../images/icons/icon-goal.svg);
            }

        }

        .animation-bubble {
            background: white;
        }
    }

    &.butt-goal-section-dark {
        background: $buttBgColourDarkYellow;
        .butt-icon {
            &.goal {
                background-color: $buttBgColourDarkYellow;
            }
        }

    }

    &.butt-goal-section-light {
        background: rgba(255,255,255,1);
        .butt-icon {
            &.goal {
                background-color: $buttBgColourLightYellow;
            }
        }
    }

    &.butt-previous,
    &.butt-next {
        margin: 0;
    }

    &.butt-next {
        float: right;
    }

	// jD: Allow us to recolour if necessary - might need to be refactored.  Used to colour buttons in Support Networks pages
	&.purple{ .butt-icon { background-color:$listDotColourPurple; } }
	&.blue	{ .butt-icon { background-color:$bgColourTwo; } }
	&.red	{ .butt-icon { background-color:$listDotColourPink; } }
	&.green { .butt-icon { background-color:$bgColourDarkGreen; } }
    &.orange{ .butt-icon { background-color:$listDotColourYellow; } } // Shib: Lol needs to be updated to orange (orange you glad I left it for you?)

}

.butt-category {
    height: auto;
    min-height: 36px;
    border-radius: 21px;
    border: 2px solid $bgColourFive;
    background: transparent;
    width: 100%;
    text-align: left;
    position: relative;
    outline: none; // TODO: accessibility
    overflow: hidden;
    font-family: 'Open Sans', sans-serif;
    color: $bgColourFour;
    font-size: 0.8em;
    margin: 0;
    padding: 0 0 0 10px;
    display: flex;
    flex-direction: row-reverse;
    align-items: center;
    transition: all 0.2s;
    margin-bottom: 5px;
    padding-top: 5px;
    padding-bottom: 5px;

    .animation-bubble {
        background-color: $bgColourFive;
    }

    .butt-icon {
        display: inline-block;
        background-color: $lightColour;
        height: 22px;
        width: 22px;
        border-radius: $buttHeight - 10px;
        z-index: 1;
        background-position: center center;
        background-repeat: no-repeat;
        background-size: 45%;
        margin-right: 5px;
        transform: scale(0) rotate(0);
        opacity: 0;
        transition: all 0.3s;

        &.close {
            background-image: url(../images/icons/icon-close.svg);
        }
    }

    .title {
        flex: 1;
    }

    &.selected {
        background: $bgColourSix;
        color: $fontColourLight;
        border: none;
        animation-duration: 0.3s;
        animation-fill-mode: both;
        animation-name: pulseBig;
        font-weight: 600;

        .butt-icon {
            transform: scale(1) rotate(360deg);
            opacity: 1;
        }
    }

    &.hover {
        color: $bgColourFour;
    }
}

.butt-scroll {
    -webkit-appearance: none;
    width: 45px;
    height: 45px;
    border-radius: 50px;
    background-color: transparent;
    border: 2px solid white;
    background-size: 60%;
    background-repeat: no-repeat;
    background-position: center 46%;
    outline: none;
    transition: all 0.3s;
    overflow: hidden;
    margin: 10px 0;

    &.up {
        background-image: url(../images/icons/icon-arrow.svg);
    }

    &.down {
        background-image: url(../images/icons/icon-arrow-down.svg);
        background-position: center 54%;
    }

    &.left {
        background-image: url(../images/icons/icon-arrow-previous.svg);
        background-size: 35%;
        background-position: 42% 50%;
    }

    &.right {
        background-image: url(../images/icons/icon-arrow-next.svg);
        background-size: 35%;
        background-position: 55% 50%;
    }

    &:hover {
        cursor: pointer;
        animation-duration: 0.3s;
        animation-fill-mode: both;
        animation-name: pulseBig;
    }


}

.butt-add-meeting {
    margin: 0;


}
