.resources-wrap {
    flex: 1;
    display: flex;
    justify-content: center;
    align-items: flex-start;
    width: 1200px;
    padding-top: 50px;
    min-height: 80vh;

    @media all and (max-width: 1300px) {
        width: 100%;
        padding: 30px 30px 0 30px;
    }

    @media all and (max-width: 800px) {
        max-width: 100%;
        padding: 30px 20px;
        flex-direction: column;
    }

    .side-blob.resources {
        width: 23%;
        min-width: 250px;
        text-align: center;
        overflow: hidden;
        position: relative;

        @media all and (max-width: 800px) {
            width: 100%;
            margin-bottom: 30px;
            padding: 20px 40px;
           
            &#resources-categories {
                height: 112px;
            }
        }

        .heading {
            text-align: left;

            h1 {
                margin-top: 0;
            }

            .butt-toggle-categories {
                position: absolute;
                top: 65px;
                right: 30px;
                width: 40px;
                height: 40px;
                background: transparent url(../images/icons/icon-arrow-purple.svg) center center no-repeat;
                transform: rotate(90deg);
                cursor: pointer;
                transition: all 0.2s;
                padding: 0;
                border: none;
                display: none;

                &:hover {
                    transform: rotate(90deg) scale(1.1);
                }

                @media all and (max-width: 800px) {
                    display: block;
                }

            }
        }

        &.show-all {

            &#resources-categories {
                height: auto;
            }

            .butt-toggle-categories {
                transform: rotate(270deg);

                &:hover {
                    transform: rotate(270deg) scale(1.1);
                }
            }
            
        }

        .categories {
            margin-bottom: 20px;

            @media all and (max-width: 800px) {
                display: flex;
                flex-direction: row;
                flex-wrap: wrap;
                justify-content: space-between;

                form {
                    width: 100%;
                }
            }

            .butt-category {

                .title {
                    @media all and (max-width: 800px) {
                        margin-right: 5px;
                    }
                }

            }

            legend {
                display: none;
            }

            .js-form-type-checkbox {
                height: auto;
                min-height: 36px;
                border-radius: 21px;
                border: 2px solid $bgColourFive;
                background: transparent;
                width: 100%;
                text-align: left;
                position: relative;
                outline: none; // TODO: accessibility
                overflow: hidden;
                margin: 0;
                display: flex;
                flex-direction: row-reverse;
                align-items: center;
                transition: all 0.2s;
                margin-bottom: 5px;
                justify-content: space-between;

                &:hover {
                    cursor: pointer;
                    animation-duration: 0.4s;
                    animation-fill-mode: both;
                    animation-name: pulse;
                }

            }

            .form-item {


                input[type=checkbox] {
                    height: 22px!important;
                    width: 22px!important;
                    border-radius: 32px;
                    margin-right: 5px;
                    opacity: 0;
                    transform: scale(0) rotate(0);
                    transition: all .3s;
                    padding: 0;
                    border: none;

                    &:checked {
                        background-color: #fff;
                    }

                }

                label {
                    font-family: 'Open Sans', sans-serif;
                    //color: $bgColourFour;
                    color: $fontColourDark;
                    font-size: 13px;
                    font-weight: normal;
                    align-items: left;
                    display: inline-block;
                    margin: 0;
                    padding: 0;
                    pointer-events: all;
                    flex: 1;
                    height: 100%;
                    padding: 8px 0 8px 10px;
                    line-height: 13px;
                    cursor: pointer;
                }

                &.highlight {
                    animation-name: pulseBig;
                    background: $bgColourSix;
                    border: none;
                    

                    label {
                        //color: $fontColourLight;
                        color: $fontColourDark;
                        font-weight: 600;
                    }

                    input[type=checkbox] {
                        opacity: 1;
                        transform: scale(1) rotate(1turn);
                    }
                }
            }

            .js-form-submit {
                background: transparent;
                border: 2px solid $bgColourThree;
                color: $bgColourThree;
                margin: 0;
                max-width: 180px;
                width: 100%;

                .animation-bubble {
                    background: #e4e0f7;
                }

                .butt-icon {
                    background-color: $bgColourThree;
                    background-image: url(../images/icons/icon-close-light.svg);
                    background-size: 45%;
                }
            }

        }
    }

    .resources-content {
        flex: 1;
        width: 80%;
        display: flex;
        flex-direction: row;

        @media all and (max-width: 800px) {
            width: 100%;
        }

        .resource-controls {
            display: flex;
            justify-content: center;
            align-items: center;
            flex-direction: column;
            padding-left: 20px;
            padding-bottom: 50px;

            @media all and (max-width: 80px) {
                display: none;
            }
        }

        .content-wrap {
            flex: 1;
            display: flex;
            flex-wrap: wrap;
            flex-direction: row;
            //justify-content: space-between;
            justify-content: flex-start;
            max-height: 560px;
            height: 560px;
            overflow: hidden;
            padding: 0 0 0 50px;
            margin-top: -20px;

            @media all and (max-width: 800px) {
                padding: 0;
                overflow: visible;
                justify-content: space-around;
                max-height: none;
                height: auto;
            }

            
        }

        .vertical-content-wrap {
            padding: 0 0 0 50px;
            width: 100%;

            @media all and (max-width: 800px) {
                padding: 0;
            }

            .resource {
                display: none;
                width: 100%;
                background: $lightColour;
                border-radius: 10px 10px 24px 24px;
                padding: 10px 20px 20px 10px;
                color: $fontColourDark;
                margin: 20px 18px 30px 0;
                overflow: hidden;
                text-decoration: none;
                cursor: initial;

                &.show {
                    display: flex;

                    @media all and (max-width: 800px) {
                        flex-direction: column;
                        padding: 20px;
                    }
                }


                .image {
                    height: 100px;
                    min-width: 180px;
                    background: $bgColourOne;
                    border-radius: 10px;
                    transition: all 0.3s;
                    overflow: hidden;
                    aspect-ratio: 16 / 9;

                    /*@media all and (max-width: 1300px) {
                        height: 100px;
                    }*/

                    @media all and (max-width: 800px) {
                        margin-bottom: 10px;
                        height: 150px;
                    }

                    a {
                        display: block;
                        width: 100%;
                        height: 100%;
                    }

                    img {
                        object-fit: cover;
                        width: 100%;
                        height: 100%;
                        transition: all 0.4s;
                        transform: scale(1) rotate(0);
                    }
                }

                .details {
                    padding-left: 20px;

                    @media all and (max-width: 800px) {
                        padding-left: 0;
                    }

                    .categories {
                        //text-align: right;
                        color: $lightColour;

                        .category  {
                            display: inline-block;
                            font-size: $font12;
                            background: $bgColourFive;
                            width: fit-content;
                            padding: 5px 10px;
                            border-radius: 30px;
                            color: $fontColourDark;
                            text-decoration: none;
                            margin-bottom: 5px;
                            pointer-events: none;
                        }

                        ul {
                            margin: 0;
                            padding: 0;
                            display: flex;
                            list-style-type: none;

                            li {
                                font-size: $font12;
                                background: $bgColourFive;
                                width: fit-content;
                                padding: 5px 10px;
                                border-radius: 30px;
                                color: $fontColourDark;
                                text-decoration: none;
                                margin-bottom: 5px;
                                margin-right: 5px;
                                pointer-events: none;
                                
                            }
                        }
                    }

                    h2 {
                        margin: 0 0 5px 0;

                        a {
                            color: $fontColourDark;
                            text-decoration: none;
                        }
                    }

                    p {
                        margin: 0;
                        padding: 0;
                    }

                    .author {
                        font-size: $font14;
                        font-style: italic;
                        margin-top: 10px;
                    }
                }

                &:hover {
                    cursor: pointer;
                    animation-duration: 0.3s;
                    animation-fill-mode: both;
                    animation-name: pulse;

                    img {
                        transform: scale(1.1) rotate(2deg);
                    }
                }

                @media all and (max-width: 768px) {
                    width: 100%;
                    //margin: 20px 0;
                }
            }

            .js-pager__items {
                display: flex;
                list-style-type: none;
                justify-content: center;
                align-items: center;
                padding: 30px 0;
                margin: 0;

                li {
                    margin: 0 10px;
                }

                li.pager__item--first,
                li.pager__item--previous,
                li.pager__item--next,
                li.pager__item--last,
                li.pager__item {
                    padding: 0 10px;
                    margin: 0;

                    @media all and (max-width: 800px) {
                        padding: 0 5px;
                    }

                    a {
                        height: 40px;
                        width: 40px;
                        background: $lightColour;
                        border-radius: 40px;
                        display: flex;
                        justify-content: center;
                        font-weight: bold;
                        text-decoration: none;
                        color: $fontColourDark;
                        transition: all 0.2s;
                        width: fit-content;
                        min-width: 40px;
                        padding: 8px 10px;
                        white-space: nowrap;

                        &:hover {
                            transform: scale(1.1);
                        }

                        @media all and (max-width: 800px) {
                            font-size: $font12;
                            min-width: fit-content;
                            padding: 10px;
                        }
                    }

                    &.is-active {

                        a {
                            background: $bgColourThree;
                            color: $fontColourLight;
                        }
                        

                    }
                }
            }
        }
    }

}
