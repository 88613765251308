$buttHeight: 42px;
$headerHeight: 100px;
$footerHeight: 50px;

$font10: 0.63rem;
$font12: 0.75rem;
$font14: 0.88rem;
$font16: 1rem;
$font18: 1.1rem;
$font19: 1.2rem;
$font30: 1.88rem;