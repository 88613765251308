.webform-submission-form {
    margin-bottom: 70px;

    h2 {
        font-size: 2rem;
        text-align: center;
    }

    p {
        text-align: center;
    }

    #edit-processed-text {
        margin-bottom: 40px;
    }

    .js-form-item {
        // max-width: 390px;
        max-width: 600px;

        textarea {
            background-color: hsla(0,0%,100%,.45);
        }

        &.form-item-consent {

            input[type=checkbox] {
                margin-right: 0;
            }

            label {
                padding: 0 10px 0 5px;
            }
        }

        @media all and (max-width: 800px) {
            width: 100%;
            padding: 0 40px;
        }
    }

    #edit-actions {
        text-align: center;

        input[type=submit] {
            text-align: center;
            padding: 0 42px;
            margin: 20px 0 50px;
        }
    }

    
}