.login-content {
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;

    .form-content {
        max-width: 390px;
        // padding-top: 30px;

        .butt-general {
            margin: 0;
        }

        @media all and (max-width: 800px) {
            padding: 30px 20px;
        }
    }

    p {
        margin: 40px 0;
        font-size: 0.9em;
    }

    .link-dark {
        color: $fontColourDark;
    }
}

.section-main {

    h1 {
        margin: 20px 0;
    }

    .form-wrap {
        display: flex;
        justify-content: center;
        // max-width: 1320px;
        padding: 30px 60px;
        width: 100%;

        @media all and (max-width: 800px) {
            padding: 30px 20px;
        }
    }

    .social-auth.auth-link {
        display: block;
    }

    .error-message {
        display: none;
        border-radius: 10px;
        font-size: 0.9em;
        background: $listDotColourPink;
        color: $lightColour;
        padding: 10px;
        margin-bottom: 5px;
        margin-top: 5px;
        text-align: center;

        &.visible {
            display: block;
        }
    }
}