.section-main {
    // max-width: 1200px;
    min-height: calc(100vh - 150px); // 100px is header height + footer height but sass won't parse the variable for this
    height: calc(100vh - 150px);
    // margin: 0 auto;
    display: flex;
    justify-content: center;
    align-items: center;
    //overflow-x: hidden;

    @media all and (max-width: 900px) {
        height: auto;
    }

    @media all and (max-width: 768px) {
        padding-top: 70px;
    }

    &.login, &.contact-us, &.partners {
        height: calc(100vh - 100px);

        @media all and (max-width: 768px) {
            height: calc(100vh - 70px);
        }
    }

    .side-blob {
        background: $lightColour;
        border-radius: 40px;
        box-shadow: 2px 2px 40px 1px rgba(36,58,71,0.25);
        color: $fontColourDark;
        padding: 40px;
    }

    #block-mywayemployability-content {
        flex: 1;
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100%;
        max-width: 1320px;
        padding: 0 60px;
        // entity_legal inserts its checkbox form alogside the child div of this element
        // making the checkbox display to the right.
        // Force the form to be below the content by setting flex-direction to column.
        flex-direction: column;

        @media all and (max-width: 1300px) {
            max-width: 100%;
            padding: 0;
        }

    }

    &.resources, &.align-top {
        #block-mywayemployability-content {
            //align-items: flex-start;
        }
    }

    &.align-top {
        height: auto;
    }


}

.section-main > div:first-child { // Drupal is putting an empty div in here for reasons? So we gotta target this way to style
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    height: 100%;
    width: 100%;
}
