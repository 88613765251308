.user-register-form, .user-form {
    //display: flex;

    @media all and (max-width: 1300px) {
        padding: 0 50px;
    }

    @media all and (max-width: 900px) {
        flex-direction: column;
        justify-content: center;
        align-items: center;
        padding: 0 0 50px 0;
    }

    h1.header-small {
        display: none;
        margin: 40px 0 50px;

        @media all and (max-width: 900px) {
            display: block;
        }
    }

    h1.header {
        text-align: center;
        margin: 50px 0 0;
    }

    .hide {
        height: 0;
        width: 0;
        pointer-events: none;
        visibility: hidden;
    }

    .form-wrap {
        display: flex;
        justify-content: center;
        max-width: 1320px;
        padding: 0 60px;
        width: 100%;

        @media all and (max-width: 800px) {
            padding: 0 20px;
        }
    }

    .form-info-copy {
        text-align: center;
    }

    .profile-section {
        margin-bottom: 60px;

        h2 {
            font-size: $font19;
            border-bottom: 1px solid;
            padding-bottom: 10px;
            margin-bottom: 30px;
        }

        &.email-preferences {

            .field--type-mailchimp-lists-subscription {

                [data-drupal-selector="edit-field-subscribers-0-value"] {
                    border: none;
                    padding: 0;
                    margin-bottom: 0;

                    .form-item-field-subscribers-0-value-subscribe {
                        display: none;
                    }

                }
                
                [data-drupal-selector="edit-field-subscribers-0-value-interest-groups"] {
                    border: none;
                    padding: 0;
                    margin-bottom: 0;
                    display: block!important;

                    .js-form-item:last-child {
                        margin-bottom: 0;
                    }
                }

                input {
                    margin-right: 0;
                }

                legend {
                    display: none;
                }

                #edit-field-subscribers-0-value--description {
                    font-size: $font12;
                    font-style: italic;
                    text-align: center;
                    margin-top: 20px;
                }


            }

        }
    }
}



.side-blob.register {
    width: 35%;
    min-width: 200px;
    //height: 475px;
    padding-bottom: 20px;
    background: transparent;
    box-shadow: none;


    @media all and (max-width: 900px) {
        width: 70%;
        height: auto;
    }

    p {
        margin: 0;
    }

    .avatar-list {
        width: 100%;
        position: relative;
        display: flex;
        flex-flow: wrap;
        justify-content: space-around;
        padding: 20px 0;

        @media all and (max-width: 900px) {
            height: auto;
            justify-content: center;
        }

        .avatar {
            width: 40%;
            height: 90px;
            display: inline-block;
            margin-bottom: 20px;
            border-radius: 30px;
            position: relative;
            overflow: hidden;
            //border: 1px solid #EBEBEB;
            background: white;

            @media all and (max-width: 900px) {
                width: 20%;
                margin: 0 10px;
            }

            img {
                object-fit: contain;
                width: 105%;
                height: 105%;
                transition: all 0.4s;
            }

            &:hover {
                cursor: pointer;
                animation-duration: 0.4s;
                animation-fill-mode: both;
                animation-name: pulse;

                img {
                    transform: scale(1.3) rotate(8deg);
                }
            }

            &.selected {
                animation-duration: 0.3s;
                animation-fill-mode: both;
                animation-name: pulseBig;
                border: 3px solid #ffc371;
            }
        }

    }

    .image-upload {
        padding-top: 30px;
        text-align: center;

        label {
            visibility: hidden;
        }

        input[name="files[user_picture_0]"] {
            visibility: hidden;
        }

        .image-widget {
            display: flex;
            justify-content: center;
            align-items: center;
            margin-top: 20px;

            img {
                border-radius: 20px;
                width: 70px;
                height: 70px;
            }

            span {
                padding: 0 10px;

                a {
                    max-width: 100px;
                    overflow: hidden;
                    display: block;
                    word-break: break-all;
                }
            }

            .file-size {
                display: none;
            }

            input[name="user_picture_0_remove_button"] {
                text-align: center;
                justify-content: center;
                margin: 0;
                padding: 0;
                width: 100px;
            }
        }
    }
}

.register-content {
    flex: 1;
    // padding: 10px 0 0 40px;
    width: 70%;

    h1 {
        display: block;

        @media all and (max-width: 900px) {
            display: none;
        }
    }

    .team-member-info {
        display: none;
    }

    #show-confirm-pw {
        margin: 30px 0;

        .butt-general {
            margin: 0;
            width: 100%;
            transition: transform 0.3s ease-out;
        }

    }

    .user-profile-edit {
        justify-content:center !important;

        .hide {
            width: auto !important;
        }
        input.button.button--primary.btn-show-confirm-pw{
            margin-left: 0px !important;
        }
        input[type=submit] {
            justify-content:center;
        }

        input.button.button--primary.btn-show-confirm-pw {
            margin-bottom: 20px;
        }

        .btn-show-confirm-pw {
            display: block;
            width: 100%;
            font-size: $font14;
            text-decoration: underline;
            cursor: pointer;
            text-align: center;
            transition: all 0.2s;

            &:hover {
                transform: scale(1.05);
            }
        }

        label {
            align-items: center;
            display: flex;
            font-size: .88rem;
            font-weight: 700;
            margin: 0 0 5px;
            max-height: 50px;
            padding: 0 0 0 20px;
            pointer-events: none;
            transition: all .3s;
            color: $fontColourDark;
        }
    }
    

    .form-content {
        //display: flex;
        //flex-direction: row;
        //justify-content: space-between;
        max-width: 390px;
        margin: 0 auto;

        @media all and (max-width: 800px) {
            padding: 30px 20px;
        }

        .content-column {
            flex: 1;
            max-width: 48%;
        }

        .custom-field {
            // margin: 0 0 40px 0;
            position: relative;

            .show-parent-consent {
                position: absolute;
                width: 40px;
                height: 40px;
                left: -50px;
                top: 5px;
                border-radius: 40px;
                border: none;
                -webkit-appearance: none;
                pointer-events: cursor;
                cursor: pointer;
                background: $bgColourTwo url(../images/icons/icon-user.svg) center center no-repeat;
                display: none;

                &.visible {
                    display: block;
                }

                @media all and (max-width: 768px) {
                    left: -40px;
                }

            }

            p {
                margin-top: 40px;
                font-size: 0.9em;
            }

            .description > a,
            .link-dark {
                color: $fontColourDark;
            }

            &.hide {
                visibility: hidden;
            }

            .field--type-address {
                summary {
                    display: none;
                }
            }

        }

        #confirm-pw {
            height: 0;
            overflow: hidden;
            transition: height 0.3s;

            &.visible {
                height: 250px;

                @media all and (max-width: 800px) {
                    height: 300px;
                }
            }

        }

        

        .form-actions {
            text-align: center;
            margin-top: 40px;

            #edit-submit {
                width: 220px;
                justify-content: center;
                padding: 0;
            }

            .butt-general {
                margin: 0;
    
                .butt-icon {
                    &.register {
                        background-image: url(../images/icons/icon-register-light.svg);
                    }
                }
            }
        }

        .already-registered {
            margin: 60px 0 30px;
            text-align: center;
        }

    }
}

.team-member {

    .side-blob.register {
        display: none;
    }

    .register-content {
        padding: 0;
        max-width: 720px;

        h1 {
            text-align: center;

            &.youth-member {
                display: none;
            }
        }

        .team-member-info {
            display: block;
            text-align: center;
            color: $fontColourDark;
        }
    }

}

.parent-consent-popup {
    position: fixed;
    width: 100vw;
    height: 100%;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    margin: auto;
    background: rgba(93, 165, 205, 0.6);
    z-index: 2;
    display: none;
    justify-content: center;
    align-items: center;

    &.visible {
        display: flex;
    }

    .error-message {
        display: none;
        border-radius: 10px;
        font-size: 0.9em;
        background: $listDotColourPink;
        color: $lightColour;
        padding: 10px;
        margin-top: 5px;
        text-align: center;

        &.visible {
            display: block;
        }
    }

    .parent-consent-fields {
        color: $fontColourDark;
        width: 40%;
        background: white;
        padding: 20px 40px 40px;
        border-radius: 30px;
        min-width: 500px;
        text-align: center;
        position: relative;

        @media all and (max-width: 768px) {
            min-width: 90%;
            width: 90%;
        }

        .consent-form {
            display: none;

            &.visible {
                display: block;
            }
        }

        .custom-field {
            text-align: left;

            #edit-field-parent-consent-wrapper {
                font-size: 0.75em;
            }
        }

        p {
            padding-bottom: 20px;
        }

        .form-item {
            input[type=text],
            input[type=email] {
                background: $bgColourOne;
            }

            &.js-form-type-checkbox {
                display: flex;
                align-items: flex-start;

                label {
                    display: none;
                }
            }
        }

    }

    .butt-close {
        position: absolute;
        top: 0;
        right: -50px;
        width: 40px;
        height: 40px;
        border-radius: 40px;
        border: none;
        background: white;
        color: $bgColourEight;
        font-size: 20px;
        line-height: 30px;
        padding: 0;
        margin: 0;
        padding-bottom: 5px;
        text-align: center;
        cursor: pointer;

        @media all and (max-width: 768px) {
            right: 5px;
            top: 5px;
        }
    }
}

.social-auth .auth-icon {
    width: 18em !important;
}