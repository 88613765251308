.sending-invites-form {

    @media all and (max-width: 640px) {
        width: 100vw;
        padding: 0 20px;
    }

    #team-member-mail-fieldset-wrapper,
    #young-person-mail-fieldset-wrapper {
        max-width: 600px;
        margin-bottom: 60px;
        text-align: center;
    }

    #edit-team-member-mail-fieldset,
    #edit-young-person-mail-fieldset,
    [data-drupal-selector="edit-young-person-mail-fieldset"],
    [data-drupal-selector="edit-team-member-mail-fieldset"] {
        margin: 0;
        padding: 0;
        border: none;
    }

    .js-form-submit {
        background: $bgColourThree;
        color: $fontColourLight;
        margin-top: 20px;

        .butt-icon {
            background: $fontColourLight url(../images/icons/icon-arrow-next-purple.svg);
            background-position: 58% 50%;
            background-repeat: no-repeat;
        }

        &#destructive {
            background: #dc3830;

            .butt-icon {
                background: #dc3830 url(../images/icons/icon-close-light.svg);
                background-position: 58% 50%;
                background-repeat: no-repeat;
            }
        }

        &:focus {
            outline: 1px auto -webkit-focus-ring-color!important;
        }
    }
    

    .inner-fieldset {
        background: $lightColour;
        margin: 40px 0;
        border-radius: 50px;
        padding: 20px 40px;
        box-shadow: 2px 2px 40px 1px rgba(36,58,71,0.25);

        @media all and (max-width: 640px) {
            padding: 20px;
        }

        .member-details {
            text-align: center;
            margin-bottom: 30px;

            .member-name {
                font-weight: bold;
            }

            &.pending {
                font-style: italic;
            }

        }

        .member-email-wrap {
            display: flex;
            align-items: flex-end;

            .member-icon {
                height: 50px;
                width: 50px;
                min-width: 50px;
                border-radius: 50px;
                margin-right: 10px;
                color: $fontColourLight;
                display: flex;
                justify-content: center;
                align-items: center;
                font-weight: 600;
                font-size: $font18;

                &.pending {
                    background: none;
                    border: 1px solid $bgColourFourteen;
                }
            }

        }

        .form-item {
            margin: 0;
        }
    }

    .inner-fieldset {

        &:nth-child(1) {
            .member-icon {
                background: #86CC88;
            }
            
        }

        &:nth-child(2) {
            .member-icon {
                background: $bgColourEight;
            }
            
        }

        &:nth-child(3) {
            .member-icon {
                background: #EE9A95;
            }
        }

    }

    #young-person-mail-fieldset-wrapper {
        .fieldset-wrapper {
            margin-top: 50px;

            .js-form-submit {
                background: $fontColourLight;
                color: $buttColour;

                .butt-icon {
                    background: $buttColour url(../images/icons/icon-arrow-next.svg);
                    background-position: 58% 50%;
                    background-repeat: no-repeat;
                }
        
                &#destructive {
                    background: $listDotColourPink;
                }
            }
        }
    }

    .message-block {
        &.no-members {
            margin-top: 50px;
        }
    }

    .consent-disclaimer {
        font-size: $font12;
        padding: 20px;
    }

    .all-invites-wrap {
        display: flex;
        flex-direction: column-reverse;

        .inner-fieldset {
            margin-top: 0;
        }
    }

    .invite-members {
        margin-top: 50px;

        .js-form-type-checkbox {
            justify-content: center;

            label {
                display: inline-block!important;
                padding: 0;
            }
        }
    }

}