.home-wrap {
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 1200px;

    #pre-splash-header {
        margin-top: 50px;
        text-align: center;

        h1 {
            color: $fontColourLight;
            margin: 0 auto 30px auto;
        }

        h2 {
            @media (max-width: 768px) {
                max-width: 20em;
            }
        }
    }

    @media all and (max-width: 1300px) {
        width: 100%;
        padding: 0 30px;
    }

    @media all and (max-width: 800px) {
        max-width: 100%;
        padding: 0 20px;
        overflow: hidden;
    }

    .splash {
        width: 100%;
        display: flex;
        justify-content: space-evenly;
        align-items: center;
        padding: 0 0 3.5vh;

        @media all and (max-width: 1300px) {
            flex-direction: column;
            height: auto;
            margin: 0;
            width: 90%;
            padding: 0;
            margin-bottom: 30px;

        }

        .splash-image,
        .splash-logo {
            flex: 1;
            text-align: center;

            img {
                width: 80%;
                object-fit: contain;
            }
        }

        .splash-logo,
        .splash-text {
            max-width: 40%;
            //font-size: 1.35em;
            font-weight: 600;
            color: $fontColourDark;
            text-align: center;

            @media (max-width: 1300px) {
                max-width: 100%;
            }
            p {
                margin-left: auto;
                margin-right: auto;
                max-width: 24em;
                font-size: 1.35em;
            }
        }

        .splash-image {
            flex: 1;
            text-align: center;
            max-width: 50%;
            @media all and (max-width: 1300px) {
                max-width: 450px;
                margin-top: 30px auto 0 auto;
            }            
            img {
                width: 100%;
                object-fit: contain;
            }
        }
    }

    section.first-actions {
        margin-bottom: 50px;

        div {
            text-align: center;
        }

    }

    .what-is {
        background: $lightColour;
        border-radius: 50px;
        text-align: center;
        max-width: 83%;
        padding: 20px 50px 40px;
        box-shadow: 2px 2px 40px 1px rgba(36,58,71,0.25);

        h2 {
            color: $fontColourDark;
        }

        @media all and (max-width: 1100px) {
            max-width: 100%;
            padding: 20px;
        }

        .what-is-content {
            display: flex;
            text-align: left;
            color: $fontColourDark;
            padding-top: 0;

            @media all and (max-width: 1100px) {
                flex-direction: column-reverse;
            }

            .image-wrap {
                flex: 1;
                max-width: 30%;
                margin: 0 50px;
                min-width: 250px;
                align-self: flex-start;

                @media all and (max-width: 1100px) {
                    min-width: auto;
                    max-width: 100%;
                    margin: auto;
                }

                img {
                    object-fit: contain;
                    width: 100%;

                    @media all and (max-width: 1100px) {

                        max-width: 450px;
                        margin-bottom: 20px;

                    }
                }
            }

            .text-wrap {
                max-width: 110%;
                @media all and (max-width: 1100px) {
                    max-width: 100%;
                }
            }


            >div {
                margin: 0 10px;
            }

            h2 {
                margin: 0;
                color: $fontColourDark;
            }

            ul {
                padding-left: $font18;

                li {
                    margin-bottom: .5em;

                    &:first-child {
                        &::marker {
                            color: $listDotColourBlue;
                        }
                    }

                    &:nth-child(2) {
                        &::marker {
                            color: $listDotColourPink;
                        }
                    }

                    &:nth-child(3) {
                        &::marker {
                            color: $listDotColourYellow;
                        }
                    }

                    &:nth-child(4) {
                        &::marker {
                            color: $listDotColourGreen;
                        }
                    }
                }
            }

            ul {
                padding-left: $font18;

                li {
                    margin-bottom: .5em;
                    font-size: 1.125rem;

                    &:first-child {
                        &::marker {
                            color: $listDotColourBlue;
                        }
                    }

                    &:nth-child(2) {
                        &::marker {
                            color: $listDotColourPink;
                        }
                    }

                    &:nth-child(3) {
                        &::marker {
                            color: $listDotColourYellow;
                        }
                    }

                    &:nth-child(4) {
                        &::marker {
                            color: $listDotColourGreen;
                        }
                    }
                }
            }
        }
    }

    .whats-involved {
        padding: 100px 0;
        text-align: center;
        width: 100%;

        .involved-content {
            text-align: left;
            display: flex;
            width: 100%;
            justify-content: center;

            @media all and (max-width: 768px) {
                flex-direction: column;
                justify-content: center;
                align-items: center;
            }

            .involved-list {
                width: 30%;
                margin: 0 30px;
                display: flex;
                flex-direction: column;
                justify-content: space-between;

                @media all and (max-width: 768px) {
                    width: 90%;
                    margin-bottom: 50px;
                }

                h2 {
                    margin: 0;
                    background: $bgColourEight;
                    border-radius: 50px;
                    text-align: center;
                    padding: 20px;
                    font-size: 1.2em;
                }

                ul {
                    color: $fontColourDark;
                    margin: 20px 0 30px;
                    line-height: 2.2em;
                    list-style: none;

                    li::before {
                        content: "\2022";
                        color: $bgColourThree;
                        font-weight: bold;
                        display: inline-block;
                        width: $font16;
                        margin-left: -$font16;
                    }
                }

                .image-wrap {
                    width: 80%;
                    margin: 0 auto;

                    img {
                        object-fit: cover;
                        width: 100%;
                    }

                    @media all and (max-width: 768px) {
                        margin-top: 30px;
                    }
                }
            }
        }
    }
 
    .supporting-resources {
        padding: 95px 0 30px;
        text-align: center;
        width: 100%;
        max-width: 1000px;

        @media all and (max-width: 768px) {
            padding: 30px 0;
            max-width: 100%;
        }


        .supporting-content {
            text-align: left;
            display: flex;
            width: 100%;
            justify-content: center;

            @media all and (max-width: 1100px) {
                flex-direction: column;
                justify-content: center;
                align-items: center;
                //width: 80%;
                //max-width: 80%;
                width: 100%;
                margin: auto;
                text-align: center;
                margin-top: 30px;
            }

            .supporting-text {
                flex: 1;
                color: $fontColourDark;
                margin-bottom: 50px;
                //margin-top: 20px;

                h1 {
                    margin-top: 0;
                }

                @media all and (max-width: 1100px) {
                    margin-bottom: 20px;
                }
            }

            .butt-resources {
                margin: 10px 0 20px -5px;

            }

            .content-wrap {
                flex: 2;
                display: flex;
                flex-wrap: wrap;
                flex-direction: row;
                justify-content: flex-start;
                width: 100%;

                padding: 0 0 0 50px;

                @media all and (max-width: 1100px) {
                    padding: 0;
                    overflow: visible;
                    justify-content: space-around;
                    max-height: none;
                    height: auto;
                }

                .resource {
                    max-width: 50%; //changed to max
                    min-width: 300px; //added in
                    background: $lightColour;
                    border-radius: 10px 10px 24px 24px;
                    padding: 10px;
                    color: $fontColourDark;
                    text-align: center;
                    max-height: 230px;
                    overflow: hidden;
                    text-decoration: none;
                    flex: 1; // changed to flex

                    margin: 0 8px 18px;
                

                    &.show {
                        display: block;
                    }

                    .image {
                        width: 100%;
                        height: 140px;
                        background: $bgColourOne;
                        border-radius: 10px;
                        transition: all 0.3s;
                        overflow: hidden;

                        @media all and (max-width: 768px) {
                            height: 160px;
                        }

                        img {
                            object-fit: cover;
                            width: 105%;
                            height: 105%;
                            transition: all 0.4s;
                            transform: scale(1) rotate(0);
                        }
                    }

                    p {
                        margin-bottom: 5px;
                        padding: 0 5px;
                    }

                    &:hover {
                        cursor: pointer;
                        animation-duration: 0.3s;
                        animation-fill-mode: both;
                        animation-name: pulse;

                        img {
                            transform: scale(1.1) rotate(2deg);
                        }
                    }

                    @media all and (max-width: 768px) {
                        width: 45%;
                        //margin: 20px 0;
                    }
                }
            }
        }
    }

    .for-me {
        text-align: center;
        padding-bottom: 100px;
        width: 100%;

        @media all and (max-width: 768px) {
            max-width: 90%;
        }

        .for-me-content {
            background: $lightColour;
            border-radius: 50px;
            text-align: center;
            max-width: 67%;
            width: 67%;
            padding: 50px;
            box-shadow: 2px 2px 40px 1px rgba(36,58,71,0.25);
            margin: 0 auto;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;

            @media all and (max-width: 1300px) {
                max-width: 80%;
                width: 80%;
            }

            @media all and (max-width: 768px) {
                max-width: 100%;
                width: 100%;
                padding: 20px;
            }

            .button-wrap {
                display: flex;

                a.butt-general {
                    font-size: 16px;
                }
            }

            .video {
                width:80%;
                max-width:600px;
                height:50%;
                margin-bottom: 30px;

                .iframe-wrapper {
                    position:relative;
                    padding-top: 56.25%;
                }

                iframe {
                    position:absolute;
                    top:0;
                    left:0;
                    width:100%;
                    height:100%;
                }
            }

            .mobile-copy {
                color: $fontColourDark;
                font-weight: bold;
                display: none;
                padding-bottom: 10px;
                @media all and (max-width: 767px) {
                    display: block;
                }

                @media all and (max-height: 412px) {
                    display: block;
                }
            }


        }

    }

    .butt-find-out-more {
        @media all and (max-width: 768px) {
            margin: 0;
        }
    }
}

#front-register {
    padding-bottom: 50px;
}