.partners-wrap {
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 1200px;

    @media all and (max-width: 1300px) {
        width: 100vw;
    }

    .partner-content {
        display: flex;
        justify-content: center;
        align-items: center;
        margin-top: 50px;

        .partner {
            width: 25%;
            background: $lightColour;
            border-radius: 10px 10px 24px 24px;
            padding: 10px;
            color: $fontColourDark;
            text-align: left;
            margin: 0 10px 20px;
            height: 230px;
            max-height: 230px;
            overflow: hidden;
            text-decoration: none;

            @media all and (max-width: 768px) {
                width: 30%;
            }

            h2 {
                font-size: $font16;
                margin: 15px 0 0;
            }

            .image {
                width: 100%;
                height: 130px;
                background: $bgColourOne;
                border-radius: 10px;
                transition: all 0.3s;
                overflow: hidden;

                img {
                    object-fit: contain;
                    width: 100%;
                    height: 100%;
                    transition: all 0.4s;
                    transform: scale(1) rotate(0);
                }

                @media all and (max-width: 1024px) {
                    height: 110px;
                }
            }

            p {
                margin-bottom: 5px;
                padding: 0 5px;
                font-size: 0.8em;
                overflow: hidden;
                overflow-wrap: break-word;
            }

            &:hover {
                cursor: pointer;
                animation-duration: 0.3s;
                animation-fill-mode: both;
                animation-name: pulseBig;

                img {
                    transform: scale(1.1) rotate(3deg);
                }
            }

        }

    }
}