$bgColourOne: #c0e5f7; // Light blue
$bgColourTwo: #5da5cd; // Dark Blue
$bgColourThree: #7b68c8;//#9181D1; // Purple
$bgColourFour: #786ac1;//#7C6EC3; // Dark Purple
$bgColourFive: #E4E0F7; // Light Purple
$bgColourSix: #AA9EE3; // Purple 2
$bgColourSeven: #4C4180; // Dark Purple 2
$bgColourEight: #59A3D0; // Dark Blue 2
$bgColourNine: #d5f2d4; // Light Green
$bgColourTen: #f8dada; // Light Pink
$bgColourEleven: #f7e9b8; // Light Yellow
$bgColourTwelve: #d3e9f7; // Light Blue 2
$bgColourThirteen: #e4e0f7; // Light Purple
$bgColourFourteen: #ebebeb; // Grey
$bgColourDarkYellow: #f8d87b; // Yellow
$buttColour: #337ca5;//#5da5cd; // Mid Blue
$buttBgColourDarkYellow: #f8d974;
$buttBgColourYellow: #F9D87C;
$buttBgColourLightYellow: #f8eab6;
$buttBgColourLightPurple: #e4e0f8;
$bgColourLightYellow: #fcf7e4; // Light Yellow
$bgColourDarkGreen: #addfae; // Dark Green
$bgColourLightGreen: #d5f2d4; // Light Green 2
$bgColourDarkPink: #f4bdba; // Dark Pink
$fontColourDark: #323333; // Dark Grey
$fontColourLight: #ffffff; // White
$fontColourPurple: #5243a0;//#786ac1;//#9b8ed3;
$lightColour: #ffffff; // White
$listDotColourPink: #ea8782; // Strong Pink
$listDotColourYellow: #f4c760; // Strong Yellow
$listDotColourBlue: #59a3d0; // Strong Blue
$listDotColourPurple: #9e92d7;//#9082d1; // Strong Purple
$listDotColourGreen: #88cb88; // Strong Green
$listDotColourGrey: #9c9e9d; // Strong Grey


