.profile-content {

	@media all and (max-width: 1300px) {
        width: 100%;
        padding: 0 30px;
    }

    @media all and (max-width: 800px) {
        max-width: 100%;
        padding: 0 20px;
        overflow: hidden;
    }

	.ajax-progress-throbber {
		display: none;
	}

	h1 {
		font-size: 32px;

		@media only screen and (max-width: 1300px) {
			font-size: 26px;
		}

		&.about-title {
			word-break: break-all;
			margin-right: 50px;

			@media all and (max-width: 800px) {
				margin-right: 0;
			}
		}
	}

	h2 {
		font-size: 24px;
	}

	h3 {
		font-size: 20px;
	}

	p {
		font-size: 16px;
	}

	a {
		text-decoration: none;
		font-weight: bold;
		font-size: 14px;
		color: #7662c6;

		&:hover {
			color: $fontColourDark;
		}
	}

	.profile-header {
		display: flex;
		justify-content: space-between;
		margin-top: 100px;

		@media all and (max-width: 800px) {
			flex-direction: column;
			margin-top: 50px;
		}

		.profile-person-details {
			color: $fontColourDark;

			.employment-status {
				margin-top: 25px;
			}

			.education-status,
			.email {
				margin-top: 20px;
			}

			.details-section-title {
				margin-bottom: 0;
			}

			.details-section-content {
				margin: 0;
			}

			@media all and (max-width: 800px) {
				margin-bottom: 30px;
				text-align: center;
			}
		}

		.profile-support-information {
			background: $lightColour;
			border-radius: 40px;
			color: $fontColourDark;
			padding: 0 33px 17px 33px;
			width: 60%;
			align-self: flex-start;

			@media all and (max-width: 800px) {
				width: 100%;
			}

			.profile-support-information-title {
				.profile-support-logo {
					width: 180px;
					margin-right: 20px;
				}

				margin-top: 20px;
				display: flex;
				align-items: flex-end;
			}
		}
	}

	.profile-quiz-section {
		margin-top: 45px;

		.profile-results-section {
			background: $lightColour;
			border-radius: 40px;
			color: $fontColourDark;
			padding: 26px 25px 30px 25px;
			margin-bottom: 50px;

			.butt-more-for-support-wrap {
				display: flex;
				justify-content: center;
			}

			.profile-results-title {
				display: flex;
				align-items: center;

				.icon-category {
					border-radius: 34px;
					//z-index: 1;
					background-position: 50%;
					background-repeat: no-repeat;
					background-size: 60%;
					width: 68px;
					height: 68px;
					margin-right: 15px;

					&.career {
						background-color: $bgColourEight;
						background-image: url(../images/icons/icon-career.svg);
					}

					&.work {
						background-color: #f3c762;
						background-image: url(../images/icons/icon-work.svg);
					}

					&.life {
						background-color: #ee9a95;
						background-image: url(../images/icons/icon-life.svg);
					}

					&.training {
						background-color: #86cc88;
						background-image: url(../images/icons/icon-training.svg);
					}

					&.strengths {
						background-color: $bgColourThree;
						background-image: url(../images/icons/icon-strengths.svg);
					}
				}
			}

			.profile-results-subtitle {
				margin: 30px 30px 0 30px;

				@media all and (max-width: 800px) {
					margin: 30px 0 0;
				}
			}

			.empty-section {
				display: flex;
				flex-direction: column;
				align-items: center;
				margin-top: 60px;

				.icon-empty {
					width: 36px;
					height: 36px;
					background-image: url(../images/icons/icon-exclamation.svg);
				}

				p {
					width: 65%;
					text-align: center;
					margin-bottom: 90px;
					font-size: 20px;
				}
			}

			ul {
				padding-left: 15px;
				padding-right: 40px 
			}

			ul li {
				margin-bottom: 20px;
			}

			ul li::marker {
				margin-right: 11px;
				vertical-align: middle;
				color: #40a3d4;
			}

			.career-top-three-heading {
				margin-left: 30px;
				margin-top: 40px;

				@media all and (max-width: 800px) {
					margin-left: 0;
				}
			}

			.grid-results {
				display: grid;
				grid-template-columns: 25% 25% 25% 25%;
				//padding: 0 30px;
				//margin-top: 30px;
				margin: 30px 30px 0 30px;

				&.life {
					grid-template-columns: 100%;
				}

				&.strengths {
					grid-template-columns: 100%;

					ul {
						column-count: 2;
						column-gap: 20px;
						margin-top: 0;

						@media all and (max-width: 800px) {
							column-count: 1;
						}
					}
				}

				&.training {
					grid-template-columns: 50% 50%;
				}

				&.career {
					grid-template-rows: 1fr 0.5fr;
					grid-template-columns: repeat(3, 1fr);
					margin-top: 32px;

					@media all and (max-width: 800px) {
						grid-template-rows: 1fr 0.5fr;
						grid-template-columns: repeat(1, 1fr);
					}
				}

				.grid-item {
					padding: 0 20px;
					border-left: 1px solid #c3c3c3;

					h3 {
						margin-top: 0;
					}

					&:first-child {
						border-left: none;
						padding-left: 0;
					}

					&:last-child {
						padding-right: 0;
					}

					.not-available {
						margin-top: 30px;
					}
				}

				&.work, &.training {

					@media all and (max-width: 800px) {
						grid-template-columns: 100%;
					}

					.grid-item {
						border: none;
						padding: 0;
					}

				}

				.career-grid-item {
					padding-bottom: 30px;

					&.career-bottom-three {
						padding-top: 23px;
						border-top: solid 1px #c3c3c3;
					}

					@media all and (max-width: 800px) {
						padding-top: 23px;
						border-top: none;
						border-bottom: solid 1px #c3c3c3;

						&.career-bottom-three {
							border-top: none;
						}
					}

					.career-grid-item-content-wrap {
						.career-type-heading {
							display: flex;
							flex-direction: row;

							.career-percentage {
								border-radius: 32px;
								//z-index: 1;
								background-repeat: no-repeat;
								width: 65px;
								height: 65px;
								display: flex;
								justify-content: center;
								align-items: center;

								&.career-top-three {
									background-color: #d4e9f7;
								}

								&.career-bottom-three {
									background-color: #dae2e8;
								}
							}

							.career-type-title {
								padding-left: 10px;
							}
						}

						.career-type-blurb {
							width: 90%;
							margin-top: 15px;

							@media all and (max-width: 800px) {
								width: 100%;
							}
						}

						.link-career-suggestions {
							text-decoration: none;
							font-weight: bold;
							display: block;

							&.career-bottom-three {
								padding-top: 15px;
							}
						}
					}
				}
			}
		}
	}
}