// Fonts
@import url('https://fonts.googleapis.com/css?family=Open+Sans:400,700&display=swap');

// Variables and reusables
@import 'sizes';
@import 'colours';
@import 'keyframes';
@import 'mixins';

// Overrides
@import 'drupal-core-overrides';

// Resusable page bits
@import 'body';
@import 'header';
@import 'footer';
@import 'buttons';
@import 'section-main';

// Individual pages
@import 'form-webform-general';
@import 'page-register';
@import 'page-login';
@import 'page-resources';
@import 'page-resource';
@import 'page-contact-us';
@import 'page-privacy-terms';
@import 'page-partners';
@import 'page-home';
@import 'page-dashboard';
@import 'page-quiz-intro';
@import 'page-quiz';
@import 'page-quiz-result-strength';
@import 'page-strength-article';
@import 'page-job';
@import 'pages-supportlayout';
@import 'page-add-edit-goal';
@import 'form-twofactory';
@import 'page-young-person-profile-report';
@import "page-dash-service-provider-employer";
@import "dialog-evidence";
@import "page-whyareyouhere";
@import "form-invites";
