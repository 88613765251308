.ui-widget-overlay {
    opacity: 0.7 !important;
    background: black !important;
}

.evidence-dialog {
    .ui-dialog-titlebar {
        display: none;
    }

    border: 0 !important;
    background: transparent;
    padding-left: 0px;
    overflow: hidden auto;
    height: 100vh !important;
}

.evidence-dialog>#drupal-modal {
    margin: 1.75rem auto;
    transform: translate(0, 0);
    max-width: 1200px;
    min-height: auto !important;
    max-height: none !important;
    padding: .5em 0;

    @media only screen and (max-width: 1300px) {
        padding: .5em 30px;
    }

    @media all and (max-width: 600px) {
        padding: 10px;
    }
}

.evidence-container {
    font-family: "Open Sans", "sans-serif";
    padding: 30px 48px;
    background-color: white;
    border-radius: 15px;
    position: relative;

    @media only screen and (max-width: 1300px) {
        padding: 17px 28px 17px 25px;
    }

    @media all and (max-width: 600px) {
        padding: 15px;
    }

    a {
        color: #7662c6;

        &:hover {
            color: $fontColourDark;
        }
    }

    .evidence-header {
        display: flex;
        flex-direction: row;
        align-items: center;

        @media all and (max-width: 600px) {
            align-items: flex-start;

            h1 {
                line-height: 1.6rem;
                margin-bottom: 10px;
            }
        }

        .close-container {

            @media all and (max-width: 600px) {
                transform: scale(0.8);
                margin-top: -10px;
            }

            span {
                font-size: 16px;
                font-weight: bold;
            }

            button {
                -webkit-appearance: none;
                width: 56px;
                height: 56px;
                border-radius: 51.7px;
                background-color: $buttColour;
                border: 2px solid white;
                background-size: 46%;
                background-repeat: no-repeat;
                background-position: center 46%;
                outline: none;
                transition: all 0.3s;
                overflow: hidden;
                margin: 10px 0 5px 0;

                @media all and (max-width: 600px) {
                    margin-top: 0;
                }

                &.next {
                    background-image: url(../images/icons/icon-close-light.svg);
                    background-size: 46%;
                    background-position: 50% 50%;
                }

                &:focus {
                    outline: none !important;
                }

                @media only screen and (max-width: 1300px) {
                    width: 50px;
                    height: 50px;
                }
            }

            display: flex;
            flex-direction: column;
            margin-left: auto;
            align-items: center;

            &:hover {
                cursor: pointer;
                animation-duration: 0.3s;
                animation-fill-mode: both;
                animation-name: pulseBig;
            }
        }
    }

    .evidence-sub-header {
        padding: 20px;
        //display: flex;
        //align-items: flex-end;

        /*img {
            width: 180px;
            margin: 15px 20px 0 15px;
        }

        h2 {
            margin-bottom: -5px;
        }*/

        @media all and (max-width: 600px) {
            padding: 20px 0;
        }

        .evidence-note {
            margin-top: 10px;
            padding: 20px;
            background: $buttBgColourLightYellow;
            border-radius: 20px;
            font-size: $font14;
        }
    }

    .header-icon {
        margin-right: 10px;
        margin-left: 15px;
        width: 64px;
        min-width: 64px;
        height: 64px;
        border-radius: 32px;
        background-repeat: no-repeat;
        background-position-x: 50%;
        background-position-y: center;

        @media all and (max-width: 600px) {
            margin-left: 0;
            width: 50px;
            height: 50px;
            min-width: 50px;
        }

        &.icon-work {
            background-color: $listDotColourYellow;
            background-image: url(../images/icons/icon-work.svg);
        }

        &.icon-life {
            background-color: #ee9a95;
            background-image: url(../images/icons/icon-life.svg);
        }

        &.icon-strengths {
            background-color: $bgColourThree;
            background-image: url(../images/icons/icon-strengths.svg);
        }

        &.icon-career {
            background-color: $bgColourEight;
            background-image: url(../images/icons/icon-career.svg);
        }

        &.icon-training {
            background-color: #86cc88;
            background-image: url(../images/icons/icon-training.svg);
        }
    }

    .evidence-mapping-empty-container {
        display: flex;
        flex-direction: column;
        align-items: center;
        color: $fontColourDark;
        margin: 85px 0;

        img {
            width: 30px;
            margin-bottom: 10px;
        }

        span {
            font-size: $font18;
        }
    }

    .evidence-mapping-container {
        color: $fontColourDark;
        padding: 20px;

        @media all and (max-width: 600px) {
            padding: 20px 0;
        }

        .mapping-area {
            margin-bottom: 40px;
        }

        .mapping-heading {
            font-size: 24px;
            border-radius: 5px;
            padding: 10px 20px;
            background-color: #f4f5f6;
            margin-top: 0;
            margin-bottom: 36px;

            @media only screen and (max-width: 1300px) {
                font-size: 20px;
            }

            @media all and (max-width: 600px) {
                margin-bottom: 20px;
            }
        }

        .mapping-heading:first-child {
            //margin-top: 20px;
        }

        h3 {
            font-size: 20px;
        }

        ul {
            padding-left: 20px;
        }

        li::marker {
            color: $listDotColourBlue;
        }

        li {
            margin-bottom: 15px;
            line-height: 22px;
        }

        p {
            line-height: 150%;
        }

        .mapping-body {
            padding: 0 12px;

            @media all and (max-width: 600px) {
                padding: 0;
            }

            p:has(+ul) { // P before a UL
                background-color: $bgColourOne;
                border-top-left-radius: 20px;
                border-top-right-radius: 20px;
                margin-bottom: 0;
                padding: 20px 20px 10px;
            }

            p ~ ul { // UL after a P
                background-color: $bgColourOne;
                border-bottom-left-radius: 20px;
                border-bottom-right-radius: 20px;
                margin: 0;
                padding: 0 40px 10px 40px;
            }

            .strength {
                background-color: $bgColourOne;
                border-radius: 20px;
                padding: 20px;
                margin-bottom: 30px;
                display: flex;
                align-items: center;

                @media all and (max-width: 600px) {
                    //flex-direction: column;
                    align-items: flex-start;
                }

                .number {
                    font-size: $font30;
                    font-weight: bold;
                    color: $listDotColourBlue;
                    margin-right: 20px;
                }

                h3 {
                    font-size: $font16;
                    margin: 0;
                }

                .strength-detail {
                    p {
                        margin: 10px 0 0 0;
                    }
                }

                .image-wrap {
                    width: 100px;

                    @media all and (max-width: 600px) {
                        width: 50px;
                        dislay: none;
                    }
                    
                    img {
                        width: 100px;
                        height: 100px;
                        object-fit: contain;

                        @media all and (max-width: 600px) {
                            width: 50px;
                            height: 50px;
                        }
                    }

                    @media all and (max-width: 600px) {
                        text-align: center;
                        margin-bottom: 10px;
                    }
                }

            }

            &.sensory {
                display: flex;

                @media all and (max-width: 600px) {
                    //flex-direction: column;
                }

                .body-wrap {
                    margin-left: 20px;

                    @media all and (max-width: 600px) {
                        margin-left: 0;
                        padding: 0 10px;
                    }
                }

                .image-wrap {

                    img {
                        width: 100px;
                        margin: 20px;
                    }

                    @media all and (max-width: 600px) {
                        //text-align: center;
                        display: none;

                        img {
                            width: 50px;
                            margin-left: 0;
                        }

                    }

                }

                

            }

            .mapping-answer {
                margin-bottom: 50px;

                &.postschool {
                    display: flex;

                    @media all and (max-width: 600px) {
                        flex-direction: column;
                    }
    
                    .body-wrap {
                        margin-left: 20px;

                        @media all and (max-width: 600px) {
                            margin-left: 0;
                            padding: 0 20px;
                        }
                    }

                    .image-wrap {

                        @media all and (max-width: 600px) {
                            //text-align: center;
                            display: none;
                        }

                    }
    
                    img {
                        width: 100px;
                        margin: 20px;
                    }
    
                }
            }
        }
    }
}