.quiz-results-wrap {
    width: 1200px;
    //padding: 0 20px; 
    //padding-top: 50px;
    //padding-bottom: 50px;

    @media all and (max-width: 1300px) {
        width: 100vw;
        padding: 0 30px;

        &.work, &.training, &.career, &.life {
            //padding: 0; 
        }
    }

    @media all and (max-width: 800px) {
        padding: 0 20px;
    }

    h1 {
        text-align: center;
    }

    .title-section {
        display: flex;
        flex: 1;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        align-items: flex-start;

        .side-back {
            width: 180px;
        }

        h1 {
            flex: 1;
            width: 100%;
        }

        .main-title {
            //width: calc(100% - 360px);
            width: 100%;
        }

        @media all and (max-width: 800px) {
            padding-top: 20px;

            .butt-general {
                width: 42px;
                padding-left: 5px;

                .butt-icon {
                    min-width: 32px;
                }
                .title { 
                    display: none;
                }
            }

            .side-back {
                width: 42px;
            }
        }
    }

    .details-wrap {
        color: $fontColourDark;
        width: 64%;
        margin: 0 auto;
        padding: 40px 0 0;
        text-align: center;

        p {
            margin: 0;
        }

        @media all and (max-width: 1300px) {
            width: 80%;
        }
    }

    .no-results {
        text-align: center;
        padding: 100px 0;
        color: $fontColourDark;
    }

    .result-options-wrap {
        overflow: hidden;
        //padding: 50px 0;
        margin: 30px 0 50px;

            .result-content {
                flex: 1;
                max-width: 1200px;
                display: flex;
                flex-direction: column;

                // result career
                .outer-wrap {
                    padding: 0 !important;
                }

                .content-wrap {
                    flex: 1;
                    display: flex;
                    flex-wrap: wrap;
                    flex-direction: row;
                    justify-content: center;
                    height: 100%;

                    &.work-preferences {
                        //justify-content: space-around;
                    }

                    ul {
                        color: $fontColourDark;
                        margin: 20px 0 20px;
                        line-height: 2.2em;
                        padding: 0 25px;
                        list-style: none;
                        background: transparent !important;

                        li::before {
                            content: "\2022";
                            font-weight: 900;
                            display: inline-block;
                            width: $font16;
                            margin-left: -$font16;
                        }

                        &.work, &.list-node-yellow{
                            li::before {
                                color: $listDotColourYellow;
                            }
                        }

                        &.life {
                            li::before {
                                color: $listDotColourPink;
                            }
                        }

                        &.training {
                            li::before {
                                color: $listDotColourGreen;
                            }
                        }

                        &.strengths {
                            li::before {
                                color: $listDotColourPurple;
                            }
                        }

                        &.career {
                            li::before {
                                color: $listDotColourBlue;
                            }
                        }
                    }


                    // title bar of a block box in different Colors
                    .title-bar-grey, .Organised {
                        background-color: $bgColourFourteen;
                    }

                    .title-bar-circle-grey, .Organised-circle {
                        background-color: $listDotColourGrey;
                    }

                    .title-bar-pink, .Helping, .life {
                        background-color: $bgColourTen;
                    }

                    .title-bar-circle-pink, .Helping-circle {
                        background-color: $listDotColourPink;
                    }

                    .title-bar-yellow, .Artistic, .work  {
                        background-color: $bgColourEleven;
                    }

                    .title-bar-circle-yellow, .Artistic-circle {
                        background-color: $listDotColourYellow;
                    }

                    .title-bar-blue, .Practical, .career {
                        background-color: $bgColourTwelve;
                    }

                    .title-bar-circle-blue, .Practical-circle {
                        background-color: $listDotColourBlue;
                    }

                    .title-bar-purple, .Investigative, .strengths {
                        background-color: $bgColourThirteen;
                    }

                    .title-bar-circle-purple, .Investigative-circle {
                        background-color: $listDotColourPurple;
                    }

                    .title-bar-green, .Enterprising, .training {
                        background-color: $bgColourNine;
                    }

                    .title-bar-circle-green, .Enterprising-circle {
                        background-color: $listDotColourGreen;
                    }

                    .icon-butt {
                        float: right;
                        border-radius: 50%;
                        width: 40px;
                        height: 40px;
                        background-color: aliceblue;
                        line-height: 40px;
                        font-weight: bold;
                        min-width: 40px;

                        &.career {
                            background-color: $listDotColourBlue;
                            background-image: url(../images/icons/icon-career.svg);
                            background-repeat: no-repeat !important;
                            background-size: 26px !important;
                            background-position: 7px !important;
                        }

                        &.strengths {
                            background-color: $listDotColourPurple;
                            background-image: url(../images/icons/icon-strengths.svg);
                            background-repeat: no-repeat !important;
                            background-size: 26px !important;
                            background-position: 7px !important;
                        }

                        &.work {
                            background-color: $listDotColourYellow;
                            background-image: url(../images/icons/icon-work.svg);
                            background-repeat: no-repeat !important;
                            background-size: 26px !important;
                            background-position: 7px !important;
                        }

                        &.training {
                            background-color: $listDotColourGreen;
                            background-image: url(../images/icons/icon-training.svg);
                            background-repeat: no-repeat !important;
                            background-size: 26px !important;
                            background-position: 7px !important;
                        }

                        &.life {
                            background-color: $listDotColourPink;
                            background-image: url(../images/icons/icon-life.svg);
                            background-repeat: no-repeat !important;
                            background-size: 26px !important;
                            background-position: 7px !important;
                        }
                    }

                    //padding: 0 0 0 50px;
                    //margin-top: -20px;

                    // result career
                    .inner-content-wrap {
                        flex: 1;
                        display: flex;
                        flex-wrap: wrap;
                        flex-direction: row;
                        height: 100%;

                        .person {
                            width: 185px;
                            height: auto;
                            mask-image: url('../images/mask-bg.svg');
                            mask-mode: alpha;
                            mask-repeat: no-repeat;
                            mask-size: contain;
                            mask-position: center;
                            max-height: 380px;
                            position: relative;

                            .fill {
                                width: 100%;

                                &.Practical {
                                    background: $listDotColourBlue;
                                }

                                &.Investigative {
                                    background: $listDotColourPurple;
                                }

                                &.Helping {
                                    background: $listDotColourPink;
                                }

                                &.Organised {
                                    background: $listDotColourGrey;
                                }

                                &.Artistic {
                                    background: $listDotColourYellow;
                                }

                                &.Enterprising {
                                    background: $listDotColourGreen;
                                }

                            }

                            @media all and (max-width: 800px) {
                                display: none;
                            }
                        }

                        img {
                            position: absolute;
                            top: 0;
                            left: 0;
                            width: 100%;
                            height: 100%;
                        }

                        .result-container-wrap, .result-content-wrap {
                            flex: 1;
                            display: flex;
                            flex-wrap: wrap;
                            height: 100%;
                            justify-content: center;
                        }

                        .result-container-wrap {
                            flex-direction: column;

                            .button-wrap {
                                display: flex;
                                justify-content: center;
                                margin-top: 50px;
                                flex-direction: row-reverse;

                                .butt-dashboard {
                                    position: absolute;
                                    left: 30px;

                                    @media all and (max-width: 800px) {
                                        left: 20px;
                                        width: 42px;

                                        .title {
                                            display: none;
                                        }

                                        .butt-icon {
                                            min-width: 32px;
                                        }
                                    }
                                }
                            }
                        }

                        .result-content-wrap {
                            flex-direction: row;
                        }
                    }

                    // result life-skills, result-training, work-preferences
                    .result, .learner-content {
                        background: #fff;
                        border-radius: 37.5px 37.5px 16px 16px ;
                        padding: 10px;
                        color: $fontColourDark;
                        text-align: left;
                        // margin: 20px 18px 30px 0;
                        margin: 15px;
                        height: auto;
                        overflow: hidden;
                        text-decoration: none;
                        min-width: 220px;

                        .header {
                            display: flex;
                            justify-content: center;
                            align-items: center;
                            min-height: 54px;
                            border-radius: 30px;
                            padding: 0 20px;
                            text-align: center;
                            height: auto;
                        }

                        h2 {
                            margin: 0;
                            font-size: $font16;
                            margin: 20px 0;
                        }

                        p {
                            padding: 0 10px;
                        }
                    }

                    .transparent-box {
                        display: flex;
                        flex-direction: column;
                        justify-content: center;
                        align-items: center;
                        background-color: transparent;
                        height: auto;
                        padding: 0;
                        border-radius: 0;
                        text-align: center;

                        h4 {
                            text-align: center;
                            height: auto;
                            padding: 0;
                        }

                        button {
                            margin: 10px 0 15px;
                        }

                        #strat-goals, #edit-actions > .butt-general {
                            margin: 10px 0;
                        }
                    }

                    .training-box {
                        width: 17.5%;
                        margin: 10px;

                        p {
                            max-height: 200px;
                            overflow: hidden;
                        }

                        .more {
                            padding: 10px;
                        }

                        @media screen and (max-width:1024px) {
                            width: 30%;
                        }

                        @media all and (max-width: 800px) {
                            width: 100%;
                        }
                    }

                    .life-skill-box {
                        width: 30%;
                    }

                    .content-box {
                        height: 100%;
                        overflow: visible;
                        width: 25%;
                        margin: 15px;

                        @media screen and (max-width:768px) {
                            width: 30%;
                        }

                    }

                    .learner-box {
                        width: 64%;
                        margin: auto;

                        @media all and (max-width: 800px) {
                            width: 100%;
                        }

                        .learner-content {
                            height: auto;
                        }
                    }

                    .career-exploration-box {
                        width: 30%;
                        // height: 100%;
                        margin: 10px !important;

                        &:hover {
                            cursor: pointer;
                            animation-duration: 0.3s;
                            animation-fill-mode: both;
                            animation-name: pulse;
                        }

                        .link {
                            color: $bgColourFour;
                            font-weight: 600;
                            font-size: 0.9em;
                            text-align: left;
                            padding: 0 10px;
                        }

                        @media screen and (max-width: 768px) {
                            width: auto;
                        }
                    }

                    .career-exploration-bar {
                        width: 25%;
                        border-radius: 35px;
                        min-height: 70px;

                        &:hover {
                            cursor: pointer;
                            animation-duration: 0.3s;
                            animation-fill-mode: both;
                            animation-name: pulse;
                        }

                        .link {
                            color: $bgColourFour;
                            font-weight: 600;
                            font-size: 0.9em;
                            text-align: left;
                            padding: 10px 10px 0;
                        }

                        @media screen and (max-width: 1031px) {
                            margin: 10px !important;
                        }
                    }

                    .my-results-box {
                        width: 30%;

                        .my-results-icon {
                            width: auto;
                            height: auto;
                            background-color: transparent;
                            padding: 0;
                            margin: 0;
                        }

                        @media screen and (max-width: 768px) {
                            width: 40%;
                        }

                        @media all and (max-width: 767px) {
                            width: 100%;
                            margin-top: 0;
                        }
                    }

                    .work-preferences-box {
                        //margin: 0 !important;
                        max-width: 250px;
                        margin: 10px;

                        @media all and (max-width: 800px) {
                            width: 100%;
                            max-width: 100%;
                        }
                    }

                    // result result-career
                    .percentage-circle {
                        text-align: center;
                        height: 50px;
                        width: 100%;
                        padding: 0 6px 0 15px;
                        border-radius: 30px;
                        margin: 0;
                        line-height: 30px;
                        display: flex;
                        justify-content: space-between;
                        align-items: center;
                        line-height: $font16;

                        h2 {
                            margin: 0;
                            padding: 0;
                            text-align: left;
                            font-size: $font16;
                        }

                        span {
                            float: right;
                            border-radius: 50%;
                            width: 40px;
                            height: 40px;
                            color: $fontColourDark;
                            line-height: 40px;
                            font-weight: bold;
                            font-size: $font14;
                        }

                        a {
                            font-weight: normal;
                            font-size: 0.6rem;
                            color: $fontColourDark;
                        }
                    }
                }

        }
        .visible-options {
            width: 200%;
            display: flex;
            justify-content: space-evenly;
            align-items: self-start;
            transition: all 1s ease-in-out;

            @media all and (max-width: 767px) {
                width: 100%;
            }

            &.scroll {
                transform: translateX(-50%);
            }

            &.unlimited {
                width: auto;
                display: block;
                white-space: nowrap;
                text-align: center;

                p {
                    margin: 0;
                    max-height: 98px;
                    overflow: hidden;
                }

                @media all and (max-width: 767px) {
                    white-space: normal;
                }
            }

            .three-wrap {
                white-space: initial;
                display: inline-flex;
                justify-content: space-evenly;
                width: 100%;
                vertical-align: bottom;

                @media all and (max-width: 767px) {
                    flex-wrap: wrap;
                }
            }

            &.mobile {
                display: none;

                @media all and (max-width: 767px) {
                    display: block;
                }
            }
        }

        .result-option {
            width: 30%;
            background: $lightColour;
            border-radius: 46px 46px 16px 16px;
            padding: 10px;
            color: $fontColourDark;
            text-align: center;
            margin: 0 10px;
            height: 250px;
            max-height: 250px;
            max-width: 340px;
            overflow: hidden;
            text-decoration: none;
            display: inline-block;
            white-space: initial;
            display: flex;
            flex-direction: column;
            justify-content: space-between;

            @media all and (max-width: 1300px) {
                max-height: 270px;
                height: 270px;
            }

            @media all and (max-width: 767px) {
                width: 100%;
                max-width: 100%;
                margin: 10px 0;
                height: fit-content;
            }

            .title {
                min-height: 74px;
                background: $bgColourFive;
                border-radius: 74px;
                display: flex;
                justify-content: center;
                align-items: center;
                padding: 0 20px;

                h2 {
                    font-size: 1.06em;
                    color: $fontColourDark;
                    margin: 0;
                    padding: 10px 0;
                }
            }

            .blurb {
                padding: 20px 10px;
                font-size: 0.9em;
                text-align: left;
                max-height: 135px;
                overflow: hidden;
            }

            .link {
                color: $bgColourFour;
                font-weight: 600;
                font-size: 0.9em;
                text-align: left;
                padding: 0 10px;
            }

			&.life-skills {
				min-width:350px;
				max-height:400px;
				height:400px;
				display:none;

				&.on { display:block; }
			}

            &:hover {
                cursor: pointer;
                animation-duration: 0.5s;
                animation-fill-mode: both;
                animation-name: pulse;
            }
        }

    }

    .result-controls {
        display: flex;
        justify-content: center;
        align-items: center;
        position: relative;
        margin: auto;
       // height: 52px;
        flex-direction: column;
        margin-bottom: 50px;

        .butt-scroll {
            margin: 0 10px 30px 10px;

            @media all and (max-width: 768px) {
                display: none;
            }
        }

        .butt-update {
           // width: 220px;
            //position: absolute;
            //right: 0;
        }

        .butt-dashboard {
            //width: 220px;
           // position: absolute;
            //right: 0;
        }

        .button-wrap {
            .butt-dashboard {
                position: absolute;
                left: 20px;

                @media all and (max-width: 800px) {
                    //left: 20px;
                    left: 0;
                    width: 42px;

                    .title {
                        display: none;
                    }

                    .butt-icon {
                        min-width: 32px;
                    }
                }
            }
        }

    }

    #training {
        /*width: 62%;
        @media screen and (max-width:768px) {
            width: 70%;
        }*/
    }

    #work-preferences {
        width: 100%;
        padding: 30px 10px;
        margin-top: 30px;
        justify-content: flex-end;

        .butt-update {
            width: 180px;
        }
    }

    #life-skill-controls {
        width: calc(90% + 60px);
        top: 20px;
        #life-skill {
            width: 180px;
        }
    }

    #career-exploration {
        width: 180px;
        margin: 40px auto 0 auto;

        .butt-update {
            margin-top: 20px;
            width: 180px;
        }
    }

    .butt-start-goals {
        background-image: url(../images/icons/icon-start.svg);
        background-repeat: no-repeat !important;
    }

    .butt-export {
        background-image: url(../images/icons/icon-upload-blue.svg);
        background-repeat: no-repeat !important;
    }
}

.quiz-complete-wrap {
    width: 1200px;
    //padding: 0 20px;
    padding-top: 50px;
    padding-bottom: 50px;

    @media all and (max-width: 1300px) {
        width: 100vw;
        padding: 50px;
    }

    .quiz-complete-box {
        background: $lightColour;
        border-radius: 50px;
        text-align: center;
        max-width: 67%;
        width: 67%;
        padding: 50px;
        box-shadow: 2px 2px 40px 1px rgba(36,58,71,0.25);
        margin: 0 auto;
        margin-top: 50px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;

        background-image: url(../images/confetti.svg);
        background-repeat: no-repeat;
        background-position: center 0;
        background-size: 100% auto;

        @media all and (max-width: 1300px) {
            max-width: 80%;
            width: 80%;
        }

        @media all and (max-width: 768px) {
            max-width: 100%;
            width: 100%;
        }

        h1 {
            margin: 10px;
        }

        .complete-content-wrap {
            display: flex;
            flex: 1;
            flex-direction: row;

            .complete-content {
                width: 50%;
                padding: 15px;

                p {
                    color: $fontColourDark;
                    text-align: left;
                }

                a {
                    float: left;
                    margin: 15px 0;
                }

                img {
                    width: 100%;
                }
            }
        }
    }
}