footer {
    height: $footerHeight;
    display: flex;
    justify-content: space-between;
    align-items: center;
    max-width: 1200px;
    width: 1200px;
    margin: 0 auto;
    font-size: 0.8em;
    color: $fontColourDark;

    .style-switch {
        flex: 1;
        display: flex;
        justify-content: flex-start;
        align-items: center;
        margin: 0 30px;

        @media all and (max-width: 800px) {
            margin-bottom: 20px;
        }
    }

    ul {
        list-style: none;
        padding: 0;
        display: flex;
        margin: 0!important;

        li {
            padding-left: 20px;

            a {
                font-size: 0.9em;
                text-transform: uppercase;
                text-decoration: none;
                color: $fontColourDark;
                font-weight: 600;
                transition: all 0.2s;

                &:hover {
                    color: $fontColourLight;
                }
            }
        }

        &.mobile-footer-menu {
            display: none;
            margin: 0;

            @media all and (max-width: 767px) {
                display: block;
            }
        }
    }

    .desktop-menu {
        display: flex;

        @media all and (max-width: 800px) {
            flex-wrap: wrap;
            justify-content: center;
            align-items: center;
            
            li {
                margin: 5px;
                padding: 0;
            }
        }
    }

    @media all and (max-width: 1300px) {
        width: 100%;
        padding: 0 50px;
    }

    @media all and (max-width: 800px) {
        flex-direction: column;
        height: fit-content;
        padding-bottom: 20px;

        p {
            margin: 0;
        }
    }

    @media all and (max-width: 767px) {
        padding: 0 20px 20px;

        li {
            padding-left: 0;
        }
    }

}

.post-footer {
    background: white;
    color: $fontColourDark;
    height: 300px;
    font-size: 0.9em;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;

    .logos {
        height: 80px;
        display: flex;
        justify-content: center;
        align-items: center;

        img {
            height: 80px;

            &:nth-child(2) {
                height: 60px;
                margin-left: 50px;
            }
        }
    }

    @media all and (max-width: 800px) {
        justify-content: flex-start;
        padding: 20px;
        height: fit-content;

        .logos {
            flex-direction: column;
            //height: 160px;
            justify-content: flex-start;

            img {

                &:nth-child(2) {
                    margin: 0;
                }
            }
        }
    }


}