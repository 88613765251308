@keyframes pulse {
    0% {
        transform: translateZ(0) scale3d(1, 1, 1);
    }
    50% {
        transform: translateZ(0) scale3d(1.02, 1.02, 1.02);
    }
    100% {
        transform: translateZ(0) scale3d(1, 1, 1);
    }
}

@keyframes pulseBig {
    0% {
        transform: translateZ(0) scale3d(1, 1, 1);
    }
    50% {
        transform: translateZ(0) scale3d(1.08, 1.08, 1.08);
    }
    100% {
        transform: translateZ(0) scale3d(1, 1, 1);
    }
}

@keyframes pulseBigger {
    0% {
        transform: translateZ(0) scale3d(1, 1, 1);
    }
    50% {
        transform: translateZ(0) scale3d(1.2, 1.2, 1.2);
    }
    100% {
        transform: translateZ(0) scale3d(1, 1, 1);
    }
}

@keyframes jiggle {
    0% {
        transform: translateZ(0) scale3d(1, 1, 1);
    }
    10%,
    20% {
        transform: translateZ(0) scale3d(.9, .9, .9) rotate3d(0, 0, 1, -3deg);
    }
    30%,
    50%,
    70%,
    90% {
        transform: translateZ(0) scale3d(1.1, 1.1, 1.1) rotate3d(0, 0, 1, 3deg);
    }
    40%,
    60%,
    80% {
        transform: translateZ(0) scale3d(1.1, 1.1, 1.1) rotate3d(0, 0, 1, -3deg);
    }
    100% {
        transform: translateZ(0) scale3d(1, 1, 1);
    }
}

@keyframes bouncey {
    0%, 100%, 20%, 53%, 80% {
      transition-timing-function: cubic-bezier(0.215, .61, .355, 1);
      transform: translate3d(0, 0, 0);
    }
    40%,
    43% {
      transition-timing-function: cubic-bezier(0.755, .050, .855, .060);
      transform: translate3d(0, -7px, 0);
    }
    70% {
      transition-timing-function: cubic-bezier(0.755, .050, .855, .060);
      transform: translate3d(0, -4px, 0);
    }
    90% {
      transform: translate3d(0, -2px, 0);
    }
}

@keyframes shake {
    0%, 100% {
      transform: translate3d(0, 0, 0);
    }
    10%,
    30%,
    50%,
    70%,
    90% {
      transform: translate3d(-3px, 0, 0);
    }
    20%,
    40%,
    60%,
    80% {
      transform: translate3d(3px, 0, 0);
    }
  }

